import React, { Component } from "react";
import { Button } from "reactstrap";
import LazyImage from "../Common/LazyImage";
import FinishButton from "./FinishButton";

class SlideEnd extends Component {
    render() {
        return (
            <div className="onboarding__content">
                <div className="row text-center align-items-center justify-content-center py-5 mb-3">
                    <div
                        className="col-12 col-md-auto"
                        style={{ maxWidth: 100 + "%" }}>
                        <div className="row text-left">
                            <div className="col-12 col-md-6 order-last order-md-first d-flex align-items-center">
                                <div className="row text-center">
                                    <div className="col-12 col-md-10 offset-md-1">
                                        <h2 className="col-12">
                                            {this.props.title}
                                        </h2>

                                        <div className="col-12">
                                            {this.props.text}
                                        </div>

                                        <div className="col-12 mt-3">
                                            <Button
                                                color="secondary"
                                                onClick={
                                                    this.props.slidePrevious
                                                }
                                                outline>
                                                Forrige
                                            </Button>
                                            <FinishButton
                                                href={`/gruppebestilling/group/${
                                                    this.props.groupId
                                                }/products`}
                                                className="btn btn-success px-4 mx-2">
                                                Ferdig
                                            </FinishButton>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.props.image ? (
                                <div className="col-12 col-md-6 order-first order-md-last mb-3 mb-md-0">
                                    <LazyImage
                                        src={this.props.image.src}
                                        alt={this.props.image.alt}
                                        width={540}
                                        height={335}
                                        className="img-fluid"
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SlideEnd;
