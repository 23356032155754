import { GroupHelper, GroupsHelper, StatusHelper } from "../Helpers";
let assign = Object.assign || require("object.assign");

const DEFAULT_STATE = {
    group: {},
    groupPosting: null,
    groupFetching: null,
    groupBackgroundFetching: null,
    groupError: null,

    groupUpdate: null,
    groupUpdateType: null,

    groupOrder: {},
    groupOrderFetching: null,
    groupOrderError: null,

    createGroupPosting: null,
    createGroupError: null,

    settings: [],
    settingsFetching: null,
    settingsError: null,
};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case "RESET_GROUP":
            // Resets all group related state to initial state
            return assign({}, DEFAULT_STATE);

        case "UPDATE_GROUP":
            return assign({}, state, {
                groupUpdate: action.payload,
                groupUpdateType: action.orderState,
            });
        case "SET_GROUP":
            return assign({}, state, {
                group: action.payload,
                groupFetching: false,
            });

        case "SET_GROUP_FETCHING":
            return assign({}, state, {
                groupFetching: action.payload,
                groupError: null,
            });

        case "SET_GROUP_ERROR":
            return assign({}, state, {
                groupError: action.payload,
                group: null,
                groupFetching: null,
            });

        case "SET_GROUP_SHOP":
            action.payload = GroupHelper.handleGroupData(action.payload);

            return assign({}, state, {
                group: action.payload,
                groupPosting: false,
            });

        case "SET_GROUP_SHOP_POSTING":
            return assign({}, state, {
                groupPosting: action.payload,
                groupError: null,
            });

        case "SET_GROUP_SHOP_ERROR":
            return assign({}, state, {
                groupError: action.payload,
                group: null,
                groupPosting: null,
            });

        case "GET_GROUP_BACKGROUND_FETCHING":
            return assign({}, state, {
                groupBackgroundFetching: action.payload,
                groupError: null,
            });

        case "GET_GROUP_BACKGROUND":
            return assign({}, state, {
                groupBackgroundFetching: null,
            });

        case "GET_GROUP":
            // Find settings name object and set the name as the "name" prop on the group object
            action.payload = GroupHelper.handleGroupData(action.payload);

            return assign({}, state, {
                group: action.payload,
                groupFetching: false,
            });

        case "GET_GROUP_FETCHING":
            return assign({}, state, {
                groupFetching: action.payload,
                groupError: null,
            });

        case "GET_GROUP_ERROR":
            return assign({}, state, {
                groupError: action.payload,
                group: null,
                groupFetching: null,
            });

        case "SET_GROUP_DEADLINE":
        case "SET_GROUP_STATUS":
            // Find settings name object and set the name as the "name" prop on the group object
            // Replace old order with new order response
            let newOrders = state.group.orders.filter(
                o => o.id !== action.payload.id
            );
            newOrders.push(action.payload);
            state.group.orders = newOrders;

            // Set humanFriendlyState for all orders
            state.group.orders.forEach(order => {
                order.humanFriendlyState = StatusHelper.getHumanFriendlyState(
                    order.state
                );
            });

            // Put human readable status in a status object
            const currentOrder = GroupHelper.getCurrentOrder(state.group);

            state.group.status = GroupsHelper.getStatus(
                currentOrder.humanFriendlyState
            );

            return assign({}, state, {
                group: state.group,
                groupFetching: false,
            });

        case "SET_GROUP_DEADLINE_POSTING":
        case "SET_GROUP_STATUS_POSTING":
            return assign({}, state, {
                groupFetching: action.payload,
                groupError: null,
            });

        case "SET_GROUP_DEADLINE_ERROR":
        case "SET_GROUP_STATUS_ERROR":
            return assign({}, state, {
                groupError: action.payload,
                group: null,
                groupFetching: null,
            });

        case "CREATE_GROUP":
            return assign({}, state, {
                createGroupPosting: false,
            });

        case "CREATE_GROUP_POSTING":
            return assign({}, state, {
                createGroupPosting: action.payload,
                createGroupError: null,
            });

        case "CREATE_GROUP_ERROR":
            return assign({}, state, {
                createGroupError: action.payload,
                createGroupPosting: null,
            });

        case "DEMOTE_CUSTOMER":
        case "PROMOTE_CUSTOMER":
            action.payload = GroupHelper.handleGroupData(action.payload);

            return assign({}, state, {
                group: action.payload,
                groupFetching: false,
            });

        case "DEMOTE_CUSTOMER_POSTING":
        case "PROMOTE_CUSTOMER_POSTING":
            return assign({}, state, {
                groupFetching: action.payload,
                groupError: null,
            });

        case "DEMOTE_CUSTOMER_ERROR":
        case "PROMOTE_CUSTOMER_ERROR":
            return assign({}, state, {
                groupError: action.payload,
                group: null,
                groupFetching: null,
            });

        case "GET_SETTINGS":
        case "SET_SETTINGS":
            // Set group settings
            state.group.settings = action.payload;

            return assign({}, state, {
                settings: action.payload,
                settingsFetching: false,
            });

        case "GET_SETTINGS_FETCHING":
        case "SET_SETTINGS_POSTING":
            return assign({}, state, {
                settingsFetching: action.payload,
                settingsError: null,
            });

        case "GET_SETTINGS_ERROR":
        case "SET_SETTINGS_ERROR":
            return assign({}, state, {
                settingsError: action.payload,
                settings: null,
                settingsFetching: null,
            });

        case "GET_GROUP_ORDER":
            action.payload = GroupHelper.handleOrderData(action.payload);

            return assign({}, state, {
                groupOrder: action.payload,
                groupOrderFetching: false,
            });

        case "GET_GROUP_ORDER_FETCHING":
            return assign({}, state, {
                groupOrderFetching: action.payload,
                groupOrderError: null,
            });

        case "GET_GROUP_ORDER_ERROR":
            return assign({}, state, {
                groupOrderError: action.payload,
                groupOrder: null,
                groupOrderFetching: null,
            });

        default:
            return state;
    }
}
