import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { removeAddition, addAddition } from "../../Actions/AdditionsActions";
import Product from "../Products/Product";
import { GroupHelper } from "../../Helpers";

class AdditionItem extends Product {
    constructor(props) {
        super(props);

        this.state = {};

        this.removeAddition = this.removeAddition.bind(this);
        this.addAddition = this.addAddition.bind(this);
    }

    removeAddition() {
        const { product } = this.props;

        this.props.removeAddition({
            orderAdditionId: product.id,
            groupId: this.props.group.group.id,
        });
    }

    addAddition() {
        const { product } = this.props;

        this.props.addAddition({
            groupId: this.props.group.group.id,
            id: product.id,
            price: this.productPrice.value,
        });
    }

    render() {
        const { product } = this.props;
        return (
            <div className="col-12">
                <div className="card mb-2">
                    <div className="card-body p-3">
                        <div className="row align-items-center">
                            <div className="col col-md-8">
                                <strong>{product.name}</strong>
                            </div>

                            <div className="col-auto col-md d-flex">
                                {this.props.inCart ? (
                                    <span className="price">
                                        {product.price},-
                                    </span>
                                ) : (
                                    <>
                                        <input
                                            type="number"
                                            className="form-control"
                                            defaultValue={product.price}
                                            ref={el => (this.productPrice = el)}
                                        />
                                        <span className="price ml-2">,-</span>
                                    </>
                                )}
                            </div>

                            <div className="col-12 mt-4 mt-md-0 col-md text-right">
                                <Button
                                    onClick={
                                        this.props.inCart
                                            ? this.removeAddition
                                            : this.addAddition
                                    }
                                    color={
                                        this.props.inCart ? "danger" : "primary"
                                    }
                                    disabled={GroupHelper.disableWhenLocked(
                                        this.props,
                                        ["Seller"]
                                    )}>
                                    {this.props.inCart
                                        ? "Slett tillegg"
                                        : "Legg til tillegg"}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AdditionItem.propTypes = {
    inCart: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

AdditionItem.defaultProps = {
    onClick: () => null,
};

function mapStateToProps(state) {
    return {
        additions: state.additions,
    };
}
export default connect(
    mapStateToProps,
    {
        addAddition,
        removeAddition,
    }
)(AdditionItem);
