import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import Restrict from "../../Auth/Restrict";

// Helpers
import { Seller } from "../../Auth/UserRoles";
import { DateHelper } from "../../Helpers";

class Notification extends Component {
    render() {
        return (
            <div
                className={`notice row ${
                    this.props.index && this.props.index > 0 ? "mt-3" : ""
                }`}>
                <div className="col-12 d-flex align-items-center mb-2">
                    <p className="text-black-50 m-0">
                        {DateHelper.toReadablestring(
                            this.props.notification.createdAt
                        )}{" "}
                        av {this.props.notification.senderName}
                    </p>

                    <Restrict {...this.props} allow={Seller}>
                        <Button
                            onClick={() =>
                                this.props.editNotification(
                                    this.props.notification
                                )
                            }
                            className="ml-2"
                            size="sm"
                            color="secondary"
                            outline>
                            Rediger notis
                        </Button>
                    </Restrict>
                </div>

                <div className="col-12 col-lg-10">
                    {this.props.notification.message}
                </div>
            </div>
        );
    }
}

Notification.propTypes = {
    notification: PropTypes.object.isRequired,
};

Notification.defaultProps = {};

export default Notification;
