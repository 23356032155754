import React, { Component } from "react";
import { Link } from "react-router-dom";

// Helpers
import { ProductHelper } from "../Helpers";

export default class UnitNav extends Component {
    render() {
        const { to, state, products } = this.props;

        if (products.productUnitsError) {
            return (
                <div className="col mb-2">
                    <div className="alert alert-danger">
                        Kunne ikke laste enheter...
                    </div>
                </div>
            );
        }
        if (products.productUnitsFetching === true) {
            return <div className="col mb-2">Laster enheter...</div>;
        }
        if (
            state.product &&
            products.productUnitsFetching === false &&
            products.productUnits &&
            products.productUnits.length
        ) {
            const sortedUnits = ProductHelper.sortUnits(products.productUnits);

            return (
                <>
                    <Link
                        to={`${to}${state.product.id}`}
                        className={`btn btn-sm mr-2 mb-4 ${
                            !state.unit ? "btn-dark" : "btn-outline-dark"
                        }`}>
                        Produkt
                    </Link>

                    {sortedUnits.map((unitGroup, i) => (
                        <React.Fragment key={`unitgroup-${i}`}>
                            {unitGroup.units && unitGroup.units.length ? (
                                <Link
                                    to={`${to}${state.product.id}/${
                                        unitGroup.units[0].id
                                    }`}
                                    style={{
                                        minWidth: 45 + "px",
                                    }}
                                    className={`btn btn-sm mr-2 mb-4 ${
                                        state.unit &&
                                        state.unit.id ===
                                            unitGroup.units[0].id &&
                                        this.props.match.params.unitId &&
                                        unitGroup.units[0].id ===
                                            this.props.match.params.unitId
                                            ? "btn-dark"
                                            : "btn-outline-dark"
                                    }`}>
                                    {unitGroup.color.name}
                                </Link>
                            ) : null}
                        </React.Fragment>
                    ))}
                </>
            );
        }

        return null;
    }
}
