import React, { Component } from "react";
import { GroupHelper, DateHelper } from "../../Helpers";

export default class Deadline extends Component {
    getDifference() {
        const currentOrder = GroupHelper.getCurrentOrder(
            this.props.group.group
        );

        if (currentOrder && currentOrder.deadlineAt) {
            let deadlineString;
            let priority;
            let deadline = new Date(currentOrder.deadlineAt);
            let now = new Date();
            let dates = [deadline, now];

            let diffDays = Math.floor(
                (Date.UTC(
                    dates[0].getFullYear(),
                    dates[0].getMonth(),
                    dates[0].getDate()
                ) -
                    Date.UTC(
                        dates[1].getFullYear(),
                        dates[1].getMonth(),
                        dates[1].getDate()
                    )) /
                    (1000 * 60 * 60 * 24)
            );

            if (diffDays === 1) {
                priority = "warning";
                deadlineString =
                    "Deadline for bestilling er i natt klokken 00.00";
            } else if (diffDays < 1) {
                priority = "danger";
                deadlineString = `Deadline for bestilling var ${DateHelper.toReadablestring(
                    currentOrder.deadlineAt,
                    { day: "2-digit", month: "2-digit", year: "numeric" }
                )}, klokken 00.00. Kontakt ordreansvarlig om å levere bestillingen etter fristen.`;
            } else {
                priority = "info";
                deadlineString = `Deadline for bestilling er om ${diffDays} dager`;
            }

            return (
                <div className={`alert mb-0 alert-${priority}`}>
                    {deadlineString}
                </div>
            );
        }

        return null;
    }

    render() {
        return <>{this.getDifference()}</>;
    }
}
