import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import DataLayerApp from "../Pages/DataLayerApp";

/**
 * Authorize
 * HOC that Handles whether or not the user is allowed to see the page.
 * @param {array} allowedRoles - user roles that are allowed to see the page.
 * @returns {Component}
 */

export default function Authorization(allowedRoles) {
    return (WrappedComponent, props) => {
        class WithAuthorization extends Component {
            // Replaces react-router parameters (:param) in a string with actual params
            replaceParams(str) {
                let paths = str.split("/");

                return paths
                    .map(path => {
                        if (
                            path.startsWith(":") &&
                            this.props.match.params[path.substring(1)]
                        ) {
                            path = path.substring(1);
                            path = this.props.match.params[path];
                        }
                        return path;
                    })
                    .join("/");
            }

            renderAuthorized() {
                // Return Error comp or throw Error to ErrorBoundary or PageError
                if (this.props.user.userError) {
                    return null;
                }

                // Return appshell comp
                if (this.props.user.userFetching === true) {
                    return null;
                }

                // Finished fetching data, allowed to access
                if (
                    this.props.user.userFetching === false &&
                    this.props.user.user &&
                    allowedRoles.includes(this.props.user.user.role)
                ) {
                    return <WrappedComponent {...this.props} {...props} />;
                } else {
                    let redirect = "/";

                    if (props && props.redirect) {
                        redirect = this.replaceParams(props.redirect);
                    }

                    return (
                        <Redirect
                            to={{
                                pathname: redirect,
                                state: { from: this.props.location },
                            }}
                        />
                    );
                }
            }

            render() {
                return (
                    <DataLayerApp {...this.props}>
                        {this.renderAuthorized()}
                    </DataLayerApp>
                );
            }
        }
        function mapStateToProps(state) {
            return {
                user: state.user,
            };
        }
        return connect(mapStateToProps)(WithAuthorization);
    };
}
