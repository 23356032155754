export default class LogoHelper {
    static getTextileTypes(typeName) {
        const types = [
            { typeName: "Normal", name: "Normal" },
            { typeName: "Embroidered", name: "Brodert" },
        ];

        const foundType = types.find(o => o.typeName === typeName);

        if (foundType) {
            return foundType;
        } else {
            return types;
        }
    }

    static getDarknessTypes(typeName) {
        const types = [
            { typeName: "Light", name: "Lys" },
            { typeName: "Dark", name: "Mørk" },
            { typeName: "Both", name: "Begge" },
        ];
        const foundType = types.find(o => o.typeName === typeName);

        if (foundType) {
            return foundType;
        } else {
            return types;
        }
    }

    /**
     * Translates a single placementName
     * @param {String} typeName
     */
    static getPlacementName(typeName) {
        switch (typeName) {
            case "Primary":
                return "Primær";
            case "LeftLeg":
                return "Venstre ben";
            case "RightLeg":
                return "Høyre ben";
            case "LeftArm":
                return "Venstre arm";
            case "RightArm":
                return "Høyre arm";
            case "Chest":
                return "Bryst";
            case "LeftChest":
                return "Venstre bryst";
            case "RightChest":
                return "Høyre bryst";
            case "LeftPocket":
                return "Venstre lomme";
            case "RightPocket":
                return "Høyre lomme";
            case "Back":
                return "Rygg";
            case "BackTop":
                return "Rygg topp";
            case "BackBottom":
                return "Rygg bunn";
            default:
                return typeName;
        }
    }

    /**
     * Returns an array of named (translated) placementTypes
     * @param {Object} props
     * @param {String} typeName
     */
    static getPlacementTypes(props, typeName) {
        if (!props) return false;

        let types = props.groupLogo.placementTypes.slice();
        types = types.map(placement => {
            return {
                ...placement,
                typeName: placement.name,
                name: this.getPlacementName(placement.name),
            };
        });

        const foundType = types.find(o => o.typeName === typeName);

        if (foundType) {
            return foundType;
        } else {
            return types;
        }
    }

    /**
     * Scales an image to a target width in px
     * @param {object} image image object instance
     * @param {number} targetWidth the desired width
     */
    static scaleToTargetWidth(image, targetWidth) {
        let scaleFactor = targetWidth / image.width;
        let targetHeight = image.height * scaleFactor;

        return {
            width: targetWidth,
            height: targetHeight,
        };
    }

    /**
     * Scales an image to a target height in px
     * @param {object} image image object instance
     * @param {number} targetHeight the desired height
     */
    static scaleToTargetHeight(image, targetHeight) {
        let scaleFactor = targetHeight / image.height;
        let targetWidth = image.width * scaleFactor;

        return {
            width: targetWidth,
            height: targetHeight,
        };
    }

    static sizeToScale(target, image) {
        return {
            scaleX: target.width / image.width,
            scaleY: target.height / image.height,
        };
    }

    /**
     * Scales an image to a target width, returns the object scale
     * @param {object} image image object instance
     * @param {object} target target width and height, returned from scaleToTargetWidth {width: num, height: num}
     * @param {object} canvas canvas object instance / total/container element with width prop
     */
    static scaleToTargetScale(image, target, canvas) {
        let scaleFactor = target.width / target.height;

        return {
            scaleX: target.width / image.width,
            scaleY: (target.height / canvas.width) * scaleFactor,
        };
    }

    /**
     * Gets the actual width/height of an object based on its scale
     * @param {Number} scaleXY Scale X or Scale Y
     * @param {Number} widthHeight Width or Height
     */
    static scaleToLength(scaleXY, widthHeight) {
        return widthHeight * scaleXY;
    }
}
