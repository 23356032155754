import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Components
import Badge from "../Common/Badge";
import Restrict from "../../Auth/Restrict";

// Helpers
import { GroupOnly, Seller, Manager } from "../../Auth/UserRoles";
import { GroupsHelper } from "../../Helpers";

class OrderCard extends Component {
    render() {
        const { group, order } = this.props;
        const status = GroupsHelper.getStatus(order.humanFriendlyState);

        return (
            <div className="col-sm-12 mb-2">
                <div className="group card h-100">
                    <div className="card-body p-3">
                        <div className="row justify-content-around align-items-center">
                            <div className="col-12 col-md-auto col-lg mb-3 mb-lg-0">
                                <span className="d-block">
                                    <strong>Ordre id:</strong>{" "}
                                    {order.incrementalId}
                                </span>
                                {this.props.customer ? (
                                    <span className="d-block">
                                        <strong>Kunde:</strong>{" "}
                                        {this.props.customer.id}
                                    </span>
                                ) : null}
                            </div>

                            <div className="col-12 col-md-auto col-lg mb-3 mb-lg-0">
                                <Badge {...status} />
                            </div>

                            {/* 
                            @consideration: Show timestamps of these - switch
                            createdAt
                            lockedAt
                            confirmedAt
                            rejectedAt
                            readyForConfirmationAt */}

                            <Restrict {...this.props} allow={Seller}>
                                {this.props.cartsDone && this.props.carts ? (
                                    <div className="col-12 col-sm">
                                        <span>
                                            <strong>Handlekurver:</strong>
                                            <br /> {this.props.cartsDone}/
                                            {this.props.carts}
                                        </span>
                                    </div>
                                ) : null}

                                <div className="col-12 col-sm">
                                    <span>
                                        <strong>Sum:</strong>{" "}
                                        {order.total ? order.total : 0}
                                        ,-
                                    </span>
                                </div>
                            </Restrict>

                            {this.props.customer ? (
                                <div className="col-12 col-sm">
                                    <Restrict {...this.props} allow={GroupOnly}>
                                        <span>
                                            <strong>Ordreansvarlig:</strong>
                                            <br />
                                            {this.props.customer.firstName}{" "}
                                            {this.props.customer.lastName}
                                        </span>
                                    </Restrict>
                                </div>
                            ) : null}

                            <div className="col-12 col-lg-auto mt-3 mt-lg-0">
                                {this.props.currentOrder ? (
                                    <Link
                                        to={`/group/${group.id}/order/${
                                            order.id
                                        }/tracking`}
                                        className="btn btn-outline-primary mr-3">
                                        Spor ordre
                                    </Link>
                                ) : null}

                                <Restrict {...this.props} allow={Manager}>
                                    <Link
                                        to={`/group/${group.id}/order/${
                                            order.id
                                        }/overview`}
                                        className="btn btn-outline-primary">
                                        Se ordre
                                    </Link>
                                </Restrict>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

OrderCard.propTypes = {
    group: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        names: PropTypes.object.isRequired,
        orders: PropTypes.array.isRequired,
        status: PropTypes.object,
    }),
    customer: PropTypes.object,
    carts: PropTypes.number,
    cartsDone: PropTypes.number,
};

OrderCard.defaultProps = {};

export default OrderCard;
