import Axios from "axios";
import { authHeader } from "./AuthenticationActions";
import { config } from "../Helpers/Config";

export function getLogsFetching(payload) {
    return {
        type: "GET_LOGS_FETCHING",
        payload,
    };
}

export function getLogs(groupId) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getLogsFetching(true));

        Axios.all([
            instance.get(`${config.apiUrl}/groups/${groupId}/logs/group`),
            instance.get(`${config.apiUrl}/groups/${groupId}/logs/order`),
        ])
            .then(response => {
                dispatch({
                    type: "GET_LOGS",
                    payload: response.map(response => response.data),
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "SET_LOGS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}
