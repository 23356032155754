import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Actions
import {
    getNotifications,
    addNotification,
    editNotification,
    deleteNotification,
} from "../../Actions/NotificationsActions";

// Components
import Notification from "./Notification";
import Restrict from "../../Auth/Restrict";
import NotificationsAppshell from "./NotificationsAppshell";

// Helpers
import { Seller } from "../../Auth/UserRoles";

class Notifications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalEdit: false,
            modalText: "",
            modalNew: false,
            notificationId: null,
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.newNotification = this.newNotification.bind(this);
        this.updateNotification = this.updateNotification.bind(this);
        this.deleteNotification = this.deleteNotification.bind(this);
    }

    componentDidMount() {
        this.props.getNotifications(this.props.match.params.id);
    }

    toggleModal(modalId) {
        this.setState(
            {
                [modalId]: !this.state[modalId],
            },
            () => {
                if (!this.state.modalEdit) {
                    this.setState({
                        notificationId: null,
                    });
                }
            }
        );
    }

    newNotification() {
        this.props.addNotification({
            groupId: this.props.group.group.id,
            message: this.newMessage.value,
            canBeSeenByManager: this.newSendToManager.checked,
        });

        this.toggleModal("modalNew");
    }

    updateNotification() {
        this.props.editNotification({
            groupId: this.props.group.group.id,
            notificationId: this.state.notificationId,
            message: this.editMessage.value,
            canBeSeenByManager: this.editSendToManager.checked,
        });

        this.toggleModal("modalEdit");
    }

    deleteNotification() {
        this.props.deleteNotification({
            groupId: this.props.group.group.id,
            notificationId: this.state.notificationId,
        });

        this.toggleModal("modalEdit");
    }

    handleEditNotification(notification) {
        this.setState({
            modalEdit: true,
            modalText: notification.message,
            notificationId: notification.id,
        });
    }

    renderNotifications() {
        const notifications = this.props.notifications.notifications;
        let notificationsToRender;

        // Only render notifications that can be seen by the manager
        if (this.props.user.user.role === "Manager") {
            notificationsToRender = notifications.filter(
                notification => notification.canBeSeenByManager
            );
        } else {
            notificationsToRender = notifications.slice();
        }

        if (notificationsToRender && notificationsToRender.length) {
            return notificationsToRender.map((notification, i) => (
                <Notification
                    {...this.props}
                    notification={notification}
                    key={`notice-${i}`}
                    index={i}
                    editNotification={response =>
                        this.handleEditNotification(response)
                    }
                />
            ));
        } else {
            return (
                <p className="text-black-50 mb-0">
                    Ingen notiser er lagt til i bestillingen...
                </p>
            );
        }
    }

    render() {
        // const currentOrder = GroupHelper.getCurrentOrder(
        //     this.props.group.group
        // );

        // Echo possible error messages or that there is no dataz etc
        if (this.props.notifications.notificationsError) return null;

        // Echo appshell while loading dataz
        if (this.props.notifications.notificationsFetching === true)
            return <NotificationsAppshell />;

        // Fetching is done and we got dataz
        if (this.props.notifications.notificationsFetching === false) {
            return (
                <div className="mb-5">
                    <div className="mb-3">
                        <h3>Notiser</h3>
                    </div>

                    <div className="card">
                        <div className="card-body p-4">
                            <div className="row no-gutters">
                                <div className="col-8" id="notifications">
                                    {this.renderNotifications()}
                                </div>
                                <div className="col-4">
                                    <div className="row">
                                        <Restrict
                                            {...this.props}
                                            allow={Seller}>
                                            <div className="col-auto ml-auto mb-3 mb-sm-0">
                                                <Button
                                                    color="primary"
                                                    onClick={() =>
                                                        this.toggleModal(
                                                            "modalNew"
                                                        )
                                                    }>
                                                    Nytt notis
                                                </Button>
                                            </div>
                                        </Restrict>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal
                        isOpen={this.state.modalNew}
                        toggle={() => this.toggleModal("modalNew")}
                        centered>
                        <ModalHeader>Opprett nytt notis</ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <label
                                    htmlFor="modalNewText"
                                    className="h4 mb-3">
                                    Notis til {this.props.group.group.name}
                                </label>
                                <textarea
                                    className="form-control"
                                    id="modalNewText"
                                    defaultValue={null}
                                    rows="4"
                                    ref={el => (this.newMessage = el)}
                                    placeholder="Skriv inn notis..."
                                />
                            </div>
                            <div className="form-check mt-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="noticeNewModalsendToManager"
                                    ref={el => (this.newSendToManager = el)}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="noticeNewModalsendToManager">
                                    Vis notis for ordreansvarlig
                                </label>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                className="mr-auto"
                                color="secondary"
                                onClick={() => this.toggleModal("modalNew")}>
                                Gå tilbake
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => this.newNotification()}>
                                Nytt notis
                            </Button>
                        </ModalFooter>
                    </Modal>

                    <Modal
                        isOpen={this.state.modalEdit}
                        toggle={() => this.toggleModal("modalEdit")}
                        centered>
                        <ModalHeader>Rediger notis</ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <label
                                    htmlFor="noticeModalEditText"
                                    className="h4 mb-3">
                                    Notis til {this.props.group.group.name}
                                </label>

                                <textarea
                                    className="form-control"
                                    id="noticeModalEditText"
                                    defaultValue={this.state.modalText}
                                    rows="4"
                                    ref={el => (this.editMessage = el)}
                                    placeholder="Skriv inn notis..."
                                />
                            </div>
                            <div className="form-check mt-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="noticeEditModalsendToManager"
                                    defaultChecked={
                                        this.state.notificationId
                                            ? this.props.notifications.notifications.find(
                                                  n =>
                                                      n.id ===
                                                      this.state.notificationId
                                              ).canBeSeenByManager
                                            : false
                                    }
                                    ref={el => (this.editSendToManager = el)}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="noticeEditModalsendToManager">
                                    Vis notis for ordreansvarlig
                                </label>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                className="mr-auto"
                                color="secondary"
                                onClick={() => this.toggleModal("modalEdit")}>
                                Gå tilbake
                            </Button>

                            <Button
                                color="danger"
                                onClick={() => this.deleteNotification()}>
                                Slett notis
                            </Button>

                            <Button
                                color="primary"
                                onClick={() => this.updateNotification()}>
                                Oppdater notis
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            );
        }

        return null;
    }
}

Notifications.propTypes = {
    id: PropTypes.number.isRequired,
    updateNotification: PropTypes.func.isRequired,
};

Notifications.defaultProps = {
    id: 0,
    updateNotification: () => null,
};

function mapStateToProps(state) {
    return {
        notifications: state.notifications,
    };
}
export default connect(
    mapStateToProps,
    {
        getNotifications,
        addNotification,
        editNotification,
        deleteNotification,
    }
)(Notifications);
