import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// Actions
import { getGroups } from "../Actions/GroupsActions";

// Components
import GroupSection from "../Components/Groups/GroupSection";
import GroupSectionAppshell from "../Components/Groups/GroupSectionAppshell";
import Create from "../Components/Common/Create";
import AdminLayout from "../Components/Admin/AdminLayout";
//import Filters from "../Components/Groups/Filters";
//import PageError from "./PageError";

// Helpers
import { TitleHelper } from "../Helpers";

class AdminGroups extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        //this.handleFilters = this.handleFilters.bind(this);
    }

    componentDidMount() {
        this.props.getGroups();

        TitleHelper.setTitle("Admin - Dine gruppebestillinger");
    }

    /*
    handleFilters() {
        alert("handleFilters");
    }
    */

    sortGroups() {
        if (
            this.props.groups.groupsFetching === false &&
            this.props.groups.groups.length
        ) {
            return [
                {
                    title: "Dine gruppebestillinger",
                    groups: this.props.groups.groups,
                },
            ];
        }
    }

    renderSections() {
        // Echo possible error messages or that there is no dataz etc
        if (this.props.groups.groupsError) return null;

        // Echo appshell while loading dataz
        if (this.props.groups.groupsFetching === true)
            return <GroupSectionAppshell />;

        // Fetching is done and we got dataz
        if (
            this.props.groups.groupsFetching === false &&
            this.props.groups.groups.length
        ) {
            return this.sortGroups().map((groups, i) => (
                <GroupSection
                    {...this.props}
                    {...groups}
                    key={`section-${i}`}
                />
            ));
        }

        if (
            this.props.groups.groupsFetching === false &&
            !this.props.groups.groups.length
        ) {
            return (
                <div>
                    Ingen gruppebestillinger tilgjengelig.{" "}
                    <Link to="/create">Opprett ny gruppebestilling</Link>
                </div>
            );
        }
    }

    render() {
        return (
            <AdminLayout {...this.props}>
                <div className="container">
                    {/* <Filters handleFilters={this.handleFilters} /> */}

                    {this.renderSections()}

                    <Create />
                </div>
            </AdminLayout>
        );
    }
}

AdminGroups.propTypes = {
    getGroups: PropTypes.func.isRequired,
};

AdminGroups.defaultProps = {};

function mapStateToProps(state) {
    return {
        errors: state.errors,
        groups: state.groups,
    };
}
export default connect(
    mapStateToProps,
    {
        getGroups,
    }
)(AdminGroups);
