import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from 'prop-types';

// Actions
import { getGroupLogos, getPlacementTypes } from "../Actions/GroupLogoActions";
import { getAllProducts, getGroupProducts } from "../Actions/ProductsActions";

// Components
import DataLayerGroup from "./DataLayerGroup";
import Layout from "../Components/Group/Layout";
import LogoLoad from "../LogoPlacer/LogoLoad";
import Subheader from "../Components/Common/Subheader";
import LogoPlacerScript from "../LogoPlacer/LogoPlacerScript";

// Helpers
import { TitleHelper, GroupHelper } from "../Helpers";

class GroupLogoPlacer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scriptLoaded: false,
            scriptError: null,
        };
    }

    componentDidMount() {
        TitleHelper.setTitle("Logoplassering");

        if (this.props.groupLogo.placementTypesFetching !== false) {
            this.props.getPlacementTypes();
        }

        if (this.props.groupLogo.groupLogosFetching !== false) {
            this.props.getGroupLogos(this.props.match.params.id);
        }

        this.props.getAllProducts();
        this.props.getGroupProducts(this.props.match.params.id);
    }

    handleScriptCreate() {
        this.setState({ scriptLoaded: false });
    }

    handleScriptError() {
        this.setState({ scriptError: true });
    }

    handleScriptLoad() {
        this.setState({ scriptLoaded: true });
    }

    renderLogoPlacer() {
        const { groupLogo, products } = this.props;
        // Echo possible error messages or that there is no dataz etc
        if (
            groupLogo.groupLogosError ||
            products.groupProductsError ||
            products.allProductsError
        )
            return; //<SectionError />;

        // Echo appshell while loading dataz
        if (
            groupLogo.groupLogosFetching === true ||
            products.groupProductsFetching === true ||
            products.allProductsFetching === true
        )
            return <div className="col">Laster logoplasserer...</div>;

        // Fetching is done and we got dataz
        if (
            groupLogo.groupLogosFetching === false &&
            groupLogo.groupLogos &&
            groupLogo.groupLogos.length &&
            products.groupProductsFetching === false &&
            products.groupProducts &&
            products.groupProducts.length &&
            products.allProductsFetching === false &&
            products.allProducts &&
            products.allProducts.length &&
            this.state.scriptLoaded
        ) {
            if (
                !GroupHelper.disableWhenLocked(this.props, [
                    "Seller",
                    "Manager",
                ])
            ) {
                return <LogoLoad {...this.props} />;
            } else {
                return (
                    <div className="col">
                        Kan ikke endre logoplasseringer på grunn av
                        ordrestatusen.
                    </div>
                );
            }
        }
    }

    render() {
        return (
            <DataLayerGroup {...this.props}>
                <Layout {...this.props}>
                    <div className="products container">
                        <Subheader
                            linkTo={`/group/${
                                this.props.match.params.id
                            }/settings`}
                            linkName="Tilbake til innstillinger"
                        />

                        <div className="row mb-4">
                            <LogoPlacerScript
                                handleScriptCreate={this.handleScriptCreate.bind(
                                    this
                                )}
                                handleScriptError={this.handleScriptError.bind(
                                    this
                                )}
                                handleScriptLoad={this.handleScriptLoad.bind(
                                    this
                                )}
                            />

                            {this.renderLogoPlacer()}
                        </div>
                    </div>
                </Layout>
            </DataLayerGroup>
        );
    }
}

GroupLogoPlacer.propTypes = {};
GroupLogoPlacer.defaultProps = {};

function mapStateToProps(state) {
    return {
        group: state.group,
        products: state.products,
        groupLogo: state.groupLogo,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    { getGroupLogos, getPlacementTypes, getAllProducts, getGroupProducts }
)(GroupLogoPlacer);
