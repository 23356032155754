import Axios from "axios";
import { authHeader } from "././AuthenticationActions";
import { config } from "../Helpers/Config";

export function getNotificationFetching(payload) {
    return {
        type: "GET_NOTIFICATIONS_FETCHING",
        payload,
    };
}

export function getNotifications(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set posting true
        dispatch(getNotificationFetching(true));

        instance
            .get(`${config.apiUrl}/groups/${payload}/notifications`)
            .then(response => {
                dispatch({
                    type: "GET_NOTIFICATIONS",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log("Get notifications error", error);

                dispatch({
                    type: "GET_NOTIFICATIONS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function addNotificationPosting(payload) {
    return {
        type: "ADD_NOTIFICATION_POSTING",
        payload,
    };
}

export function addNotification(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set posting true
        dispatch(addNotificationPosting(true));

        instance
            .post(`${config.apiUrl}/groups/${payload.groupId}/notifications`, {
                message: payload.message,
                canBeSeenByManager: payload.canBeSeenByManager,
            })
            .then(response => {
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: response.data,
                    //payload: dummyData,
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "ADD_NOTIFICATION_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function editNotificationPosting(payload) {
    return {
        type: "EDIT_NOTIFICATION_POSTING",
        payload,
    };
}

export function editNotification(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set posting true
        dispatch(editNotificationPosting(true));

        instance
            .post(
                `${config.apiUrl}/groups/${payload.groupId}/notifications/${
                    payload.notificationId
                }`,
                {
                    message: payload.message,
                    canBeSeenByManager: payload.canBeSeenByManager,
                }
            )
            .then(response => {
                dispatch({
                    type: "EDIT_NOTIFICATION",
                    payload: response.data,
                    //payload: dummyData,
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "EDIT_NOTIFICATION_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function deleteNotificationPosting(payload) {
    return {
        type: "DELETE_NOTIFICATION_POSTING",
        payload,
    };
}

export function deleteNotification(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set posting true
        dispatch(deleteNotificationPosting(true));

        instance
            .delete(
                `${config.apiUrl}/groups/${payload.groupId}/notifications/${
                    payload.notificationId
                }`
            )
            .then(response => {
                dispatch({
                    type: "DELETE_NOTIFICATION",
                    payload: response.data,
                    //payload: dummyData,
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "DELETE_NOTIFICATION_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}
