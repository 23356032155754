import Axios from "axios";
import { authHeader } from "./AuthenticationActions";
import { config } from "../Helpers/Config";
import { createError } from "./ErrorsActions";
import { getGroupProduct } from "./ProductsActions";

export function getCartFetching(payload) {
    return {
        type: "GET_CART_FETCHING",
        payload,
    };
}

export function getCart(groupId) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getCartFetching(true));

        instance
            .get(`${config.apiUrl}/groups/${groupId}/cart`)
            .then(response => {
                dispatch({
                    type: "GET_CART",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log("Get cart error", error);
                dispatch({
                    type: "GET_CART_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function addItemPosting(payload) {
    return {
        type: "ADD_CART_ITEM_POSTING",
        payload,
    };
}

export function addItem(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return async dispatch => {
        // Set fetching true
        dispatch(addItemPosting(true));

        try {
            const response = await instance.post(
                `${config.apiUrl}/groups/${payload.groupId}/cart/`,
                {
                    customerId: payload.customerId,
                    unitId: payload.unitId,
                    textValue: payload.textValue,
                    qty: payload.qty,
                    isCommunalItem: payload.isCommunalItem,
                }
            );
            dispatch({
                type: "ADD_CART_ITEM",
                payload: response.data,
            });
        } catch (error) {
            console.log("Add cart item error", error, error.response);
            if (
                error.response &&
                error.response &&
                error.response.status === 400
            ) {
                // TODO: the api should really implement custom error id's...
                // The requested stock (8) could not be fulfilled
                if (
                    error.response.data.Message.includes(
                        "The requested stock"
                    ) &&
                    error.response.data.Message.includes(
                        "could not be fulfilled"
                    )
                ) {
                    dispatch({
                        type: "ADD_CART_ITEM_ERROR",
                        payload: { error, errorId: "noStock" },
                    });
                    dispatch({
                        type: "ADD_ERROR",
                        error: createError(
                            `Kan ikke legge til ${payload.qty} enheter`,
                            "Lagerbeholdning er tom."
                        ),
                    });
                }
            } else {
                dispatch({
                    type: "ADD_CART_ITEM_ERROR",
                    payload: true,
                });
                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            }
        }
    };
}

// Combined actions
export function addCartItem(payload) {
    // It also injects a second argument called getState() that lets us read the current state.
    // e.g const fetchedUser = getState().usersById[userId]
    return (dispatch, getState) => {
        return dispatch(addItem(payload)).then(() => {
            return dispatch(getGroupProduct(payload));
        });
    };
}

export function editItemPosting(payload) {
    return {
        type: "EDIT_CART_ITEM_POSTING",
        payload,
    };
}

export function editItem(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return async dispatch => {
        // Set fetching true
        dispatch(editItemPosting(true));

        try {
            const response = await instance.post(
                `${config.apiUrl}/groups/${payload.groupId}/cart/${
                    payload.itemId
                }`,
                {
                    customerId: payload.customerId,
                    textValue: payload.textValue,
                    qty: payload.qty,
                }
            );
            dispatch({
                type: "EDIT_CART_ITEM",
                payload: response.data,
            });
        } catch (error) {
            console.log("Edit cart item error", error, error.response);
            if (
                error.response &&
                error.response &&
                error.response.status === 400
            ) {
                // TODO: the api should really implement custom error id's...
                // The requested stock (8) could not be fulfilled
                if (
                    error.response.includes("The requested stock") &&
                    error.response.includes("could not be fulfilled")
                ) {
                    dispatch({
                        type: "EDIT_CART_ITEM_ERROR",
                        payload: { error, errorId: "noStock" },
                    });
                    dispatch({
                        type: "ADD_ERROR",
                        error: createError(
                            `Kan ikke legge til ${payload.qty} enheter`,
                            "Lagerbeholdning er tom."
                        ),
                    });
                }
            } else {
                dispatch({
                    type: "EDIT_CART_ITEM_ERROR",
                    payload: true,
                });
                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            }
        }
    };
}

// Edits a cart item and gets new stock
export function editCartItem(payload) {
    return (dispatch, getState) => {
        return dispatch(editItem(payload)).then(() => {
            return dispatch(getGroupProduct(payload));
        });
    };
}

export function replaceItemPosting(payload) {
    return {
        type: "REPLACE_CART_ITEM_POSTING",
        payload,
    };
}

export function replaceItem(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return async dispatch => {
        // Set fetching true
        dispatch(replaceItemPosting(true));

        const customerId = payload.customerId;

        try {
            await instance.delete(
                `${config.apiUrl}/groups/${payload.groupId}/cart/${
                    payload.itemId
                }`,
                {
                    data: {
                        customerId,
                    },
                }
            );
            const response_1 = await instance.post(
                `${config.apiUrl}/groups/${payload.groupId}/cart/`,
                {
                    customerId: payload.customerId,
                    unitId: payload.unitId,
                    textValue: payload.textValue,
                    qty: payload.qty,
                    isCommunalItem: payload.isCommunalItem,
                }
            );

            dispatch({
                type: "REPLACE_CART_ITEM",
                payload: response_1.data,
            });
        } catch (error) {
            console.log("Replace cart item error", error);
            dispatch({
                type: "REPLACE_CART_ITEM_ERROR",
                payload: true,
            });
            // Dispatch generic error to the errorHandler
            dispatch({ type: "ADD_ERROR", error: error });
        }
    };
}

// Replaces a cart item (deletes, then adds) and gets new stock
export function replaceCartItem(payload) {
    return (dispatch, getState) => {
        return dispatch(replaceItem(payload)).then(() => {
            return dispatch(getGroupProduct(payload));
        });
    };
}

export function deleteItemDeleting(payload) {
    return {
        type: "DELETE_CART_ITEM_DELETING",
        payload,
    };
}

export function deleteItem(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return async dispatch => {
        // Set fetching true
        dispatch(deleteItemDeleting(true));

        const customerId = payload.customerId;

        try {
            const response = await instance.delete(
                `${config.apiUrl}/groups/${payload.groupId}/cart/${
                    payload.itemId
                }`,
                {
                    data: {
                        customerId,
                    },
                }
            );
            dispatch({
                type: "DELETE_CART_ITEM",
                payload: response.data,
            });
        } catch (error) {
            console.log("Delete cart item error", error);
            dispatch({
                type: "DELETE_CART_ITEM_ERROR",
                payload: true,
            });
            // Dispatch generic error to the errorHandler
            dispatch({ type: "ADD_ERROR", error: error });
        }
    };
}

// Deletes a cart item and gets new stock
export function deleteCartItem(payload) {
    return (dispatch, getState) => {
        return dispatch(deleteItem(payload)).then(() => {
            return dispatch(getGroupProduct(payload));
        });
    };
}

export function confirmCartPosting(payload) {
    return {
        type: "CONFIRM_CART_POSTING",
        payload,
    };
}

export function confirmCart(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(confirmCartPosting(true));

        instance
            .post(`${config.apiUrl}/groups/${payload.groupId}/cart/confirm`, {
                customerId: payload.customerId,
            })
            .then(response => {
                dispatch({
                    type: "CONFIRM_CART",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log("Confirm cart error", error);

                dispatch({
                    type: "CONFIRM_CART_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function unconfirmCartPosting(payload) {
    return {
        type: "UNCONFIRM_CART_POSTING",
        payload,
    };
}

export function unconfirmCart(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(unconfirmCartPosting(true));

        instance
            .post(`${config.apiUrl}/groups/${payload.groupId}/cart/unconfirm`, {
                customerId: payload.customerId,
            })
            .then(response => {
                dispatch({
                    type: "UNCONFIRM_CART",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log("Unconfirm cart error", error);

                dispatch({
                    type: "UNCONFIRM_CART_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}
