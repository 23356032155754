import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Helpers
import Restrict from "../../Auth/Restrict";
import { Seller } from "../../Auth/UserRoles";

export default class NotificationStatusModal extends Component {
    render() {
        const { state } = this.props;
        return (
            <Modal
                isOpen={state.modal}
                toggle={this.props.toggleModal}
                centered>
                <ModalHeader>
                    Oppdater status til "{state.statusText}"
                </ModalHeader>

                <Restrict {...this.props} allow={Seller}>
                    <ModalBody>
                        <div className="form-group">
                            <label
                                htmlFor="noticeModalNewText"
                                className="h4 mb-3">
                                Notis til {this.props.group.group.name}
                            </label>
                            <textarea
                                className="form-control"
                                id="noticeModalNewText"
                                defaultValue={state.modalText}
                                rows="4"
                                placeholder="Skriv inn notis..."
                                ref={this.props.newMessage}
                            />
                        </div>
                        <div className="form-check mt-3">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="noticeNewModalsendToManager"
                                ref={this.props.newSendToManager}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="noticeNewModalsendToManager">
                                Vis notis for ordreansvarlig
                            </label>
                        </div>
                    </ModalBody>
                </Restrict>

                <ModalFooter>
                    <Button
                        className="mr-auto"
                        color="secondary"
                        onClick={() => this.props.resetLocalGroupStatus()}>
                        Gå tilbake
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => this.props.updateGroupStatus()}>
                        Oppdater status
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
