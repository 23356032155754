import React, { Component } from "react";
import PropTypes from "prop-types";
import AdditionItem from "./AdditionItem";

class AdditionsSection extends Component {
    render() {
        return (
            <div>
                <div className="row mb-2">
                    <div className="col">
                        <h2>{this.props.title}</h2>
                        {this.props.desc ? <p>{this.props.desc}</p> : null}
                    </div>
                </div>

                <div className="row mb-4">
                    {this.props.additions && this.props.additions.length ? (
                        this.props.additions.map((item, i) => (
                            <AdditionItem
                                {...this.props}
                                product={item}
                                inCart={this.props.inCart}
                                key={`section-row-${i}`}
                            />
                        ))
                    ) : (
                        <div className="col">
                            Ingen tilleggsprodukter {this.props.type}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

AdditionsSection.propTypes = {
    title: PropTypes.string.isRequired,
    desc: PropTypes.string,
    additions: PropTypes.array.isRequired,
};

AdditionsSection.defaultProps = {
    title: "Tilgjengelige tilleggsprodukter",
    additions: [],
};

export default AdditionsSection;
