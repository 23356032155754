import React, { Component } from "react";
import PropTypes from "prop-types";

class ActionText extends Component {
    render() {
        return (
            <div className="col-12 col-md">
                <span className={`action__text text-${this.props.color}`}>
                    {this.props.children}
                </span>
            </div>
        );
    }
}

ActionText.propTypes = {
    children: PropTypes.node,
};

ActionText.defaultProps = {
    color: "light",
};

export default ActionText;
