import React from "react";
import AppShell from "../AppShell/AppShell";

export default class CustomerAppshell extends AppShell {
    renderShell(i) {
        return (
            <div className="shell col-sm-6 mb-3" key={`shell-${i}`}>
                <div className="card h-100">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="row align-items-center">
                                    <div className="col-4 col-lg-2 mb-3 mb-lg-auto">
                                        <div>
                                            <img
                                                className="shell__img img-fluid rounded-circle"
                                                src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 300 300'%2F%3E"
                                                alt="Laster bilde"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                                        <span className="h4">
                                            <span className="shell__text" />
                                        </span>

                                        <span className="d-block">
                                            <span className="shell__text" />
                                        </span>
                                        <span className="d-block">
                                            <span className="shell__text" />
                                        </span>
                                        <span className="d-block">
                                            <span className="shell__text" />
                                        </span>

                                        <span className="d-block">
                                            <span className="shell__text" />
                                        </span>

                                        <span className="d-block">
                                            <span className="shell__text" />
                                        </span>

                                        <div className="mt-3">
                                            <span className="shell__text" />
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-4 mt-auto mt-lg-0">
                                        <span className="shell__btn btn-block" />
                                        <span className="shell__btn btn-block" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return <>{this.createAppShell(6)}</>;
    }
}
