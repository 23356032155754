import { DateHelper } from ".";

export default class StatusHelper {
    /**
     * Gets the currently appliccable order state (1) from the group's state object
     * @param {Object} states
     */
    static getHumanFriendlyState(states) {
        // If more than one active at a time, get the most recent
        // If just one, return it
        let allStates = Object.entries(states)
            .filter(([key, value]) => (value ? key : false))
            .map(([key, value]) => {
                return {
                    name: key,
                    value,
                };
            });

        // We have multiple possible states, just return the most recent
        // unless we have states of type "completedAt", "closedAt" or "externalOrderState"
        if (
            allStates &&
            allStates.length > 1 &&
            !allStates.some(v =>
                ["completedAt", "closedAt", "externalOrderState"].includes(
                    v.name
                )
            )
        ) {
            let sortedStates = DateHelper.sortByDate(allStates, "value");
            return this.translateState(sortedStates[0].name);
        }

        // Completed and closed states go over everything
        // External order state goes over everything but completed and closed
        if (states.completedAt !== null) {
            return this.translateState("completedAt");
        }
        if (states.closedAt !== null) {
            return this.translateState("closedAt");
        }
        if (states.externalOrderState !== null) {
            // Translate external order state
            return this.translateState(states.externalOrderState);
        }
        if (states.lockedForCustomersAt !== null) {
            return this.translateState("lockedForCustomersAt");
        }
        if (states.lockedForGroupAt !== null) {
            return this.translateState("lockedForGroupAt");
        }
        if (states.lockedForSellersAt !== null) {
            return this.translateState("lockedForSellersAt");
        }
        if (states.readyForConfirmationAt !== null) {
            return this.translateState("readyForConfirmationAt");
        }
        if (states.rejectedAt !== null) {
            return this.translateState("rejectedAt");
        }
        if (states.confirmedAt !== null) {
            return this.translateState("confirmedAt");
        }
        if (states.sentForProcessingAt !== null) {
            return this.translateState("sentForProcessingAt");
        }
        if (states.readyForManagerAt !== null) {
            return this.translateState("readyForManagerAt");
        }
        if (states.readyForMembersAt !== null) {
            return this.translateState("readyForMembersAt");
        }

        return this.translateState();
    }

    /**
     * Translate a state from the API to a humanFriendlyState
     * @param {String} stateName
     */
    static translateState(stateName) {
        switch (stateName) {
            case "completedAt":
                return "Finished";
            case "closedAt":
                return "Closed";
            case "lockedForCustomersAt":
                return "LockedCustomers";
            case "lockedForGroupAt":
                return "LockedGroup";
            case "lockedForSellersAt":
                return "LockedSellers";
            case "readyForConfirmationAt":
                return "RequestingConfirmation";
            case "rejectedAt":
                return "RejectedConfirmation";
            case "confirmedAt":
                return "Confirmed";
            case "sentForProcessingAt":
                return "SendToProcessing";
            case "readyForManagerAt":
                return "OpenForManager";
            case "readyForMembersAt":
                return "Open";
            // External order states below
            case "Registered":
                return "Registered";
            case "Confirmed":
                return "Confirmed";
            case "Produced":
                return "Produced";
            case "Invoiced":
                return "Invoiced";
            case "Sent":
                return "Sent";
            case "Stopped":
                return "Stopped";
            default:
                return "New";
        }
    }
}
