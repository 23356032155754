import { Utils, DateHelper } from "../Helpers";

let assign = Object.assign || require("object.assign");

const DEFAULT_STATE = {
    logs: [],
    logsFetching: null,
    logsError: null,
};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case "GET_LOGS":
            // Flatten logs from different sources into one array
            let logs = Utils.flatten(action.payload);

            // Sort all logs based on ISO date, reverse and return
            logs = DateHelper.sortByDate(logs, "createdAt");

            return assign({}, state, {
                logs: logs,
                logsFetching: false,
            });

        case "GET_LOGS_FETCHING":
            return assign({}, state, {
                logsFetching: action.payload,
                logsError: null,
            });

        case "GET_LOGS_ERROR":
            return assign({}, state, {
                logsError: action.payload,
                logs: null,
                logsFetching: null,
            });

        default:
            return state;
    }
}
