export default class TitleHelper {
    /**
     * Set the window page title
     * @param {*} prefix
     * @param {*} suffix
     */
    static setTitle(prefix = "", suffix = "") {
        document.title = prefix + suffix;
    }
}
