import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

// Actions
import { promoteCustomer, demoteCustomer } from "../../Actions/GroupActions";

// Components
import Badge from "../Common/Badge";
import LazyImage from "../Common/LazyImage";
import Restrict from "../../Auth/Restrict";
import Group from "./Group";

// Helpers
import { Seller } from "../../Auth/UserRoles";
import { GroupHelper, config, CartHelper } from "../../Helpers";

class Customer extends Component {
    constructor(props) {
        super(props);
        this.handleInvite = this.handleInvite.bind(this);
        this.promoteCustomer = this.promoteCustomer.bind(this);
        this.demoteCustomer = this.demoteCustomer.bind(this);
    }

    promoteCustomer() {
        this.props.promoteCustomer({
            foundManager: GroupHelper.getGroupManager(
                this.props.group.group.customers
            ),
            groupId: this.props.group.group.id,
            customerId: this.props.customer.customer.id,
        });
    }

    demoteCustomer() {
        this.props.demoteCustomer({
            groupId: this.props.group.group.id,
            customerId: this.props.customer.customer.id,
        });
    }

    isInGroup() {
        if (this.props.customer.isClient) {
            return true;
        } else if (
            this.props.group &&
            this.props.group.group &&
            this.props.group.group.customers
        ) {
            return this.props.group.group.customers.includes(
                this.props.customer
            );
        }
    }

    isSelf() {
        return this.props.customer.customer.id === this.props.user.user.id;
    }

    handleInvite() {
        this.props.onInvite({
            id: this.props.customer.customer.id,
            vehicleId: this.props.customer.vehicleId,
        });
    }

    renderInviteButton() {
        const groups = this.props.customer.groups;

        if (groups && groups.length && !this.isInGroup()) {
            return (
                <Button
                    onClick={this.handleInvite}
                    color="success"
                    size="sm"
                    outline
                    block>
                    {this.props.inviteText}
                </Button>
            );
        } else if (
            this.props &&
            this.props.search &&
            this.props.search.customerGroupsPosting
        ) {
            return (
                <span className="btn btn-block btn-outline-secondary btn-block btn-sm mt-2 disabled">
                    Laster gruppe...
                </span>
            );
        } else {
            return (
                <span className="btn btn-outline-secondary btn-block btn-sm mt-2 disabled">
                    Medlem er ikke i en gruppe
                </span>
            );
        }
    }

    renderButtons() {
        const customer = this.props.customer;

        if (customer.isClient) {
            return (
                <Button
                    onClick={this.props.onRemove}
                    color="danger"
                    size="sm"
                    outline
                    block>
                    Fjern kunde
                </Button>
            );
        } else {
            return (
                <Restrict {...this.props} allow={Seller}>
                    {this.isInGroup() &&
                    customer.customerType.name === "Customer" ? (
                        <Button
                            onClick={this.promoteCustomer}
                            color="primary"
                            size="sm"
                            outline
                            block>
                            Gjør til ordreansvarlig
                        </Button>
                    ) : null}

                    {this.isInGroup() &&
                    customer.customerType.name === "Manager" ? (
                        <Button
                            onClick={this.demoteCustomer}
                            color="danger"
                            size="sm"
                            outline
                            block>
                            Degrader til kunde
                        </Button>
                    ) : null}

                    {this.props.invitation && this.renderInviteButton()}
                </Restrict>
            );
        }
    }

    renderProfilePicture() {
        const customer = this.props.customer.customer;
        let picture;

        if (customer && customer.picture && customer.picture.small) {
            picture = customer.picture.small;
        } else if (customer && customer.images) {
            picture = customer.images.find(o => o.type === "Profile").url;
        }

        if (customer && picture) {
            return (
                <div
                    className="col-auto mb-2 mb-xl-auto"
                    style={{ maxWidth: 76 + "px" }}>
                    <a
                        href={`${config.feedUrl}/${customer.communityUserId}`}
                        tabIndex="-1">
                        <LazyImage
                            src={picture}
                            className="img-fluid"
                            alt={`Profilbildet til ${customer.firstName}`}
                        />
                    </a>
                </div>
            );
        }
    }

    render() {
        const customer = this.props.customer.customer;
        const role = this.props.customer.customerType.name;
        const groups = this.props.customer.groups;

        return (
            <div className="member card h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <div className="row align-items-center">
                                {this.renderProfilePicture()}

                                <div className="col-12 col-xl-6 mb-4 mb-xl-0 align-self-start">
                                    <h3 className="h4">
                                        {CartHelper.hasCustomerAcceptedCart(
                                            this.props.cart.cart,
                                            customer
                                        ) ? (
                                            <span
                                                className="tick"
                                                title={`${customer.firstName} har godkjent handlekurven sin`}
                                            />
                                        ) : null}
                                        <a
                                            href={`${config.feedUrl}/${customer.communityUserId}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-body">
                                            {customer.firstName}{" "}
                                            {customer.lastName}
                                        </a>
                                    </h3>

                                    {role === "Manager" && (
                                        <span className="font-weight-bold d-block mb-2">
                                            Ordreansvarlig
                                        </span>
                                    )}

                                    {customer.school && (
                                        <span className="d-block">
                                            {customer.school.name},{" "}
                                            {customer.school.className}
                                        </span>
                                    )}

                                    <span className="d-block">
                                        {customer.russyear}
                                    </span>

                                    {customer.address && (
                                        <span className="d-block">
                                            {customer.address.address},{" "}
                                            {customer.address.postalCode}{" "}
                                            {customer.address.postalPlace}
                                        </span>
                                    )}

                                    {customer.email && (
                                        <span className="d-block">
                                            <a
                                                href={`mailto:${customer.email}`}>
                                                {customer.email}
                                            </a>
                                        </span>
                                    )}

                                    {customer.mobile && (
                                        <span className="d-block">
                                            <a href={`tel:${customer.mobile}`}>
                                                {customer.mobile}
                                            </a>
                                        </span>
                                    )}

                                    <div className="mt-3">
                                        {/* <span>Verifiseringer:</span> */}
                                        <Restrict
                                            {...this.props}
                                            allow={Seller}>
                                            {role === "Manager" && (
                                                <Badge
                                                    className="mx-1"
                                                    title={`${
                                                        customer.firstName
                                                    } er ${
                                                        customer.isCreditVerified
                                                            ? ""
                                                            : "ikke"
                                                    } kredittverifisert`}
                                                    statusCode={
                                                        customer.isCreditVerified
                                                            ? "success"
                                                            : "danger"
                                                    }
                                                    uppercase={false}>
                                                    Kreditt
                                                </Badge>
                                            )}
                                        </Restrict>

                                        <Badge
                                            className="mx-1"
                                            title={`${customer.firstName} er ${
                                                customer.isMobileVerified
                                                    ? ""
                                                    : "ikke"
                                            } mobilverifisert`}
                                            statusCode={
                                                customer.isMobileVerified
                                                    ? "success"
                                                    : "danger"
                                            }
                                            uppercase={false}>
                                            SMS
                                        </Badge>
                                        {/* <Badge
                                            className="mx-1"
                                            statusCode={
                                                customer.isFacebookVerified ? "success" : "danger"
                                            }
                                            uppercase={false}>
                                            Facebook
                                        </Badge> */}
                                    </div>
                                </div>

                                <div className="col-12 col-xl-4 mt-auto mt-xl-0">
                                    <a
                                        href={`${config.feedUrl}/${customer.communityUserId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-outline-primary btn-block btn-sm">
                                        Se profil
                                    </a>

                                    {this.renderButtons()}
                                </div>
                            </div>
                        </div>

                        {groups && groups.length ? (
                            <div className="col-12 col-md mt-4 mt-md-0 member__groups">
                                <h2 className="h6 text-uppercase">
                                    Medlem av:
                                </h2>

                                {groups.map(group => (
                                    <Group group={group} key={group.id} />
                                ))}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

Customer.propTypes = {
    customer: PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        mobile: PropTypes.string,
        school: PropTypes.string,
        russyear: PropTypes.string,
        address: PropTypes.string,
        image: PropTypes.object,
    }),
    inviteText: PropTypes.string,
};

Customer.defaultProps = {
    inviteText: "Inviter medlem",
};

function mapStateToProps(state) {
    return {
        group: state.group,
        cart: state.cart,
    };
}
export default connect(
    mapStateToProps,
    {
        promoteCustomer,
        demoteCustomer,
    }
)(Customer);
