import React, { Component } from "react";
import { Link } from "react-router-dom";
//import PropTypes from "prop-types";
import { Button, Popover, PopoverBody } from "reactstrap";

// Components
import Icon from "./Icon";

class Create extends Component {
    constructor(props) {
        super(props);

        this.togglePopover = this.togglePopover.bind(this);
        this.activatePopover = this.activatePopover.bind(this);
        this.deactivatePopover = this.deactivatePopover.bind(this);
        this.state = {
            popoverOpen: false,
        };
    }

    togglePopover() {
        this.setState({
            popoverOpen: !this.state.popoverOpen,
        });
    }

    activatePopover() {
        if (!this.state.popoverOpen) {
            this.togglePopover();
        }
    }

    deactivatePopover() {
        if (this.state.popoverOpen) {
            this.togglePopover();
        }
    }

    render() {
        return (
            <Link to="/create" className="create" id="createGroup">
                <Popover
                    placement="top"
                    isOpen={this.state.popoverOpen}
                    target="createGroup"
                    toggle={this.togglePopover}>
                    <PopoverBody>Opprett ny gruppebestilling</PopoverBody>
                </Popover>
                <Button
                    title="Legg til ny gruppebestilling"
                    color="primary"
                    className="create__button"
                    onMouseOver={this.activatePopover}
                    onMouseOut={this.deactivatePopover}>
                    <Icon
                        className="create__icon"
                        name="plus"
                        title="Legg til ny"
                        fill="#ffffff"
                        width={22}
                        height={22}
                    />
                </Button>
            </Link>
        );
    }
}

Create.propTypes = {};

Create.defaultProps = {};

export default Create;
