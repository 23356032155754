import React, { Component } from "react";
import PropTypes from "prop-types";
import Imgix from "react-imgix";

class LazyImage extends Component {
    render() {
        return (
            <Imgix
                src={this.props.src}
                className={`lazyload ${this.props.className}`}
                alt={this.props.alt}
                width={this.props.width}
                height={this.props.height}
                imgixParams={
                    this.props.productImage
                        ? { crop: "entropy", fit: "clip" }
                        : this.props.imgixParams
                }
                attributeConfig={{
                    src: "data-src",
                    srcSet: "data-srcset",
                    sizes: "data-sizes",
                }}
            />
        );
    }
}

LazyImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};
LazyImage.defaultProps = {
    width: 680,
    height: 1074,
};

export default LazyImage;
