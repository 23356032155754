import React from "react";
import AppShell from "../AppShell/AppShell";
import { Number } from "../../Helpers";

export default class NotificationsAppshell extends AppShell {
    render() {
        return (
            <div className="shell mb-5">
                <div className="mb-3">
                    <h3>
                        <span
                            className="shell__text font-weight-bold"
                            style={{
                                "--width": Number.random(25, 50) + "%",
                            }}
                        />
                    </h3>
                </div>
                <div className="card">
                    <div className="card-body p-4">
                        <div className="row no-gutters">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-auto ml-auto mb-3 mb-sm-0">
                                        <span className="shell__btn btn btn-light btn-block rounded-0" />
                                    </div>
                                </div>

                                <div className="d-block">
                                    <span
                                        className="shell__text"
                                        style={{
                                            "--width":
                                                Number.random(40, 80) + "%",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
