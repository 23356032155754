import { GroupHelper } from ".";

export default class GroupsHelper {
    /**
     * Gets the current sum total for all the current orders of a section of groups
     * @param {Object} groups
     */
    static sumGroupsTotal(groups) {
        return groups
            .map(group => {
                const currentOrder = GroupHelper.getCurrentOrder(group);

                if (currentOrder && currentOrder.total) {
                    return currentOrder.total;
                } else {
                    return 0;
                }
            })
            .reduce((a, b) => a + b);
    }

    /**
     * Get all the available statuses for the user, or all available statuses
     * @param {Object} user
     */
    static getStatuses(user) {
        /**
         * statusCode: Identifier for the status
         * statusText: Human friendly translation
         * description: Describes what the status does, and what the different user roles can access
         * roles: User roles that can set the status
         * exceptions: UserRoles and an array of statusCodes which will disable that user from setting the status
         */
        let statuses = [
            {
                statusCode: "New",
                statusText: "Fersk",
                description:
                    "Ordren er opprettet nylig og er kun åpen for selger. Kan ikke byttes til manuelt.",
                roles: ["Admin", "Seller"],
            },
            {
                statusCode: "OpenForManager",
                statusText: "Åpen for ordreansvarlig",
                description:
                    "Ordren er kun åpen for selger og ordreansvarlig. Kun ordreansvarlig kan åpne og lukke ordren.",
                roles: ["Admin", "Seller", "Manager"],
                exceptions: {
                    Seller: ["OpenForManager", "Confirmed"],
                },
            },
            {
                statusCode: "Open",
                statusText: "Åpen",
                description:
                    "Ordren er åpen for hele gruppen. Ordreansvarlig kan kun redigere egne produkter. Selger kan ikke redigere produkter.",
                roles: ["Admin", "Seller", "Manager"],
                exceptions: {
                    Seller: [
                        "OpenForManager",
                        "Confirmed",
                        "RejectedConfirmation",
                    ],
                },
            },
            {
                statusCode: "LockedSellers",
                statusText: "Låst for selgere",
                description:
                    "Ordren er låst for gruppen samt selgere og kan kun redigeres av en administrator.",
                roles: ["Admin"],
                exceptions: {
                    Seller: ["New", "OpenForManager", "Confirmed"],
                },
            },
            {
                statusCode: "LockedGroup",
                statusText: "Låst for gruppe",
                description:
                    "Ordren er låst for hele gruppen og kan redigeres av selger og administrator.",
                roles: ["Admin", "Seller"],
                exceptions: {
                    Seller: ["New", "OpenForManager", "Confirmed"],
                },
            },
            {
                statusCode: "LockedCustomers",
                statusText: "Låst for medlemmer",
                description:
                    "Ordren er låst for gruppemedlemmer og kan redigeres av ordreansvarlig, selger og administrator.",
                roles: ["Admin", "Seller", "Manager"],
                exceptions: {
                    Seller: ["New", "OpenForManager", "Confirmed"],
                },
            },
            {
                statusCode: "RequestingConfirmation",
                statusText: "Til godkjennelse",
                description:
                    "Ordren ansees som ferdig av ordreansvarlig og venter på godkjennelse av selger.",
                roles: ["Admin", "Manager"],
                exceptions: {
                    Seller: ["OpenForManager", "Confirmed"],
                },
            },
            {
                statusCode: "RejectedConfirmation",
                statusText: "Mangler",
                description:
                    "Ordren har mangler som må utbedres av ordreansvarlig før den kan godkjennes.",
                roles: ["Admin", "Seller"],
                exceptions: {
                    Seller: [
                        "New",
                        "Open",
                        "OpenForManager",
                        "LockedSellers",
                        "LockedGroup",
                        "LockedCustomers",
                        "Confirmed",
                        "SendToProcessing",
                        "Production",
                        "Closed",
                    ],
                },
            },
            {
                statusCode: "Confirmed",
                statusText: "Godkjent",
                description:
                    "Ordren er godkjent av selger og klar til å sendes til produksjon av ordreansvarlig.",
                roles: ["Admin", "Seller"],
                exceptions: {
                    Seller: ["Confirmed"],
                },
            },
            {
                statusCode: "SendToProcessing",
                statusText: "Sendt til produksjon",
                description:
                    "Ordren er godkjent av ordreansvarlig og sendt til produksjon.",
                roles: ["Admin", "Manager"],
            },
            {
                statusCode: "Production",
                statusText: "I produksjon",
                description: "Ordren er sendt til produksjon.",
                roles: ["Admin"],
            },
            {
                statusCode: "Produced",
                statusText: "Produsert",
                description:
                    "Ordren er ferdigprodusert. Kan ikke endres til manuelt.",
                roles: [],
            },
            {
                statusCode: "Closed",
                statusText: "Stengt",
                description:
                    "Ordren er permanent stengt. Den faktureres ikke og det kan ikke gjøres flere endringer.",
                roles: ["Admin", "Seller"],
            },
            {
                statusCode: "Finished",
                statusText: "Ferdig",
                description:
                    "Ordren er ferdigstilt og fakturert. Kan ikke endres til manuelt.",
                roles: [],
            },
            {
                statusCode: "Sent",
                statusText: "Sendt",
                description: "Ordren er sendt. Kan ikke endres til manuelt.",
                roles: [],
            },
            {
                statusCode: "Registered",
                statusText: "Registrert",
                description:
                    "Ordren er registrert. Kan ikke endres til manuelt.",
                roles: [],
            },
            {
                statusCode: "Invoiced",
                statusText: "Fakturert",
                description:
                    "Ordren er fakturert. Kan ikke endres til manuelt.",
                roles: [],
            },
            {
                statusCode: "Stopped",
                statusText: "Stengt",
                description:
                    "Ordren er permanent stengt. Den faktureres ikke og det kan ikke gjøres flere endringer.",
                roles: [],
            },
        ];

        if (user && user.role) {
            return statuses.filter(status => status.roles.includes(user.role));
        } else {
            return statuses;
        }
    }

    static getStatus(statusCode) {
        let statuses = this.getStatuses();
        return statuses.find(o => o.statusCode === statusCode);
    }
}
