import { ProductHelper } from "../Helpers";
let assign = Object.assign || require("object.assign");

const DEFAULT_STATE = {
    allProducts: [],
    allProductsFetching: null,
    allProductsError: null,

    productUnits: [],
    productUnitsFetching: null,
    productUnitsError: null,

    groupProducts: [],
    groupProductsFetching: null,
    groupProductsError: null,

    groupProduct: [],
    groupProductFetching: null,
    groupProductError: null,

    groupManageProducts: [],
    groupManageProductsFetching: null,
    groupManageProductsError: null,

    productImage: {},
    productImagePosting: null,
    productImageError: null,
};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case "GET_ALL_PRODUCTS":
            return assign({}, state, {
                allProducts: action.payload,
                allProductsFetching: false,
            });

        case "GET_ALL_PRODUCTS_FETCHING":
            return assign({}, state, {
                allProductsFetching: action.payload,
                allProductsError: null,
            });

        case "GET_ALL_PRODUCTS_ERROR":
            return assign({}, state, {
                allProductsError: action.payload,
                allProducts: null,
                allProductsFetching: null,
            });
        case "GET_PRODUCT_UNITS":
            // When getting product units, find product by productId in products, add all the units
            let product = state.allProducts.find(
                p => p.id === action.payload.productId
            );
            product.units = action.payload.data;

            return assign({}, state, {
                allProducts: state.allProducts,
                productUnits: action.payload.data,
                productUnitsFetching: false,
            });

        case "GET_PRODUCT_UNITS_FETCHING":
            return assign({}, state, {
                productUnitsFetching: action.payload,
                productUnitsError: null,
            });

        case "GET_PRODUCT_UNITS_ERROR":
            return assign({}, state, {
                productUnitsError: action.payload,
                productUnits: null,
                productUnitsFetching: null,
            });

        case "GET_GROUP_PRODUCTS":
            // Create ProductGroups
            const productGroups = action.payload
                .map(product => ProductHelper.createProductGroups(product))
                .reduce((a, b) => [...a, ...b]);

            return assign({}, state, {
                groupProducts: productGroups,
                groupProductsFetching: false,
            });

        case "GET_GROUP_PRODUCTS_FETCHING":
            return assign({}, state, {
                groupProductsFetching: action.payload,
                groupProductsError: null,
            });

        case "GET_GROUP_PRODUCTS_ERROR":
            return assign({}, state, {
                groupProductsError: action.payload,
                groupProducts: null,
                groupProductsFetching: null,
            });

        case "MANAGE_SORT_ORDER":
        case "GET_GROUP_MANAGE_PRODUCTS":
            // Create ProductGroup
            const productManageGroups = action.payload
                .map(product => ProductHelper.createProductGroups(product))
                .reduce((a, b) => [...a, ...b]);

            return assign({}, state, {
                groupManageProducts: productManageGroups,
                groupManageProductsFetching: false,
            });

        case "MANAGE_SORT_ORDER_POSTING":
        case "GET_GROUP_MANAGE_PRODUCTS_FETCHING":
            return assign({}, state, {
                groupManageProductsFetching: action.payload,
                groupManageProductsError: null,
            });

        case "MANAGE_SORT_ORDER_ERROR":
        case "GET_GROUP_MANAGE_PRODUCTS_ERROR":
            return assign({}, state, {
                groupManageProductsError: action.payload,
                groupManageProducts: null,
                groupManageProductsFetching: null,
            });

        case "MANAGE_PRODUCT":
            let oldProduct = ProductHelper.cloneDeepProduct(
                state.groupManageProducts.find(
                    product => product.productGroupId === action.productGroupId
                )
            );

            let newUnits = action.payload.units.filter(
                unit => unit.color.name === oldProduct.units[0].color.name
            );

            // @consideration create productgroup only for the updated units since api returns ALL units
            // MUST HAVE productGroupId
            assign(
                state.groupManageProducts.find(
                    product => product.productGroupId === action.productGroupId
                ).units,
                newUnits
            );

            return assign({}, state, {
                groupManageProducts: state.groupManageProducts,
                groupManageProductsFetching: false,
            });

        case "MANAGE_PRODUCT_POSTING":
            return assign({}, state, {
                groupManageProductsFetching: action.payload,
                groupManageProductsError: null,
            });

        case "MANAGE_PRODUCT_ERROR":
            // Bad requests when managing products only show a custom error message
            // dispatched to Errors, keep grouManageProducts state
            if (
                action.payload &&
                action.payload.response &&
                action.payload.response.status &&
                action.payload.response.status === 400
            ) {
                return assign({}, state, {
                    groupManageProductsError: null,
                    groupManageProducts: state.groupManageProducts,
                    groupManageProductsFetching: false,
                });
            } else {
                return assign({}, state, {
                    groupManageProductsError: action.payload,
                    groupManageProducts: null,
                    groupManageProductsFetching: null,
                });
            }

        case "UPLOAD_PRODUCT_IMAGE":
            return assign({}, state, {
                productImage: action.payload,
                productImagePosting: false,
            });

        case "UPLOAD_PRODUCT_IMAGE_POSTING":
            return assign({}, state, {
                productImageFetching: action.payload,
                productImageError: null,
            });

        case "UPLOAD_PRODUCT_IMAGE_ERROR":
            return assign({}, state, {
                productImageError: action.payload,
                productImage: null,
                productImagePosting: null,
            });

        // Singular group product
        case "GET_GROUP_PRODUCT_FETCHING":
            return assign({}, state, {
                groupProductFetching: action.payload,
                groupProductError: null,
            });

        case "GET_GROUP_PRODUCT":
            // Create ProductGroups
            const groupProductGroups = ProductHelper.createProductGroups(
                action.payload
            );

            // Swap old group products for new ones
            let newGroupProducts = state.groupProducts.slice();

            groupProductGroups.forEach(product => {
                let index = newGroupProducts.findIndex(
                    p => p.id === product.id && p.colorId === product.colorId
                );
                if (index !== -1) {
                    newGroupProducts.splice(index, 1, product);
                }
            });

            return assign({}, state, {
                groupProduct: action.payload,
                groupProducts: newGroupProducts,
                groupProductFetching: false,
                groupProductError: null,
            });
        case "GET_GROUP_PRODUCT_ERROR":
            return assign({}, state, {
                groupProductError: action.payload,
                groupProduct: null,
                groupProductFetching: null,
            });

        default:
            return state;
    }
}
