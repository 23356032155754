import { Utils } from ".";

export default class ProductHelper {
    static createProductGroup(product, units) {
        const color = units[0].color;

        // @consideration optimize
        let unitsStockQtyLeft = units
            .map(unit => unit.stockQtyLeft)
            .reduce((a, b) => a + b);

        if (isNaN(unitsStockQtyLeft)) {
            unitsStockQtyLeft = null;
        }

        const productGroupId = this.createProductGroupId(product, color);

        // @consideration quicker way of getting the price average
        return {
            ...product,
            productGroupId,
            color,
            colorId: color.id,
            price: this.getProductGroupPrice(units),
            unitsStockQtyLeft: unitsStockQtyLeft,
            units: units,
        };
    }

    /**
     * Sorts all units of a single product into groups
     * @param {Object} product
     * @param {Array} units
     */
    static createProductGroups(product, units = product.units) {
        const allGroups = Utils.uniqueColors(units, "color", "id");

        return allGroups.map(colorId => {
            const groupUnits = units.filter(unit => unit.color.id === colorId);
            return this.createProductGroup(product, groupUnits);
        });
    }

    static cloneDeepProduct(product) {
        return JSON.parse(JSON.stringify(product));
    }

    /**
     * Creates a unique id for the productGroup
     * @param {Object} product
     * @param {Object} color
     */
    static createProductGroupId(product, color) {
        let idFrom;

        if (!color) {
            idFrom = product.name
                .split("")
                .map(s => s.charCodeAt())
                .join("");
        } else {
            idFrom = color.id;
        }

        return `${product.id}-${idFrom}`;
    }

    static isProductHidden(units) {
        if (units.every(unit => unit.isHidden)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Get the expected price of a product grouping by trying for the M then L product,
     * falls back to the first product
     * @param {Array} units
     */
    static getProductGroupPrice(units) {
        let foundUnit = units.find(
            o => o.valueName === "M" || o.valueName === "L"
        );

        if (foundUnit) {
            return foundUnit.price;
        } else {
            return units[0].price;
        }
    }

    /**
     * Returns array of sorted productGroups based on the sortOrder
     * @param {Array} list
     */
    static sortProductGroups(list) {
        return list.sort((a, b) => a.units[0].sortOrder - b.units[0].sortOrder);
    }

    /**
     * Returns array of sorted units based on the valueName (size name)
     * @param {Array} units Array of units with valueName
     */
    static sortUnitSizes(units) {
        const sizesOrder = [
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "3 ÅR",
            "4 ÅR",
            "6 ÅR",
            "8 ÅR",
            "10 ÅR",
            "5XS",
            "4XS",
            "3XS",
            "2XS",
            "XXS",
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL",
        ];
        return units.sort(
            (a, b) =>
                sizesOrder.indexOf(a.valueName.toUpperCase()) -
                sizesOrder.indexOf(b.valueName.toUpperCase())
        );
    }
    // Create array of objects with same colour and all the sizes
    static sortUnits(units) {
        let sortedUnits = [];
        units.forEach(unit => {
            let found = sortedUnits.find(u => u.color.name === unit.color.name);
            if (found) {
                found.units.push({ ...unit });
            } else {
                sortedUnits.push({
                    color: unit.color,
                    units: [{ ...unit }],
                });
            }
        });
        return sortedUnits;
    }

    static getShortName(name) {
        let shortName = name.substring(0, name.indexOf(" "));

        if (!shortName || shortName === " ") {
            return name;
        }

        return shortName;
    }

    static getProductsWithMinimumQty(products) {
        return products.filter(product => product.units[0].minimumQty > 0);
    }
}
