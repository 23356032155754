import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Actions
import {
    updateGroupLogo,
    deleteGroupLogo,
} from "../../Actions/GroupLogoActions";

// Components
import Icon from "../Common/Icon";
import LazyImage from "../Common/LazyImage";

// Helpers
import { LogoHelper } from "../../Helpers";

class LogoSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            placementTypeIds: null,
            darknessType: null,
            textileType: null,
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.deleteLogo = this.deleteLogo.bind(this);
        this.togglePlacement = this.togglePlacement.bind(this);
        this.togglePlacementAll = this.togglePlacementAll.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.getLogoTextileTypeName = this.getLogoTextileTypeName.bind(this);
    }

    componentDidMount() {
        const { logo } = this.props;
        const allPlacementTypes = LogoHelper.getPlacementTypes(this.props);

        this.setState(
            {
                placementTypeIds: allPlacementTypes
                    .filter(
                        type =>
                            logo.placementTypes &&
                            logo.placementTypes.find(
                                placement => placement.id === type.id
                            )
                    )
                    .map(type => type.id),
                darknessType: logo.darknessTypeName,
                textileType: logo.textileTypeName,
            },
            () => {
                if (
                    allPlacementTypes.length ===
                    this.state.placementTypeIds.length
                ) {
                    // Make sure "all" checkbox is checked if all placementTypes are checked
                    if (this.logoPlacementAll) {
                        this.logoPlacementAll.checked = true;
                    }
                }
            }
        );
    }

    toggleModal() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    deleteLogo(logo) {
        const { group } = this.props;

        this.props.deleteGroupLogo({
            logoId: logo.id,
            groupId: group.id,
        });
    }

    togglePlacement(id) {
        const index = this.state.placementTypeIds.indexOf(id);
        const allPlacementTypes = LogoHelper.getPlacementTypes(this.props);

        if (index < 0) {
            this.state.placementTypeIds.push(id);

            // Make sure "all" checkbox is unchecked if unchecking any
            if (this.logoPlacementAll) {
                this.logoPlacementAll.checked = false;
            }
        } else {
            this.state.placementTypeIds.splice(index, 1);
        }

        this.setState(
            { placementTypeIds: [...this.state.placementTypeIds] },
            () => {
                // Make sure "all" checkbox is checked if all placementTypes are checked
                if (
                    allPlacementTypes.length ===
                    this.state.placementTypeIds.length
                ) {
                    if (this.logoPlacementAll) {
                        this.logoPlacementAll.checked = true;
                    }
                }
            }
        );
    }

    togglePlacementAll(e) {
        const { logo } = this.props;

        let checkBoxes = [
            ...document.querySelectorAll(
                `input[name="logo${logo.id}Placements"]`
            ),
        ];

        if (e.target.checked) {
            // Check all, and remove disabled
            checkBoxes.forEach(checkBox => {
                if (!checkBox.checked && !checkBox.disabled) {
                    checkBox.checked = true;
                    this.togglePlacement(parseInt(checkBox.value, 0));
                }
            });
        } else {
            // Uncheck all
            checkBoxes.forEach(checkBox => {
                if (!checkBox.disabled) {
                    let value = parseInt(checkBox.value, 0);
                    checkBox.checked = false;
                    this.togglePlacement(value);
                }
            });
        }
    }

    getLogosSameDarkess() {
        const { logo, groupLogo } = this.props;

        return groupLogo.groupLogos.filter(
            item =>
                (item.darknessType === logo.darknessType &&
                    item.id !== logo.id) ||
                (logo.darknessType === 2 && item.id !== logo.id) ||
                (item.darknessType === 2 && item.id !== logo.id)
        );
    }

    getLogoTextileTypeName(logo) {
        let title = "";

        if (
            logo.textileTypeName &&
            this.props.logoTextileTypes[logo.textileTypeName]
        ) {
            title = this.props.logoTextileTypes[logo.textileTypeName];
        } else {
            title = this.props.logoTextileTypes.default;
        }

        return title;
    }

    saveChanges() {
        const { logo, group } = this.props;

        this.toggleModal();

        this.props.updateGroupLogo({
            groupId: group.id,
            logoId: logo.id,
            placementTypeIds: this.state.placementTypeIds,
            darknessType: this.logoDarknessType.value,
            textileType: this.logoTextileType.value,
        });
    }

    render() {
        const { logo } = this.props;
        const logosSameDarkness = this.getLogosSameDarkess();

        // TODO Optimize logo images when they are served from imgix
        return (
            <div className="dropzone__motif">
                <div className="dropzone__media">
                    <LazyImage
                        src={logo.url ? logo.url : logo.preview}
                        alt={logo.alt ? logo.alt : logo.name}
                        // imgixParams={{
                        //     w: 253,
                        //     h: 144,
                        //     dpr: 1,
                        //     q: 50,
                        //     fit: "clip",
                        // }}
                        // width={253}
                        // height={144}
                        className="img-fluid"
                    />
                </div>
                <div className="bg-white row mx-0 justify-content-center">
                    <Button
                        className="m-2"
                        color="primary"
                        size="sm"
                        title="Logoinstillinger"
                        disabled={this.props.disabled}
                        onClick={this.toggleModal}>
                        Innstillinger
                    </Button>
                    <Button
                        className="m-2"
                        color="danger"
                        size="sm"
                        title="Slett logo"
                        disabled={this.props.disabled}
                        onClick={() => this.deleteLogo(logo)}>
                        <span>Slett</span>

                        <Icon
                            name="delete"
                            fill="#fff"
                            className="dropzone__icon"
                            title="Slett logo"
                        />
                    </Button>
                </div>
                <div className="d-block mt-1">
                    <small
                        className="font-weight-bold"
                        title="Logoen settes inn automatisk på følgende plasseringer">
                        Plasseringer:
                    </small>
                    {logo.placementTypes && logo.placementTypes.length ? (
                        logo.placementTypes.map(type => (
                            <span
                                className="m-1 badge badge-primary"
                                key={type.id}>
                                {LogoHelper.getPlacementName(type.name)}
                            </span>
                        ))
                    ) : (
                        <span className="m-1 badge badge-secondary">
                            Ingen plasseringer
                        </span>
                    )}
                </div>
                {logo.textileTypeName !== "Normal" ? (
                    <div className="d-block mt-1">
                        <span className="badge badge-info">
                            {this.getLogoTextileTypeName(logo)}
                        </span>
                    </div>
                ) : null}

                {this.state.modal && (
                    <Modal
                        isOpen={this.state.modal}
                        className="modal--product"
                        toggle={this.toggleModal}
                        size="lg"
                        centered={true}>
                        <ModalHeader toggle={this.toggleModal}>
                            <span>Innstillinger for logo</span>
                        </ModalHeader>
                        <ModalBody>
                            <div className="row">
                                <div className="col-12 col-lg-6 d-flex align-items-center">
                                    <div className="dropzone__media">
                                        <LazyImage
                                            src={
                                                logo.url
                                                    ? logo.url
                                                    : logo.preview
                                            }
                                            alt={
                                                logo.alt ? logo.alt : logo.name
                                            }
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                                    <div className="form">
                                        <div className="form-group row mb-2">
                                            <div className="col-6 col-form-label col-form-label-sm">
                                                <label
                                                    className="font-weight-bold"
                                                    htmlFor={`logoPlacement${logo.id}`}>
                                                    Tillatte plasseringer på
                                                    plagg
                                                </label>
                                                <small className="d-block mb-2">
                                                    Dersom en plassering er
                                                    utilgjengelig er den
                                                    allerede tatt av en annen
                                                    logo med samme farge.
                                                </small>
                                                <small className="d-block text-danger">
                                                    * Minst én plassering er
                                                    påkrevd for automatisk
                                                    plassering.
                                                </small>
                                            </div>
                                            <div className="col-6">
                                                <div className="row pl-3">
                                                    <div className="col-12 form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="logoPlacementAll"
                                                            ref={el =>
                                                                (this.logoPlacementAll = el)
                                                            }
                                                            onChange={
                                                                this
                                                                    .togglePlacementAll
                                                            }
                                                            defaultChecked={
                                                                logo.placementTypes &&
                                                                LogoHelper.getPlacementTypes(
                                                                    this.props
                                                                ) &&
                                                                logo
                                                                    .placementTypes
                                                                    .length ===
                                                                    LogoHelper.getPlacementTypes(
                                                                        this
                                                                            .props
                                                                    ).length
                                                            }
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="logoPlacementAll">
                                                            Alle
                                                        </label>
                                                    </div>

                                                    {LogoHelper.getPlacementTypes(
                                                        this.props
                                                    ).map(type => (
                                                        <div
                                                            className="col-12 form-check"
                                                            key={type.typeName}>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name={`logo${logo.id}Placements`}
                                                                id={`logo${logo.id}placement${type.id}`}
                                                                disabled={
                                                                    logosSameDarkness.length &&
                                                                    logosSameDarkness.find(
                                                                        logo =>
                                                                            logo.placementTypes.find(
                                                                                placement =>
                                                                                    placement.id ===
                                                                                    type.id
                                                                            )
                                                                    )
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={() =>
                                                                    this.togglePlacement(
                                                                        type.id
                                                                    )
                                                                }
                                                                defaultChecked={
                                                                    logo.placementTypes.find(
                                                                        placement =>
                                                                            placement.id ===
                                                                            type.id
                                                                    )
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={type.id}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`logo${logo.id}placement${type.id}`}>
                                                                {type.name}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row mb-2">
                                            <label
                                                className="font-weight-bold col-6 col-form-label col-form-label-sm"
                                                htmlFor={`logoDarkness${logo.id}`}>
                                                Farge
                                            </label>
                                            <div className="col-6">
                                                <select
                                                    className="form-control form-control-sm"
                                                    id={`logoDarkness${logo.id}`}
                                                    ref={el =>
                                                        (this.logoDarknessType = el)
                                                    }
                                                    defaultValue={
                                                        logo.darknessTypeName
                                                    }>
                                                    {LogoHelper.getDarknessTypes().map(
                                                        type => (
                                                            <option
                                                                key={
                                                                    type.typeName
                                                                }
                                                                value={
                                                                    type.typeName
                                                                }>
                                                                {type.name}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row mb-2">
                                            <label
                                                className="font-weight-bold col-6 col-form-label col-form-label-sm"
                                                htmlFor={`logoTextile${logo.id}`}>
                                                Type trykk
                                            </label>
                                            <div className="col-6">
                                                <select
                                                    className="form-control form-control-sm"
                                                    id={`logoTextile${logo.id}`}
                                                    ref={el =>
                                                        (this.logoTextileType = el)
                                                    }
                                                    defaultValue={
                                                        logo.textileTypeName
                                                    }>
                                                    {LogoHelper.getTextileTypes().map(
                                                        type => (
                                                            <option
                                                                key={
                                                                    type.typeName
                                                                }
                                                                value={
                                                                    type.typeName
                                                                }>
                                                                {type.name}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                className="mr-auto"
                                color="secondary"
                                onClick={this.toggleModal}>
                                Gå tilbake
                            </Button>
                            <Button color="primary" onClick={this.saveChanges}>
                                Lagre endringer
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}
            </div>
        );
    }
}

LogoSettings.propTypes = {
    logo: PropTypes.shape({
        url: PropTypes.string.isRequired,
        placementTypes: PropTypes.array.isRequired,
        textileTypeName: PropTypes.string.isRequired,
        darknessTypeName: PropTypes.string.isRequired,
    }),
    disabled: PropTypes.bool,
};

LogoSettings.defaultProps = {
    disabled: false,
    logoTextileTypes: {
        Normal: "Normal",
        Embroidered: "Brodert",
        default: "Normal",
    },
};

function mapStateToProps(state) {
    return {
        groupLogo: state.groupLogo,
    };
}
export default connect(
    mapStateToProps,
    {
        updateGroupLogo,
        deleteGroupLogo,
    }
)(LogoSettings);
