import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup } from "reactstrap";

// Components
import Product from "./Product";
import Restrict from "../../Auth/Restrict";
import ProductPrice from "./ProductPrice";

// Helpers
import { Seller } from "../../Auth/UserRoles";
import { ProductHelper } from "../../Helpers";

class ProductSettings extends Product {
    constructor(props) {
        super(props);

        this.state = { selectedUnits: [] };

        this.toggleSize = this.toggleSize.bind(this);
        this.validateMinimumQty = this.validateMinimumQty.bind(this);
    }

    componentDidMount() {
        const { product } = this.props;

        // @consideration: Keep track of units that have a different select state than what they mounted as
        product.units.forEach(unit => {
            if (unit.isHidden === false) {
                this.state.selectedUnits.push(unit.id);
            }
        });

        this.setState({ selectedUnits: [...this.state.selectedUnits] });
    }

    validateMinimumQty(e) {
        let target = e.target;
        let value = parseInt(target.value, 0);

        if (value < 0) {
            value = 0;
            target.value = value;
        }

        this.props.handleMinimumQty(value);
    }

    toggleSize(selected) {
        const index = this.state.selectedUnits.indexOf(selected);

        if (index < 0) {
            this.state.selectedUnits.push(selected);
        } else {
            this.state.selectedUnits.splice(index, 1);
        }

        this.setState({ selectedUnits: [...this.state.selectedUnits] }, () => {
            // Return a new units array with new isHidden values based on local state
            let clonedProduct = ProductHelper.cloneDeepProduct(
                this.props.product
            );
            clonedProduct.units = clonedProduct.units.map(unit => {
                if (this.state.selectedUnits.includes(unit.id)) {
                    unit.isHidden = false;
                } else {
                    unit.isHidden = true;
                }
                return unit;
            });

            this.props.onToggleSize(clonedProduct);
        });
    }

    render() {
        const { product } = this.props;
        const representingAllUnits = product.units[0];

        return (
            <div className="row">
                <div className="col-12 col-lg-4">
                    <div className="row no-gutters">
                        {this.renderImages(538, 424)}
                    </div>
                </div>

                <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                    <h4 className="h5">Innstillinger</h4>

                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={this.props.handleNametagToggle}
                            defaultChecked={
                                representingAllUnits.displayTextValue
                            }
                            id={`showTag${product.id}`}
                        />
                        <label
                            className="form-check-label"
                            htmlFor={`showTag${product.id}`}>
                            Vis navntag
                        </label>
                    </div>

                    <Restrict {...this.props} allow={Seller}>
                        <div className="form-group row">
                            <label
                                htmlFor={`modalInlineMinimum${product.id}`}
                                className="col-12 col-form-label">
                                Minimumsantall
                            </label>
                            <div className="col-sm-4">
                                <input
                                    type="number"
                                    className="form-control"
                                    id={`modalInlineMinimum${product.id}`}
                                    min={0}
                                    step={1}
                                    style={{ minWidth: 100 + "px" }}
                                    defaultValue={
                                        representingAllUnits.minimumQty
                                    }
                                    onChange={this.validateMinimumQty}
                                />
                            </div>
                        </div>
                    </Restrict>

                    <h4 className="h5 mt-2">Størrelser</h4>
                    {/* <p className="text-secondary">
                        Velg tilgjengelige størrelser
                    </p> */}

                    <div className="row no-gutters">
                        <ButtonGroup className="pr-1 flex-wrap">
                            {ProductHelper.sortUnitSizes(product.units).map(
                                unit => (
                                    <Button
                                        key={`size-${unit.id}`}
                                        size="sm"
                                        color={
                                            this.state.selectedUnits.includes(
                                                unit.id
                                            )
                                                ? "primary"
                                                : "secondary"
                                        }
                                        onClick={() =>
                                            this.toggleSize(unit.id)
                                        }>
                                        {unit.valueName}
                                    </Button>
                                )
                            )}
                        </ButtonGroup>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                    <h4 className="h5">Pris</h4>
                    <ProductPrice
                        product={product}
                        handleCustomPrice={this.props.handleCustomPrice}
                    />
                </div>
            </div>
        );
    }
}

ProductSettings.propTypes = {
    name: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
};

ProductSettings.defaultProps = {
    name: "Default title",
    items: [],
};

export default ProductSettings;
