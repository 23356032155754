import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from 'prop-types';

// Components
import DataLayerGroup from "./DataLayerGroup";
import Layout from "../Components/Group/Layout";
import Customers from "../Components/Customers/Customers";
import Restrict from "../Auth/Restrict";

// Helpers
import { Manager } from "../Auth/UserRoles";
import { TitleHelper, CustomerHelper, config } from "../Helpers";

class GroupCustomers extends Component {
    componentDidMount() {
        TitleHelper.setTitle("Medlemmer");
    }

    render() {
        const { group } = this.props;
        return (
            <DataLayerGroup {...this.props}>
                <Layout {...this.props}>
                    <div className="products container">
                        <div className="row mb-2">
                            <div className="col">
                                <h2>
                                    Medlemmer (
                                    {group &&
                                    group.group &&
                                    group.group.customers &&
                                    group.group.customers.length
                                        ? CustomerHelper.getCustomers(
                                              this.props
                                          ).length
                                        : null}
                                    )
                                </h2>
                                {group &&
                                group.group &&
                                group.group.externalCommunityGroupId ? (
                                    <Restrict {...this.props} allow={Manager}>
                                        <a
                                            href={`${config.smsInvitationUrl}/${
                                                group.group
                                                    .externalCommunityGroupId
                                            }`}>
                                            Inviter medlemmer på SMS
                                        </a>
                                    </Restrict>
                                ) : null}
                            </div>
                        </div>

                        <div className="row mb-4">
                            {group && <Customers {...this.props} />}
                        </div>
                    </div>
                </Layout>
            </DataLayerGroup>
        );
    }
}

GroupCustomers.propTypes = {};
GroupCustomers.defaultProps = {};

function mapStateToProps(state) {
    return {
        group: state.group,
        errors: state.errors,
    };
}
export default connect(mapStateToProps)(GroupCustomers);
