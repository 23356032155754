import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { addNotification } from "../../Actions/NotificationsActions";
import {
    setGroupStatus,
    setGroupShop,
    setGroupDeadline,
} from "../../Actions/GroupActions";

// Components
import BigDropdown from "./BigDropdown";
import DatePicker from "./DatePicker";
import NotificationStatusModal from "../Notification/NotificationStatusModal";

// Helpers
import Restrict from "../../Auth/Restrict";
import { Seller } from "../../Auth/UserRoles";
import { GroupHelper, GroupsHelper } from "../../Helpers";

class SettingsGeneral extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            selectedInput: null,
            statusCode: "Open",
            statusText: "",
        };

        this.newMessage = React.createRef();
        this.newSendToManager = React.createRef();

        this.toggleModal = this.toggleModal.bind(this);
        this.updateGroupStatus = this.updateGroupStatus.bind(this);
        this.changeLocalGroupStatus = this.changeLocalGroupStatus.bind(this);
        this.changeGroupShop = this.changeGroupShop.bind(this);
        this.changeOrderDeadline = this.changeOrderDeadline.bind(this);
        this.resetLocalGroupStatus = this.resetLocalGroupStatus.bind(this);
    }

    componentDidMount() {
        if (this.props.group.groupFetching === false) {
            this.setState({
                statusCode: this.props.group.group.status.statusCode,
            });
        }
    }

    toggleModal() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    /*
    getSelectedOptionText(e) {
        if (e.selectedIndex === -1) return null;

        return e.options[e.selectedIndex].text;
    }
    */

    // LOCAL ONLY -> To show the status text in the modal
    changeLocalGroupStatus(e) {
        this.setState(
            {
                selectedInput: e.target,
                statusCode: e.target.value,
                statusText: GroupsHelper.getStatus(e.target.value).statusText,
            },
            () => {
                this.toggleModal();
            }
        );
    }

    changeOrderDeadline(day) {
        const deadline = new Date(day).toISOString();

        this.props.setGroupDeadline({
            groupId: this.props.group.group.id,
            deadline,
        });
    }

    // Resets the local state to the action group status
    resetLocalGroupStatus() {
        this.setState(
            {
                selectedInput: null,
                statusCode: this.props.group.group.status.statusCode,
                statusText: this.props.group.group.status.statusText,
            },
            () => {
                this.toggleModal();
            }
        );
    }

    changeGroupShop(e) {
        this.props.setGroupShop({
            groupId: this.props.group.group.id,
            shopType: e.target.value,
        });
    }

    updateGroupStatus() {
        this.toggleModal();

        if (
            this.newMessage &&
            this.newMessage.current &&
            this.newMessage.current.value
        ) {
            this.props.addNotification({
                groupId: this.props.group.group.id,
                message: this.newMessage.current.value,
                canBeSeenByManager: this.newSendToManager.current.checked,
            });
        }

        this.props.setGroupStatus({
            groupId: this.props.group.group.id,
            orderState: this.state.statusCode,
            isLock: true,
        });
    }

    getGroupStatusOptions() {
        const { user, group } = this.props;

        if (user.user && user.user.role && group.group.status) {
            const userRole = user.user.role;
            const currentStatus = group.group.status.statusCode;
            let availableStatuses = GroupsHelper.getStatuses(user.user);

            // If the current group status isn't available to the current user,
            // then add it disabled to the beginning of the array of options
            if (
                !availableStatuses.find(
                    status => status.statusCode === currentStatus
                )
            ) {
                availableStatuses.unshift({
                    ...GroupsHelper.getStatus(currentStatus),
                    attr: { disabled: true },
                });
            }

            // Always disable the new / fresh status since an group is only new from the api
            let newStatus = availableStatuses.find(
                status => status.statusCode === "New"
            );
            if (newStatus) {
                newStatus.attr = { disabled: true };
            }

            // Disable group status selections based on role and current status
            availableStatuses.forEach(status => {
                if (
                    status.exceptions &&
                    status.exceptions[userRole] &&
                    status.exceptions[userRole].length &&
                    status.exceptions[userRole].includes(currentStatus)
                ) {
                    status.attr = { disabled: true };
                }
            });

            return availableStatuses;
        }
    }

    render() {
        const currentOrder = GroupHelper.getCurrentOrder(
            this.props.group.group
        );

        return (
            <>
                <Restrict {...this.props} allow={Seller}>
                    <div className="row mb-2">
                        <div className="col">
                            <h2>Generelt</h2>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-12 col-md-6">
                            <BigDropdown
                                onChange={this.changeLocalGroupStatus}
                                value={this.state.statusCode}
                                valueText={
                                    GroupsHelper.getStatus(
                                        this.state.statusCode
                                    ).statusText
                                }
                                items={this.getGroupStatusOptions()}
                            />
                        </div>

                        <div className="col-12 col-md-6 col-lg-auto">
                            <label
                                className="my-1 mr-2 font-weight-bold"
                                htmlFor="orderDeadline">
                                Deadline for bestilling
                            </label>

                            <DatePicker
                                onDayChange={this.changeOrderDeadline}
                                value={currentOrder.deadlineAt}
                                disabled={GroupHelper.disableWhenLocked(
                                    this.props,
                                    ["Seller"]
                                )}
                            />
                        </div>

                        {/* TODO: Implement changeShopType when Admin role has been implemented in API and community */}
                        {/* <Restrict {...this.props} allow={Admin}>
            <div className="col-12 col-md-6 col-lg-auto">
                <div className="form-inline">
                    <label
                        className="my-1 mr-2 font-weight-bold"
                        htmlFor="changeShopType">
                        Tilhørighet
                    </label>

                    {this.props.group.groupFetching ===
                        false &&
                    this.props.group.group.customers
                        .length ? (
                        <select
                            className="form-control my-1 mr-sm-2"
                            onChange={this.changeGroupShop}
                            id="changeShopType"
                            defaultValue={
                                this.props.group.group.shop
                                    .name
                            }
                            disabled={GroupHelper.disableWhenLocked(
                                this.props,
                                ["Seller", "Manager"]
                            )}>
                            {GroupHelper.getShopTypes().map(
                                shop => (
                                    <option
                                        key={shop.shopType}
                                        value={
                                            shop.shopType
                                        }>
                                        {shop.name}
                                    </option>
                                )
                            )}
                        </select>
                    ) : null}
                </div>
            </div>
        </Restrict> */}
                    </div>
                </Restrict>

                <NotificationStatusModal
                    {...this.props}
                    state={this.state}
                    newMessage={this.newMessage}
                    newSendToManager={this.newSendToManager}
                    toggleModal={this.toggleModal}
                    resetLocalGroupStatus={this.resetLocalGroupStatus}
                    updateGroupStatus={this.updateGroupStatus}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        notifications: state.notifications,
        group: state.group,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        addNotification,
        setGroupStatus,
        setGroupShop,
        setGroupDeadline,
    }
)(SettingsGeneral);
