import React from "react";

export default function SliderNextArrow(props) {
    const { fill = "#ffffff", className, style, onClick } = props;

    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24">
                <path
                    fill={fill}
                    d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"
                />
            </svg>
        </div>
    );
}
