import React from "react";
import AppShell from "../AppShell/AppShell";

export default class LogoSettingsAppshell extends AppShell {
    renderShell(i) {
        return (
            <div
                className="shell col-6 col-md-4 col-xl-3 my-3"
                key={`shell-${i}`}>
                <div className="dropzone__motif">
                    <div className="dropzone__media">
                        <img
                            className="shell__img img-fluid"
                            src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 300 120'%2F%3E"
                            alt="Laster bilde"
                        />
                    </div>
                    <div className="bg-white row mx-0 justify-content-center">
                        <span className="shell__btn m-2" />
                        <span className="shell__btn m-2" />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return <div className="row">{this.createAppShell(3)}</div>;
    }
}
