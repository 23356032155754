import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, FormGroup, Label, Input } from "reactstrap";
//import PropTypes from "prop-types";

// Actions
import { createGroup } from "../Actions/GroupActions";
import { resetSearch, getCustomerAndGroup } from "../Actions/SearchActions";

// Components
import InviteSearch from "../Components/Customers/InviteSearch";
import Customer from "../Components/Customers/Customer";
import AdminLayout from "../Components/Admin/AdminLayout";
//import PageError from "./PageError";

// Helpers
import { TitleHelper } from "../Helpers";

class AdminGroupCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            universityCustomerCode: null,
            communityUserId: null,
            communityGroupId: null,
            searchContent: "",
            invitedCustomer: null,
        };

        this.submitCreateGroup = this.submitCreateGroup.bind(this);
        this.inviteSearch = this.inviteSearch.bind(this);
        this.inviteSearchChange = this.inviteSearchChange.bind(this);
        this.inviteClient = this.inviteClient.bind(this);
        this.removeClient = this.removeClient.bind(this);
        this.handleCustomerCode = this.handleCustomerCode.bind(this);
    }

    componentDidMount() {
        TitleHelper.setTitle("Admin - Opprett ny gruppebestilling");

        // Reset search state each time we navigate here
        this.props.resetSearch();
    }

    inviteClient(customer) {
        // Take all user data and put it into a local state object
        // Clear the search result
        // Render a customer card from the local state object

        this.setState({
            invitedCustomer: { ...customer, isClient: true },
            communityUserId: customer.customer.userId,
            communityGroupId: customer.customer.vehicleId,
        });
    }

    removeClient() {
        this.resetState();
    }

    resetState() {
        this.setState({
            invitedCustomer: null,
            communityUserId: null,
            communityGroupId: null,
        });
    }

    inviteSearch(e) {
        e.preventDefault();
        this.resetState();
        this.props.getCustomerAndGroup(this.state.searchContent);
    }

    inviteSearchChange(searchValue) {
        this.setState({ searchContent: searchValue });
    }

    renderCreateButton() {
        if (
            this.state.universityCustomerCode &&
            this.state.communityUserId &&
            this.state.communityGroupId
        ) {
            return true;
        } else {
            return false;
        }
    }

    renderSearch() {
        if (this.state.invitedCustomer) {
            const customer = this.state.invitedCustomer;
            const classNames =
                customer.groups && customer.groups.length
                    ? "col-12 mb-3"
                    : "col-6 mb-3";

            return (
                <div className="row mb-4">
                    <div className={classNames}>
                        <Customer
                            {...this.props}
                            onRemove={this.removeClient}
                            customer={this.state.invitedCustomer}
                        />
                    </div>
                </div>
            );
        }

        if (this.props.search.searchPosting) {
            return (
                <div className="row mb-4">
                    <div className="col">
                        <p>Søker...</p>
                    </div>
                </div>
            );
        } else if (
            this.props.search.searchPosting === false &&
            this.props.search.search.length
        ) {
            return (
                <div className="row mb-4">
                    {this.props.search.search.map((customer, i) => {
                        const classNames =
                            customer.groups && customer.groups.length
                                ? "col-12 mb-3"
                                : "col-6 mb-3";

                        return (
                            <div className={classNames} key={`section-${i}`}>
                                <Customer
                                    {...this.props}
                                    invitation
                                    customer={customer}
                                    onInvite={() => this.inviteClient(customer)}
                                    inviteText="Legg til kunde"
                                />
                            </div>
                        );
                    })}
                </div>
            );
        } else if (
            this.props.search.searchError &&
            this.props.search.searchError.error &&
            this.props.search.searchError.error.response &&
            this.props.search.searchError.error.response.status &&
            this.props.search.searchError.error.response.status === 404
        ) {
            return (
                <div className="row mb-4">
                    <div className="col">
                        <p>
                            Ingen resultater for{" "}
                            <b>"{this.props.search.searchError.email}"</b>
                        </p>
                    </div>
                </div>
            );
        }
    }

    renderCustomerCodeError() {
        if (
            this.props.group.createGroupError &&
            this.props.group.createGroupError.error &&
            this.props.group.createGroupError.payload &&
            this.props.group.createGroupError.error.response &&
            this.props.group.createGroupError.error.response.status &&
            this.props.group.createGroupError.error.response.status === 404
        ) {
            return (
                <div className="row mb-4">
                    <div className="col">
                        <div className="alert alert-danger">
                            <p className="mb-0">
                                Finner ikke kunde i University med kundekode{" "}
                                <b>
                                    "
                                    {
                                        this.props.group.createGroupError
                                            .payload.universityCustomerCode
                                    }
                                    "
                                </b>
                            </p>
                        </div>
                    </div>
                </div>
            );
        }
    }

    submitCreateGroup(e) {
        e.preventDefault();

        if (this.renderCreateButton()) {
            this.props.createGroup({
                universityCustomerCode: this.state.universityCustomerCode,
                communityUserId: this.state.communityUserId,
                communityGroupId: this.state.communityGroupId,
            });
        }
    }

    handleCustomerCode(e) {
        this.setState({ universityCustomerCode: e.target.value });
    }

    render() {
        return (
            <AdminLayout {...this.props}>
                <div className="container mb-4">
                    <h1>Opprett ny gruppebestilling</h1>
                    <ul className="mb-5">
                        <li>
                            <strong>
                                Opprettele av gruppebestilling gjøres kun én
                                gang per gruppe
                            </strong>
                        </li>
                        <li>
                            Nye ordre på en eksisterende gruppe opprettes
                            automatisk hver gang en ordre stenges eller sendes
                            til produksjon
                        </li>
                        <li>
                            Kunden må opprettes i University før en
                            gruppebestilling kan opprettes
                        </li>
                    </ul>

                    {!this.state.invitedCustomer ? (
                        <div className="row mb-4">
                            <div className="col-12 col-md-6">
                                <InviteSearch
                                    title="1. Legg til gruppe"
                                    onChange={this.inviteSearchChange}
                                    onSearch={this.inviteSearch}>
                                    <ul>
                                        <li>
                                            Søk opp et medlem i gruppen på
                                            russ.no med epost og legg dem til i
                                            gruppebestillingen
                                        </li>
                                        <li>
                                            <strong>
                                                Kunden må være medlem av en
                                                gruppe for å kunne inviteres
                                            </strong>
                                        </li>
                                    </ul>
                                </InviteSearch>
                            </div>
                        </div>
                    ) : null}

                    {this.renderSearch()}

                    <form onSubmit={this.submitCreateGroup}>
                        {this.state.invitedCustomer ? (
                            <div className="row mb-4">
                                <div className="col-12">
                                    <h3>2. Skriv inn kundekode</h3>
                                </div>
                                <div className="col-12 col-md-6">
                                    <FormGroup>
                                        <Label for="universityCustomerCode">
                                            Kundekode i University
                                        </Label>
                                        <Input
                                            type="text"
                                            name="universityCustomerCode"
                                            id="universityCustomerCode"
                                            placeholder="Skriv inn kundekode..."
                                            onChange={this.handleCustomerCode}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        ) : null}

                        {this.renderCustomerCodeError()}

                        {/* @consideration Admin can set which shop the group belongs to? */}

                        {this.renderCreateButton() ? (
                            <>
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <h3>3. Opprett ny gruppebestilling</h3>
                                    </div>
                                </div>
                                <Button
                                    type="submit"
                                    color="success"
                                    onClick={this.submitCreateGroup}>
                                    Opprett ny gruppebestilling
                                </Button>
                            </>
                        ) : null}
                    </form>
                </div>
            </AdminLayout>
        );
    }
}

AdminGroupCreate.propTypes = {};
AdminGroupCreate.defaultProps = {};

function mapStateToProps(state) {
    return {
        search: state.search,
        group: state.group,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        createGroup,
        resetSearch,
        getCustomerAndGroup,
    }
)(AdminGroupCreate);
