import React, { Component } from "react";
import { connect } from "react-redux";
//import PropTypes from 'prop-types';
import { getAdditions } from "../../Actions/AdditionsActions";
import AdditionProduct from "./AdditionProduct";
import AdditionItemAppshell from "./AdditionItemAppshell";
import { GroupHelper } from "../../Helpers";

class AdditionsRow extends Component {
    componentDidMount() {
        if (this.props.additions.addedAdditionsFetching !== false) {
            this.props.getAdditions({
                groupId: this.props.match.params.id,
                orderId: GroupHelper.getCurrentOrder(this.props.group.group).id,
            });
        }
    }

    render() {
        // Echo possible error messages or that there is no dataz etc
        if (this.props.additions.addedAdditionsError) return null;

        // Echo appshell while loading dataz
        if (this.props.additions.addedAdditionsFetching === true)
            return <AdditionItemAppshell />;

        // Fetching is done and we got dataz
        if (
            this.props.additions.addedAdditionsFetching === false &&
            this.props.additions.addedAdditions.length
        ) {
            return this.props.additions.addedAdditions.map((item, i) => (
                <AdditionProduct
                    {...this.props}
                    product={item}
                    key={`section-${i}`}
                />
            ));
        }

        // None of the above
        return null;
    }
}

AdditionsRow.propTypes = {};
AdditionsRow.defaultProps = {};

function mapStateToProps(state) {
    return {
        group: state.group,
        additions: state.additions,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        getAdditions,
    }
)(AdditionsRow);
