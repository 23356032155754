import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
//import PropTypes from 'prop-types';

// Actions
import { updateGroup, setGroupStatus } from "../../Actions/GroupActions";

// Components
import Subheader from "../Common/Subheader";
import Header from "./Header";
import Navigation from "./Navigation";
import Restrict from "../../Auth/Restrict";
import Action from "../Common/Action";
import AlertHandler from "../Common/AlertHandler";
import LockedAlert from "./LockedAlert";
import ErrorBoundary from "../Common/ErrorBoundary";

// Helpers
import { Seller, ManagerOnly } from "../../Auth/UserRoles";
import { Utils, config } from "../../Helpers";

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            hasTriggeredModal: false,
        };
        this.handleGroupStatus = this.handleGroupStatus.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getManager = this.getManager.bind(this);
    }

    componentDidMount() {
        const { group } = this.props;

        if (
            group &&
            group.groupUpdate &&
            group.groupUpdateType &&
            this.state.hasTriggeredModal === false
        ) {
            this.setState({
                hasTriggeredModal: true,
                modal: true,
            });
        }
    }

    closeModal() {
        this.props.updateGroup(null);
        this.setState(
            {
                modal: false,
                hasTriggeredModal: false,
            },
            () => {
                this.props.history.push(
                    `/group/${this.props.match.params.id}/overview`
                );
                // TODO remove window location reload when we can get the new order immediately
                window.location.reload();
            }
        );
    }

    handleGroupStatus(orderState) {
        this.props.setGroupStatus({
            orderState: orderState,
            groupId: this.props.group.group.id,
        });
    }

    getUpdatedModalTitle(orderState) {
        switch (orderState) {
            case "Production":
            case "SendToProcessing":
                return "Bestillingen er sendt til produksjon!";
            case "Closed":
                return "Bestillingen er stengt.";
            case "Finished":
                return "Bestillingen er ferdigstilt.";
            default:
                return "Bestillingen er oppdatert!";
        }
    }

    getManager() {
        if (
            this.props.group &&
            this.props.group.group &&
            this.props.group.group.customers
        ) {
            return this.props.group.group.customers.find(
                c => c.customerType.name === "Manager"
            );
        }
    }

    render() {
        const { group } = this.props.group;
        const manager = this.getManager();

        return (
            <ErrorBoundary>
                <AlertHandler {...this.props} />

                <Restrict {...this.props} allow={Seller}>
                    <Subheader linkTo="/admin" linkName="Admin" />
                </Restrict>

                <Header {...group} />

                {group && ["New"].includes(group.status.statusCode) ? (
                    <Restrict {...this.props} allow={Seller}>
                        <div className="container">
                            <Action>
                                <Action.Text>
                                    <p className="lead">
                                        Åpne bestillingen for ordreansvarlig når
                                        den er klargjort.
                                    </p>
                                    <p className="mb-0">
                                        Kun ordreansvarlig kan åpne og lukke
                                        bestillingen for medlemmer etter dette.
                                    </p>
                                </Action.Text>

                                <Action.Button
                                    onClick={() =>
                                        this.handleGroupStatus("OpenForManager")
                                    }>
                                    Åpne for ordreansvarlig
                                </Action.Button>
                            </Action>
                        </div>
                    </Restrict>
                ) : null}

                {group &&
                ["RequestingConfirmation"].includes(group.status.statusCode) ? (
                    <Restrict {...this.props} allow={Seller}>
                        <div className="container">
                            <Action
                                color={
                                    manager && !manager.isCreditVerified
                                        ? "danger"
                                        : "primary"
                                }>
                                <Action.Text>
                                    <p className="lead">
                                        Bestillingen er klargjort av
                                        ordreansvarlig
                                        {manager && !manager.isCreditVerified
                                            ? ", men ordreansvarlig er ikke kredittverifisert"
                                            : null}
                                    </p>
                                    <p className="mb-0">
                                        Send den tilbake til gruppen for
                                        korrigering dersom den har mangler,
                                        eller klargjør bestillingen ved å legge
                                        til tilleggsprodukter med mer. Når
                                        bestillingen er klar kan den godkjennes.
                                        En godkjent bestilling kan sendes til
                                        produksjon av ordreansvarlig.
                                    </p>
                                </Action.Text>

                                <Action.Button
                                    outline
                                    color="light"
                                    onClick={() =>
                                        this.handleGroupStatus(
                                            "RejectedConfirmation"
                                        )
                                    }>
                                    Bestillingen har mangler
                                </Action.Button>

                                <Action.Button
                                    onClick={() =>
                                        this.handleGroupStatus("Confirmed")
                                    }>
                                    Godkjenn bestilling
                                </Action.Button>
                            </Action>
                        </div>
                    </Restrict>
                ) : null}

                <Navigation {...this.props} />

                <main className="main mb-4" id="main">
                    <div className="container">
                        {group &&
                        !Utils.isPage(this.props, "help") &&
                        [
                            "Open",
                            "OpenForManager",
                            "RejectedConfirmation",
                            "LockedCustomers",
                        ].includes(group.status.statusCode) ? (
                            <Restrict {...this.props} allow={ManagerOnly}>
                                <Action>
                                    <Action.Text>
                                        Send bestillingen til godkjennelse av
                                        selger dersom den er klar
                                    </Action.Text>

                                    <Action.Button
                                        onClick={() =>
                                            this.handleGroupStatus(
                                                "RequestingConfirmation"
                                            )
                                        }>
                                        Send til godkjennelse
                                    </Action.Button>
                                </Action>
                            </Restrict>
                        ) : null}

                        {group &&
                        !Utils.isPage(this.props, "help") &&
                        ["Confirmed"].includes(group.status.statusCode) ? (
                            <Restrict {...this.props} allow={ManagerOnly}>
                                <div className="container px-0">
                                    <Action>
                                        <Action.Text>
                                            <p className="lead">
                                                Selger har godkjent
                                                bestillingen!
                                            </p>
                                            <p className="mb-0">
                                                Send bestillingen til produksjon
                                                dersom den er klar, eller velg
                                                "Rediger bestilling" for å kunne
                                                redigere den.{" "}
                                                <strong>
                                                    Merk at bestillingen må
                                                    godkjennes av selger på nytt
                                                    dersom den redigeres.
                                                </strong>
                                            </p>
                                        </Action.Text>

                                        <Action.Button
                                            outline
                                            color="light"
                                            onClick={() =>
                                                this.handleGroupStatus(
                                                    "RejectedConfirmation"
                                                )
                                            }>
                                            Rediger bestilling
                                        </Action.Button>

                                        <Action.Button
                                            onClick={() =>
                                                this.handleGroupStatus(
                                                    "SendToProcessing"
                                                )
                                            }>
                                            Send til produksjon
                                        </Action.Button>
                                    </Action>
                                </div>
                            </Restrict>
                        ) : null}

                        {group &&
                        !Utils.isPage(this.props, "help") &&
                        [
                            "OpenForManager",
                            "LockedCustomers",
                            "RejectedConfirmation",
                        ].includes(group.status.statusCode) ? (
                            <Restrict {...this.props} allow={ManagerOnly}>
                                <Action>
                                    <Action.Text>
                                        Åpne bestillingen for å la medlemmer
                                        legge til produkter
                                    </Action.Text>

                                    <Action.Button
                                        onClick={() =>
                                            this.handleGroupStatus("Open")
                                        }>
                                        Åpne bestilling
                                    </Action.Button>
                                </Action>
                            </Restrict>
                        ) : null}

                        {group &&
                        !Utils.isPage(this.props, "help") &&
                        ["Open"].includes(group.status.statusCode) ? (
                            <Restrict {...this.props} allow={ManagerOnly}>
                                <Action>
                                    <Action.Text>
                                        Lås bestillingen for å redigere
                                        medlemmers handlekurver
                                    </Action.Text>
                                    <Action.Button
                                        onClick={() =>
                                            this.handleGroupStatus(
                                                "LockedCustomers"
                                            )
                                        }>
                                        Lås bestilling
                                    </Action.Button>
                                </Action>
                            </Restrict>
                        ) : null}

                        {group && !Utils.isPage(this.props, "help") ? (
                            <LockedAlert {...this.props} />
                        ) : null}

                        {this.props.group &&
                        this.props.group.groupUpdate &&
                        this.props.group.groupUpdateType ? (
                            <Modal
                                isOpen={this.state.modal}
                                toggle={this.closeModal}
                                centered={true}>
                                <ModalHeader toggle={this.closeModal}>
                                    <span>
                                        {this.getUpdatedModalTitle(
                                            this.props.group.groupUpdateType
                                        )}
                                    </span>
                                </ModalHeader>
                                <ModalBody>
                                    <Restrict {...this.props} allow={Seller}>
                                        <p>
                                            En ny, fersk ordre er lagt til i
                                            gruppen.
                                        </p>
                                    </Restrict>
                                    <Restrict
                                        {...this.props}
                                        allow={ManagerOnly}>
                                        <p>
                                            Kontakt din selger dersom du ønsker
                                            å opprette en ny bestilling.
                                        </p>
                                    </Restrict>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        className="mr-auto"
                                        color="secondary"
                                        onClick={this.closeModal}>
                                        Lukk
                                    </Button>

                                    <a
                                        href={config.feedUrl}
                                        className="btn btn-primary">
                                        Tilbake til russ.no
                                    </a>
                                </ModalFooter>
                            </Modal>
                        ) : null}
                    </div>
                    {this.props.children}
                </main>
            </ErrorBoundary>
        );
    }
}

Layout.propTypes = {};
Layout.defaultProps = {};

function mapStateToProps(state) {
    return {
        group: state.group,
    };
}
export default connect(
    mapStateToProps,
    {
        updateGroup,
        setGroupStatus,
    }
)(Layout);
