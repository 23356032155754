import React, { Component } from "react";
import PropTypes from "prop-types";
import { LogoHelper } from "../../Helpers";

class LogoPlacement extends Component {
    render() {
        return (
            <div
                className={`placeholder placeholder--active-${
                    this.props.active
                } ${
                    this.props.placement.imageId !== this.props.imageId
                        ? "is-hidden"
                        : ""
                }`}
                style={{
                    top: `${this.props.top}px`,
                    left: `${this.props.left}px`,
                    width: `${this.props.width}px`,
                    height: `${this.props.height}px`,
                    transform: `rotate(${this.props.angle}deg)`,
                    transformOrigin: "left top",
                }}>
                {this.props.active ? (
                    <button
                        type="button"
                        className={`placeholder__area placeholder__area--active-${
                            this.props.active
                        }`}
                        title={`${this.props.title}${
                            this.props.placement.placementType &&
                            this.props.placement.placementType.name
                                ? ` på ${LogoHelper.getPlacementName(
                                      this.props.placement.placementType.name
                                  )}`
                                : ""
                        }`}
                        onClick={() => this.props.onClick()}
                    />
                ) : (
                    <div
                        className={`placeholder__area placeholder__area--active-${
                            this.props.active
                        }`}
                    />
                )}

                {this.props.active ? (
                    <div className="placeholder__marker" />
                ) : null}
            </div>
        );
    }
}

LogoPlacement.propTypes = {
    placement: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    angle: PropTypes.number,
    id: PropTypes.string,
    title: PropTypes.string,
};

LogoPlacement.defaultProps = {
    title: "Legg til logo",
    top: 30,
    left: 30,
    width: 50,
    height: 50,
    angle: 0,
};

export default LogoPlacement;
