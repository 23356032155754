import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from 'prop-types';

// Components
import DataLayerGroup from "./DataLayerGroup";
import Layout from "../Components/Group/Layout";
import OrdersArchive from "../Components/Group/OrdersArchive";

// Helpers
import { TitleHelper } from "../Helpers";

class GroupTracking extends Component {
    componentDidMount() {
        TitleHelper.setTitle("Sporing");
    }

    render() {
        return (
            <DataLayerGroup {...this.props}>
                <Layout {...this.props}>
                    <div className="container">
                        {this.props.group.group ? (
                            <OrdersArchive
                                {...this.props}
                                showPastOrders={false}
                            />
                        ) : null}
                    </div>
                </Layout>
            </DataLayerGroup>
        );
    }
}

GroupTracking.propTypes = {};
GroupTracking.defaultProps = {};

function mapStateToProps(state) {
    return {
        group: state.group,
        errors: state.errors,
    };
}
export default connect(mapStateToProps)(GroupTracking);
