import "../styles/logo-placer.css";

// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Actions
import {
    getPlacements,
    getAllLogoPlacements,
    getLogoPlacements,
    setLogoPlacements,
    getGroupLogos,
} from "../Actions/GroupLogoActions";
import { getProductUnits } from "../Actions/ProductsActions";

// Components
import LogoPlacer from "./LogoPlacer";

// Helpers
import { PlacerHelper, Utils } from "../Helpers";

class LogoLoad extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: null,
            product: null,
            unit: null,
            itemImageTypes: null,
            itemViews: null,
            imageLoading: null,
            shouldLoadImages: null,
            typeName: null,
            imageId: null,
            width: null,
            height: null,
            logos: null,
            placements: [],
            logoPlacements: [],
            savedlogoPlacements: [],
            placementsLoaded: false,
            showOverlays: true,
            shouldDrawLogoPlacements: false,
        };

        this.editor = React.createRef();
        this.fabric = window.fabric;
        this.canvas = new this.fabric.Canvas();

        this.logoAdd = this.logoAdd.bind(this);
        this.logoReset = this.logoReset.bind(this);
        this.logoResetAll = this.logoResetAll.bind(this);
        this.logoResetAllToDefault = this.logoResetAllToDefault.bind(this);

        this.saveLogoPlacements = this.saveLogoPlacements.bind(this);
        this.drawLogoPlacements = this.drawLogoPlacements.bind(this);
        this.getLogoGroupTitle = this.getLogoGroupTitle.bind(this);
        this.getLogoTextileTypeName = this.getLogoTextileTypeName.bind(this);
        this.getDefaultLogoForPlacement = this.getDefaultLogoForPlacement.bind(
            this
        );
        this.handleLogoProps = this.handleLogoProps.bind(this);
        this.itemHasImages = PlacerHelper.itemHasImages.bind(this);
        this.itemHasPlacements = PlacerHelper.itemHasPlacements.bind(this);
        this.placementHasLogo = this.placementHasLogo.bind(this);
    }

    // TODO, cancel any async tasks or subscriptions
    /*
    componentWillUnmount() {
        // this.props.getProductUnits
        // PlacerHelper.handleNavigation();
        // PlacerHelper.handleUnitLoading
        // PlacerHelper.itemImageHandler
        // this.props.getAllLogoPlacements
        // this.props.getPlacements
    }
    */

    // Load first product, or product from this.props.match.params.productId
    // TODO, always init / load product and navigate to first available product unit.
    // Never go to just product
    componentDidMount() {
        const { products } = this.props;
        PlacerHelper.handleInit(this);

        if (
            this.props.match.params.productId &&
            !products.productUnitsFetching
        ) {
            this.props.getProductUnits(this.props.match.params.productId);
        }
    }

    componentDidUpdate(prevProps) {
        const { groupLogo } = this.props;

        PlacerHelper.handleNavigation(this, prevProps);
        PlacerHelper.handleUnitLoading(this, prevProps);

        // Prepare incoming fetched saved placements and item (product or unit) views
        if (
            groupLogo.placementsFetching === false &&
            groupLogo.placements &&
            groupLogo.logoPlacementsFetching === false &&
            groupLogo.logoPlacements &&
            this.state.product &&
            this.state.product.id === groupLogo.placements.id &&
            this.state.placementsLoaded === false
        ) {
            const isUnit = this.state.unit ? true : false;
            const itemViews = PlacerHelper.getItemViews(
                groupLogo.placements,
                isUnit
            );

            let placements;
            let logoPlacements;

            // @consideration sort item views? (to get id's in ascending order)
            if (itemViews) {
                placements = PlacerHelper.getSavedPlacements(this, itemViews);
            } else {
                placements = [];
            }

            if (groupLogo.logoPlacements && groupLogo.logoPlacements.length) {
                logoPlacements = PlacerHelper.getSavedPlacements(
                    this,
                    groupLogo.logoPlacements,
                    true
                );
            } else {
                logoPlacements = [];
            }

            this.setState(
                {
                    placementsLoaded: true,
                    placements,
                    shouldDrawLogoPlacements: true,
                    savedlogoPlacements: groupLogo.logoPlacements,
                    logoPlacements,
                    itemViews,
                },
                () => {
                    // Activate default logo
                    if (this.state.logos && this.state.logos.length) {
                        this.logoButtonToggleActive(
                            this.getDefaultLogoForPlacement(),
                            false
                        );
                    }
                }
            );
        }

        // Make sure this.editor.current is defined before loading any images
        // If the canvas hasn't initialized we have nowhere to put any images
        // Load all product images => then load and render all placeholders
        if (
            this.editor &&
            this.editor.current &&
            this.state.itemImageTypes &&
            this.state.shouldLoadImages
        ) {
            this.setState({ shouldLoadImages: false }, () => {
                PlacerHelper.itemImageHandler(this);
            });
        }

        // Draw all saved placements to the canvas after images have loaded
        if (
            this.state.imageLoading === false &&
            this.state.shouldDrawLogoPlacements === true &&
            (this.state.placements || this.state.logoPlacements) &&
            this.editor &&
            this.editor.current
        ) {
            this.setState({ shouldDrawLogoPlacements: false }, () => {
                this.drawLogoPlacements();
            });
        }
    }

    // Draw placements with something in placement.logo (a saved logoPlacement)
    // or if the placement.placementType.isDefault is true
    drawLogoPlacements(
        placements = this.state.placements,
        logoPlacements = this.state.logoPlacements
    ) {
        // Default placements to draw
        // Any placement without custom logo placement data, and a logo that matches its placement type is a default placement
        const defaultPlacements = placements.filter(
            placement => !placement.logo && this.placementHasLogo(placement)
        );

        // all logoPlacements must be drawn
        // any defaultPlacements that are not found in logoPlacements can also be drawn
        const defaultPlacementsToDraw = defaultPlacements.filter(
            placement =>
                !logoPlacements.find(
                    p =>
                        p.imageId === placement.imageId &&
                        placement.placementType &&
                        p.placementType &&
                        p.placementType.id === placement.placementType.id
                )
        );

        // Set logoPlacements placement width/height to found placement in this.state.placements width/height
        // And logoPlacement placement coords
        const updatedPlacements = logoPlacements.map(placement => {
            const foundPlacement = this.state.placements.find(
                p =>
                    p.imageId === placement.imageId &&
                    placement.placementType &&
                    p.placementType &&
                    p.placementType.id === placement.placementType.id
            );

            // When we fetch saved logoPlacements we only get the logo coords, not the placement coords
            // Set placement coords from found placement in fetched placements
            if (foundPlacement) {
                placement.rotation = foundPlacement.rotation;
                placement.x = foundPlacement.x;
                placement.y = foundPlacement.y;
                placement.width = foundPlacement.width;
                placement.height = foundPlacement.height;
            }

            return placement;
        });

        const placementsToDraw = [
            ...updatedPlacements,
            ...defaultPlacementsToDraw,
        ];

        if (placementsToDraw && placementsToDraw.length) {
            this.setState({ logoPlacements: [] }, () => {
                this.logoAdd(placementsToDraw);
            });
        }
    }

    /**
     * Initialize the logo placer -> Check if we can load the first item
     */
    init = (productId, unitId) => {
        const { products, groupLogo } = this.props;

        if (
            products &&
            products.groupProducts &&
            products.allProducts &&
            groupLogo &&
            groupLogo.placementTypes &&
            groupLogo.groupLogos
        ) {
            // Prepare groupProducts found in products.
            // We do this to get original images and for product navigation purposes
            // Sort based on groupProducts sorting
            const productsState = products.allProducts
                .filter(product =>
                    products.groupProducts.find(
                        groupProduct => groupProduct.id === product.id
                    )
                )
                .sort(function(a, b) {
                    let foundA = products.groupProducts.find(
                        o => o.id === a.id
                    );
                    let foundB = products.groupProducts.find(
                        o => o.id === b.id
                    );
                    return (
                        products.groupProducts.indexOf(foundA) -
                        products.groupProducts.indexOf(foundB)
                    );
                });

            const foundProduct = productsState.find(
                product => product.id === productId
            );
            let product = productsState[0];
            let productIndex = 0;
            let logos = null;
            let foundUnit;

            if (unitId && foundProduct && foundProduct.units) {
                foundUnit = foundProduct.units.find(unit => unit.id === unitId);
            }

            if (productId && foundProduct) {
                product = foundProduct;
                productIndex = productsState.indexOf(foundProduct);
            }

            // Handle logos
            logos = groupLogo.groupLogos.map(logo => {
                logo.selected = false;
                return logo;
            });

            this.setState(
                {
                    products: productsState,
                    product,
                    productIndex: productIndex,
                    logos,
                    unit: foundUnit,
                },
                () => {
                    if (this.state.unit) {
                        this.itemLoad(this.state.product, this.state.unit);
                    } else {
                        this.itemLoad(this.state.product);
                    }
                }
            );
        }
    };

    /**
     * Loads a single item
     * @param {Object} product
     * @param {Object} unit (optional)
     */
    itemLoad(product, unit) {
        // Update current state
        if (this.state.products.indexOf(product) !== -1) {
            this.setState(
                {
                    product: product,
                    productIndex: this.state.products.indexOf(product),
                    placements: [],
                    placementsLoaded: false,
                    productViews: null,
                    imageLoading: null,
                    shouldLoadImages: null,
                },
                () => {
                    // Decide if item to load is unit or product
                    const item = unit ? unit : product;
                    const isUnit = unit ? true : false;

                    // Clear canvas of previous product images and logos
                    this.canvas.clear();

                    // Fetch product placements
                    this.props.getPlacements(product.id);

                    const itemImageTypes = PlacerHelper.getItemImageTypes(
                        this.props,
                        product,
                        item,
                        isUnit
                    );

                    const firstItemImage = itemImageTypes[0];

                    // If we have an image
                    if (firstItemImage) {
                        // Fetch logo placements
                        this.props.getAllLogoPlacements({
                            itemImageTypes: itemImageTypes,
                            groupId: this.props.match.params.id,
                        });

                        this.setState({
                            itemImageTypes,
                            typeName: firstItemImage.typeName,
                            imageId: firstItemImage.id,
                            width: firstItemImage.width,
                            height: firstItemImage.height,
                            shouldLoadImages: true,
                        });
                    } else {
                        // Item has no images
                        this.setState({
                            typeName: this.props.defaultTypeName,
                            width: this.props.maxWidth,
                            height: this.props.defaultHeight,
                            shouldLoadImages: false,
                        });
                    }
                }
            );
        }
    }

    // Gets the selected logo button
    getSelectedLogoButton() {
        if (this.state.logos) {
            return this.state.logos.find(logo => logo.selected === true);
        }

        return null;
    }

    // Check if the placement has a logo that matches its placementType
    placementHasLogo(placement, logos = this.state.logos) {
        if (
            placement &&
            placement.placementType &&
            placement.placementType.name
        ) {
            return logos.some(
                logo =>
                    logo.placementTypes &&
                    logo.placementTypes.length &&
                    logo.placementTypes.some(
                        placementType =>
                            placementType.name === placement.placementType.name
                    )
            );
        }

        return false;
    }

    // Gets the light or dark logo for default fields for the product
    getDefaultLogoForPlacement(
        placement,
        imageId = this.state.imageId,
        typeName = this.state.typeName,
        placements = this.state.placements,
        logos = this.state.logos
    ) {
        let logo;

        if (!placement) {
            placement = placements.find(
                placement =>
                    placement.imageType &&
                    placement.imageType.typeName === typeName &&
                    placement.imageId === imageId
            );
        }

        if (placement) {
            const imageIsDark =
                placement.imageType && placement.imageType.isDark;
            const placementType =
                placement.placementType && placement.placementType.name;

            if (imageIsDark) {
                // Look for a light logo for a dark image
                logo = logos.find(
                    logo =>
                        logo.darknessTypeName === "Light" &&
                        logo.placementTypes.some(
                            type => type.name === placementType
                        )
                );
            } else {
                // Look for a dark logo for a light image
                logo = logos.find(
                    logo =>
                        logo.darknessTypeName === "Dark" &&
                        logo.placementTypes.some(
                            type => type.name === placementType
                        )
                );
            }

            // Look for a logo that can be used on a light or dark image that match the placement
            if (!logo) {
                logo = logos.find(
                    logo =>
                        logo.darknessTypeName === "Both" &&
                        logo.placementTypes.some(
                            type => type.name === placementType
                        )
                );
            }
        }

        // Fall back to first logo
        if (!logo && logos) {
            logo = logos[0];
        }

        return logo;
    }

    logoReset(placement) {
        let active;
        let logoPlacement;
        let newLogoPlacements;
        let placementContainer;

        if (placement) {
            active = this.getCanvasLogo(placement.id);
            logoPlacement = placement;
            placementContainer = this.canvas
                .getObjects()
                .find(
                    o =>
                        o.placerType === "placement" &&
                        o.uid === placement.id &&
                        placement.imageType &&
                        o.placerTypeName === placement.imageType.typeName &&
                        o.imageId === placement.imageType.id
                );
        } else {
            active = this.canvas.getActiveObject();

            if (active) {
                logoPlacement = this.getLogoPlacement(active.uid);
                placementContainer = this.canvas
                    .getObjects()
                    .find(
                        o =>
                            o.placerType === "placement" &&
                            o.uid === active.uid &&
                            o.placerTypeName === active.placerTypeName &&
                            o.imageId === active.imageId
                    );
            }
        }

        if (logoPlacement) {
            newLogoPlacements = [
                ...this.state.logoPlacements.filter(
                    placement => placement.id !== logoPlacement.id
                ),
            ];
        }

        if (placementContainer) {
            this.canvas.remove(placementContainer);
        }

        if (active) {
            this.canvas.remove(active);
            this.canvas.renderAll();
        }

        if (newLogoPlacements) {
            this.setState({ logoPlacements: newLogoPlacements });
        }
    }

    /**
     * 'Resets' the logos by deleting them, and showing the placeholder buttons again
     */
    logoResetAll(done) {
        let objects = this.canvas.getObjects();

        objects.forEach(object => {
            if (
                object.placerType === "logo" ||
                object.placerType === "placement"
            ) {
                this.canvas.remove(object);
            }
        });

        this.canvas.renderAll();

        this.setState(
            {
                logoPlacements: [],
            },
            () => {
                if (done) done();
            }
        );
    }

    /**
     * Deletes all logos, but also shows logos in all default positions
     * Uses the default logo
     */
    logoResetAllToDefault() {
        // Delete all logos, and all new logo areas
        this.logoResetAll(() => {
            // Select the default logo button
            this.logoButtonToggleActive(this.getDefaultLogoForPlacement());

            // Draw all default logos
            const placementsToDraw = this.state.placements.filter(
                placement =>
                    placement.placementType && placement.placementType.isDefault
            );

            if (placementsToDraw && placementsToDraw.length) {
                this.logoAdd(placementsToDraw);
            }
        });
    }

    // Toggles the active logo
    logoButtonToggleActive = (logoButton, swapLogo = true) => {
        let logos = this.state.logos.map(o => {
            if (o.id === logoButton.id) {
                o.selected = true;
            } else {
                o.selected = false;
            }
            return o;
        });

        this.setState({
            logos: logos,
        });
    };

    logoCreate(placement, useSelectedLogo = false) {
        let logoPlacement = { ...placement };

        // Get the correct logo for the placement
        // Get default logo if rendering for the first time, or the selected logo
        let selectedLogo;

        if (
            !useSelectedLogo &&
            ((placement.placementType && placement.placementType.isDefault) ||
                !placement.logo ||
                (placement.logo && !placement.logo.url))
        ) {
            selectedLogo = this.getDefaultLogoForPlacement(placement);
        } else {
            selectedLogo = this.getSelectedLogoButton();
        }

        if (!logoPlacement.logo) {
            logoPlacement.logo = {
                id: selectedLogo.id,
                url: selectedLogo.url,
            };
        }

        return logoPlacement;
    }

    // Transform placement to logoPlacement, add it to the state and to the canvas
    logoAdd(placements, useSelectedLogo = false) {
        let logoPlacements;

        if (Array.isArray(placements)) {
            logoPlacements = placements.map(placement =>
                this.logoCreate(placement, useSelectedLogo)
            );
        } else {
            logoPlacements = [this.logoCreate(placements, useSelectedLogo)];
        }

        let newPlacements = [...this.state.logoPlacements, ...logoPlacements];

        this.setState(
            {
                logoPlacements: newPlacements,
            },
            () => {
                logoPlacements.forEach(placement => {
                    this.editor.current.createCanvasLogo(placement);
                });
            }
        );
    }

    /**
     * Updates a logos props after being modified in the canvas
     * @param {Object} canvasPlacement
     */
    handleLogoProps(canvasLogo) {
        const foundPlacement = this.state.logoPlacements.find(
            p => p.id === canvasLogo.uid
        );

        if (!foundPlacement) return false;

        let logo = {
            logoId: canvasLogo.logoId,
            placementTypeId: foundPlacement.placementType,
            boundingCoords: canvasLogo.boundingCoords,
            coords: {
                x: canvasLogo.left,
                y: canvasLogo.top,
            },
            x: canvasLogo.left,
            y: canvasLogo.top,
            rotation: canvasLogo.angle,
            width: canvasLogo.width * canvasLogo.scaleX,
            height: canvasLogo.height * canvasLogo.scaleY,
        };

        let placement = Object.assign({}, foundPlacement, {
            id: canvasLogo.uid,
            logoId: canvasLogo.logoId,
            imageId: foundPlacement.imageId,
            placementType: foundPlacement.placementType,
            imageType: foundPlacement.imageType,
            logo,
        });

        this.setState({
            logoPlacements: [
                ...this.state.logoPlacements.filter(p => p.id !== placement.id),
                placement,
            ],
        });
    }

    // Gets a logo that is in the canvas
    getCanvasLogo(id) {
        return this.canvas
            .getObjects()
            .find(o => o.placerType === "logo" && o.uid === id);
    }

    // Gets a logo that is stored in the state
    getLogoPlacement(id) {
        return this.state.logoPlacements.find(o => o.id === id);
    }

    // Returns the appropriate title for a section of logo buttons
    getLogoGroupTitle(logoGroup) {
        let logo = logoGroup[0];
        let title = "";

        if (
            logo.darknessTypeName &&
            this.props.logoDarknessTypes[logo.darknessTypeName]
        ) {
            title = this.props.logoDarknessTypes[logo.darknessTypeName];
        } else {
            title = this.props.logoDarknessTypes.default;
        }

        return title;
    }

    getLogoTextileTypeName(logo) {
        let title = "";

        if (
            logo.textileTypeName &&
            this.props.logoTextileTypes[logo.textileTypeName]
        ) {
            title = this.props.logoTextileTypes[logo.textileTypeName];
        } else {
            title = this.props.logoTextileTypes.default;
        }

        return title;
    }

    /**
     * Saves all the placements associated with any images of the item
     * NOTE: Always save everything as saving overrides any previously saved data
     */
    saveLogoPlacements() {
        // Prepare placement data to conform to api
        let allPlacements = this.state.logoPlacements.map(logoPlacement => {
            // Transform coordinates from resized image to fit real image size
            let scaleRatio = PlacerHelper.getScaleRatio(
                logoPlacement.imageType.naturalWidth,
                logoPlacement.imageType.width
            );

            return {
                ...logoPlacement,
                productImageId: logoPlacement.imageType.id, // Used for sorting
                logoId: logoPlacement.logoId,
                testId: `test-${logoPlacement.id}`,
                width: PlacerHelper.resizedToNatural(
                    logoPlacement.logo.width,
                    scaleRatio
                ),
                height: PlacerHelper.resizedToNatural(
                    logoPlacement.logo.height,
                    scaleRatio
                ),
                boundingCoords: {
                    x: PlacerHelper.resizedToNatural(
                        logoPlacement.logo.boundingCoords.x,
                        scaleRatio
                    ),
                    y: PlacerHelper.resizedToNatural(
                        logoPlacement.logo.boundingCoords.y,
                        scaleRatio
                    ),
                },
                coords: {
                    x: PlacerHelper.resizedToNatural(
                        logoPlacement.logo.x,
                        scaleRatio
                    ),
                    y: PlacerHelper.resizedToNatural(
                        logoPlacement.logo.y,
                        scaleRatio
                    ),
                },
                rotation: logoPlacement.logo.rotation
                    ? logoPlacement.logo.rotation
                    : 0,
            };
        });

        // Sort based on imageType id
        let placementTypeGroups = Utils.sortByProp(
            allPlacements,
            "productImageId"
        );

        this.state.itemImageTypes.forEach(imageType => {
            // If no placementTypeGroups (no placements at all)
            // Delete all the placements by posting empty array
            let logoPlacements = [];

            if (placementTypeGroups && placementTypeGroups[imageType.id]) {
                logoPlacements = placementTypeGroups[imageType.id];
            }

            this.props.setLogoPlacements({
                groupId: this.props.group.group.id,
                productId: this.state.product.id,
                productImageId: imageType.id,
                logoPlacements: logoPlacements,
            });
        });
    }

    render() {
        const { groupLogo } = this.props;

        // Echo possible error messages
        if (
            groupLogo.placementsError ||
            groupLogo.groupLogosError ||
            groupLogo.logoPlacementsError
        )
            return <div className="col">Kunne ikke laste produkt.</div>;

        // Echo appshell
        if (
            groupLogo.placementsFetching === true ||
            groupLogo.groupLogosFetching === true ||
            groupLogo.logoPlacementsFetching === true
        )
            return <div className="col">Laster produkter...</div>;

        // Fetching is done
        if (
            groupLogo.placementsFetching === false &&
            groupLogo.groupLogosFetching === false &&
            groupLogo.groupLogos &&
            groupLogo.groupLogos.length &&
            groupLogo.logoPlacementsFetching === false &&
            this.state.product
        ) {
            return (
                <LogoPlacer
                    parentThisRef={this}
                    state={this.state}
                    fabric={this.fabric}
                    editor={this.editor}
                    canvas={this.canvas}
                    saveLogoPlacements={this.saveLogoPlacements}
                    getLogoGroupTitle={this.getLogoGroupTitle}
                    getLogoTextileTypeName={this.getLogoTextileTypeName}
                    getSelectedLogoButton={this.getSelectedLogoButton}
                    logoButtonToggleActive={this.logoButtonToggleActive}
                    logoAdd={this.logoAdd}
                    logoReset={this.logoReset}
                    logoResetAllToDefault={this.logoResetAllToDefault}
                    handleLogoProps={this.handleLogoProps}
                    itemHasImages={this.itemHasImages}
                    itemHasPlacements={this.itemHasPlacements}
                    {...this.props}
                />
            );
        }

        return null;
    }
}

LogoLoad.propTypes = {
    logoDarknessTypes: PropTypes.object,
};

LogoLoad.defaultProps = {
    maxWidth: 345,
    defaultHeight: 545,
    defaultTypeName: "Front",
    text: {
        nextProduct: "Neste produkt",
        previousProduct: "Forrige produkt",
        deleteLogos: "Slett alle logoer",
        deleteLogo: "Slett logo",
        resetToDefault: "Tilbakestill til standard",
        activeText: "Skjul plasseringer",
        inactiveText: "Vis plasseringer",
        save: "Lagre",
        saving: "Lagrer...",
        logos: "Logoer",
    },
    logoDarknessTypes: {
        Both: "Logoer til mørke og lyse plagg",
        Dark: "Mørke logoer",
        Light: "Lyse logoer",
        default: "Logoer",
    },
    logoTextileTypes: {
        Normal: "Normal",
        Embroidered: "Brodert",
        default: "Normal",
    },
    forms: {
        big: {
            title: "CM-bredde på logo (M - XXL)",
            placeholder: "Bredde i CM, kun tall",
        },
        small: {
            title: "CM-bredde på logo (XXS - S)",
            placeholder: "Bredde i CM, kun tall",
            info:
                "Disse størrelsene påvirker ikke logoplasseringen, men vises kun som info til kundene.",
        },
    },
};

function mapStateToProps(state) {
    return {
        products: state.products,
        groupLogo: state.groupLogo,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        getPlacements,
        getAllLogoPlacements,
        getLogoPlacements,
        setLogoPlacements,
        getGroupLogos,
        getProductUnits,
    }
)(LogoLoad);
