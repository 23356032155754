import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import { setUser } from "../Actions/UserActions";

class RoleSwitcher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: true,
        };

        this.onDismiss = this.onDismiss.bind(this);
        this.switchRole = this.switchRole.bind(this);
    }

    onDismiss() {
        this.setState({ visible: false });
    }

    switchRole(e) {
        let newUser = {
            ...this.props.user.user,
            role: e.target.value,
        };

        if (e.target.value === "Customer") {
            newUser.id = 2;
        } else {
            newUser.id = 1;
        }

        this.props.setUser(newUser);
    }

    render() {
        const { user } = this.props.user;
        return (
            <Alert
                color="info"
                fade={false}
                isOpen={this.state.visible}
                toggle={this.onDismiss}>
                <div className="row">
                    <div className="col mt-2 font-weight-bold">
                        ENV: {process.env.NODE_ENV}
                    </div>
                    <div className="col mt-2">
                        Logget inn som:{" "}
                        <strong>
                            {user.firstName} {user.lastName}
                        </strong>
                        {/* <details>
                            <summary>Detaljer</summary>
                            <span>
                                {Object.entries(user).map(
                                    ([key, value]) => (
                                        <p key={key}>
                                            {key}: {value}
                                        </p>
                                    )
                                )}
                            </span>
                        </details> */}
                    </div>
                    <div className="col">
                        <div className="form-group row mb-0">
                            <label className="col-5 mt-2" htmlFor="roles">
                                Brukerrolle:
                            </label>
                            <select
                                className="col-7 form-control"
                                id="roles"
                                onChange={this.switchRole}
                                value={user.role}>
                                {/* <option value="Admin">Admin</option> */}
                                <option value="Seller">Selger</option>
                                <option value="Manager">Ordreansvarlig</option>
                                <option value="Customer">Medlem</option>
                            </select>
                        </div>
                    </div>
                </div>
            </Alert>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}
export default connect(
    mapStateToProps,
    {
        setUser,
    }
)(RoleSwitcher);
