import React, { Component } from "react";
import PropTypes from "prop-types";

class Icon extends Component {
    getPath = (name, props) => {
        switch (name) {
            case "arrow_back":
                return (
                    <path
                        {...props}
                        d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
                    />
                );
            case "arrow_forward":
                return (
                    <path
                        {...props}
                        d="M4 13h12.2l-5.6 5.6 1.4 1.4 8-8-8-8-1.4 1.4 5.6 5.6h-12.2v2z"
                    />
                );
            case "delete":
                return (
                    <path
                        {...props}
                        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                    />
                );
            case "plus":
                return (
                    <path {...props} d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                );
            case "arrow_drop_up":
                return <path {...props} d="M7 14l5-5 5 5z" />;
            case "arrow_drop_down":
                return <path {...props} d="M7 10l5 5 5-5z" />;
            case "shopping_cart":
                return <path {...props} d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"/>;
            default:
                return <path />;
        }
    };

    render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={this.props.width}
                height={this.props.height}
                viewBox={this.props.viewBox}
                className={this.props.className}>
                <title>
                    {this.props.title ? this.props.title : this.props.name}
                </title>
                {this.getPath(this.props.name, { fill: this.props.fill })}
            </svg>
        );
    }
}

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    fill: PropTypes.string,
    viewBox: PropTypes.string,
    className: PropTypes.string,
};

Icon.defaultProps = {
    width: 18,
    height: 18,
    fill: "#000000",
    viewBox: "0 0 24 24",
    className: "",
};

export default Icon;
