import React, { Component } from "react";
import Cart from "../Cart/Cart";
import { CartHelper, CustomerHelper } from "../../Helpers";

export default class CustomerCarts extends Component {
    render() {
        const { isSeller, showPrices } = this.props;
        const communalCart = CartHelper.getCustomerCart(this.props.cart);

        return (
            <div className="row">
                {this.props.group.group.customers &&
                this.props.group.group.customers.length ? (
                    CustomerHelper.getCustomers(this.props).map(customer => {
                        const customerCart = CartHelper.getCustomerCart(
                            this.props.cart,
                            customer.customer
                        );

                        if (customerCart && customerCart.length) {
                            return (
                                <div
                                    key={customer.customer.id}
                                    className="col-12 mb-2">
                                    <div className="row no-gutters">
                                        <div className="col-12">
                                            <span className="d-block">
                                                {CartHelper.hasCustomerAcceptedCart(
                                                    this.props.cart,
                                                    customer.customer
                                                ) ? (
                                                    <span
                                                        className="tick"
                                                        title={`${customer.customer.firstName} har godkjent handlekurven sin`}
                                                    />
                                                ) : null}
                                                <strong>
                                                    {
                                                        customer.customer
                                                            .firstName
                                                    }{" "}
                                                    {customer.customer.lastName}
                                                </strong>
                                                {customer.customerType.name ===
                                                "Manager" ? (
                                                    <span>
                                                        {" "}
                                                        - Ordreansvarlig
                                                    </span>
                                                ) : null}
                                            </span>
                                        </div>

                                        <Cart.List
                                            list={customerCart}
                                            showPrices={showPrices}
                                            isSeller={isSeller}
                                            type="product"
                                        />
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    key={customer.customer.id}
                                    className="col-12 mb-2">
                                    <div className="row no-gutters">
                                        <div className="col-12">
                                            <span className="d-block">
                                                {CartHelper.hasCustomerAcceptedCart(
                                                    this.props.cart,
                                                    customer.customer
                                                ) ? (
                                                    <span
                                                        className="tick"
                                                        title={`${customer.customer.firstName} har godkjent handlekurven sin`}
                                                    />
                                                ) : null}
                                                <strong>
                                                    {
                                                        customer.customer
                                                            .firstName
                                                    }{" "}
                                                    {customer.customer.lastName}
                                                </strong>
                                                {customer.customerType.name ===
                                                "Manager" ? (
                                                    <span>
                                                        {" "}
                                                        - Ordreansvarlig
                                                    </span>
                                                ) : null}
                                            </span>
                                        </div>
                                        <div className="col-12 text-secondary">
                                            Har ingen produkter i handlekurven
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })
                ) : (
                    <div className="col-12 mb-2">
                        Ingen medlemmer er lagt til i bestillingen.
                    </div>
                )}

                <div className="col-12 mb-2">
                    <div className="row no-gutters">
                        <div className="col-12">
                            <strong className="d-block">Fellesprodukter</strong>
                        </div>

                        {communalCart && communalCart.length ? (
                            <Cart.List
                                list={communalCart}
                                showPrices={showPrices}
                                isSeller={isSeller}
                                type="product"
                            />
                        ) : (
                            <div className="col-12 text-secondary">
                                Ingen fellesprodukter i handlekurven
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
