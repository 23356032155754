import React from "react";
import AppShell from "../AppShell/AppShell";
import { Number } from "../../Helpers";

export default class AdditionItemAppshell extends AppShell {
    renderShell(i) {
        return (
            <div className="shell col-12" key={`shell-${i}`}>
                <div className="card mb-2">
                    <div className="card-body p-3">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-lg-7">
                                <span
                                    className="shell__text"
                                    style={{
                                        "--width": Number.random(30, 80) + "%",
                                    }}
                                />
                            </div>

                            <div className="col">
                                <span className="shell__text" />
                            </div>

                            <div className="col-12 mt-4 mt-sm-0 col-md text-right">
                                <span className="shell__btn" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="shell row mb-2">
                    <div className="col">
                        <span className="h2">
                            <span
                                className="shell__text"
                                style={{
                                    "--width": 45 + "%",
                                }}
                            />
                        </span>
                    </div>
                </div>

                <div className="row mb-4">{this.createAppShell(10)}</div>
            </>
        );
    }
}
