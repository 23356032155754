import React from "react";
import AppShell from "../AppShell/AppShell";
import { Number } from "../../Helpers";
import LogoSettingsAppshell from "./LogoSettingsAppshell";

export default class SettingsLogosAppshell extends AppShell {
    render() {
        return (
            <div className="shell">
                <div className="mb-2">
                    <h2>
                        <span
                            className="shell__text font-weight-bold"
                            style={{
                                "--width": Number.random(25, 50) + "%",
                            }}
                        />
                    </h2>
                </div>
                <div className="card mb-2">
                    <div className="card-body p-4">
                        <div className="row no-gutters">
                            <div className="col-12">
                                <div className="d-block mb-2">
                                    <span
                                        className="shell__text"
                                        style={{
                                            "--width":
                                                Number.random(40, 60) + "%",
                                        }}
                                    />
                                </div>
                                <div className="d-block">
                                    <span
                                        className="shell__text"
                                        style={{
                                            "--width":
                                                Number.random(20, 30) + "%",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-2">
                    <LogoSettingsAppshell />
                </div>
            </div>
        );
    }
}
