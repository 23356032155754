import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

class InviteSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchContent: "",
        };

        this.updateCustomerSearchText = this.updateCustomerSearchText.bind(
            this
        );
    }

    updateCustomerSearchText(e) {
        this.setState({ searchContent: e.target.value });

        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    handleInviteDisabled() {
        if (this.state.searchContent && this.state.searchContent !== "") {
            return null;
        } else {
            return true;
        }
    }

    render() {
        return (
            <form className="form h-100 row no-gutters">
                <div className="col-12 mb-2">
                    <label htmlFor="staticEmail2" className="h3">
                        {this.props.title}
                    </label>

                    {this.props.children ? this.props.children : null}
                </div>

                <div className="col-12 mt-auto">
                    <div className="row">
                        <div className="col-12 col-lg mb-3 mb-lg-0">
                            <input
                                type="text"
                                onChange={this.updateCustomerSearchText}
                                className="search-input form-control"
                                id="staticEmail2"
                                placeholder="Søk på epost..."
                            />
                        </div>

                        <div className="col-auto">
                            <Button
                                type="submit"
                                onClick={this.props.onSearch}
                                className="mb-3 px-4"
                                color="primary">
                                Søk
                            </Button>

                            {/* {this.props.multiple ? (
                                <Button
                                    type="submit"
                                    onClick={this.props.onInviteAll}
                                    className="mb-3 ml-4 search-invite-all"
                                    color="primary"
                                    disabled={this.handleInviteDisabled()}>
                                    Inviter alle
                                </Button>
                            ) : null} */}
                        </div>

                        {this.props.info ? (
                            <div className="col-12">
                                <small className="d-block">
                                    {this.props.info}
                                </small>
                            </div>
                        ) : null}
                    </div>
                </div>
            </form>
        );
    }
}

InviteSearch.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    info: PropTypes.string,
    multiple: PropTypes.bool,
};

InviteSearch.defaultProps = {};

export default InviteSearch;
