import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Components
import LazyImage from "../Common/LazyImage";
import ProductImagesSettings from "./ProductImagesSettings";

class ProductImages extends Component {
    constructor(props) {
        super(props);
        this.state = { modal: false };

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    renderImages(maxImages = 2) {
        if (this.props.product && this.props.product.images.length) {
            return this.props.product.images
                .slice(0, maxImages)
                .map((image, i) => (
                    <div className="col" key={`image-${i}`}>
                        <LazyImage
                            src={image.src}
                            alt={image.alt}
                            className="img-fluid"
                            productImage
                        />
                    </div>
                ));
        }
    }

    renderName(includePrice = true) {
        return (
            <>
                <span className="font-weight-bold">{`${
                    this.props.product.name
                } `}</span>

                {this.renderPrice(includePrice)}
            </>
        );
    }

    renderPrice(includePrice = true) {
        return (
            <>
                {includePrice &&
                this.props.group &&
                this.props.group.group &&
                this.props.group.group.showPrice ? (
                    <span className="price">{this.props.product.price},-</span>
                ) : null}
            </>
        );
    }

    render() {
        const { product } = this.props;

        return (
            <div className="product card h-100">
                <div className="row">
                    {this.props.showProductImages ? (
                        <div
                            className="product__images col-3 mr-0 mb-4 mb-sm-0 col-md-2 col-lg-2"
                            style={{
                                maxWidth: 150 + "px",
                            }}
                            data-toggle="modal"
                            data-target={`#productModal${product.id}`}>
                            <div className="row no-gutters">
                                {this.renderImages()}
                            </div>
                        </div>
                    ) : null}

                    <div className="product__settings py-3 py-lg-3 col">
                        <div className="row px-3 mr-0 h-100 align-items-center">
                            <div className="col-12 col-lg mb-3 mb-lg-0">
                                <h3 className="h6 mb-0">
                                    {this.renderName(this.props.showPrice)}
                                </h3>
                            </div>

                            <div className="col col-sm-auto pr-0 mt-3 mt-lg-0">
                                <Button
                                    color="primary"
                                    className="mr-2"
                                    onClick={this.toggleModal}>
                                    Bilder
                                </Button>

                                <Link
                                    className={`btn btn-primary ${
                                        this.props.product.images &&
                                        this.props.product.images.length
                                            ? ""
                                            : "disabled"
                                    }`}
                                    to={`/placement/${this.props.product.id}`}>
                                    Plasseringer
                                </Link>

                                <Modal
                                    isOpen={this.state.modal}
                                    className="modal--product"
                                    toggle={this.toggleModal}
                                    size="lg"
                                    centered={true}>
                                    <ModalHeader toggle={this.toggleModal}>
                                        <span>
                                            {this.renderName(
                                                this.props.showPrice
                                            )}
                                        </span>
                                    </ModalHeader>
                                    <ModalBody>
                                        <ProductImagesSettings
                                            {...this.props}
                                            product={this.props.product}
                                        />
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            className="mr-auto"
                                            color="secondary"
                                            onClick={this.toggleModal}>
                                            Gå tilbake
                                        </Button>
                                        {/* <Button
                                            color="primary"
                                            onClick={this.toggleModal}>
                                            Lagre endringer
                                        </Button> */}
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ProductImages.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        images: PropTypes.array,
    }),
};

ProductImages.defaultProps = {};

function mapStateToProps(state) {
    return {
        products: state.products,
    };
}
export default connect(mapStateToProps)(ProductImages);
