import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Actions
import { manageProduct } from "../../Actions/ProductsActions";

// Components
import Product from "./Product";
import ProductSettings from "./ProductSettings";
import Restrict from "../../Auth/Restrict";

// Helpers
import { Seller } from "../../Auth/UserRoles";
import { GroupHelper, ProductHelper } from "../../Helpers";

class ProductSmall extends Product {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            minimumQty: undefined,
            displayTextValue: undefined,
            shouldSaveProduct: null,
            customPrice: null,
            productToSave: null,
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.handleCustomPrice = this.handleCustomPrice.bind(this);
        this.handleMinimumQty = this.handleMinimumQty.bind(this);
        this.handleNametagToggle = this.handleNametagToggle.bind(this);
        this.onToggleSize = this.onToggleSize.bind(this);
    }

    toggleModal() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    handleCustomPrice(value) {
        this.setState({
            customPrice: value,
        });
    }

    handleMinimumQty(value) {
        this.setState({
            minimumQty: value,
        });
    }

    handleNametagToggle(e) {
        this.setState({
            displayTextValue: e.target.checked,
        });
    }

    // Check if we need to update individual unit isHidden
    onToggleSize(selectedUnitsProduct) {
        let shouldSave = false;

        this.props.product.units.forEach(unit => {
            if (
                unit.isHidden !==
                selectedUnitsProduct.units.find(u => u.id === unit.id).isHidden
            ) {
                shouldSave = true;
            }
        });

        if (shouldSave) {
            this.setState({
                shouldSaveProduct: true,
                productToSave: selectedUnitsProduct,
            });
        } else {
            this.setState({ shouldSaveProduct: false, productToSave: null });
        }
    }

    saveChanges() {
        this.toggleModal();

        if (
            [
                this.state.shouldSaveProduct,
                this.state.customPrice,
                this.state.minimumQty,
                this.state.displayTextValue,
            ].some(v => typeof v !== "undefined")
        ) {
            let newProductManage;

            if (this.state.shouldSaveProduct) {
                newProductManage = this.state.productToSave;
            } else {
                newProductManage = ProductHelper.cloneDeepProduct(
                    this.props.product
                );
            }

            // Set unit values, never directly on the product
            newProductManage.units.forEach(unit => {
                if (this.state.customPrice !== "undefined") {
                    unit.price = this.state.customPrice;
                }
                if (this.state.minimumQty !== "undefined") {
                    unit.minimumQty = this.state.minimumQty;
                }
                if (this.state.displayTextValue !== "undefined") {
                    unit.displayTextValue = this.state.displayTextValue;
                }
            });

            this.props.manageProduct({
                groupId: this.props.group.group.id,
                productId: newProductManage.id,
                ...newProductManage,
            });
        }
    }

    render() {
        const { product } = this.props;
        const isProductHidden = ProductHelper.isProductHidden(product.units);

        return (
            <div className="product card h-100">
                <div className="row">
                    {this.props.showProductImages ? (
                        <div className="product__images col-3 mr-0 mb-4 mb-sm-0 col-md-2 col-lg-2">
                            <div
                                className="row no-gutters p-2"
                                style={{
                                    maxWidth: 84 + "px",
                                }}>
                                {this.renderImages(120, 94)}
                            </div>
                        </div>
                    ) : null}

                    <div className="product__settings py-3 py-lg-3 col">
                        <div className="row px-3 mr-0 h-100 align-items-center">
                            <div className="col-12 col-lg mb-3 mb-lg-0">
                                <h3 className="h6 mb-0">
                                    {this.renderName(this.props.showPrice)}
                                </h3>
                            </div>

                            {this.renderStock() ? (
                                <div className="col-12 col-md-auto">
                                    {this.renderStock()}
                                </div>
                            ) : null}

                            {/* <Restrict {...this.props} allow={Seller}>
                                <div className="col-12 col-md-auto mt-3 mt-lg-0">
                                    <div className="form-group row mb-0">
                                        <label
                                            htmlFor={`inlineMinimum${
                                                product.id
                                            }`}
                                            className="col-auto col-form-label">
                                            Minimum
                                        </label>

                                        <div className="col-sm-4">
                                            <input
                                                type="number"
                                                className="form-control"
                                                id={`inlineMinimum${
                                                    product.id
                                                }`}
                                                onChange={this.updateMinimum}
                                                disabled={GroupHelper.disableWhenLocked(
                                                    this.props,
                                                    ["Seller"]
                                                )}
                                                defaultValue={
                                                    product.minimumQty
                                                        ? product.minimumQty
                                                        : 0
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Restrict> */}

                            <div className="col col-sm-auto pr-0 mt-3 mt-lg-0">
                                <Restrict {...this.props} allow={Seller}>
                                    <Button
                                        color="primary"
                                        className="mr-2"
                                        disabled={GroupHelper.disableWhenLocked(
                                            this.props,
                                            ["Seller", "Manager"]
                                        )}
                                        onClick={this.toggleModal}>
                                        Innstillinger
                                    </Button>
                                </Restrict>

                                {this.state.modal && (
                                    <Modal
                                        isOpen={this.state.modal}
                                        className="modal--product"
                                        toggle={this.toggleModal}
                                        size="lg"
                                        centered={true}>
                                        <ModalHeader toggle={this.toggleModal}>
                                            <span>Innstillinger for </span>

                                            {this.renderName(
                                                this.props.showPrice
                                            )}

                                            {this.renderStock()
                                                ? this.renderStock("ml-2")
                                                : null}
                                        </ModalHeader>
                                        <ModalBody>
                                            <ProductSettings
                                                {...this.props}
                                                product={product}
                                                onToggleSize={this.onToggleSize}
                                                handleCustomPrice={
                                                    this.handleCustomPrice
                                                }
                                                handleMinimumQty={
                                                    this.handleMinimumQty
                                                }
                                                handleNametagToggle={
                                                    this.handleNametagToggle
                                                }
                                            />
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button
                                                className="mr-auto"
                                                color="secondary"
                                                onClick={this.toggleModal}>
                                                Gå tilbake
                                            </Button>
                                            <Button
                                                color="primary"
                                                onClick={this.saveChanges}>
                                                Lagre endringer
                                            </Button>
                                        </ModalFooter>
                                    </Modal>
                                )}

                                <Button
                                    onClick={
                                        isProductHidden
                                            ? this.props.showProduct
                                            : this.props.hideProduct
                                    }
                                    color={
                                        isProductHidden ? "success" : "danger"
                                    }
                                    disabled={GroupHelper.disableWhenLocked(
                                        this.props,
                                        ["Seller", "Manager"]
                                    )}
                                    outline>
                                    {isProductHidden ? (
                                        <>
                                            <span>✓</span> <span>Vis</span>
                                        </>
                                    ) : (
                                        <>
                                            <span>×</span> <span>Skjul</span>
                                        </>
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ProductSmall.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        color: PropTypes.object.isRequired,
        images: PropTypes.array,
    }),
    onToggleVisibilityClick: PropTypes.func.isRequired,
    onToggleSettingsClick: PropTypes.func.isRequired,
};

ProductSmall.defaultProps = {
    onToggleVisibilityClick: () => null,
    onToggleSettingsClick: () => null,
};

function mapStateToProps(state) {
    return {
        group: state.group,
        products: state.products,
    };
}
export default connect(
    mapStateToProps,
    {
        manageProduct,
    }
)(ProductSmall);
