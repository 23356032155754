import Axios from "axios";
import { authHeader } from "./AuthenticationActions";
import { config } from "../Helpers/Config";

export function getGroupsFetching(payload) {
    return {
        type: "GET_GROUPS_FETCHING",
        payload,
    };
}

export function getGroups() {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getGroupsFetching(true));

        instance
            .get(`${config.apiUrl}/groups`)
            .then(response => {
                dispatch({
                    type: "GET_GROUPS",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log("Get groups error", error);
                dispatch({
                    type: "GET_GROUPS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}
