import React from "react";

export default function SliderPrevArrow(props) {
    const { fill = "#ffffff", className, style, onClick } = props;
    return (
        <button className={className} style={{ ...style }} onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24">
                <path
                    fill={fill}
                    d="M0 12l10.975 11 2.848-2.828-6.176-6.176H24v-3.992H7.646l6.176-6.176L10.975 1 0 12z"
                />
            </svg>
        </button>
    );
}
