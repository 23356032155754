// Global styles must be imported before components to avoid order issues with
// styled-components and createGlobalStyle - see Pages/DataLayerGroup
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./styles/critical.css";
import "./styles/styles.css";
import "lazysizes";
import {} from "dotenv/config";

// Dependencies
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import promise from "redux-promise";
import multi from "redux-multi";
import reducers from "./Reducers/index";

// Index handler for all user roles
import IndexHandler from "./Pages/IndexHandler";

// Admin pages
import AdminGroups from "./Pages/AdminGroups";
import AdminReport from "./Pages/AdminReport";
import AdminGroupCreate from "./Pages/AdminGroupCreate";
import AdminImages from "./Pages/AdminImages";
import AdminPlacementCreator from "./Pages/AdminPlacementCreator";

// Group pages
import GroupOverview from "./Pages/GroupOverview";
import GroupSettings from "./Pages/GroupSettings";
import GroupLogoPlacer from "./Pages/GroupLogoPlacer";
import GroupAdditions from "./Pages/GroupAdditions";
import GroupCustomers from "./Pages/GroupCustomers";
import GroupProducts from "./Pages/GroupProducts";
import GroupLog from "./Pages/GroupLog";
import GroupTracking from "./Pages/GroupTracking";
import GroupOnboarding from "./Pages/GroupOnboarding";

// Group order pages
import OrderOverview from "./Pages/OrderOverview";
import OrderTracking from "./Pages/OrderTracking";

// Other pages
import PageError from "./Pages/PageError";

// Auth
import Authorization from "./Auth/Authorization";
import { Seller, Manager, Customer } from "./Auth/UserRoles";

const CustomerRole = Authorization(Customer());
const ManagerRole = Authorization(Manager());
const SellerRole = Authorization(Seller());

const middleware = [thunk, promise, multi];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducers,
    composeEnhancer(applyMiddleware(...middleware))
);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename="/gruppebestilling">
            <Switch>
                <Route exact path="/" component={CustomerRole(IndexHandler)} />}
                />
                <Route path="/admin" component={SellerRole(AdminGroups)} />
                <Route path="/images" component={SellerRole(AdminImages)} />
                <Route path="/report" component={SellerRole(AdminReport)} />
                <Route
                    path="/create"
                    component={SellerRole(AdminGroupCreate)}
                />
                <Route
                    path="/placement/:productId/:unitId?"
                    component={SellerRole(AdminPlacementCreator)}
                />
                {/* Only one group per member, keep in case we need it for v2 */}
                {/* <Route path="/select" component={GroupOnlyRole(SelectGroups)} /> */}
                <Route
                    path="/group/:id/overview"
                    component={ManagerRole(GroupOverview, {
                        redirect: "/group/:id/products",
                    })}
                />
                <Route
                    path="/group/:id/settings"
                    component={ManagerRole(GroupSettings)}
                />
                <Route
                    path="/group/:id/placement/:productId/:unitId?"
                    component={SellerRole(GroupLogoPlacer)}
                />
                <Route
                    path="/group/:id/additions"
                    component={SellerRole(GroupAdditions)}
                />
                <Route
                    path="/group/:id/help"
                    component={CustomerRole(GroupOnboarding)}
                />
                <Route
                    path="/group/:id/members"
                    component={CustomerRole(GroupCustomers)}
                />
                <Route
                    path="/group/:id/products"
                    component={CustomerRole(GroupProducts)}
                />
                <Route
                    path="/group/:id/log"
                    component={CustomerRole(GroupLog)}
                />
                <Route
                    path="/group/:id/tracking"
                    component={CustomerRole(GroupTracking)}
                />
                <Route
                    path="/group/:id/order/:orderId/overview"
                    component={CustomerRole(OrderOverview)}
                />
                <Route
                    path="/group/:id/order/:orderId/tracking"
                    component={CustomerRole(OrderTracking)}
                />
                <Route component={PageError} />
            </Switch>
        </BrowserRouter>
    </Provider>,
    document.getElementById("groupshop")
);
