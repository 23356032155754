import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Components
import Icon from "../Components/Common/Icon";

// Helpers
import { PlacerHelper } from "../Helpers";

class PlacerNav extends Component {
    render() {
        return (
            <nav className="prodnav">
                <Link
                    to={`${this.props.to}${PlacerHelper.getNavigationItemId(
                        this.props,
                        "previous"
                    )}`}
                    className={`btn border bg-white ${
                        PlacerHelper.navIsDisabled(this.props, "previous")
                            ? "disabled"
                            : ""
                    }`}>
                    <Icon
                        name="arrow_back"
                        title="Forrige"
                        width={24}
                        height={24}
                    />
                </Link>

                <Link
                    to={`${this.props.to}${PlacerHelper.getNavigationItemId(
                        this.props,
                        "next"
                    )}`}
                    className={`btn border bg-white ${
                        PlacerHelper.navIsDisabled(this.props, "next")
                            ? "disabled"
                            : ""
                    }`}>
                    <Icon
                        name="arrow_forward"
                        title="Neste"
                        width={24}
                        height={24}
                    />
                </Link>
            </nav>
        );
    }
}

PlacerNav.propTypes = {
    to: PropTypes.string.isRequired,
};

PlacerNav.defaultProps = {};

export default PlacerNav;
