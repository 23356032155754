import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from 'prop-types';

// Actions
import { getAllProducts } from "../Actions/ProductsActions";
import { getNotifications } from "../Actions/NotificationsActions";

// Components
import DataLayerGroup from "./DataLayerGroup";
import Layout from "../Components/Group/Layout";
import Info from "../Components/Group/Info";
import Notifications from "../Components/Notification/Notifications";
import OrdersArchive from "../Components/Group/OrdersArchive";
import OrderGroup from "../Components/Group/OrderGroup";

// Helpers
import { TitleHelper, GroupHelper } from "../Helpers";

class GroupOverview extends Component {
    componentDidMount() {
        TitleHelper.setTitle("Oversikt");
    }

    render() {
        return (
            <DataLayerGroup {...this.props}>
                <Layout {...this.props}>
                    <div className="container">
                        {this.props.group.group &&
                        this.props.group.group.orders ? (
                            <Info
                                group={this.props.group.group}
                                order={GroupHelper.getCurrentOrder(
                                    this.props.group.group
                                )}
                            />
                        ) : null}

                        {this.props.group.group ? (
                            <OrdersArchive {...this.props} />
                        ) : null}

                        {this.props.group.group ? (
                            <Notifications {...this.props} />
                        ) : null}

                        {this.props.group.group && (
                            <OrderGroup {...this.props} />
                        )}
                    </div>
                </Layout>
            </DataLayerGroup>
        );
    }
}

GroupOverview.propTypes = {};
GroupOverview.defaultProps = {};

function mapStateToProps(state) {
    return {
        group: state.group,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        getNotifications,
        getAllProducts,
    }
)(GroupOverview);
