export default class Utils {
    /**
     * Checks the current page path against the Router
     * @param {*} props
     * @param {*} pathName
     * @param {*} exact
     */
    static isPage(props, pathName, exact = false) {
        if (props.match && props.match.path) {
            if (exact) {
                return props.match.path === pathName;
            } else {
                return props.match.path.includes(pathName);
            }
        } else {
            return false;
        }
    }

    /**
     * Deep array flattening
     */
    static flatten(values) {
        return values.reduce(
            (acc, val) =>
                Array.isArray(val)
                    ? acc.concat(this.flatten(val))
                    : acc.concat(val),
            []
        );
    }

    /**
     * Create an array of all unique color values
     */
    static uniqueColors = (array, obj, prop) =>
        array.reduce((a, b) => {
            if (a.indexOf(b[obj][prop]) < 0) a.push(b[obj][prop]);
            return a;
        }, []);

    /**
     * Create an array of all unique prop values of a given prop value
     */
    static uniqueProps = (array, prop) =>
        array.reduce((a, b) => {
            if (a.indexOf(b[prop]) < 0) a.push(b[prop]);
            return a;
        }, []);

    /**
     * Remove all duplicate objects from an array based on a prop name
     * @param {Array} array array to search in
     * @param {String} prop propName to check for
     * @returns {Array} unique objects
     */
    static filterUniqueObjects = (array, prop = "id") =>
        array.filter(
            (a, i, self) => i === self.findIndex(t => t[prop] === a[prop])
        );

    /**
     * Sorts an array of objects based on the Date of the dateProp in the object
     */
    static sortBy = (array, prop, reverse = true) => {
        let sorted = array.sort((a, b) =>
            a[prop] < b[prop] ? -1 : a[prop] > b[prop] ? 1 : 0
        );

        if (reverse) {
            return sorted.reverse();
        }

        return sorted;
    };

    /**
     * Sorts an array of objects into an object sorted by the values of the input prop
     * @param {Array} array array of objects to sort
     * @param {String} prop propName to sort by
     */
    static sortByProp(array, prop) {
        let sortedList = {};

        if (array.some(item => item[prop])) {
            array.forEach(item => {
                if (!sortedList[item[prop]]) {
                    sortedList[item[prop]] = [];
                }
                sortedList[item[prop]].push(item);
            });

            return sortedList;
        }

        return null;
    }

    /*
     * Remove HTML *and* unwanted characters from string
     */
    static sanitizeString(string) {
        let sanitized = this.stripHTML(string);
        sanitized = this.cleanString(sanitized);
        return sanitized || "";
    }

    /**
     * Remove HTML from string
     */
    static stripHTML(html) {
        let doc = new DOMParser().parseFromString(html, "text/html");
        return doc.body.textContent || "";
    }

    /**
     * Removes unwanted characters from string
     * Removes encoded HTML entities, special ASCII characters, emojis
     * Allow:
     * az + norwegian characters æøå and some common accents
     * (Add the characters you want to KEEP after \w)
     * numbers
     * common symbols
     *
     * is case insensitive
     */
    static cleanString(string) {
        let clean = string.replace(
            /[^\wæøåàáâäãèéêëìíïîñóòöôùúüûÿý@€£/$-/:-?#{-~!"^_`[\]\s]/gi,
            ""
        );

        return clean || "";
    }
}
