import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

class ActionButton extends Component {
    render() {
        return (
            <div className="action__button col-auto mt-2 mt-md-0">
                <Button
                    {...this.props}
                    color={this.props.color}
                    onClick={() => this.props.onClick()}>
                    {this.props.children}
                </Button>
            </div>
        );
    }
}

ActionButton.propTypes = {
    color: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
};

ActionButton.defaultProps = {
    color: "light",
};

export default ActionButton;
