import React, { Component } from "react";
import PropTypes from "prop-types";
import Badge from "../Common/Badge";

class Header extends Component {
    render() {
        return (
            <header className="container my-4">
                <div className="pagetitle">
                    <h1 className="h2">
                        {this.props.name}{" "}
                        {this.props.status ? (
                            <Badge {...this.props.status} />
                        ) : null}
                    </h1>
                </div>
            </header>
        );
    }
}

Header.propTypes = {
    name: PropTypes.string,
    status: PropTypes.object,
};

Header.defaultProps = {};

export default Header;
