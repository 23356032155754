import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";

// Actions
import { getProductUnits } from "../../Actions/ProductsActions";

// Components
import ImagesItem from "../Images/ImagesItem";
import ImagesUnit from "../Images/ImagesUnit";
import { ProductHelper } from "../../Helpers";

class ProductImagesSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    componentDidMount() {
        if (this.props.product.id) {
            this.props.getProductUnits(this.props.product.id);
        }
    }

    renderUnits() {
        const { product, products } = this.props;
        if (products.productUnitsError) {
            return <div className="col">Kunne ikke laste enheter...</div>;
        }
        if (products.productUnitsFetching === true) {
            return <div className="col">Laster enheter...</div>;
        }
        if (
            products.productUnitsFetching === false &&
            product &&
            product.units &&
            product.units.length
        ) {
            const sortedUnits = ProductHelper.sortUnits(product.units);
            return (
                <>
                    <h3 className="col-12 h4 mb-3">Enheter</h3>

                    {sortedUnits.map((unitGroup, i) => (
                        <ImagesUnit
                            key={`${product.id}_${unitGroup.units[0].id}_${i}`}
                            product={product}
                            unitGroup={unitGroup}
                        />
                    ))}
                </>
            );
        }

        return null;
    }

    // @consideration: Show progress / spinner / appshell when uploading and deleting
    render() {
        const { product } = this.props;
        return (
            <div className="row">
                <div className="col-12">
                    <div className="row no-gutters">
                        <h3 className="col-12 h4">Produkt</h3>
                        <p>
                            Bilder som hører til produktet vises dersom det ikke
                            finnes bilder som hører til en spesifikk farge.
                        </p>

                        <strong className="col-12">{product.name}</strong>

                        <ImagesItem product={product} item={product} />

                        {this.renderUnits()}
                    </div>
                </div>
            </div>
        );
    }
}

ProductImagesSettings.propTypes = {};
ProductImagesSettings.defaultProps = {};

function mapStateToProps(state) {
    return {
        products: state.products,
    };
}
export default connect(
    mapStateToProps,
    {
        getProductUnits,
    }
)(ProductImagesSettings);
