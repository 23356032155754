import React, { Component } from "react";
import { connect } from "react-redux";
//import PropTypes from 'prop-types';

// Actions
import { resetGroup } from "../../Actions/GroupActions";

// Components
import AdminNavigation from "./AdminNavigation";
import AlertHandler from "../Common/AlertHandler";
import ErrorBoundary from "../Common/ErrorBoundary";

class AdminLayout extends Component {
    componentDidMount() {
        // Reset group data whenever navigating in admin
        this.props.resetGroup();
    }

    render() {
        return (
            <ErrorBoundary>
                <AlertHandler {...this.props} />
                <AdminNavigation {...this.props} />

                <main className="main mb-4" id="main">
                    {this.props.children}
                </main>
            </ErrorBoundary>
        );
    }
}

function mapStateToProps(state) {
    return {
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        resetGroup,
    }
)(AdminLayout);
