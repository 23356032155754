import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import { getGroupProducts } from "../../Actions/ProductsActions";
import { getCart } from "../../Actions/GroupCartActions";

// Components
import ProductCard from "./ProductCard";
import ProductCardsAppshell from "./ProductCardsAppshell";

// Helpers
import { ProductHelper } from "../../Helpers";

class ProductGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
        };

        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    componentDidMount() {
        this.props.getCart(this.props.match.params.id);
        this.props.getGroupProducts(this.props.match.params.id);

        if (
            this.props.user.user.role === "Admin" ||
            this.props.user.user.role === "Seller"
        ) {
            this.setState({
                collapse: true,
            });
        }
    }

    toggleCollapse() {
        this.setState({
            collapse: !this.state.collapse,
        });
    }

    render() {
        // Echo possible error messages or that there is no dataz etc
        if (this.props.products.groupProductsError) return null; // <SectionError />;

        // Echo appshell while loading dataz
        if (this.props.products.groupProductsFetching === true)
            return <ProductCardsAppshell />;

        // Fetching is done and we got dataz
        if (
            this.props.cart.cartFetching === false &&
            this.props.cart.cart &&
            this.props.products.groupProductsFetching === false
        ) {
            if (this.props.products.groupProducts.length) {
                const { groupProducts } = this.props.products;
                return ProductHelper.sortProductGroups(groupProducts).map(
                    (product, i) => (
                        <div
                            className="col-12 col-md-6 mb-3"
                            key={`product-${product.id}-${product.name}-${i}`}>
                            <ProductCard
                                {...this.props}
                                product={product}
                                handleCollapse={this.toggleCollapse}
                                collapse={this.state.collapse}
                                customers={this.props.group.group.customers}
                            />
                        </div>
                    )
                );
            } else {
                return (
                    <div className="col-12">
                        <h4>Ingen synlige produkter</h4>
                        <p>
                            Gå til{" "}
                            <Link
                                to={`/group/${this.props.group.group.id}/settings`}>
                                Innstillinger
                            </Link>{" "}
                            og legg til synlige produkter under "Produktutvalg"
                        </p>
                    </div>
                );
            }
        }

        return null;
    }
}

ProductGroup.propTypes = {};
ProductGroup.defaultProps = {};

function mapStateToProps(state) {
    return {
        group: state.group,
        cart: state.cart,
        products: state.products,
        additions: state.additions,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        getCart,
        getGroupProducts,
    }
)(ProductGroup);
