import { GroupsHelper, StatusHelper } from ".";

export default class GroupHelper {
    /**
     *
     * @param {Object} props ...this.props rest param
     * @param {Array} param1 array of objects with user roles and status codes to disable. E.g: [{ userRoles: ["Admin", "Manager"...], statusCodes: ["New", "Open"...]}]
     */
    // @consideration: if true Push a notice to the alert handler that
    // some settings are disabled because of the current status
    static disabledByStatus(props, disableConditions) {
        if (!props && !props.user.user && !props.group.group) return false;

        const currentUser = props.user.user.role; // current user role
        const currentStatus = props.group.group.status.statusCode; // current status code

        // If any disableConditions object contains the current user
        // and if its statusCodes conditions are met return true
        // else false
        const foundCurrentUserRoleConditions = disableConditions.find(
            o => o.userRole === currentUser
        );

        if (
            foundCurrentUserRoleConditions &&
            foundCurrentUserRoleConditions.statusCodes.includes(currentStatus)
        ) {
            return true;
        } else {
            return false;
        }
    }

    /**
     *
     * @param {Object} props ...this.props rest param
     * @param {Array} userRoles array of user roles to disable when group is locked or in prod
     */
    static disableWhenLocked(props, userRoles) {
        const availableSettings = [
            {
                userRole: "Seller",
                statusCodes: [
                    //"OpenForManager",
                    "Production",
                    "SendToProcessing",
                    //"RejectedConfirmation",
                    "LockedSellers",
                    "Closed",
                    "Finished",
                ],
            },
            {
                userRole: "Manager",
                statusCodes: [
                    "New",
                    "Production",
                    "SendToProcessing",
                    "RequestingConfirmation",
                    "LockedSellers",
                    "LockedGroup",
                    "Closed",
                    "Finished",
                ],
            },
            {
                userRole: "Customer",
                statusCodes: [
                    "New",
                    "OpenForManager",
                    "Production",
                    "SendToProcessing",
                    "RejectedConfirmation",
                    "RequestingConfirmation",
                    "LockedSellers",
                    "LockedGroup",
                    "LockedCustomers",
                    "Closed",
                    "Finished",
                ],
            },
        ];

        const settings = userRoles.map(role =>
            availableSettings.find(o => o.userRole === role)
        );

        return this.disabledByStatus(props, settings);
    }

    /**
     * Gets a list of the available shop types
     */
    static getShopTypes() {
        return [
            {
                name: "Russedress",
                shopType: "Russedress",
            },
            {
                name: "Russeservice",
                shopType: "Russeservice",
            },
        ];
    }

    /**
     * Gets the manager of the group
     * @param {Object} customers
     * Gets the group's Manager
     * @param {Array} customers
     */
    static getGroupManager(customers) {
        let foundManager = customers.find(
            customer => customer.customerType.name === "Manager"
        );

        if (foundManager && foundManager.customer) return foundManager.customer;
    }

    /**
     * Format group setting strings into booleans
     * @param {Object} props
     * @param {String} settingName
     */
    static getNormalizedSetting(props, settingName) {
        const { group } = props.group;
        const setting = group.settings.find(
            setting => setting.type === settingName
        );

        if (!setting) return;

        const settingValue = setting.value;

        switch (settingValue) {
            case "0":
            case "false":
                return false;
            case "1":
            case "true":
                return true;
            default:
                return true;
        }
    }

    /**
     * Gets the group's current/active order.
     * Only one order is active at a time, and it has one of the statuses below
     * @param {Object} group
     */
    static getCurrentOrder(group) {
        const currentStatus = [
            "New",
            "Open",
            "OpenForManager",
            "RejectedConfirmation",
            "RequestingConfirmation",
            "Confirmed",
            "SendToProcessing",
            "LockedCustomers",
            "LockedGroup",
            "LockedSellers",
        ];
        let foundOrder = group.orders.find(order =>
            currentStatus.includes(order.humanFriendlyState)
        );

        if (foundOrder) {
            return foundOrder;
        } else {
            return group.orders[0];
        }
    }

    /**
     * Gets the group's currently trackable order if there are any.
     * @param {Object} order
     */
    static isTrackableOrder(order) {
        const currentStatus = ["Registered", "Production", "Sent", "Invoiced"];

        if (
            order &&
            order.humanFriendlyState &&
            order.tracking &&
            currentStatus.includes(order.humanFriendlyState)
        ) {
            return order;
        } else {
            return null;
        }
    }

    /**
     * Checks if the group has any trackable orders
     * @param {Object} group
     */
    static hasTrackableOrder(group) {
        return group.orders
            .map(order => this.isTrackableOrder(order))
            .some(v => v);
    }

    /**
     * Handles data manipulation for a single group
     * @param {*} payload
     */
    static handleGroupData(payload) {
        // Set GroupName to "name"
        let nameObj = payload.settings.find(o => o.type === "GroupName");
        payload.name = nameObj.value;

        // Put all names in a "names" object
        let allNames = {};
        payload.settings.forEach(setting => {
            if (setting.type.includes("Name")) {
                allNames[setting.type] = setting.value;
            }
        });
        payload.names = allNames;

        // Set humanFriendlyState for all orders
        payload.orders.forEach(order => {
            this.handleOrderData(order);
        });

        // Put human readable status in a status object
        const currentOrder = this.getCurrentOrder(payload);

        payload.status = GroupsHelper.getStatus(
            currentOrder.humanFriendlyState
        );

        return payload;
    }

    /**
     * Handles the data for one order
     * @param {Object} order
     */
    static handleOrderData(order) {
        order.humanFriendlyState = StatusHelper.getHumanFriendlyState(
            order.state
        );

        return order;
    }
}
