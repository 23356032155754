let assign = Object.assign || require("object.assign");

const DEFAULT_STATE = {
    errors: [],
};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case "ADD_ERROR":
            if (!action.error.info && action.error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                action.error.info = action.error.response;
            } else if (!action.error.info && action.error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                action.error.info = action.error.request;
            } else if (!action.error.info) {
                // Something happened in setting up the request that triggered an Error
                action.error.info = action.error.message;
            }

            /*
            @consideration handle timeout errors, automatically try again at an interval?
            handle in actions?
            if (action.error.code === 'ECONNABORTED') {
            }
            */

            return assign({}, state, {
                errors: state.errors.concat([action.error]),
            });

        case "REMOVE_ERROR":
            return assign({}, state, {
                errors: state.errors.filter((error, i) => i !== action.index),
            });

        default:
            return state;
    }
}
