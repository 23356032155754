import React from "react";
import AppShell from "../AppShell/AppShell";
import { Number } from "../../Helpers";

class ProductCardsAppshell extends AppShell {
    renderShell(i) {
        return (
            <div className="shell col-12 col-md-6 mb-3" key={`shell-${i}`}>
                <div className="product card h-100">
                    <div className="card-body card-body--product">
                        <span className="h5 card-title mb-0">
                            <div className="row">
                                <div className="col">
                                    <span
                                        className="shell__text font-weight-bold"
                                        style={{
                                            "--width":
                                                Number.random(60, 100) + "%",
                                        }}
                                    />
                                </div>

                                <div className="col-auto">
                                    <span className="shell__text price" />
                                </div>
                            </div>
                        </span>
                    </div>

                    <div className="card-img-top card-img-top--product">
                        <div className="row no-gutters">
                            <div className="col">
                                <img
                                    className="shell__img img-fluid"
                                    src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 680 1074'%2F%3E"
                                    alt="Laster bilde"
                                />
                            </div>
                            <div className="col">
                                <img
                                    className="shell__img img-fluid"
                                    src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 680 1074'%2F%3E"
                                    alt="Laster bilde"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="product__bottom">
                        <span className="shell__btn btn btn-light btn-block rounded-0" />

                        <div className="collapse show collapseMembers">
                            <div className="p-3 product__members">
                                <div className="row">
                                    <div className="col">
                                        <span className="shell__text font-weight-bold" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="shell__text" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return <>{this.createAppShell(6)}</>;
    }
}

export default ProductCardsAppshell;
