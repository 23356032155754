import Axios from "axios";
import { authHeader } from "././AuthenticationActions";
import { config } from "../Helpers/Config";

export function getUserFetching(payload) {
    return {
        type: "GET_USER_FETCHING",
        payload,
    };
}

export function getUser(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getUserFetching(true));

        instance
            .get(`${config.apiUrl}/customers/current-user`)
            .then(response => {
                dispatch({
                    type: "GET_USER",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log("Get user data error", error, error.response);

                dispatch({
                    type: "GET_USER_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function setUserGroupRolePosting(payload) {
    return {
        type: "SET_USER_GROUP_ROLE_POSTING",
        payload,
    };
}

// For switching user roles during development
export function setUserGroupRole(payload) {
    return dispatch => {
        // Set fetching true
        dispatch(setUserGroupRolePosting(true));

        if (payload) {
            dispatch({
                type: "SET_USER_GROUP_ROLE",
                payload,
            });
        } else {
            dispatch({
                type: "SET_USER_GROUP_ROLE_ERROR",
                payload: true,
            });
        }
    };
}

export function setUserPosting(payload) {
    return {
        type: "SET_USER_POSTING",
        payload,
    };
}

// For switching user roles during development
export function setUser(payload) {
    return dispatch => {
        // Set fetching true
        dispatch(setUserPosting(true));

        if (payload) {
            dispatch({
                type: "SET_USER",
                payload,
            });
        } else {
            dispatch({
                type: "SET_USER_ERROR",
                payload: true,
            });
        }
    };
}

export function setOnboarding(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // GET fetching true
        dispatch(setUserPosting(true));

        instance
            .post(`${config.apiUrl}/customers/onboarding`, {
                onboardingFinishedAt: payload.onboardingFinishedAt,
                onboardingVersion: payload.onboardingVersion,
            })
            .then(response => {
                dispatch({
                    type: "SET_USER",
                    payload: response.data,
                });

                if (payload.redirect) {
                    window.location = payload.redirect;
                }
            })
            .catch(error => {
                console.log("Set user onboarding error", error);
                dispatch({
                    type: "SET_USER_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}
