import React, { Component } from "react";
import PropTypes from "prop-types";

class ButtonRadio extends Component {
    render() {
        return (
            <button
                className={`btn ${
                    this.props.priority ? `btn-${this.props.priority}` : ""
                } ${this.props.active ? "active" : ""}`}
                type="button"
                aria-pressed={this.props.active ? true : false}
                onClick={() => this.props.onClick()}>
                {this.props.text}
            </button>
        );
    }
}

ButtonRadio.propTypes = {
    priority: PropTypes.string,
    type: PropTypes.string,
    active: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

ButtonRadio.defaultProps = {
    priority: null,
    type: "button",
};

export default ButtonRadio;
