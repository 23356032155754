import React, { Component } from "react";
import PropTypes from "prop-types";
import OrderCard from "./OrderCard";
import { GroupHelper } from "../../Helpers";

export default class OrdersArchive extends Component {
    getPastOrders(orders, currentOrder) {
        return orders.filter(
            order =>
                order.id !== currentOrder.id &&
                ![
                    "SentToProcessing",
                    "Registered",
                    "Production",
                    "Sent",
                    "Invoiced",
                ].includes(order.humanFriendlyState)
        );
    }

    getActiveOrders(orders, currentOrder) {
        return orders.filter(
            order =>
                order.id !== currentOrder.id &&
                [
                    "SentToProcessing",
                    "Registered",
                    "Production",
                    "Sent",
                    "Invoiced",
                ].includes(order.humanFriendlyState)
        );
    }

    render() {
        const {
            showCurrentOrders,
            showCurrentOrdersTitle,
            showPastOrders,
            showPastOrdersTitle,
        } = this.props;
        const { orders } = this.props.group.group;
        const { group } = this.props.group;

        if (orders && orders.length > 1) {
            const currentOrder = GroupHelper.getCurrentOrder(group);
            const pastOrders = this.getPastOrders(orders, currentOrder);
            const activeOrders = this.getActiveOrders(orders, currentOrder);

            return (
                <div className="mb-3">
                    {showCurrentOrders &&
                    activeOrders &&
                    activeOrders.length ? (
                        <>
                            {showCurrentOrdersTitle ? (
                                <div className="mb-3">
                                    <h3 className="d-block">
                                        Igangsatte ordre
                                    </h3>
                                </div>
                            ) : null}
                            <div
                                className={`row ${
                                    showPastOrders ? "mb-3" : ""
                                }`}>
                                {activeOrders.map(order => (
                                    <OrderCard
                                        {...this.props}
                                        order={order}
                                        group={group}
                                        currentOrder={true}
                                        key={`section-row-${order.id}`}
                                    />
                                ))}
                            </div>
                        </>
                    ) : null}

                    {showPastOrders && pastOrders && pastOrders.length ? (
                        <>
                            {showPastOrdersTitle ? (
                                <div className="mb-3">
                                    <h3 className="d-block">Tidligere ordre</h3>
                                </div>
                            ) : null}
                            <div className="row">
                                {pastOrders.map(order => (
                                    <OrderCard
                                        {...this.props}
                                        order={order}
                                        group={group}
                                        key={`section-row-${order.id}`}
                                    />
                                ))}
                            </div>
                        </>
                    ) : null}
                </div>
            );
        }

        return null;
    }
}

OrdersArchive.propTypes = {
    showCurrentOrders: PropTypes.bool,
    showCurrentOrdersTitle: PropTypes.bool,
    showPastOrders: PropTypes.bool,
    showPastOrdersTitle: PropTypes.bool,
};

OrdersArchive.defaultProps = {
    showCurrentOrders: true,
    showCurrentOrdersTitle: true,
    showPastOrders: true,
    showPastOrdersTitle: true,
};
