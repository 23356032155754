import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { setGroupStatus } from "../../Actions/GroupActions";
import {
    getCart,
    confirmCart,
    unconfirmCart,
} from "../../Actions/GroupCartActions";

// Components
import Action from "../Common/Action";
import Restrict from "../../Auth/Restrict";

// Helpers
import { CustomerOnly, ManagerOnly } from "../../Auth/UserRoles";
import { GroupHelper, CartHelper } from "../../Helpers";

class ProductActions extends Component {
    constructor(props) {
        super(props);

        //this.lockGroup = this.lockGroup.bind(this);
        this.confirmUserOrder = this.confirmUserOrder.bind(this);
        this.unConfirmUserOrder = this.unConfirmUserOrder.bind(this);
    }

    componentDidMount() {
        if (this.props.cart.cartFetching !== false) {
            this.props.getCart(this.props.match.params.id);
        }
    }

    confirmUserOrder() {
        // Only add customerId if confirming someone else's cart
        this.props.confirmCart({
            groupId: this.props.group.group.id,
            //customerId: this.props.user.user.id,
            customerId: null,
        });
    }

    unConfirmUserOrder() {
        // Only add customerId if unConfirming someone else's cart
        this.props.unconfirmCart({
            groupId: this.props.group.group.id,
            //customerId: this.props.user.user.id,
            customerId: null,
        });
    }

    render() {
        const { group } = this.props.group;

        if (this.props.cart.cartFetching === false && this.props.cart.cart) {
            return (
                <>
                    {!CartHelper.hasCustomerAcceptedCart(
                        this.props.cart.cart,
                        this.props.user.user
                    ) && group.status.statusCode === "Open" ? (
                        <Restrict {...this.props} allow={CustomerOnly}>
                            <Action>
                                <Action.Text>
                                    Godkjenn og lås din handlekurv når du er
                                    ferdig med å legge til dine produkter
                                </Action.Text>
                                <Action.Button onClick={this.confirmUserOrder}>
                                    Godkjenn og lås handlekurv
                                </Action.Button>
                            </Action>
                        </Restrict>
                    ) : null}

                    {CartHelper.hasCustomerAcceptedCart(
                        this.props.cart.cart,
                        this.props.user.user
                    ) && group.status.statusCode === "Open" ? (
                        <Restrict {...this.props} allow={CustomerOnly}>
                            <Action>
                                <Action.Text>
                                    Lås opp handlekurven din for å legge til
                                    flere produkter eller endre på handlekurven
                                    din
                                </Action.Text>
                                <Action.Button
                                    onClick={this.unConfirmUserOrder}>
                                    Lås opp handlekurv
                                </Action.Button>
                            </Action>
                        </Restrict>
                    ) : null}

                    {GroupHelper.getGroupManager(group.customers) ? (
                        <Restrict {...this.props} allow={CustomerOnly}>
                            <Action color="secondary">
                                <Action.Text>
                                    Kontakt din ordreansvarlig dersom det er
                                    behov for å endre på bestillingen eller
                                    produktene
                                </Action.Text>
                                <Action.Link
                                    href={`mailto:${
                                        GroupHelper.getGroupManager(
                                            group.customers
                                        ).email
                                    }`}>
                                    Kontakt ordreansvarlig
                                </Action.Link>
                            </Action>
                        </Restrict>
                    ) : null}

                    {group.creator && (
                        <Restrict {...this.props} allow={ManagerOnly}>
                            <Action color="secondary">
                                <Action.Text>
                                    Kontakt din selger angående priser eller
                                    endringer i bestillingen
                                </Action.Text>
                                <Action.Link
                                    href={`mailto:${group.creator.email}`}>
                                    Kontakt selger
                                </Action.Link>
                            </Action>
                        </Restrict>
                    )}
                </>
            );
        }

        return null;
    }
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        group: state.group,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        setGroupStatus,
        getCart,
        confirmCart,
        unconfirmCart,
    }
)(ProductActions);
