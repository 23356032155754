import React, { Component } from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Actions
import {
    getPlacementTypes,
    getGroupLogos,
    uploadGroupLogos,
} from "../../Actions/GroupLogoActions";

// Components
import LogoSettings from "./LogoSettings";

// Helpers
import { GroupHelper, LogoHelper, Utils } from "../../Helpers";
import SettingsLogosAppshell from "./SettingsLogosAppshell";
import LogoSettingsAppshell from "./LogoSettingsAppshell";

class SettingsLogos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUploading: false,
            acceptedFiles: null,
            rejectedFiles: null,
        };

        this.onDrop = this.onDrop.bind(this);
    }

    componentDidMount() {
        const { groupLogo, user } = this.props;
        if (groupLogo.groupLogosFetching !== false) {
            this.props.getGroupLogos(this.props.match.params.id);
        }

        if (
            (user &&
                user.user.role === "Seller" &&
                groupLogo.placementTypesFetching !== false) ||
            (user &&
                user.user.role === "Admin" &&
                groupLogo.placementTypesFetching !== false)
        ) {
            this.props.getPlacementTypes();
        }
    }

    componentWillUnmount() {
        // Make sure to revoke the data uris to avoid memory leaks
        if (this.state.acceptedFiles) {
            this.state.acceptedFiles.forEach(file =>
                URL.revokeObjectURL(file.preview)
            );
        }
    }

    onDrop(acceptedFiles, rejectedFiles) {
        this.setState({ isUploading: true });

        const defaultDarknessType = LogoHelper.getDarknessTypes("Both")
            .typeName;
        const defaultTextileType = LogoHelper.getTextileTypes("Normal")
            .typeName;

        this.props.uploadGroupLogos({
            groupId: this.props.group.group.id,
            images: acceptedFiles,
            darknessType: defaultDarknessType,
            textileType: defaultTextileType,
        });
    }

    renderGroupLogos() {
        const { groupLogo } = this.props;
        // Echo possible error messages or that there is no dataz etc
        if (groupLogo.groupLogosError) return null;

        // Echo appshell while loading dataz
        if (groupLogo.groupLogosFetching === true)
            return <LogoSettingsAppshell />;

        // Fetching is done and we got dataz
        if (
            groupLogo.groupLogosFetching === false &&
            groupLogo.groupLogos &&
            groupLogo.groupLogos.length
        ) {
            if (this.state.isUploading) {
                this.setState({ isUploading: false });
            }
            return (
                <div className="row">
                    {Utils.sortBy(groupLogo.groupLogos, "id").map(logo => (
                        <div
                            className="col-6 col-md-4 col-xl-3 my-3"
                            key={logo.id}>
                            <LogoSettings
                                logo={logo}
                                group={this.props.group.group}
                                groupLogo={groupLogo}
                                disabled={GroupHelper.disableWhenLocked(
                                    this.props,
                                    ["Seller", "Manager"]
                                )}
                            />
                        </div>
                    ))}
                </div>
            );
        }
    }

    render() {
        const { groupLogo, products } = this.props;
        // Echo possible error messages or that there is no dataz etc
        if (groupLogo.placementTypesError || products.groupProductsError)
            return <div className="col">Kunne ikke laste logoer</div>;

        // Echo appshell while loading dataz
        if (
            groupLogo.placementTypesFetching === true ||
            products.groupProductsFetching
        )
            return <SettingsLogosAppshell />;

        // Fetching is done and we got dataz
        if (
            groupLogo.placementTypesFetching === false &&
            groupLogo.placementTypes &&
            groupLogo.placementTypes.length
        ) {
            return (
                <>
                    <div className="row mb-2">
                        <div className="col">
                            <h2>Logoer</h2>
                            {/* <p>
                                Logoer må lastes opp for å få tilgang til
                                logoplasseringer på plagg
                            </p> */}
                        </div>
                    </div>

                    <Dropzone
                        accept="image/jpg, image/jpeg, image/gif, image/png, image/bmp"
                        onDrop={this.onDrop}>
                        {({
                            getRootProps,
                            getInputProps,
                            isDragActive,
                            isDragAccept,
                            isDragReject,
                        }) => (
                            <div
                                {...getRootProps()}
                                className={`dropzone ${
                                    isDragActive ? "dropzone--active" : ""
                                } ${isDragAccept ? "dropzone--accept" : ""} ${
                                    isDragReject ? "dropzone--reject" : ""
                                }`}>
                                <input
                                    {...getInputProps()}
                                    disabled={GroupHelper.disableWhenLocked(
                                        this.props,
                                        ["Seller", "Manager"]
                                    )}
                                />

                                {!this.state.isUploading ? (
                                    <>
                                        <p className="d-none d-sm-block mb-1">
                                            Dra og slipp logofiler her eller{" "}
                                            <span className="btn btn-link p-0">
                                                klikk for å laste opp logoer.
                                            </span>
                                        </p>

                                        <p className="btn btn-link d-block d-sm-none mb-1 p-0">
                                            Klikk for å laste opp logoer.
                                        </p>

                                        {/* Kun *.pdf, *.svg og *.png -filer kan lastes opp. */}

                                        <small>
                                            Kun *.jpg, *.jpeg, *.gif, *.png, og
                                            *.bmp -filer kan lastes opp.
                                        </small>
                                    </>
                                ) : (
                                    <>
                                        {groupLogo.groupLogosError
                                            ? "Noe gikk galt under opplastingen av bildene. Vennligst prøv igjen."
                                            : "Laster opp bilder..."}
                                    </>
                                )}
                            </div>
                        )}
                    </Dropzone>

                    {this.renderGroupLogos()}

                    <div className="row mb-5">
                        {groupLogo.groupLogosFetching === false &&
                        groupLogo.groupLogos &&
                        groupLogo.groupLogos.length &&
                        products.groupProductsFetching === false &&
                        products.groupProducts.length &&
                        !GroupHelper.disableWhenLocked(this.props, [
                            "Seller",
                            "Manager",
                        ]) ? (
                            <div className="col">
                                <Link
                                    to={`/group/${
                                        this.props.group.group.id
                                    }/placement/${
                                        products.groupProducts[0].id
                                    }`}
                                    className="btn btn-primary">
                                    Velg logoplasseringer
                                </Link>
                            </div>
                        ) : null}
                    </div>
                </>
            );
        }

        return null;
    }
}

SettingsLogos.propTypes = {
    updateSettingsLogos: PropTypes.func.isRequired,
};

SettingsLogos.defaultProps = {
    updateSettingsLogos: () => null,
};

function mapStateToProps(state) {
    return {
        products: state.products,
        groupLogo: state.groupLogo,
    };
}
export default connect(
    mapStateToProps,
    {
        getPlacementTypes,
        getGroupLogos,
        uploadGroupLogos,
    }
)(SettingsLogos);
