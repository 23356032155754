import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from 'prop-types';

// Actions
import { getAvailableAdditions } from "../Actions/AdditionsActions";

// Components
import DataLayerGroup from "./DataLayerGroup";
import Layout from "../Components/Group/Layout";
import AdditionItemAppshell from "../Components/Additions/AdditionItemAppshell";
import AdditionsRow from "../Components/Additions/AdditionsRow";
import ProductGroup from "../Components/Products/ProductGroup";
import ProductActions from "../Components/Products/ProductActions";
import Deadline from "../Components/Common/Deadline";
import Restrict from "../Auth/Restrict";

// Helpers
import { Manager } from "../Auth/UserRoles";
import { TitleHelper } from "../Helpers";

class GroupProducts extends Component {
    componentDidMount() {
        TitleHelper.setTitle("Bestilling");
    }

    renderAdditions() {
        // Could not find group ID or maybe API is dead
        if (this.props.group.groupError) {
            return null;
        }

        // App is currently trying to fetch data, please serve appshell
        if (this.props.group.groupFetching === true) {
            return <AdditionItemAppshell />;
        }

        // App has successfully finished fetching
        if (this.props.group.groupFetching === false) {
            return <AdditionsRow {...this.props} />;
        }

        return null;
    }

    render() {
        const { group } = this.props.group;

        return (
            <DataLayerGroup {...this.props}>
                <Layout {...this.props}>
                    <div className="products container">
                        <h2 className="sr-only">Bestilling</h2>

                        {group ? (
                            <div className="row mb-3 justify-content-center">
                                <div className="col-auto">
                                    <Deadline {...this.props} />
                                </div>
                            </div>
                        ) : null}

                        {group ? <ProductActions {...this.props} /> : null}

                        {group ? (
                            <div className="row mb-2">
                                <ProductGroup {...this.props} />
                            </div>
                        ) : null}

                        {group ? (
                            <Restrict {...this.props} allow={Manager}>
                                <div className="row mb-2">
                                    {this.renderAdditions()}
                                </div>
                            </Restrict>
                        ) : null}
                    </div>
                </Layout>
            </DataLayerGroup>
        );
    }
}

GroupProducts.propTypes = {};
GroupProducts.defaultProps = {};

function mapStateToProps(state) {
    return {
        group: state.group,
        additions: state.additions,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        getAvailableAdditions,
    }
)(GroupProducts);
