import { GroupHelper } from "../Helpers";

let assign = Object.assign || require("object.assign");

const DEFAULT_STATE = {
    groups: [],
    groupsFetching: null,
    groupsError: null,
};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case "GET_GROUPS":
            // Find settings name object and set the name as the "name" prop on the group object
            action.payload.forEach(group => {
                group = GroupHelper.handleGroupData(group);
            });

            return assign({}, state, {
                groups: action.payload,
                groupsFetching: false,
            });

        case "GET_GROUPS_FETCHING":
            return assign({}, state, {
                groupsFetching: action.payload,
                groupsError: null,
            });

        case "GET_GROUPS_ERROR":
            return assign({}, state, {
                groupsError: action.payload,
                groups: null,
                groupsFetching: null,
            });

        default:
            return state;
    }
}
