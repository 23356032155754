export default class ImageHelper {
    static getImageTypes(typeName) {
        const types = [
            { name: "Fremside", type: "Front" },
            { name: "Bak", type: "Back" },
            { name: "Venstre", type: "SideLeft" },
            { name: "Høyre", type: "SideRight" },
            { name: "Annen", type: "Other" },
        ];
        const foundType = types.find(type => type.type === typeName);

        if (typeName) {
            return foundType;
        } else {
            return types;
        }
    }

    /**
     * Conserve aspect ratio of the original region.
     * Constrain width to the maxWidth
     *
     * @param {Number} srcWidth width of source image
     * @param {Number} srcHeight height of source image
     * @param {Number} maxWidth maximum available width
     * @return {Object} { width, height }
     */
    static resizeToFitWidth(srcWidth, srcHeight, maxWidth) {
        let ratio = Math.min(maxWidth / srcWidth);

        return {
            width: maxWidth,
            height: Math.floor(srcHeight * ratio),
        };
    }

    static sortImages(item) {
        if (item && item.images && item.images.length) {
            const images = item.images.slice();
            const imagesOrder = [
                "FRONT",
                "BACK",
                "SIDELEFT",
                "SIDERIGHT",
                "OTHER",
            ];
            return images.sort(
                (a, b) =>
                    imagesOrder.indexOf(a.typeName.toUpperCase()) -
                    imagesOrder.indexOf(b.typeName.toUpperCase())
            );
        }

        return null;
    }
}
