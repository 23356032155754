import React from "react";
import PropTypes from "prop-types";
import Product from "../Products/Product";

class AdditionProduct extends Product {
    render() {
        const { product } = this.props;
        return (
            <div className="col-12">
                <div className="card mb-2">
                    <div className="card-body p-3">
                        <div className="row align-items-center">
                            <div className="col col-md-8">
                                <strong>{product.name}</strong>
                            </div>

                            <div className="col text-right">
                                {this.renderPrice(this.props.renderPrice)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AdditionProduct.propTypes = {
    product: PropTypes.object.isRequired,
};

export default AdditionProduct;
