export default class CartHelper {
    /**
     * Checks if the current user, or any customer, has accepted their cart
     * @param {Object} cart this.props.cart.cart
     * @param {Object} customer pass in this.props.customer.customer
     */
    static hasCustomerAcceptedCart(cart, customer) {
        if (
            cart &&
            customer &&
            cart.customerConfirmations &&
            cart.customerConfirmations.length &&
            cart.customerConfirmations.some(o => o.customerId === customer.id)
        ) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Gets all the items in cart for each customer
     * Returns the communal items if no customers are passed in
     * @param {Object} cart
     * @param {Object} customer pass in this.props.customer.customer
     */
    static getCustomerCart(cart, customer) {
        if (customer) {
            return cart.items.filter(
                item => item.customerId === customer.id && !item.isCommunalItem
            );
        } else {
            return cart.items.filter(item => item.isCommunalItem);
        }
    }
}
