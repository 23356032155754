import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Nav,
    NavItem,
    Collapse,
} from "reactstrap";
import { config } from "../../Helpers";

class AdminNavigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.toggleNavbar = this.toggleNavbar.bind(this);
    }

    toggleNavbar() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    render() {
        return (
            <Navbar className="bg-white border mb-3 p-3" light expand="lg">
                <NavbarBrand href="/" className="font-weight-bold">
                    Gruppebestilling admin
                </NavbarBrand>
                <NavbarToggler onClick={this.toggleNavbar}>
                    <span className="navbar-toggler__title">
                        {this.props.text.menu}
                    </span>
                    <span className="navbar-toggler-icon" />
                </NavbarToggler>

                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="w-100" navbar>
                        <NavItem className="nav-item">
                            <a
                                className="nav-link"
                                href={config.universityAdminUrl}>
                                {this.props.text.external}
                            </a>
                        </NavItem>
                        <NavItem
                            className={
                                this.props.match.path === "/admin"
                                    ? "nav-item active"
                                    : "nav-item"
                            }>
                            <Link className="nav-link" to="/admin">
                                {this.props.text.admin}
                            </Link>
                        </NavItem>
                        <NavItem
                            className={
                                this.props.match.path === "/images"
                                    ? "nav-item active"
                                    : "nav-item"
                            }>
                            <Link className="nav-link" to="/images">
                                {this.props.text.images}
                            </Link>
                        </NavItem>
                        <NavItem
                            className={`ml-lg-auto ${
                                this.props.match.path === "/create"
                                    ? "nav-item active"
                                    : "nav-item"
                            }`}>
                            <Link
                                className="btn btn-outline-primary"
                                to="/create">
                                {this.props.text.create}
                            </Link>
                        </NavItem>

                        {/* <NavItem
                            className={
                                this.props.match.path === "/report"
                                    ? "nav-item active"
                                    : "nav-item"
                            }>
                            <Link className="nav-link" to="/report">
                                {this.props.text.report}
                            </Link>
                        </NavItem> */}
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

AdminNavigation.propTypes = {
    text: PropTypes.shape({
        menu: PropTypes.string,
        external: PropTypes.string,
        admin: PropTypes.string,
        images: PropTypes.string,
        report: PropTypes.string,
        create: PropTypes.string,
    }),
};
AdminNavigation.defaultProps = {
    text: {
        menu: "Meny",
        external: "University admin",
        admin: "Dine gruppebestillinger",
        images: "Bilder og plasseringer",
        report: "Rapport",
        create: "Ny gruppebestilling",
    },
};

export default AdminNavigation;
