import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from 'prop-types';

// Actions
import { getOnboarding } from "../Actions/OnboardingActions";

// Components
import DataLayerGroup from "./DataLayerGroup";
import Layout from "../Components/Group/Layout";
import Onboarding from "../Components/Onboarding/Onboarding";

// Helpers
import { TitleHelper } from "../Helpers";

class GroupOnboarding extends Component {
    componentDidMount() {
        this.props.getOnboarding();
        TitleHelper.setTitle("Velkommen til Russedress gruppebestilling!");
    }

    renderOnboarding() {
        // Echo possible error messages or that there is no dataz etc
        if (this.props.onboarding.onboardingError) return null;

        // Echo appshell while loading dataz
        if (this.props.onboarding.onboardingFetching === true) return null;

        // Fetching is done and we got dataz
        if (
            this.props.onboarding.onboardingFetching === false &&
            this.props.onboarding.onboarding
        )
            return (
                <Onboarding
                    {...this.props.onboarding.onboarding}
                    groupId={this.props.group.group.id}
                />
            );
    }

    render() {
        return (
            <DataLayerGroup {...this.props}>
                <Layout {...this.props}>
                    <div className="products container">
                        <h2 className="sr-only">Onboarding</h2>

                        <div className="mb-4">{this.renderOnboarding()}</div>
                    </div>
                </Layout>
            </DataLayerGroup>
        );
    }
}

GroupOnboarding.propTypes = {};
GroupOnboarding.defaultProps = {};

function mapStateToProps(state) {
    return {
        group: state.group,
        onboarding: state.onboarding,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        getOnboarding,
    }
)(GroupOnboarding);
