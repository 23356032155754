import { combineReducers } from "redux";
import AuthenticationReducer from "./AuthenticationReducer";
import GroupsReducer from "./GroupsReducer";
import GroupReducer from "./GroupReducer";
import GroupLogReducer from "./GroupLogReducer";
import GroupLogoReducer from "./GroupLogoReducer";
import GroupCartReducer from "./GroupCartReducer";
import AdditionsReducer from "./AdditionsReducer";
import ProductsReducer from "./ProductsReducer";
import UserReducer from "./UserReducer";
import OnboardingReducer from "./OnboardingReducer";
import SearchReducer from "./SearchReducer";
import ErrorsReducer from "./ErrorsReducer";
import NotificationsReducer from "./NotificationsReducer";

const rootReducers = combineReducers({
    errors: ErrorsReducer,
    authentication: AuthenticationReducer,
    notifications: NotificationsReducer,
    user: UserReducer,
    search: SearchReducer,
    groups: GroupsReducer,
    group: GroupReducer,
    additions: AdditionsReducer,
    logs: GroupLogReducer,
    cart: GroupCartReducer,
    products: ProductsReducer,
    onboarding: OnboardingReducer,
    groupLogo: GroupLogoReducer,
});

export default rootReducers;
