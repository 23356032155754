import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from 'prop-types';

// Components
import DataLayerGroup from "./DataLayerGroup";
import Layout from "../Components/Group/Layout";
import Logs from "../Components/Logs/Logs";

// Helpers
import { TitleHelper } from "../Helpers";

class GroupLogs extends Component {
    componentDidMount() {
        TitleHelper.setTitle("Logg");
    }

    render() {
        const { group } = this.props;
        return (
            <DataLayerGroup {...this.props}>
                <Layout {...this.props}>
                    <div className="products container">
                        <h2 className="sr-only">Logg</h2>

                        <div className="row mb-4">
                            {group ? <Logs {...this.props} /> : null}
                        </div>
                    </div>
                </Layout>
            </DataLayerGroup>
        );
    }
}

GroupLogs.propTypes = {};
GroupLogs.defaultProps = {};

function mapStateToProps(state) {
    return {
        group: state.group,
        errors: state.errors,
    };
}
export default connect(mapStateToProps)(GroupLogs);
