import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Label } from "reactstrap";

// Actions
import { deleteImage } from "../../Actions/ImageActions";

// Components
import ImageUpload from "./ImageUpload";
import LazyImage from "../Common/LazyImage";
import Icon from "../Common/Icon";

// Helpers
import { ImageHelper, Utils } from "../../Helpers";

class ImagesItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.renderImages = this.renderImages.bind(this);
    }

    toggleCollapse() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    getItemImages() {
        const { item, product, group, showImagesSortedInColors } = this.props;
        // Product images (all colored images)
        let itemImages = item.images;

        // Product group images
        // TODO hideImagesSortedInColors
        if (group && group.units && product && product.imagesSortedInColors) {
            const foundColorImageObject = product.imagesSortedInColors.find(
                o => o.color.id === group.units[0].color.id
            );

            // Found match
            if (
                foundColorImageObject &&
                foundColorImageObject.images &&
                foundColorImageObject.images.length
            ) {
                itemImages = foundColorImageObject.images;
            } else {
                // No found color match
                itemImages = null;
            }
        }

        // Filter out all images sorted in colors
        // So we are left with only the item images
        if (!showImagesSortedInColors) {
            let imagesToOmit = Utils.flatten(
                product.imagesSortedInColors.map(o => o.images)
            );

            itemImages = product.images.filter(
                i => !imagesToOmit.find(o => o.id === i.id)
            );
        }

        return itemImages;
    }

    renderImages(itemImages) {
        const { item, isUnit } = this.props;

        return (
            <div className="col-12">
                <div className="row mb-3">
                    {itemImages.map(image => {
                        return (
                            <div className="col-3 text-center" key={image.url}>
                                <LazyImage
                                    className="img-fluid"
                                    src={image.url}
                                    alt={item.name}
                                />
                                {image.typeName ? (
                                    <small className="d-block mt-2">
                                        Bildetype:{" "}
                                        <strong>
                                            {ImageHelper.getImageTypes(
                                                image.typeName
                                            )
                                                ? ImageHelper.getImageTypes(
                                                      image.typeName
                                                  ).name
                                                : image.typeName}
                                        </strong>
                                    </small>
                                ) : null}
                                {/* @consideration: Show image isDark designation */}
                                {!isUnit ? (
                                    <Button
                                        className="m-2"
                                        color="danger"
                                        size="sm"
                                        title="Slett bilde"
                                        disabled={this.props.disabled}
                                        onClick={() =>
                                            this.props.deleteImage(image.id)
                                        }>
                                        <span>Slett</span>

                                        <Icon
                                            name="delete"
                                            fill="#fff"
                                            className="dropzone__icon"
                                            title="Slett motiv"
                                        />
                                    </Button>
                                ) : null}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    render() {
        const { item, product, group, isUnit } = this.props;
        let itemImages = this.getItemImages();

        return (
            <>
                {itemImages && itemImages.length ? (
                    <>{this.renderImages(itemImages)}</>
                ) : (
                    <span className="col-12 d-block text-muted mb-3">
                        {product.name}{" "}
                        {group && group.color && group.color.name
                            ? group.color.name
                            : null}{" "}
                        har ingen bilder
                    </span>
                )}

                {!isUnit ? (
                    <div className="col-12">
                        <Label
                            for={`upload${item.id}`}
                            className="font-weight-bold">
                            Last opp bilde til {product.name}{" "}
                            {group && group.color && group.color.name
                                ? group.color.name
                                : item && item.color && item.color.name
                                ? item.color.name
                                : null}{" "}
                            {item.valueName ? item.valueName : null}
                        </Label>
                        <ImageUpload
                            id={`upload${item.id}`}
                            productId={product.id}
                            units={group && group.units ? group.units : null}
                            unitId={item.id !== product.id ? item.id : null}
                        />
                    </div>
                ) : null}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        products: state.products,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        deleteImage,
    }
)(ImagesItem);
