import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import { getGroupOrder } from "../../Actions/GroupActions";

// Components
import Restrict from "../../Auth/Restrict";

// Helpers
import { Manager } from "../../Auth/UserRoles";
import { GroupHelper, config } from "../../Helpers";

class TrackingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.getStatusMessage = this.getStatusMessage.bind(this);
        this.getExternalStatus = this.getExternalStatus.bind(this);
        this.renderStatusSequence = this.renderStatusSequence.bind(this);
    }

    componentDidMount() {
        this.props.getGroupOrder({
            groupId: this.props.match.params.id,
            orderId: this.props.match.params.orderId,
        });
    }

    getStatusMessage(statusCode) {
        switch (statusCode) {
            case "Registered":
                return {
                    title: this.getExternalStatus("Registered").titleCurrent,
                    text:
                        "Vårt dyktige produksjonsteam klargjør og kontrollerer gruppebestillingen slik at dere får akkurat det dere har bestilt.",
                };
            case "Produced":
                return {
                    title: this.getExternalStatus("Produced").titleCurrent,
                    text: "Gruppebestillingen plukkes, produseres og pakkes.",
                };

            case "Invoiced":
                return {
                    title: this.getExternalStatus("Invoiced").titleCurrent,
                    text:
                        "Gruppebestillingen faktureres, og er snart klar til å sendes.",
                };

            case "Sent":
                return {
                    title: this.getExternalStatus("Sent").titleCurrent,
                    text: "Gruppebestillingen er på vei til din adresse.",
                };
            default:
                return {
                    title: this.getExternalStatus().titleCurrent,
                    text:
                        "Ordreansvarlig har fått en melding om hvorfor bestillingen er stanset.",
                };
        }
    }

    getExternalStatus(statusCode) {
        const statuses = [
            {
                name: "Registered",
                titleCurrent: "Klargjøres og kontrolleres",
                titlePast: "Klargjort og kontrollert",
            },
            {
                name: "Produced",
                titleCurrent: "Produseres",
                titlePast: "Produsert",
            },
            {
                name: "Invoiced",
                titleCurrent: "Faktureres",
                titlePast: "Fakturert",
            },
            {
                name: "Sent",
                titleCurrent: "Sendt!",
                titlePast: "Sendt!",
            },
        ];

        if (statusCode === "all") {
            return statuses;
        }

        const foundStatus = statuses.find(status => status.name === statusCode);

        if (foundStatus) {
            return foundStatus;
        } else {
            return statuses[0];
        }
    }

    renderStatusSequence(statusCode) {
        let hasCurrentStatus = false;
        return this.getExternalStatus("all").map((status, i) => {
            let className = "";

            if (status.name === statusCode) {
                hasCurrentStatus = true;
                className = "tracking__item--current";
            } else if (status.name !== statusCode && !hasCurrentStatus) {
                className = "tracking__item--finished";
            }

            return (
                <li
                    className={`tracking__item ${className}`}
                    key={`status-${i}`}>
                    {status.name === statusCode
                        ? status.titleCurrent
                        : status.titlePast}
                </li>
            );
        });
    }

    render() {
        // Echo possible error messages or that there is no dataz etc
        if (this.props.group.groupOrderFetching) return null;

        // Echo appshell while loading dataz
        if (this.props.group.groupOrderFetching === true) return null;

        // Fetching is done and we got dataz
        if (this.props.group.groupOrderFetching === false) {
            const trackableOrder = GroupHelper.isTrackableOrder(
                this.props.group.groupOrder
            );

            if (trackableOrder) {
                const message = this.getStatusMessage(
                    trackableOrder.tracking.state
                );
                return (
                    <div className="tracking container pt-md-5">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-md-10 col-lg-8">
                                <h1 className="text-center">
                                    <span className="h2">
                                        “{this.props.group.group.name}”
                                    </span>
                                    <span className="d-block">
                                        {message.title}
                                    </span>
                                </h1>

                                <p className="lead text-center my-3">
                                    {message.text}
                                </p>

                                <div className="row justify-content-center mb-2">
                                    <ol className="tracking__list col-auto">
                                        {this.renderStatusSequence(
                                            trackableOrder.tracking.state
                                        )}
                                    </ol>
                                </div>

                                <div className="text-center">
                                    {trackableOrder &&
                                    trackableOrder.tracking.url ? (
                                        <a
                                            href={trackableOrder.tracking.url}
                                            className="btn btn-primary m-1">
                                            Sporing{" "}
                                            {trackableOrder.tracking.number
                                                ? trackableOrder.tracking.number
                                                : null}
                                        </a>
                                    ) : null}

                                    {trackableOrder &&
                                    trackableOrder.tracking.number ? (
                                        <p className="font-weight-bold">
                                            {trackableOrder.tracking.vendor
                                                ? `Levering med ${
                                                      trackableOrder.tracking
                                                          .vendor
                                                  }. `
                                                : null}
                                            Sporingsnummer{" "}
                                            {trackableOrder.tracking.number}
                                        </p>
                                    ) : null}

                                    {trackableOrder &&
                                    !trackableOrder.tracking.number &&
                                    !trackableOrder.tracking.url ? (
                                        <p className="font-weight-bold">
                                            Sporingsnummer vises når
                                            gruppebestillingen sendes.
                                        </p>
                                    ) : null}

                                    <Restrict {...this.props} allow={Manager}>
                                        <Link
                                            to={`/group/${
                                                this.props.group.group.id
                                            }/overview`}
                                            className="btn btn-primary m-1">
                                            Gå til oversikten
                                        </Link>
                                    </Restrict>

                                    <a
                                        href={config.feedUrl}
                                        className="btn btn-outline-secondary m-1">
                                        Tilbake til russ.no
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="tracking container pt-md-5">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-md-10 col-lg-8">
                                <h1 className="text-center">
                                    Ingen sporingsdata tilgjengelig for
                                    øyeblikket.
                                </h1>

                                <p className="text-center font-weight-bold mt-4">
                                    Vennligst sjekk innom om kort tid.
                                    Sporingsnummeret vises når
                                    gruppebestillingen sendes.
                                </p>

                                <div className="text-center mt-4">
                                    <Restrict {...this.props} allow={Manager}>
                                        <Link
                                            to={`/group/${
                                                this.props.group.group.id
                                            }/overview`}
                                            className="btn btn-primary m-1">
                                            Gå til oversikten
                                        </Link>
                                    </Restrict>

                                    <a
                                        href={config.feedUrl}
                                        className="btn btn-outline-secondary m-1">
                                        Tilbake til russ.no
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return null;
    }
}

function mapStateToProps(state) {
    return {
        group: state.group,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    { getGroupOrder }
)(TrackingPage);
