import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from 'prop-types';

// Actions
import { getAllProducts } from "../Actions/ProductsActions";

// Components
import DataLayerGroup from "./DataLayerGroup";
import Layout from "../Components/Group/Layout";
import SettingsGeneral from "../Components/Settings/SettingsGeneral";
import SettingsProducts from "../Components/Settings/SettingsProducts";
import SettingsLogos from "../Components/Settings/SettingsLogos";
import Action from "../Components/Common/Action";
import Restrict from "../Auth/Restrict";

// Helpers
import { Seller } from "../Auth/UserRoles";
import { TitleHelper, GroupHelper } from "../Helpers";

class GroupSettings extends Component {
    componentDidMount() {
        TitleHelper.setTitle("Innstillinger");

        /*
        if (
            (this.props.user.user.role === "Admin" ||
                this.props.user.user.role === "Seller") &&
            this.props.products.allProductsFetching !== false
        ) {
            this.props.getAllProducts();
        }
        */
    }

    shouldShowProductSettings() {
        return (
            this.props.group.groupFetching === false &&
            (this.props.user.user.role === "Admin" ||
                this.props.user.user.role === "Seller" ||
                (this.props.user.user.role === "Manager" &&
                    GroupHelper.getNormalizedSetting(
                        this.props,
                        "CanManagerControlProductSelection"
                    )))
        );
    }

    getSellerEmail() {
        if (
            this.props.group.group &&
            this.props.group.group.creator &&
            this.props.group.group.creator.email
        ) {
            return this.props.group.group.creator.email;
        } else if (
            this.props.group &&
            this.props.group.group &&
            this.props.group.group.shop
        ) {
            return `support@${this.props.group.group.shop.name.toLowerCase()}.no`;
        } else {
            // @consideration different/general support email?
            return "support@russeservice.no";
        }
    }

    render() {
        return (
            <DataLayerGroup {...this.props}>
                <Layout {...this.props}>
                    <div className="products container">
                        {this.props.group && this.props.group.group ? (
                            <SettingsGeneral {...this.props} />
                        ) : null}

                        {this.props.group && this.props.group.group ? (
                            <Restrict {...this.props} allow={Seller}>
                                <SettingsLogos {...this.props} />
                            </Restrict>
                        ) : null}

                        {this.shouldShowProductSettings() ? (
                            <SettingsProducts {...this.props} />
                        ) : (
                            <Action color="secondary">
                                <Action.Text>
                                    Kontakt din selger dersom du ønsker tilgang
                                    til produktinnstillingene
                                </Action.Text>
                                <Action.Link
                                    href={`mailto:${this.getSellerEmail()}`}>
                                    Kontakt selger
                                </Action.Link>
                            </Action>
                        )}
                    </div>
                </Layout>
            </DataLayerGroup>
        );
    }
}

GroupSettings.propTypes = {};
GroupSettings.defaultProps = {};

function mapStateToProps(state) {
    return {
        group: state.group,
        products: state.products,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        getAllProducts,
    }
)(GroupSettings);
