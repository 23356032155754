import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Actions
import { getGroupProducts } from "../../Actions/ProductsActions";

// Components
import Restrict from "../../Auth/Restrict";

// Helpers
import { Seller } from "../../Auth/UserRoles";
import { ProductHelper } from "../../Helpers";

class Requirements extends Component {
    componentDidMount() {
        if (this.props.products.groupProductsFetching !== false) {
            this.props.getGroupProducts(this.props.match.params.id);
        }
    }

    getAddedCartItemsQty(product) {
        const { cart } = this.props.cart;

        let foundUnits =
            cart && cart.items && cart.items.length
                ? cart.items.filter(item => {
                      let foundUnit = product.units.find(
                          unit => unit.id === item.unitId
                      );
                      if (foundUnit && item.unitId === foundUnit.id) {
                          return item;
                      }
                      return null;
                  })
                : null;

        if (foundUnits && foundUnits.length) {
            return foundUnits.length;
        }

        return 0;
    }

    renderMinimumQty() {
        if (
            this.props.cart &&
            this.props.products.groupProductsFetching === false &&
            this.props.products.groupProducts.length
        ) {
            const minQtyProducts = ProductHelper.sortProductGroups(
                ProductHelper.getProductsWithMinimumQty(
                    this.props.products.groupProducts
                )
            );

            if (minQtyProducts && minQtyProducts.length) {
                return minQtyProducts.map((product, i) => (
                    <React.Fragment key={`requirement-${i}`}>
                        <div className="col-6 mb-2">
                            {product.name} {product.color.name}
                        </div>
                        <div className="col-6 mb-2 text-right">
                            {this.getAddedCartItemsQty(product)}/
                            {product.units[0].minimumQty}
                        </div>
                    </React.Fragment>
                ));
            } else {
                return (
                    <div className="col-12 mb-2 text-secondary">
                        Ingen produkter har krav om minimumsantall
                    </div>
                );
            }
        }

        return <div className="col mb-2">Laster minimumsantall...</div>;
    }

    render() {
        return (
            <div className="row no-gutters mb-2 border-bottom">
                <div className="col-12">
                    <div className="row">
                        <div className="col-6 mb-2">
                            <strong>Minimumsantall</strong>
                        </div>

                        <Restrict {...this.props} allow={Seller}>
                            <div className="col-6 mb-2 text-right d-print-none">
                                <Link
                                    className="btn btn-outline-primary btn-sm"
                                    to={`/group/${
                                        this.props.group.group.id
                                    }/settings`}>
                                    Rediger{" "}
                                    <span className="d-none d-md-inline">
                                        minimumsantall
                                    </span>
                                </Link>
                            </div>
                        </Restrict>
                    </div>
                </div>

                {this.renderMinimumQty()}
            </div>
        );
    }
}

Requirements.propTypes = {
    requirements: PropTypes.array.isRequired,
};

Requirements.defaultProps = {
    requirements: [],
};

function mapStateToProps(state) {
    return {
        group: state.group,
        products: state.products,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        getGroupProducts,
    }
)(Requirements);
