import "react-day-picker/lib/style.css";

import React, { Component } from "react";
import { Input } from "reactstrap";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { DateUtils } from "react-day-picker";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";

// Localize DatePicker
const FORMAT = "D/M/YYYY";
const MONTHS = [
    "Januar",
    "Februar",
    "Mars",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Desember",
];
const WEEKDAYS_LONG = [
    "Søndag",
    "Mandag",
    "Tirsdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lørdag",
];
const WEEKDAYS_SHORT = ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø"];

export default class DatePicker extends Component {
    parseDate(str, format, locale) {
        const parsed = dateFnsParse(str, format, { locale });
        if (DateUtils.isDate(parsed)) {
            return parsed;
        }
        return undefined;
    }

    formatDate(date, format, locale) {
        return dateFnsFormat(date, format, { locale });
    }

    // Today
    // ${dateFnsFormat(new Date(), FORMAT)}
    render() {
        if (this.props.disabled) {
            return (
                <Input
                    type="text"
                    placeholder="Velg dato for deadline"
                    disabled={this.props.disabled}
                />
            );
        } else {
            return (
                <DayPickerInput
                    dayPickerProps={{
                        locale: "no",
                        months: MONTHS,
                        weekdaysLong: WEEKDAYS_LONG,
                        weekdaysShort: WEEKDAYS_SHORT,
                        firstDayOfWeek: 1,
                    }}
                    classNames={{
                        container: "DayPickerInput form-control",
                        overlayWrapper: "DayPickerInput-OverlayWrapper",
                        overlay: "DayPickerInput-Overlay",
                    }}
                    value={
                        this.props.value
                            ? this.formatDate(this.props.value, FORMAT, "no")
                            : undefined
                    }
                    formatDate={this.formatDate}
                    format={FORMAT}
                    parseDate={this.parseDate}
                    placeholder="Velg dato for deadline"
                    onDayChange={this.props.onDayChange}
                />
            );
        }
    }
}
