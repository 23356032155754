import React, { Component } from "react";
import PropTypes from "prop-types";

class Restrict extends Component {
    render() {
        const allowedRoles = this.props.allow();

        if (
            this.props.user &&
            this.props.user.userFetching === false &&
            this.props.user.user &&
            allowedRoles.includes(this.props.user.user.role)
        ) {
            return <React.Fragment>{this.props.children}</React.Fragment>;
        } else {
            return null;
        }
    }
}

Restrict.propTypes = {
    children: PropTypes.node,
    allow: PropTypes.func.isRequired,
};
Restrict.defaultProps = {
    allow: () => ["Customer"],
};

export default Restrict;
