import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { getCart } from "../../Actions/GroupCartActions";

// Components
import Customer from "./Customer";
import CustomerAppshell from "./CustomerAppshell";

// Helpers
import { CustomerHelper } from "../../Helpers";

class Customers extends Component {
    componentDidMount() {
        if (this.props.cart.cartFetching !== false) {
            this.props.getCart(this.props.match.params.id);
        }
    }

    render() {
        // Echo possible error messages or that there is no dataz etc
        if (this.props.group.groupError || this.props.cart.cartError)
            return null; //<SectionError />;

        // Echo appshell while loading dataz
        if (
            this.props.group.groupFetching === true ||
            this.props.cart.cartFetching === true
        )
            return <CustomerAppshell />;

        // Fetching is done and we got dataz
        if (
            this.props.group.groupFetching === false &&
            this.props.group.group.customers.length &&
            this.props.cart.cartFetching === false
        ) {
            const customers = CustomerHelper.getCustomers(this.props);
            return customers.map((customer, i) => (
                <div className="col-sm-6 mb-3" key={`customer-${i}`}>
                    <Customer {...this.props} customer={customer} />
                </div>
            ));
        }

        // Fetching is done and there is no data
        if (
            this.props.group.groupFetching === false &&
            this.props.cart.cartFetching === false &&
            !this.props.group.group.length
        ) {
            return (
                <div className="col">
                    Ingen medlemmer er lagt til i bestillingen.
                </div>
            );
        }

        return null;
    }
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        group: state.group,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        getCart,
    }
)(Customers);
