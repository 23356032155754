import React, { Component } from "react";
import { connect } from "react-redux";
//import { Link } from "react-router-dom";
//import PropTypes from "prop-types";

// Actions
import { getAllProducts } from "../Actions/ProductsActions";

// Components
import AdminLayout from "../Components/Admin/AdminLayout";
import ProductImages from "../Components/Products/ProductImages";
//import PageError from "./PageError";

// Helpers
import { TitleHelper } from "../Helpers";

class AdminImages extends Component {
    componentDidMount() {
        if (this.props.products.allProductsFetching !== false) {
            this.props.getAllProducts();
        }

        TitleHelper.setTitle("Admin - Bilder og plasseringer");
    }

    renderAvailableProducts() {
        // Echo possible error messages or that there is no dataz etc
        if (this.props.products.allProductsError)
            return <div className="col">Kunne ikke laste produkter...</div>;

        // Echo appshell while loading dataz
        if (this.props.products.allProductsFetching === true)
            return <div className="col">Laster produkter...</div>;

        // Fetching is done and we got dataz
        if (
            this.props.products.allProductsFetching === false &&
            this.props.products.allProducts.length
        ) {
            return this.props.products.allProducts.map(product => (
                <div className="col-12 col-md-6 mb-2" key={product.id}>
                    <ProductImages {...this.props} product={product} />
                </div>
            ));
        }

        return null;
    }

    render() {
        return (
            <AdminLayout {...this.props}>
                <div className="container mb-4">
                    <h1>Bilder og plasseringer</h1>

                    <div className="row my-2">
                        {this.renderAvailableProducts()}
                    </div>
                </div>
            </AdminLayout>
        );
    }
}

AdminImages.propTypes = {};
AdminImages.defaultProps = {};

function mapStateToProps(state) {
    return {
        products: state.products,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        getAllProducts,
    }
)(AdminImages);
