export default class Theme {
    /**
     * Get the theme for a Shop vendor - use with GlobalStyle styled-components
     */
    static getTheme = themeName => {
        const themes = {
            Russedress: {
                fontFamily: "Nexa",
                fontConfig: {
                    custom: {
                        families: ["Nexa"],
                        urls: ["/fonts/nexa.css"],
                    },
                },
            },
            Russeservice: {
                fontFamily: "Open Sans",
                fontFamilyHeaders: "Open Sans Condensed",
                fontConfig: {
                    google: {
                        families: [
                            "Open+Sans+Condensed:700:latin,latin-ext",
                            "Open+Sans:300,400,600:latin,latin-ext",
                        ],
                    },
                },
            },
            University: {
                fontFamily: "Open Sans",
                fontConfig: {
                    google: {
                        families: ["Open+Sans:300,400,600:latin,latin-ext"],
                    },
                },
            },
        };

        return themes[themeName] ? themes[themeName] : themes.Russedress;
    };
}
