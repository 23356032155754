import React, { Component } from "react";
import { Alert, Button } from "reactstrap";
import Icon from "../Common/Icon";

// Helpers
import { ProductHelper } from "../../Helpers";

class ProductLine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedUnitId: null,
            nameTag: null,
            qty: null,
            showQtyWarning: false,
        };
        this.handleNametagValue = this.handleNametagValue.bind(this);
        this.handleNametag = this.handleNametag.bind(this);
        this.handleSizeChange = this.handleSizeChange.bind(this);
        this.disableSize = this.disableSize.bind(this);
        this.handleQtyValue = this.handleQtyValue.bind(this);
        this.handleQty = this.handleQty.bind(this);
        this.disableQty = this.disableQty.bind(this);
        this.getMaxQty = this.getMaxQty.bind(this);
        this.onDismissQtyWarning = this.onDismissQtyWarning.bind(this);
        this.getUid = this.getUid.bind(this);
    }

    componentDidMount() {
        const { unit, customer } = this.props;
        if (unit && unit.localUnit && customer) {
            this.setState({
                nameTag: customer.customer.firstName,
                qty: unit.qty,
            });
        }

        if (unit && !unit.localUnit && unit.qty > 0) {
            this.setState({
                qty: unit.qty,
            });
        }
    }

    getUid(idString) {
        let uid = "";
        if (this.props.product.productGroupId) {
            uid += this.props.product.productGroupId;
        }
        if (this.props.customer && this.props.customer.customer) {
            uid += this.props.customer.customer.id;
        }
        if (this.props.productIndex) {
            uid += this.props.productIndex;
        }
        return uid + idString;
    }

    // Gets the text value for the product
    getTextValueName() {
        const { unit } = this.props;

        // Allow for empty nametags
        if (
            unit &&
            (unit.textValue || unit.textValue === "" || unit.textValue === " ")
        ) {
            return unit.textValue;
        } else if (this.props.customer && unit.localUnit) {
            return this.props.customer.customer.firstName;
        } else {
            return "";
        }
    }

    getQtyValue() {
        const { unit } = this.props;

        if (unit && unit.qty) {
            return unit.qty;
        } else {
            return 0;
        }
    }

    getSelectedUnitSize() {
        if (this.props.unit && !this.props.unit.localUnit) {
            return this.props.unit.unitId;
        }
        return 0;
    }

    // Create handler for returning the correct data to add product
    handleProductAdd() {
        const { unit, product } = this.props;
        let customerId = null;

        // CustomerId must be null if adding a communal item
        if (this.props.customer && !this.props.self) {
            customerId = this.props.customer.customer.id;
        }

        let payload = {
            productId: product.id,
            groupId: this.props.group.group.id,
            customerId,
            unitId: this.state.selectedUnitId,
            textValue: this.state.nameTag,
            qty: this.state.qty,
            isCommunalItem: this.props.isCommunal,
        };

        // Props for editing an already added unit
        if (unit && !unit.localUnit) {
            payload.id = unit.id;
            payload.textValue = unit.textValue;
        }

        this.props.updateSize(payload, unit);
    }

    handleQtyValue(e) {
        let qty = e.target.value;
        let showQtyWarning = false;

        if (
            e.target &&
            parseInt(e.target.value, 0) > parseInt(e.target.max, 0)
        ) {
            showQtyWarning = true;
            qty = e.target.max;
        }

        this.setState({ qty, showQtyWarning });
    }

    // Handles posting of qty changes
    handleQty() {
        const { unit, product } = this.props;
        // Only update existing unit qty if the qty has changed and is less than or eq to max
        if (
            unit &&
            unit.id &&
            !unit.localUnit &&
            unit.qty !== this.state.qty &&
            parseInt(this.state.qty, 0) <= this.getMaxQty()
        ) {
            const { unit } = this.props;
            let customerId = null;

            if (!this.props.self) {
                customerId = unit.customerId;
            }

            this.props.updateQty({
                productId: product.id,
                groupId: this.props.group.group.id,
                itemId: unit.id,
                customerId,
                textValue: this.state.nameTag,
                qty: this.state.qty,
            });
        } else if (
            this.state.selectedUnitId &&
            parseInt(this.state.qty, 0) <= this.getMaxQty()
        ) {
            this.handleProductAdd();
        }
    }

    handleNametagValue(e) {
        this.setState({ nameTag: e.target.value });
    }

    handleNametag() {
        const { isCommunal, unit, product } = this.props;

        if (
            (!isCommunal || (unit && !unit.localUnit && unit.qty > 0)) &&
            unit &&
            unit.id &&
            !unit.localUnit &&
            unit.textValue !== this.state.nameTag
        ) {
            const { unit } = this.props;
            let customerId = null;

            if (!this.props.self) {
                customerId = unit.customerId;
            }

            this.props.updateNametag({
                productId: product.id,
                groupId: this.props.group.group.id,
                itemId: unit.id,
                customerId,
                textValue: this.state.nameTag,
                qty: this.state.qty,
            });
        } else if (this.state.selectedUnitId && !isCommunal) {
            this.handleProductAdd();
        }
    }

    handleSizeChange(e) {
        const { isCommunal, unit } = this.props;

        // TODO, post new unit directly if it changes, do nothing if it is the first time we select a product
        this.setState({ selectedUnitId: e.target.value }, () => {
            if (!isCommunal || (unit && !unit.localUnit && unit.qty > 0)) {
                this.handleProductAdd();
            }
        });
    }

    disableQty() {
        const { unit } = this.props;
        const disabled = this.props.disableProductControl();
        const maxQty = this.getMaxQty();
        const qtyValue = this.getQtyValue();

        // Disable qty if previously added the unit, but the max qty is less than the current value? Or do we need to diff?
        // Or local unit and disabled by parent or not selected unit
        if (
            disabled ||
            (unit && !unit.localUnit && maxQty <= qtyValue) ||
            (unit && unit.localUnit && !this.state.selectedUnitId)
        ) {
            return true;
        } else {
            return false;
        }
    }

    showRemoveButton() {
        if (
            this.props.removeProduct &&
            this.props.unit &&
            !(this.props.unit.localUnit && this.props.productIndex === 0)
        ) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Returns the max available qty for the selected unit / size
     * fallback to props.maxQty
     */
    getMaxQty() {
        const { product, unit } = this.props;
        let foundUnit;
        let unitId;

        if (product && unit && !unit.localUnit) {
            unitId = unit.unitId;
        } else {
            unitId = this.state.selectedUnitId;
        }

        // Unit from props can be localUnit without stockQtyleft
        // Or a cart unit, also without stockQtyLeft
        // So find the unit in product.units
        foundUnit =
            product &&
            product.units &&
            product.units.find(u => u.id === unitId);

        if (foundUnit && typeof foundUnit.stockQtyLeft !== "undefined") {
            return parseInt(foundUnit.stockQtyLeft, 0);
        }

        return this.props.maxQty;
    }

    onDismissQtyWarning() {
        this.setState({ showQtyWarning: false });
    }

    // Disable size selection if a communal item already has been added to the cart
    disableSize() {
        const { unit, isCommunal } = this.props;
        const disabled = this.props.disableProductControl();

        if (disabled || (isCommunal && unit && !unit.localUnit)) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { isCommunal, product, unit } = this.props;
        const representingAllUnits = product.units[0];

        return (
            <>
                <div className="row mx-0 mb-2">
                    {/* <label
                    className="col-4 col-lg d-flex align-items-center mb-2 mb-lg-0"
                    htmlFor={this.getUid("productSize")}>
                    {ProductHelper.getShortName(product.name)}{" "}
                    {productIndex ? productIndex + 1 : "1"}
                </label> */}

                    <div className="col-auto">
                        <div className="form-inline">
                            <label
                                className="mr-2 sr-only"
                                htmlFor={this.getUid("productSize")}>
                                Størrelse
                            </label>

                            <select
                                className="form-control"
                                onChange={this.handleSizeChange}
                                id={this.getUid("productSize")}
                                disabled={this.disableSize()}
                                defaultValue={this.getSelectedUnitSize()}>
                                <option value="0">Velg str</option>
                                {ProductHelper.sortUnitSizes(product.units).map(
                                    unit => (
                                        <option
                                            disabled={
                                                typeof unit.stockQtyLeft !==
                                                    "undefined" &&
                                                unit.stockQtyLeft <= 0
                                                    ? true
                                                    : null
                                            }
                                            value={unit.id}
                                            key={`size-${unit.id}`}>
                                            {unit.valueName}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                    </div>

                    {representingAllUnits.displayTextValue ? (
                        <div className="col px-0 pr-3">
                            <div className="form-inline">
                                <label
                                    className="mr-2 sr-only"
                                    htmlFor={this.getUid("productDisplayText")}>
                                    Tekst på plagg
                                </label>

                                <input
                                    type="text"
                                    onChange={this.handleNametagValue}
                                    onBlur={this.handleNametag}
                                    className="form-control w-100"
                                    id={this.getUid("productDisplayText")}
                                    placeholder={`Skriv inn ${
                                        isCommunal ? "tekst" : "navn"
                                    }...`}
                                    disabled={this.props.disableProductControl()}
                                    defaultValue={this.getTextValueName()}
                                />
                            </div>
                        </div>
                    ) : null}

                    {isCommunal && (
                        <div className="col-auto px-0 pr-3">
                            <div className="form-inline">
                                <label
                                    className="mr-2 sr-only"
                                    htmlFor={this.getUid("productQty")}>
                                    Antall
                                </label>

                                <input
                                    style={{ width: "6rem" }}
                                    type="number"
                                    min={0}
                                    max={this.getMaxQty()}
                                    maxLength={4}
                                    onChange={this.handleQtyValue}
                                    onBlur={this.handleQty}
                                    className="form-control"
                                    id={this.getUid("productQty")}
                                    placeholder="Skriv inn antall..."
                                    disabled={this.disableQty()}
                                    defaultValue={this.getQtyValue()}
                                />
                            </div>
                        </div>
                    )}

                    {this.showRemoveButton() ? (
                        <div className="col-auto pl-0">
                            <Button
                                className="product__remove"
                                onClick={this.props.removeProduct}
                                color="secondary"
                                title="Fjern produkt"
                                disabled={this.props.disableProductControl()}
                                outline>
                                <Icon name="delete" title="Fjern produkt" />
                            </Button>
                        </div>
                    ) : null}
                </div>

                {this.state.showQtyWarning && unit ? (
                    <div className="row mx-0 mb-2">
                        <div className="col">
                            <Alert
                                color={
                                    this.getMaxQty() > 0 ? "warning" : "danger"
                                }
                                isOpen={this.state.showQtyWarning}
                                toggle={this.onDismissQtyWarning}>
                                {this.getMaxQty() > 0 ? (
                                    <>
                                        Maks antall {unit.valueName}:{" "}
                                        {this.getMaxQty()}
                                    </>
                                ) : (
                                    <>
                                        Kan ikke legge til flere{" "}
                                        {unit.valueName}.{" "}
                                        <strong>
                                            Lagerstatus: Ikke på lager.
                                        </strong>
                                    </>
                                )}
                            </Alert>
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
}

ProductLine.propTypes = {};
ProductLine.defaultProps = {
    maxQty: 999,
};

export default ProductLine;
