import React, { Component } from "react";
import Script from "react-load-script";

export default class LogoPlacerScript extends Component {
    render() {
        return (
            <Script
                url="https://cdnjs.cloudflare.com/ajax/libs/fabric.js/2.4.5/fabric.min.js"
                onCreate={this.props.handleScriptCreate}
                onError={this.props.handleScriptError}
                onLoad={this.props.handleScriptLoad}
            />
        );
    }
}
