import React, { Component } from "react";

// Helpers
import { LogoHelper } from "../../Helpers";

class Fabric extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            lastLeft: null,
            lastTop: null,
            lastScaleX: null,
            lastScaleY: null,
            lastAngle: null,
        };
        this.constrainLogoWithinPlacement = this.constrainLogoWithinPlacement.bind(
            this
        );
        this.canvasNode = React.createRef();
    }

    /**
     * Sets the active logo when selecting a logo of that type on the canvas
     * @param {Object} e
     */
    swapActiveLogoButton(e) {
        let logoButton = {
            logoId: e.target.logoId,
        };

        if (this.props.logoButtonToggleActive) {
            this.props.logoButtonToggleActive(logoButton, false);
        }
    }

    /**
     * Handles the updating of the coordinates of a placement or logo in the state
     * from the coordinates of the placement on the canvas
     * @param {Object} item canvas item
     * @param {String} type placement|logo
     */
    updateStoredItem(item, type = "placement") {
        const { canvas } = this.props;

        const bounds = item.getBoundingRect();
        const boundingCoords = {
            x: bounds.left,
            y: bounds.top,
        };

        item.set({
            left: item.left,
            top: item.top,
            boundingCoords,
            coords: {
                x: item.left,
                y: item.top,
            },
        });

        canvas.renderAll();

        if (type === "placement") {
            this.props.handlePlacementProps(item);
        } else if (type === "logo") {
            this.props.handleLogoProps(item);
        } else {
            throw Error(
                `Update stored item type "${type}" must be "placement" or "logo"`
            );
        }
    }

    /**
     * Sets the canvas width and height, and resizes the current view image to fit it
     * @param {Number} width
     * @param {Number} height
     */
    setCanvasSize(width, height) {
        const { canvas, typeName, imageId } = this.props;

        canvas.setWidth(width);
        canvas.setHeight(height);

        let objects = canvas.getObjects();

        // Only scale backgrounds that match current
        objects.forEach(object => {
            if (
                object.placerType === "image" &&
                object.placerTypeName === typeName &&
                object.imageId === imageId
            ) {
                object.set({
                    scaleX: width / object.width,
                    scaleY: height / object.height,
                });
            }
        });
        canvas.renderAll();
    }

    /**
     * Creates a square on the canvas, representing a placement
     * @param {Object} options
     */
    createCanvasRect(options) {
        const { canvas, fabric, typeName, imageId } = this.props;
        let defaults = {
            width: 140,
            height: 40,
            rotation: 0,
            y: null,
            x: null,
            imageType: {
                typeName: typeName,
            },
        };
        options = Object.assign({}, defaults, options);

        let rect = new fabric.Rect({
            originX: "left",
            originY: "top",
            width: options.width,
            height: options.height,
            angle: options.rotation,
            fill: "rgba(255,0,0,0.3)",
            transparentCorners: false,
            borderColor: "#007bff",
            borderDashArray: [3, 3],
        });

        // Set custom properties
        rect.set({
            placerType: "placement",
            placerTypeName: options.imageType.typeName,
            imageId: options.imageId,
            uid: options.id,
        });

        // Set position if we have it
        if (options.x || options.y) {
            rect.set({
                top: options.y,
                left: options.x,
            });
        }

        canvas.add(rect);

        // Bring newly added to front, else send it to back
        // (f.ex if drawing a saved placement that doesn't match the current typeName)
        if (
            options.imageType.typeName === typeName &&
            options.imageId === imageId
        ) {
            canvas.bringToFront(rect);
            canvas.setActiveObject(rect);
        } else {
            canvas.sendToBack(rect);
        }

        // Center if we don't have any coordinates
        if (!options.x && !options.y) {
            canvas.centerObject(rect);
        }

        const bounds = rect.getBoundingRect();
        const boundingCoords = {
            x: bounds.left,
            y: bounds.top,
        };
        rect.set({
            boundingCoords,
            coords: {
                x: rect.left,
                y: rect.top,
            },
        });

        canvas.renderAll();
    }

    /**
     * Creates a logo on the canvas
     * @param {Object} options
     */
    createCanvasLogo(options) {
        const { canvas, fabric, typeName, imageId } = this.props;
        let defaults = {
            width: 140,
            height: 40,
            rotation: 0,
            y: null,
            x: null,
            imageType: {
                typeName: typeName,
            },
        };
        options = Object.assign({}, defaults, options);

        fabric.Image.fromURL(
            options.logo.url,
            canvasLogo => {
                // TODO, abort this if we have unmounted

                canvasLogo.set({
                    lockUniScaling: true,
                    originX: "left",
                    originY: "top",
                });

                // Saved logo coords
                if (options.logo && options.logo.width && options.logo.height) {
                    let savedScale = LogoHelper.sizeToScale(
                        options.logo,
                        canvasLogo
                    );
                    canvasLogo.set({
                        angle: options.logo.rotation,
                        scaleX: savedScale.scaleX,
                        scaleY: savedScale.scaleY,
                    });
                } else {
                    let target = LogoHelper.scaleToTargetWidth(
                        canvasLogo,
                        options.width
                    );

                    if (target.height > options.height) {
                        target = LogoHelper.scaleToTargetHeight(
                            target,
                            options.height
                        );
                    }

                    let scaled = LogoHelper.sizeToScale(target, canvasLogo);

                    canvasLogo.set({
                        angle: options.rotation,
                        scaleX: scaled.scaleX,
                        scaleY: scaled.scaleY,
                    });
                }

                if (options.logo && options.logo.x && options.logo.y) {
                    canvasLogo.set({
                        top: options.logo.y,
                        left: options.logo.x,
                    });
                }

                // Create a canvas object of the placement container
                // Used to constrain logo movement and center logo x/y within
                // use placementContainer to find the true center of of the placement after rotation
                let placementContainer = new fabric.Rect({
                    selectable: false,
                    evented: false,
                    hasBorders: false,
                    hasControls: false,
                    lockMovementX: true,
                    lockMovementY: true,
                    top: options.y,
                    left: options.x,
                    originX: "left",
                    originY: "top",
                    angle: options.rotation,
                    width: options.width,
                    height: options.height,
                    opacity: 0,
                    fill: "rgba(0,0,255,0)",
                });

                placementContainer.set({
                    placerType: "placement",
                    imageId: options.imageType.id,
                    placerTypeName: options.imageType.typeName,
                    uid: options.id,
                });

                // Transform image/logo origin and center it within the placement
                // Only for newly placed logos
                if (
                    !options.logo ||
                    (options.logo && !options.logo.x && !options.logo.y)
                ) {
                    canvasLogo.setPositionByOrigin(
                        placementContainer.getCenterPoint(),
                        "center",
                        "center"
                    );
                }

                canvas.add(placementContainer);
                canvas.add(canvasLogo);

                // Bring newly added to front, else send it to back
                // (f.ex if drawing a saved placement that doesn't match the current typeName)
                if (
                    options.imageType.typeName === typeName &&
                    options.imageId === imageId
                ) {
                    canvas.bringToFront(canvasLogo);
                    canvas.setActiveObject(canvasLogo);
                } else {
                    canvas.sendToBack(canvasLogo);
                }

                const bounds = canvasLogo.getBoundingRect();
                const boundingCoords = {
                    x: bounds.left,
                    y: bounds.top,
                };
                canvasLogo.set({
                    boundingCoords,
                    coords: {
                        x: canvasLogo.left,
                        y: canvasLogo.top,
                    },
                });

                canvas.renderAll();
            },
            {
                placerType: "logo",
                imageId: options.imageType.id,
                placerTypeName: options.imageType.typeName,
                logoId: options.logo.id,
                logoUrl: options.logo.url,
                uid: options.id,
            }
        );
    }

    /**
     * Gets the canvas representation of a placement
     * @param {Number} id
     */
    getCanvasPlacement(id) {
        let objects = this.canvas.getObjects();

        return objects.find(
            object => object.uid === id && object.placerType === "placement"
        );
    }

    /**
     * Removes the currently active canvas object from the canvas
     */
    removeActiveObject() {
        const { canvas } = this.props;
        let active = canvas.getActiveObject();

        if (active) {
            canvas.remove(active);
            canvas.renderAll();
        }
    }

    /**
     * Handles restrictToBounds, resetting or using the state based on which canvas object you are modifying
     * @param {Object} canvasLogo
     */
    constrainLogoWithinPlacement(canvasLogo) {
        // If new or different logo, reset
        if (canvasLogo.uid !== this.state.id) {
            this.setState(
                {
                    id: canvasLogo.uid,
                    lastLeft: null,
                    lastTop: null,
                    lastScaleX: null,
                    lastScaleY: null,
                    lastAngle: null,
                },
                () => {
                    this.restrictToBounds(canvasLogo);
                }
            );
        } else {
            this.restrictToBounds(canvasLogo);
        }
    }

    /**
     * Prevents moving a canvasLogo out of the intersecting bounds of its associated canvas placement shape
     * @param {Object} canvasLogo
     */
    restrictToBounds(canvasLogo) {
        const { canvas } = this.props;
        let obj = canvasLogo;
        let bounds = canvas
            .getObjects()
            .find(
                o => o.placerType === "placement" && o.uid === canvasLogo.uid
            );
        obj.setCoords();

        if (
            !obj.isContainedWithinObject(bounds) &&
            (this.state.lastTop ||
                this.state.lastLeft ||
                this.state.lastScaleX ||
                this.state.lastScaleY ||
                this.state.lastAngle)
        ) {
            obj.set({
                top: this.state.lastTop,
                left: this.state.lastLeft,
                scaleX: this.state.lastScaleX,
                scaleY: this.state.lastScaleY,
                angle: this.state.lastAngle,
            });
        } else {
            this.setState({
                lastLeft: obj.left,
                lastTop: obj.top,
                lastScaleX: obj.scaleX,
                lastScaleY: obj.scaleY,
                lastAngle: obj.angle,
            });
        }
    }

    componentDidMount() {
        const { canvas } = this.props;
        let el = this.canvasNode.current;

        // Initialize a fabric canvas
        canvas.initialize(el, {
            height: this.props.height,
            width: this.props.width,
            selection: false,
        });

        canvas.on("object:added", e => {
            // Update logo's state after adding to canvas because we don't know the real coordinates until after adding them
            if (
                e.target &&
                e.target.placerType === "logo" &&
                this.props.handleLogoProps
            ) {
                this.updateStoredItem(e.target, "logo");
            }
        });

        canvas.on("object:moving", e => {
            if (e.target && e.target.placerType === "logo") {
                this.constrainLogoWithinPlacement(e.target);
            }
        });

        // Save the logo position after it is moved
        canvas.on("object:moved", e => {
            if (
                e.target &&
                e.target.placerType === "logo" &&
                this.props.handleLogoProps
            ) {
                this.updateStoredItem(e.target, "logo");
            }

            if (
                e.target &&
                e.target.placerType === "placement" &&
                this.props.handlePlacementProps
            ) {
                this.updateStoredItem(e.target, "placement");
            }
        });

        canvas.on("object:rotating", e => {
            if (e.target && e.target.placerType === "logo") {
                this.constrainLogoWithinPlacement(e.target);
            }
        });

        // Save the logo angle after it is rotated
        // Be warned, rotation also changes top and left coordinates!
        canvas.on("object:rotated", e => {
            if (
                e.target &&
                e.target.placerType === "logo" &&
                this.props.handleLogoProps
            ) {
                this.updateStoredItem(e.target, "logo");
            }

            if (
                e.target &&
                e.target.placerType === "placement" &&
                this.props.handlePlacementProps
            ) {
                this.updateStoredItem(e.target, "placement");
            }
        });

        canvas.on("object:scaling", e => {
            if (e.target && e.target.placerType === "logo") {
                this.constrainLogoWithinPlacement(e.target);
            }
        });

        // Save the logo size after it is resized
        // Be warned, scaling also changes top and left coordinates!
        canvas.on("object:scaled", e => {
            if (
                e.target &&
                e.target.placerType === "logo" &&
                this.props.handleLogoProps
            ) {
                this.updateStoredItem(e.target, "logo");
            }

            if (
                e.target &&
                e.target.placerType === "placement" &&
                this.props.handlePlacementProps
            ) {
                this.updateStoredItem(e.target, "placement");
            }
        });

        // Toggle the logo button for the currently selected logo
        canvas.on("selection:created", e => {
            if (e.target && e.target.placerType === "logo") {
                this.swapActiveLogoButton(e);
            }

            if (
                e.target &&
                e.target.placerType === "placement" &&
                this.props.activePlacementHandler
            ) {
                this.props.activePlacementHandler(e.target);
            }
        });

        // Toggle the logo button for the currently selected logo
        canvas.on("selection:updated", e => {
            if (e.target && e.target.placerType === "logo") {
                this.swapActiveLogoButton(e);
            }
            if (
                e.target &&
                e.target.placerType === "placement" &&
                this.props.activePlacementHandler
            ) {
                this.props.activePlacementHandler(e.target);
            }
        });
    }

    render() {
        return (
            <canvas
                ref={this.canvasNode}
                width={this.props.width}
                height={this.props.height}
            />
        );
    }
}

export default Fabric;
