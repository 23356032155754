import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Actions
import { login } from "../Actions/AuthenticationActions";

// Components
import PageError from "./PageError";

// Helpers
//import {RoleSwitcher} from "../Helpers";

class DataLayerApp extends Component {
    componentDidMount() {
        if (
            this.props.authentication.authenticationFetching !== false &&
            this.props.user.userFetching !== false
        ) {
            this.props.login();
        }
    }

    render() {
        // Could not find group ID or maybe API is dead
        if (
            this.props.authentication.authenticationError ||
            this.props.user.userError
        ) {
            return <PageError {...this.props} />;
        }

        // App is currently trying to fetch data, please serve appshell
        if (
            this.props.authentication.authenticationFetching === true ||
            this.props.user.userFetching === true
        ) {
            return null; //<Appshell/>
        }

        // App has successfully finished fetching
        if (
            this.props.authentication.authenticationFetching === false &&
            this.props.user.userFetching === false
        ) {
            return (
                <React.Fragment>
                    {/* <RoleSwitcher {...this.props} /> */}
                    {this.props.children}
                </React.Fragment>
            );
        }

        // None of the above
        return null;
    }
}

DataLayerApp.propTypes = {
    user: PropTypes.object,
    authentication: PropTypes.object,
};
DataLayerApp.defaultProps = {};

function mapStateToProps(state) {
    return {
        authentication: state.authentication,
        user: state.user,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        login,
    }
)(DataLayerApp);
