export default class CustomerHelper {
    /**
     * Get customers based on a find param of "self", "others" or undefined
     * Excludes Seller|Admin: Only gets self if you are a "Customer" or "Manager"
     * @param {Object} props this.props
     * @param {String} find self|others|undefined
     * "self": returns only self,
     * "others": everyone except yourself,
     * "customers": only customers (not manager or seller),
     * undefined: all)
     */

    // @consideration sort customers a-z?
    static getCustomers(props, find) {
        let user = props.user.user;
        let customers = props.group.group.customers
            .filter(
                customer =>
                    customer.customerType.name !== "Seller" &&
                    customer.customerType.name !== "Admin"
            )
            .slice();

        if (user && customers) {
            const self = customers.find(
                c =>
                    c.customer.id === user.id &&
                    c.customerType.name === user.role
            );
            const manager = customers.find(
                c => c.customerType.name === "Manager"
            );

            if (self) {
                if (find === "self") {
                    return self;
                }

                // Remove self from array to put in first for anything but others
                customers.splice(customers.indexOf(self), 1);

                if (!find) {
                    customers.unshift(self);
                }
            } else if (!self && find === "self") {
                return null;
            } else if (!self && find === "customers" && manager) {
                customers.splice(customers.indexOf(manager), 1);
            } else if (!self && !find && manager) {
                // Put manager first if getting everyone
                customers.splice(customers.indexOf(manager), 1);
                customers.unshift(manager);
            }

            return customers;
        }
    }
}
