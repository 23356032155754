import Axios from "axios";
import { authHeader } from "./AuthenticationActions";
import { config } from "../Helpers/Config";
import { GroupHelper } from "../Helpers";

export function updateGroup(payload, orderState = null) {
    return dispatch => {
        dispatch({
            type: "UPDATE_GROUP",
            payload,
            orderState,
        });
    };
}

export function resetGroup() {
    return dispatch => {
        dispatch({
            type: "RESET_GROUP",
        });
    };
}

export function getGroupFetching(payload) {
    return {
        type: "GET_GROUP_FETCHING",
        payload,
    };
}

export function getGroupBackgroundFetching(payload) {
    return {
        type: "GET_GROUP_BACKGROUND_FETCHING",
        payload,
    };
}

export function getGroup(groupId, group) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        if (group) {
            dispatch(getGroupBackgroundFetching(true));
        } else {
            dispatch(getGroupFetching(true));
        }

        instance
            .get(`${config.apiUrl}/groups/${groupId}`)
            .then(response => {
                let newGroupData;
                let newGroupCurrentOrder;
                let oldGroupCurrentOrder;

                // @consideration, check a lastModifiedAt timestamp on the group itself instead of
                // manipulating the group data each time
                if (group && response.data) {
                    newGroupData = GroupHelper.handleGroupData(response.data);
                    newGroupCurrentOrder = GroupHelper.getCurrentOrder(
                        newGroupData
                    );
                    oldGroupCurrentOrder = GroupHelper.getCurrentOrder(group);

                    dispatch({
                        type: "GET_GROUP_BACKGROUND",
                    });
                }

                // Don't do anything if fetched group current order status
                // is same as old group current order status
                if (
                    !(
                        newGroupCurrentOrder &&
                        oldGroupCurrentOrder &&
                        newGroupCurrentOrder.humanFriendlyState ===
                            oldGroupCurrentOrder.humanFriendlyState
                    )
                ) {
                    dispatch({
                        type: "SET_USER_GROUP_ROLE",
                        payload: response.data,
                    });

                    dispatch({
                        type: "GET_GROUP",
                        payload: response.data,
                    });
                }
            })
            .catch(error => {
                console.log("Get group error", error);
                dispatch({
                    type: "GET_GROUP_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function getGroupOrderFetching(payload) {
    return {
        type: "GET_GROUP_ORDER_FETCHING",
        payload,
    };
}

export function getGroupOrder(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getGroupOrderFetching(true));

        instance
            .get(
                `${config.apiUrl}/groups/${payload.groupId}/orders/${
                    payload.orderId
                }`
            )
            .then(response => {
                dispatch({
                    type: "GET_GROUP_ORDER",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log("Get group error", error);
                dispatch({
                    type: "GET_GROUP_ORDER_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function setGroupStatusPosting(payload) {
    return {
        type: "SET_GROUP_STATUS_POSTING",
        payload,
    };
}

export function setGroupStatus(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    const getEndpoint = payload => {
        switch (payload.orderState) {
            case "RequestingConfirmation":
                return `/groups/${
                    payload.groupId
                }/management/ready-for-confirmation`;
            case "RejectedConfirmation":
                return `/groups/${payload.groupId}/management/reject-order`;
            case "Production":
            case "SendToProcessing":
                return `/groups/${
                    payload.groupId
                }/management/send-for-processing`;
            case "Confirmed":
                return `/groups/${payload.groupId}/management/confirm-order`;
            case "LockedCustomers":
            case "LockedSellers":
            case "LockedGroup":
            case "New":
            case "Open":
            case "OpenForManager":
            case "Closed":
            case "Finished":
                payload.isLock = true;
                return `/groups/${payload.groupId}/management/order-state`;
            default:
                payload.isLock = null;
                return `/groups/${payload.groupId}/management/order-state`;
        }
    };

    const endpoint = getEndpoint(payload);
    const body =
        payload.isLock == null
            ? null
            : { state: payload.orderState, isLock: payload.isLock };

    return dispatch => {
        // Set fetching true
        dispatch(setGroupStatusPosting(true));

        instance
            .post(`${config.apiUrl}${endpoint}`, body)
            .then(response => {
                // For statuses that create a "New" order automatically, we have to fetch
                // the new order, and set that as the new group.
                if (
                    [
                        "Production",
                        "SendToProcessing",
                        "Closed",
                        "Finished",
                    ].includes(payload.orderState)
                ) {
                    dispatch(getGroupFetching(true));

                    instance
                        .get(`${config.apiUrl}/groups/${payload.groupId}`)
                        .then(response => {
                            dispatch(updateGroup(true, payload.orderState));

                            dispatch({
                                type: "SET_USER_GROUP_ROLE",
                                payload: response.data,
                            });

                            dispatch({
                                type: "GET_GROUP",
                                payload: response.data,
                            });
                        })
                        .catch(error => {
                            console.log("Get group error", error);
                            dispatch({
                                type: "GET_GROUP_ERROR",
                                payload: true,
                            });

                            // Dispatch generic error to the errorHandler
                            dispatch({ type: "ADD_ERROR", error: error });
                        });
                } else {
                    dispatch({
                        type: "SET_GROUP_STATUS",
                        payload: response.data,
                    });
                }
            })
            .catch(error => {
                console.log("Set group status error", error);
                dispatch({
                    type: "SET_GROUP_STATUS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function setGroupShopPosting(payload) {
    return {
        type: "SET_GROUP_SHOP_POSTING",
        payload,
    };
}

export function setGroupShop(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(setGroupShopPosting(true));

        const shopType = payload.shopType;

        instance
            .post(
                `${config.apiUrl}/groups/${payload.groupId}/management/shop`,
                null,
                {
                    params: {
                        shopType,
                    },
                }
            )
            .then(response => {
                dispatch({
                    type: "SET_GROUP_SHOP",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "SET_GROUP_SHOP_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function setGroupDeadlinePosting(payload) {
    return {
        type: "SET_GROUP_DEADLINE_POSTING",
        payload,
    };
}

export function setGroupDeadline(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(setGroupDeadlinePosting(true));

        instance
            .post(
                `${config.apiUrl}/groups/${
                    payload.groupId
                }/management/deadline`,
                {
                    deadline: payload.deadline,
                }
            )
            .then(response => {
                dispatch({
                    type: "SET_GROUP_DEADLINE",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "SET_GROUP_DEADLINE_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function createGroupPosting(payload) {
    return {
        type: "CREATE_GROUP_POSTING",
        payload,
    };
}

export function createGroup(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(createGroupPosting(true));

        instance
            .post(`${config.apiUrl}/groups`, payload)
            .then(response => {
                dispatch({
                    type: "CREATE_GROUP",
                    payload: response.data,
                });

                if (response.data && response.data.id) {
                    window.location = `/gruppebestilling/group/${
                        response.data.id
                    }/overview`;
                }
            })
            .catch(error => {
                console.log("Create group error", error);

                if (
                    error.response &&
                    error.response.status &&
                    error.response.status === 404
                ) {
                    dispatch({
                        type: "CREATE_GROUP_ERROR",
                        payload: { error, payload },
                    });
                } else {
                    dispatch({
                        type: "CREATE_GROUP_ERROR",
                        payload: true,
                    });

                    // Dispatch generic error to the errorHandler
                    dispatch({ type: "ADD_ERROR", error: error });
                }
            });
    };
}

export function promoteCustomerPosting(payload) {
    return {
        type: "PROMOTE_CUSTOMER_POSTING",
        payload,
    };
}

export function promoteCustomer(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set posting true
        dispatch(promoteCustomerPosting(true));

        instance
            .post(
                `${config.apiUrl}/groups/${payload.groupId}/promotion/promote`,
                {
                    customerId: payload.customerId,
                }
            )
            .then(response => {
                dispatch({
                    type: "PROMOTE_CUSTOMER",
                    payload: response.data,
                });

                // Demote any found manager when promoting another
                if (payload.foundManager && payload.foundManager.id) {
                    instance
                        .post(
                            `${config.apiUrl}/groups/${
                                payload.groupId
                            }/promotion/demote`,
                            {
                                customerId: payload.foundManager.id,
                            }
                        )
                        .then(response => {
                            dispatch({
                                type: "DEMOTE_CUSTOMER",
                                payload: response.data,
                            });
                        })
                        .catch(error => {
                            dispatch({
                                type: "DEMOTE_CUSTOMER_ERROR",
                                payload: true,
                            });

                            // Dispatch generic error to the errorHandler
                            dispatch({ type: "ADD_ERROR", error: error });
                        });
                }
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "PROMOTE_CUSTOMER_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function demoteCustomerPosting(payload) {
    return {
        type: "DEMOTE_CUSTOMER_POSTING",
        payload,
    };
}

export function demoteCustomer(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set posting true
        dispatch(demoteCustomerPosting(true));

        instance
            .post(
                `${config.apiUrl}/groups/${payload.groupId}/promotion/demote`,
                {
                    customerId: payload.customerId,
                }
            )
            .then(response => {
                dispatch({
                    type: "DEMOTE_CUSTOMER",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "DEMOTE_CUSTOMER_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}
