let assign = Object.assign || require("object.assign");

const DEFAULT_STATE = {
    search: [],
    searchPosting: null,
    searchError: null,
    customerGroups: [],
    customerGroupsError: null,
    customerGroupsPosting: null,
};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case "RESET_SEARCH":
            // Resets all search related state to initial state
            return assign({}, DEFAULT_STATE);
        case "GET_SEARCH":
            // Normalize search data to match expected member / customer data structure
            action.payload = action.payload.map(result => {
                let customerType = {
                    name: "Customer",
                    type: 0,
                };
                if (result.isSeller) {
                    customerType = {
                        name: "Seller",
                        type: 2,
                    };
                }

                return {
                    customerType,
                    customer: {
                        id: result.userId,
                        communityUserId: result.userId,
                        ...result,
                    },
                };
            });

            return assign({}, state, {
                search: action.payload,
                searchPosting: false,
            });

        case "GET_SEARCH_POSTING":
            return assign({}, state, {
                searchPosting: action.payload,
                searchError: null,
            });

        case "GET_SEARCH_ERROR":
            return assign({}, state, {
                searchError: action.payload,
                search: null,
                searchPosting: null,
            });

        case "GET_CUSTOMER_GROUPS":
            // Map fetched groups to users
            // Beware, in the future we might have several groups per user
            let newSearchState = state.search.map(user => {
                let matchedGroups = action.payload.filter(
                    o => o.id === user.customer.vehicleId
                );
                user.groups = matchedGroups;
                return user;
            });

            return assign({}, state, {
                search: newSearchState,
                customerGroups: action.payload,
                customerGroupsPosting: false,
            });

        case "GET_CUSTOMER_GROUPS_POSTING":
            return assign({}, state, {
                customerGroupsPosting: action.payload,
                customerGroupsError: null,
            });

        case "GET_CUSTOMER_GROUPS_ERROR":
            return assign({}, state, {
                customerGroupsError: action.payload,
                customerGroups: null,
                customerGroupsPosting: null,
            });

        default:
            return state;
    }
}
