let assign = Object.assign || require("object.assign");

const DEFAULT_STATE = {
    cart: {},
    cartFetching: null,
    cartPosting: null,
    cartError: null,
};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case "GET_CART":
            return assign({}, state, {
                cart: action.payload,
                cartFetching: false,
            });

        case "GET_CART_FETCHING":
            return assign({}, state, {
                cartFetching: action.payload,
                cartError: null,
            });

        case "GET_CART_ERROR":
            return assign({}, state, {
                cartError: action.payload,
                cart: null,
                cartFetching: null,
            });

        case "UNCONFIRM_CART":
        case "CONFIRM_CART":
        case "REPLACE_CART_ITEM":
        case "EDIT_CART_ITEM":
        case "ADD_CART_ITEM":
        case "DELETE_CART_ITEM":
            return assign({}, state, {
                cart: action.payload,
                cartPosting: false,
            });

        case "UNCONFIRM_CART_POSTING":
        case "CONFIRM_CART_POSTING":
        case "REPLACE_CART_ITEM_POSTING":
        case "EDIT_CART_ITEM_POSTING":
        case "ADD_CART_ITEM_POSTING":
        case "DELETE_CART_ITEM_DELETING":
            return assign({}, state, {
                cartPosting: action.payload,
                cartError: null,
            });

        case "UNCONFIRM_CART_ERROR":
        case "CONFIRM_CART_ERROR":
        case "REPLACE_CART_ITEM_ERROR":
        case "EDIT_CART_ITEM_ERROR":
        case "ADD_CART_ITEM_ERROR":
        case "DELETE_CART_ITEM_ERROR":
            return assign({}, state, {
                cartError: action.payload,
                cart: null,
                cartPosting: null,
            });

        default:
            return state;
    }
}
