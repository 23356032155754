import React, { Component } from "react";
//import PropTypes from "prop-types";
// import { Button } from "reactstrap";
//import LazyImage from "../Common/LazyImage";
import { config } from "../../Helpers";

class Group extends Component {
    render() {
        const { group } = this.props;
        const href = `${config.communityUrl}/groups/view/${group.id}`;

        return (
            <div className="member__group row align-items-center">
                <div className="col-12 col-xl-6 mb-4 mb-xl-0">
                    <h3 className="h4">{group.name}</h3>
                    <p>{group.memberCount} medlemmer</p>
                </div>
                <div className="col">
                    <a
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-outline-primary btn-block btn-sm">
                        Se gruppe
                    </a>
                </div>
            </div>
        );
    }
}

Group.propTypes = {};
Group.defaultProps = {};

export default Group;
