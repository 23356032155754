import React, { Component } from "react";
import { Button, ButtonGroup } from "reactstrap";

export default class ProductPrice extends Component {
    constructor(props) {
        super(props);

        this.state = { selected: "+" };

        this.toggleSelected = this.toggleSelected.bind(this);
        this.calcPriceFromPercentage = this.calcPriceFromPercentage.bind(this);
        this.calcPercentageFromPrice = this.calcPercentageFromPrice.bind(this);
    }

    toggleSelected(value) {
        this.setState({ selected: value }, () => {
            this.calcPriceFromPercentage(this.percentage.value);
        });
    }

    toggleFocus(e) {
        let isFocused = document.activeElement === e.target;
        let parent = e.target.parentNode;

        if (isFocused) {
            parent.classList.add("is-focus");
        } else {
            parent.classList.remove("is-focus");
        }
    }

    calcPriceFromPercentage(percentage) {
        const { product } = this.props;
        const representingAllUnits = product.units[0];
        const basePrice = representingAllUnits.basePrice;

        let price;
        let difference = parseFloat(
            ((basePrice * percentage) / 100).toFixed(2),
            0
        );

        if (this.state.selected === "+") {
            price = parseInt(basePrice + difference, 0);
        } else {
            price = parseInt(basePrice - difference, 0);
        }

        this.priceNew.value = price;
        this.setDifference(difference);

        this.props.handleCustomPrice(price);
    }

    calcPercentageFromPrice(price) {
        const { product } = this.props;
        const representingAllUnits = product.units[0];
        const basePrice = representingAllUnits.basePrice;

        if (!price || price === "" || price === " ") {
            price = basePrice;
        } else {
            price = parseInt(price, 0);
        }

        let difference;
        let operator;
        let percentage = (price / basePrice) * 100;

        if (price >= basePrice) {
            operator = "+";
            difference = price - basePrice;
            percentage = percentage - 100;
        } else {
            operator = "-";
            difference = basePrice - price;
            percentage = 100 - percentage;
        }

        this.percentage.value = parseFloat(percentage.toFixed(2), 0);

        this.setState({ selected: operator }, () => {
            this.setDifference(difference);
        });

        this.props.handleCustomPrice(price);
    }

    setDifference(difference) {
        if (difference === 0) {
            this.priceDiff.value = difference;
        } else {
            this.priceDiff.value = `${this.state.selected} ${difference}`;
        }
    }

    render() {
        const { product } = this.props;
        const representingAllUnits = product.units[0];

        return (
            <>
                <div className="form-control bg-light disabled d-flex align-items-center overflow-hidden p-0 mb-2">
                    <label
                        htmlFor="originalPrice"
                        className="flex-shrink-0 my-0 mx-2">
                        Originalpris
                    </label>
                    <input
                        type="number"
                        id="originalPrice"
                        className="p-2 border-0 bg-light w-100"
                        value={representingAllUnits.basePrice}
                        readOnly
                        disabled
                    />
                    <span className="flex-shrink-0 mr-3">,-</span>
                </div>

                <div className="form-control d-flex align-items-center overflow-hidden p-0 mb-2">
                    <ButtonGroup>
                        <Button
                            color={
                                this.state.selected === "-"
                                    ? "primary"
                                    : "secondary"
                            }
                            onClick={() => this.toggleSelected("-")}>
                            -
                        </Button>
                        <Button
                            color={
                                this.state.selected === "+"
                                    ? "primary"
                                    : "secondary"
                            }
                            onClick={() => this.toggleSelected("+")}>
                            +
                        </Button>
                    </ButtonGroup>
                    <input
                        type="number"
                        ref={el => (this.percentage = el)}
                        id="percentageChange"
                        className="form-control-composite p-2 border-0 w-100"
                        onFocus={this.toggleFocus}
                        onBlur={this.toggleFocus}
                        onChange={e =>
                            this.calcPriceFromPercentage(e.target.value)
                        }
                    />
                    <label
                        htmlFor="percentageChange"
                        className="flex-shrink-0 my-0 mr-3">
                        %
                    </label>
                </div>

                <div className="form-control bg-light disabled d-flex align-items-center overflow-hidden p-0 mb-2">
                    <label
                        htmlFor="priceDiff"
                        className="flex-shrink-0 my-0 mx-2">
                        Endring
                    </label>
                    <input
                        type="text"
                        ref={el => (this.priceDiff = el)}
                        id="priceDiff"
                        className="p-2 border-0 bg-light w-100"
                        readOnly
                        disabled
                    />
                    <span className="flex-shrink-0 mr-3">,-</span>
                </div>

                <div className="form-control d-flex align-items-center overflow-hidden p-0">
                    <label
                        htmlFor="newPrice"
                        className="flex-shrink-0 my-0 mx-2">
                        Ny pris
                    </label>
                    <input
                        type="number"
                        ref={el => (this.priceNew = el)}
                        id="newPrice"
                        className="form-control-composite p-2 border-0 w-100"
                        onFocus={this.toggleFocus}
                        onBlur={this.toggleFocus}
                        defaultValue={
                            representingAllUnits.basePrice !==
                            representingAllUnits.price
                                ? representingAllUnits.price
                                : null
                        }
                        onChange={e =>
                            this.calcPercentageFromPrice(e.target.value)
                        }
                    />
                    <span className="flex-shrink-0 mr-3">,-</span>
                </div>
            </>
        );
    }
}
