import React, { Component } from "react";
import PropTypes from "prop-types";
import GroupCard from "./GroupCard";
import { GroupsHelper } from "../../Helpers";
//import {GroupsHelper} from "../../Helpers";

class GroupSection extends Component {
    render() {
        const { groups } = this.props;
        return (
            <div>
                <div className="row mb-2">
                    <div className="col">
                        <h2 className="h1">{this.props.title}</h2>
                    </div>
                </div>

                <div className="row mb-2">
                    {groups.map((group, i) => (
                        <GroupCard
                            {...this.props}
                            group={group}
                            key={`section-row-${group.id}`}
                        />
                    ))}
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg offset-md-7 offset-lg-7 pl-4 pl-md-0 pl-xl-3">
                                <strong>Totalt:</strong>{" "}
                                {GroupsHelper.sumGroupsTotal(groups)},-
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

GroupSection.propTypes = {
    title: PropTypes.string.isRequired,
    groups: PropTypes.array.isRequired,
};

GroupSection.defaultProps = {
    title: "Dine gruppebestillinger",
    groups: [],
};

export default GroupSection;
