import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert } from "reactstrap";

// Actions
import { removeError } from "../../Actions/ErrorsActions";

class Errors extends Component {
    constructor(props) {
        super(props);
        this.onDismiss = this.onDismiss.bind(this);
    }

    // Use the error index to remove the same error from the state
    onDismiss(i) {
        this.props.removeError(i);
    }

    render() {
        if (this.props.errors.errors && this.props.errors.errors.length) {
            return this.props.errors.errors.map((error, i) => {
                const hasInfo =
                    error.info && error.info.data && error.info.data.Message;
                return (
                    <div
                        key={`error-${i}`}
                        className={
                            this.props.className
                                ? this.props.className
                                : "col-auto"
                        }>
                        <Alert
                            color="danger"
                            toggle={() => this.onDismiss(i)}
                            fade={false}>
                            <p className="h5">{error.toString()}</p>
                            {hasInfo ? (
                                <p className="mb-0">
                                    {error.info.data.Message}
                                </p>
                            ) : (
                                <p className="mb-0">
                                    {error.message.toString()}
                                </p>
                            )}
                        </Alert>
                    </div>
                );
            });
        }

        return null;
    }
}

function mapStateToProps(state) {
    return {
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    { removeError }
)(Errors);
