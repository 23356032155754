import React, { Component } from "react";
import { Button } from "reactstrap";

// Helpers
import { config } from "../Helpers";
import Errors from "../Components/Common/Errors";

export default class PageError extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirected: false,
        };
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    noAccess() {
        const { errors } = this.props;

        return (
            errors &&
            errors.errors &&
            errors.errors.length &&
            errors.errors.length === 1 &&
            errors.errors[0].response &&
            errors.errors[0].response.status &&
            (errors.errors[0].response.status === 400 ||
                errors.errors[0].response.status === 401)
        );
    }

    getTitle() {
        const { errors } = this.props;

        if (
            errors &&
            errors.errors &&
            errors.errors.length &&
            errors.errors.length === 1
        ) {
            const error = errors.errors[0];

            if (
                error.response &&
                error.response.status &&
                (error.response.status === 400 || error.response.status === 401)
            ) {
                return "Ingen tilgang";
            }
            return "Noe gikk galt";
        } else if (errors && errors.errors) {
            return "Noe gikk galt";
        } else {
            return "Siden finnes ikke";
        }
    }

    render() {
        return (
            <main className="main" id="main">
                <div className="container">
                    <div
                        className="d-flex text-center align-items-center justify-content-center"
                        style={{ minHeight: 100 + "vh" }}>
                        <div className="container">
                            <h1 className="mb-4">{this.getTitle()}</h1>

                            <div className="row justify-content-center">
                                <Errors {...this.props} />
                            </div>

                            <div className="row justify-content-center">
                                <div className="col">
                                    <Button color="link" onClick={this.goBack}>
                                        Gå tilbake
                                    </Button>
                                    <a
                                        className="btn btn-link"
                                        href={config.feedUrl}>
                                        Tilbake til russ.no
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

PageError.propTypes = {};
PageError.defaultProps = {};
