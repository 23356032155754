import React, { Component } from "react";
import { connect } from "react-redux";
//import PropTypes from "prop-types";
import {
    getAvailableAdditions,
    getAdditions,
} from "../../Actions/AdditionsActions";
import AdditionsSection from "./AdditionsSection";
import AdditionItemAppshell from "./AdditionItemAppshell";
import PageError from "../../Pages/PageError";
import { GroupHelper } from "../../Helpers";

class Additions extends Component {
    componentDidMount() {
        this.props.getAvailableAdditions();

        this.props.getAdditions({
            groupId: this.props.match.params.id,
            orderId: GroupHelper.getCurrentOrder(this.props.group.group).id,
        });
    }

    sortAdditions() {
        return [
            {
                title: "Tilleggsprodukter i handlekurven",
                type: "i handlekurven",
                inCart: true,
                additions: this.props.additions.addedAdditions,
            },
            {
                title: "Tilgjengelige tilleggsprodukter",
                desc: "Skriv inn minuspris for å legge til rabatt (f.eks -99)",
                type: "tilgjengelig",
                inCart: false,
                additions: this.props.additions.availableAdditions,
            },
        ];
    }

    renderAdditions() {}

    render() {
        // Echo possible error messages or that there is no dataz etc
        if (
            this.props.additions.addedAdditionsError ||
            this.props.additions.availableAdditionsError
        )
            return <PageError {...this.props} />;

        // Echo appshell while loading dataz
        if (
            this.props.additions.addedAdditionsFetching === true ||
            this.props.additions.availableAdditionsFetching === true
        )
            return <AdditionItemAppshell />;

        // Fetching is done and we got dataz
        if (
            this.props.additions.addedAdditionsFetching === false &&
            this.props.additions.availableAdditionsFetching === false &&
            this.props.additions.availableAdditions.length &&
            this.props.group.group
        ) {
            return this.sortAdditions().map((addition, i) => (
                <AdditionsSection
                    {...this.props}
                    {...addition}
                    key={`section-${i}`}
                />
            ));
        }

        // None of the above
        return null;
    }
}

Additions.propTypes = {};
Additions.defaultProps = {};

function mapStateToProps(state) {
    return {
        group: state.group,
        additions: state.additions,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        getAvailableAdditions,
        getAdditions,
    }
)(Additions);
