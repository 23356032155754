let assign = Object.assign || require("object.assign");

const DEFAULT_STATE = {
    onboarding: {},
    onboardingFetching: null,
    onboardingError: null,
};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case "GET_ONBOARDING":
            return assign({}, state, {
                onboarding: action.payload,
                onboardingFetching: false,
            });

        case "GET_ONBOARDING_FETCHING":
            return assign({}, state, {
                onboardingFetching: action.payload,
                onboardingError: null,
            });

        case "GET_ONBOARDING_ERROR":
            return assign({}, state, {
                onboardingError: action.payload,
                onboarding: null,
                onboardingFetching: null,
            });

        default:
            return state;
    }
}
