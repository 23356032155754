import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { getGroupOrder } from "../../Actions/GroupActions";

// Components
import Logo from "../Common/Logo";
import Carts from "./Carts";
import Info from "./Info";

// Helpers
import { GroupsHelper, config } from "../../Helpers";

class OrderPage extends Component {
    componentDidMount() {
        this.props.getGroupOrder({
            groupId: this.props.match.params.id,
            orderId: this.props.match.params.orderId,
        });
    }

    render() {
        // Echo possible error messages or that there is no dataz etc
        if (this.props.group.groupOrderFetching) return null;

        // Echo appshell while loading dataz
        if (this.props.group.groupOrderFetching === true) return null;

        // Fetching is done and we got dataz
        if (this.props.group.groupOrderFetching === false) {
            const { group } = this.props;
            const order = group.groupOrder;
            const status = GroupsHelper.getStatus(order.humanFriendlyState);

            return (
                <>
                    <a href={config.feedUrl} className="logo">
                        <div className="logo__inner col-8 pl-0">
                            <Logo
                                className="logo__image"
                                name={group.group.shop.name}
                            />
                        </div>
                    </a>

                    <div className="mt-2 mb-4">
                        <h1>{group.group.name}</h1>
                    </div>

                    {this.props.group.group && (
                        <div className="mb-4">
                            <Info
                                group={this.props.group.group}
                                order={order}
                                showStatus={true}
                                status={status}
                            />
                        </div>
                    )}

                    <div className="mb-3">
                        {this.props.group.group && (
                            <>
                                <div className="mb-3">
                                    <h2 className="h3">Detaljer</h2>
                                </div>
                                <Carts
                                    match={this.props.match}
                                    user={this.props.user}
                                    group={group}
                                    order={order}
                                    cart={group.groupOrder}
                                    isArchive={true}
                                />
                            </>
                        )}
                    </div>
                </>
            );
        }

        return null;
    }
}

function mapStateToProps(state) {
    return {
        group: state.group,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    { getGroupOrder }
)(OrderPage);
