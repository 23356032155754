import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

// Helpers
import { DateHelper, GroupsHelper, GroupHelper } from "../../Helpers";

class LogItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showMessage: false,
        };

        this.toggleInfo = this.toggleInfo.bind(this);
    }

    getActionText(action) {
        const { log } = this.props;
        let affectedName = action.affectedByName;
        let customerName = action.customerName;
        let printName =
            affectedName && customerName && affectedName !== customerName
                ? customerName
                : "medlem";

        switch (action) {
            case "GroupCreated":
                return "opprettet gruppebestilling";
            case "GroupUpdated":
                return "oppdaterte gruppebestilling";
            case "GroupShopChanged":
                return "byttet shop for gruppen";
            case "GroupCustomerAdded":
                return `la til ${printName}`;
            case "GroupCustomerRemoved":
                return `fjernet ${printName}`;
            case "CustomerCreated":
                return "opprettet medlem";
            case "CustomerUpdated":
                return "oppdaterte medlem";
            case "CustomerPromoted":
                return `promoterte ${printName} til ordreansvarlig`;
            case "CustomerDemoted":
                return `degraderte ${printName} fra ordreansvarlig`;
            case "Confirmed":
                return "godkjente bestillingen";
            case "Rejected":
                return "avslo bestillingen";
            case "Lock":
                return "låste";
            case "Unlock":
                return "låste opp";
            case "ReadyForConfirmation":
                return `endret status til "Til godkjennelse"`;
            case "SendToProcessing":
            case "SentForProcessing":
                return "sendte bestillingen til produksjon";
            case "ItemAdded":
                return `la til ${this.getUnitText(log.unit)}`;
            case "ItemUpdated":
                return `oppdaterte ${this.getUnitText(log.unit)}`;
            case "ItemRemoved":
                return `fjernet ${this.getUnitText(log.unit)}`;
            case "AdditionAdded":
                return `la til ${
                    log.actionName
                        ? `tilleggsprodukt "${log.actionName}"`
                        : "tillegg"
                }`;
            case "AdditionRemoved":
                return `fjernet ${
                    log.actionName
                        ? `tilleggsprodukt "${log.actionName}"`
                        : "tillegg"
                }`;
            case "State":
                let foundStatus = GroupsHelper.getStatus(log.actionName);

                return `endret status til "${
                    foundStatus ? foundStatus.statusText : log.actionName
                }"`;
            case "CartConfirmed":
                return "bekreftet handlekurv";
            case "CartUnconfirmed":
                return "ubekreftet handlekurv";
            case "Deadline":
                return `satt deadline til ${log.actionValue}`;

            default:
                return "";
        }
    }

    getUnitText(unit) {
        const { log } = this.props;
        return `${log.productName} ${unit.color.name} - ${unit.valueName}${
            log.textValue ? `  med teksten "${log.textValue}"` : ""
        }`;
    }

    toggleInfo() {
        this.setState({ showMessage: !this.state.showMessage });
    }

    render() {
        const { log, user } = this.props;
        const action = log.action;
        const showPrices = GroupHelper.getNormalizedSetting(
            this.props,
            "CanDisplayPrices"
        );

        return (
            <div className="col-12">
                <div className="card mb-2">
                    <div className="card-body p-3">
                        <div className="row">
                            <div className="col-12 col-md-auto order-md-1 mb-2 mb-md-0">
                                {user.user.role === "Admin" ||
                                user.user.role === "Seller" ||
                                (user.user.role === "Manager" && showPrices) ? (
                                    <Button
                                        color="primary"
                                        size="sm"
                                        outline
                                        onClick={this.toggleInfo}>
                                        {this.state.showMessage
                                            ? "Skjul info"
                                            : "Mer info"}
                                    </Button>
                                ) : null}

                                <small className="text-black-50 ml-3">
                                    {DateHelper.toReadablestring(log.createdAt)}
                                </small>
                            </div>
                            <div className="col order-md-0">
                                <div className="row">
                                    <div className="col">
                                        <strong>
                                            {log.affectedByName
                                                ? log.affectedByName
                                                : "Systemet"}
                                        </strong>{" "}
                                        <span>
                                            {this.getActionText(action)}
                                        </span>
                                    </div>

                                    {(this.state.showMessage &&
                                        user.user.role === "Admin") ||
                                    (this.state.showMessage &&
                                        user.user.role === "Seller") ||
                                    (this.state.showMessage &&
                                        user.user.role === "Manager" &&
                                        showPrices) ? (
                                        <span className="col-12 text-secondary text-small">
                                            {log.message}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

LogItem.propTypes = {
    log: PropTypes.shape({
        customerName: PropTypes.string,
        action: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
    }),
};

LogItem.defaultProps = {};

export default LogItem;
