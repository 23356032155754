export default class DateHelper {
    /**
     * Gets the current time as an ISO string
     */
    static string = () => new Date().toISOString();

    /**
     * Converts an ISO string to a human readable format based on a Norwegian locale setting
     */
    static toReadablestring = (isoDate, options) =>
        new Date(isoDate).toLocaleString("nb-NO", options);

    /**
     * Sorts an array of objects based on the Date of the dateProp in the object
     */
    static sortByDate = (array, dateProp, reverse = true) => {
        let sorted = array.sort((a, b) =>
            a[dateProp] < b[dateProp] ? -1 : a[dateProp] > b[dateProp] ? 1 : 0
        );

        if (reverse) {
            return sorted.reverse();
        }

        return sorted;
    };
}
