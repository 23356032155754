import React, { Component } from "react";
import PropTypes from "prop-types";
import Badge from "../Common/Badge";
import { DateHelper, GroupHelper } from "../../Helpers";

class Info extends Component {
    render() {
        const { group, order } = this.props;
        const manager = GroupHelper.getGroupManager(group.customers);

        return (
            <div className="mb-4">
                <div className="row">
                    <div className="pagetitle col-12 mb-2">
                        <h3 className="d-block">
                            Ordre id: {order.incrementalId}{" "}
                            {this.props.showStatus && this.props.status ? (
                                <Badge {...this.props.status} />
                            ) : null}
                        </h3>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md">
                                {group.creator && (
                                    <span className="d-block">
                                        <strong>Selger:</strong>{" "}
                                        {group.creator.firstName}{" "}
                                        {group.creator.lastName}
                                    </span>
                                )}
                                {manager && (
                                    <span className="d-block">
                                        <strong>Ordreansvarlig:</strong>{" "}
                                        {manager.firstName} {manager.lastName}
                                    </span>
                                )}
                            </div>
                            <div className="col-12 col-md">
                                {group.customer && (
                                    <div>
                                        <span>
                                            <strong>Kunde:</strong>{" "}
                                            {group.customer.id}
                                        </span>
                                        <span className="d-inline-block ml-2">
                                            <strong>Navn:</strong>{" "}
                                            {group.customer.firstName}{" "}
                                            {group.customer.lastName}
                                        </span>
                                    </div>
                                )}
                                {group.createdAt && group.creator ? (
                                    <span className="d-block">
                                        <strong>Opprettet: </strong>
                                        {DateHelper.toReadablestring(
                                            order.createdAt
                                        )}{" "}
                                        av {group.creator.firstName}{" "}
                                        {group.creator.lastName}
                                    </span>
                                ) : null}

                                {this.props.status &&
                                this.props.status.statusCode &&
                                this.props.status.statusCode === "Closed" &&
                                order.state &&
                                order.state.closedAt ? (
                                    <span className="d-block">
                                        <strong>Stengt: </strong>
                                        {DateHelper.toReadablestring(
                                            order.state.closedAt
                                        )}{" "}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Info.propTypes = {
    group: PropTypes.object,
};

Info.defaultProps = {};

export default Info;
