import Axios from "axios";
import { config } from "../Helpers/Config";
import { authHeader } from "./AuthenticationActions";

export function uploadImagePosting(payload) {
    return {
        type: "UPLOAD_PRODUCT_IMAGE_POSTING",
        payload,
    };
}

export function uploadImage(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    let bodyFormData = new FormData();
    bodyFormData.set("ProductId", payload.productId);
    bodyFormData.set("UnitId", payload.unitId === null ? "" : payload.unitId);
    bodyFormData.set("Type", payload.type);
    bodyFormData.set("IsDark", payload.isDark);

    bodyFormData.append("Image", payload.image);

    return dispatch => {
        // Set fetching true
        dispatch(uploadImagePosting(true));

        instance
            .post(`${config.apiUrl}/images/product`, bodyFormData)
            .then(response => {
                // TODO: Remove window.reload and update reducer when we get product and unit id from api
                window.location.reload();

                dispatch({
                    type: "UPLOAD_PRODUCT_IMAGE",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "UPLOAD_PRODUCT_IMAGE_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function uploadImagesSortedInColors(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    let bodyFormData = new FormData();

    bodyFormData.set("ProductId", payload.productId);

    //bodyFormData.set("UnitId", data.unitId === null ? "" : data.unitId);
    bodyFormData.set("ColorId", payload.colorId);
    bodyFormData.set("Type", payload.type);
    bodyFormData.set("IsDark", payload.isDark);
    bodyFormData.append("Image", payload.image);

    return dispatch => {
        // Set fetching true
        dispatch(uploadImagePosting(true));

        instance
            .post(`${config.apiUrl}/images/product`, bodyFormData)
            .then(response => {
                // TODO: Remove window.reload and update reducer when we get product and unit id from api
                window.location.reload();

                dispatch({
                    type: "UPLOAD_PRODUCT_IMAGE",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "UPLOAD_PRODUCT_IMAGE_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function deleteImageDeleting(payload) {
    return {
        type: "DELETE_PRODUCT_IMAGE_DELETING",
        payload,
    };
}

export function deleteImage(productImageId) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(deleteImageDeleting(true));

        instance
            .delete(`${config.apiUrl}/images/${productImageId}`)
            .then(response => {
                // TODO: Remove window.reload and update reducer when we get product and unit id from api
                window.location.reload();

                dispatch({
                    type: "DELETE_PRODUCT_IMAGE",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "DELETE_PRODUCT_IMAGE_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}
