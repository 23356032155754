import React, { Component } from "react";
import PropTypes from "prop-types";

class ActionLink extends Component {
    render() {
        return (
            <div className="action__button col-auto mt-2 mt-md-0">
                <a {...this.props} className={`btn btn-${this.props.color}`}>
                    {this.props.children}
                </a>
            </div>
        );
    }
}

ActionLink.propTypes = {
    color: PropTypes.string,
    children: PropTypes.node,
};

ActionLink.defaultProps = {
    color: "light",
};

export default ActionLink;
