let assign = Object.assign || require("object.assign");

const DEFAULT_STATE = {
    authentication: {},
    authenticationFetching: null,
    authenticationError: null,
};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case "LOGIN_SUCCESS":
            return assign({}, state, {
                authentication: action.payload,
                authenticationFetching: false,
            });

        case "LOGIN_REQUEST":
            return assign({}, state, {
                authenticationFetching: action.payload,
                authenticationError: null,
            });

        case "LOGIN_ERROR":
            return assign({}, state, {
                authenticationError: action.payload,
                authentication: null,
                authenticationFetching: null,
            });

        default:
            return state;
    }
}
