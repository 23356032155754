import React, { Component } from "react";

// TODO: Sort cartlist in same order as product page, or a-z?
class CartList extends Component {
    /**
     * Creates an array which has the sum qty of all cart items of the same unitId
     * Each array object is a total representation of each unit
     */
    getCombinedCartItems() {
        let sortedList = {};
        let sumList = [];

        if (this.props.list.some(item => item.unitId)) {
            this.props.list.forEach(item => {
                let sameUnitId = item.unitId + item.price;
                if (!sortedList[sameUnitId]) {
                    sortedList[sameUnitId] = [];
                }
                sortedList[sameUnitId].push(item);
            });

            Object.entries(sortedList).forEach(([id, items]) => {
                let totalQty = items.reduce((a, b) => a + b.qty, 0);

                sumList.push({
                    ...items[0],
                    qty: totalQty,
                });
            });

            return sumList;
        } else {
            return this.props.list;
        }
    }

    renderProduct(product) {
        const { isSeller, showPrices } = this.props;
        return (
            <>
                <div
                    className={`mb-2 ${
                        isSeller || showPrices || product.qty
                            ? "col-6"
                            : "col-12"
                    }`}>
                    {product.name ? product.name : product.productName}{" "}
                    {product.colorName ? product.colorName : null}
                    {product.valueName ? ` - ${product.valueName}` : null}
                </div>

                {product.qty ? (
                    <>
                        <div className="col text-right">{product.qty}</div>

                        {isSeller || showPrices ? (
                            <div className="col text-right d-none d-md-block">
                                <span className="price">{product.price},-</span>{" "}
                                <small>Per stk.</small>
                            </div>
                        ) : null}
                    </>
                ) : null}

                {isSeller || showPrices ? (
                    <div className="col text-right">
                        <span className="price d-block">
                            {product.qty
                                ? product.price * product.qty
                                : product.price}
                            ,-
                        </span>
                    </div>
                ) : null}
            </>
        );
    }

    renderList() {
        if (this.props.list) {
            const sumList = this.getCombinedCartItems();

            return sumList.map((item, i) => {
                return (
                    <React.Fragment key={`listItem-${i}`}>
                        {this.renderProduct(item)}
                    </React.Fragment>
                );
            });
        }
    }

    render() {
        return <>{this.renderList()}</>;
    }
}

export default CartList;
