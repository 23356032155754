import React, { Component } from "react";
import PropTypes from "prop-types";

class Badge extends Component {
    getStatusCode() {
        switch (this.props.statusCode) {
            case "Open":
                return "primary";

            case "Confirmed":
            case "Sent":
            case "success":
            case "Finished":
            case "Produced":
            case "Invoiced":
            case "Success":
                return "success";

            case "info":
            case "RequestingConfirmation":
                return "info";

            case "primary":
            case "Production":
                return "primary";

            case "OpenForManager":
            case "warning":
            case "RejectedConfirmation":
                return "warning";

            case "danger":
            case "Registered":
            case "Stopped":
            case "Closed":
                return "danger";

            case "dark":
            case "New":
                return "dark";

            default:
                return "danger";
        }
    }

    render() {
        return (
            <span
                title={this.props.title}
                className={`badge badge-${this.getStatusCode()} 
            ${this.props.pill ? "badge-pill" : ""} 
            ${this.props.uppercase ? "text-uppercase" : ""} 
            ${this.props.className}`}>
                {this.props.statusText
                    ? this.props.statusText
                    : this.props.children}
            </span>
        );
    }
}

Badge.propTypes = {
    statusText: PropTypes.string,
    statusCode: PropTypes.string,
    uppercase: PropTypes.bool,
    pill: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
};

Badge.defaultProps = {
    className: "",
    uppercase: true,
    pill: false,
};

export default Badge;
