import React, { Component } from "react";
import { Button } from "reactstrap";
import FinishButton from "./FinishButton";

class SlideIntro extends Component {
    render() {
        return (
            <div className="onboarding__intro">
                <div className="row text-center align-items-center justify-content-center py-5 mb-3">
                    <div
                        className="col-12 col-md-auto"
                        style={{ maxWidth: 100 + "%" }}>
                        <div className="row justify-content-center">
                            <h2 className="col-12 onboarding__title">
                                {this.props.title}
                            </h2>
                            <div className="col-12 col-md-6">
                                {this.props.text}
                            </div>
                        </div>

                        <div className="row my-3">
                            <div className="col-12">
                                <Button
                                    color="primary"
                                    onClick={this.props.slideNext}>
                                    Ta en kjapp gjennomgang
                                </Button>
                            </div>
                            <div className="col-12 mt-2">
                                <FinishButton
                                    href={`/gruppebestilling/group/${
                                        this.props.groupId
                                    }/products`}
                                    className="btn btn-link text-secondary">
                                    Hopp over
                                </FinishButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SlideIntro;
