import React from "react";
import ButtonRadio from "../LogoPlacer/components/ButtonRadio";
import { ImageHelper } from ".";

export default class PlacerHelper {
    // Get the product and possibly unit color.name
    static getItemName(state) {
        return `${state.product.name}${
            state.unit && state.unit.color && state.unit.color.name
                ? ` ${state.unit.color.name}`
                : ""
        }`;
    }

    /**
     * Loads new units when navigating between products
     * @param {*} _this
     * @param {*} prevProps
     */
    static handleUnitLoading(_this, prevProps) {
        const { products, location, match } = _this.props;

        if (
            !products.productUnitsFetching &&
            match.params.productId &&
            (prevProps.match.params.productId !== _this.state.product.id ||
                location !== prevProps.location)
        ) {
            _this.props.getProductUnits(match.params.productId);
        }
    }

    // Handle navigation
    static handleNavigation(_this, prevProps) {
        const { products, location, match } = _this.props;

        const navFromUnit =
            match.params.productId === _this.state.product.id &&
            !match.params.unitId &&
            _this.state.unit &&
            _this.state.unit.id === prevProps.match.params.unitId;

        // Navigate unit
        if (
            location !== prevProps.location &&
            match.params.productId === _this.state.product.id &&
            products.productUnits &&
            products.productUnits.length &&
            match.params.unitId
        ) {
            _this.init(match.params.productId, match.params.unitId);
        }

        // Navigate product
        else if (
            location !== prevProps.location &&
            (match.params.productId !== _this.state.product.id || navFromUnit)
        ) {
            // Reset local unit state if navigating from a unit back to the base product
            if (navFromUnit) {
                _this.setState({ unit: null }, () => {
                    _this.init(match.params.productId);
                });
            } else {
                _this.init(match.params.productId);
            }
        }
    }

    // Handle init
    static handleInit(_this) {
        if (
            _this.props.match.params.productId &&
            _this.props.match.params.unitId
        ) {
            _this.init(
                _this.props.match.params.productId,
                _this.props.match.params.unitId
            );
        } else if (_this.props.match.params.productId) {
            _this.init(_this.props.match.params.productId);
        } else {
            _this.init();
        }
    }

    // Gets saved item views
    // @consideration, use similar approach for images without placements?
    static getItemViews(placements, isUnit) {
        if (!placements) return null;

        if (
            placements.images &&
            placements.images.length &&
            placements.images.some(image => image.placements.length)
        ) {
            return placements.images;
        }

        return null;
    }

    /**
     * Gets all the placements for a specific view / image typeName
     * @param {String} typeName
     * @param {Array} itemViews
     */
    static getSavedPlacements(_this, itemViews) {
        if (!itemViews) return [];

        // Update API placements GET response to fit the API placements
        // POST data structure (API should be consistent...),
        // and the required front end structure
        let placements = itemViews
            .map(view => {
                return view.placements.map(placement => {
                    const image = view.productImage;

                    // Resize image proportionally from natural width/height to constrain to the editor's width
                    const imageResizedSize = ImageHelper.resizeToFitWidth(
                        image.width,
                        image.height,
                        _this.props.maxWidth
                    );

                    // Transform coordinates from natural scale to fit resized image
                    let scaleRatio = this.getScaleRatio(
                        image.width,
                        imageResizedSize.width
                    );
                    let placementWidth = this.naturalToResized(
                        placement.width,
                        scaleRatio
                    );
                    let placementHeight = this.naturalToResized(
                        placement.height,
                        scaleRatio
                    );
                    let boundingCoords = placement.boundingCoords;
                    let coords = placement.coords;

                    if (!boundingCoords) {
                        boundingCoords = {
                            x: null,
                            y: null,
                        };
                    }

                    // Transform coords to resized
                    if (
                        coords &&
                        typeof coords.x !== "undefined" &&
                        typeof coords.y !== "undefined"
                    ) {
                        coords = {
                            x: this.naturalToResized(coords.x, scaleRatio),
                            y: this.naturalToResized(coords.y, scaleRatio),
                        };
                    } else if (
                        typeof placement.x !== "undefined" &&
                        typeof placement.y !== "undefined"
                    ) {
                        coords = {
                            x: this.naturalToResized(placement.x, scaleRatio),
                            y: this.naturalToResized(placement.y, scaleRatio),
                        };
                    } else {
                        coords = {
                            x: null,
                            y: null,
                        };
                    }

                    placement = {
                        ...placement,
                        id: image.id + placement.placementType.id,
                        width: placementWidth,
                        height: placementHeight,
                        boundingCoords,
                        coords,
                        ...coords,
                        imageId: image.id,
                        placementTypeId: placement.placementType.id,
                        imageType: {
                            ...image,
                            name: PlacerHelper.getTypeName(image.typeName),
                            naturalWidth: image.width,
                            naturalHeight: image.height,
                            width: imageResizedSize.width,
                            height: imageResizedSize.height,
                        },
                    };

                    if (placement.logo && placement.logo.url) {
                        placement.logo = {
                            ...placement.logo,
                            width: placementWidth,
                            height: placementHeight,
                            x: placement.x,
                            y: placement.y,
                            rotation: placement.rotation,
                        };
                    }

                    return placement;
                });
            })
            .reduce((acc, val) => acc.concat(val));

        return placements;
    }

    /**
     * Ensures all current item images are loaded before creating logo placeholders
     * Helps with setting up everything with the correct z-index
     * @param {Array} items array of objects with {url: product[typeName], type: typeName}
     */
    static itemImageHandler(_this) {
        _this.setState({ imageLoading: true }, () => {
            Promise.all(
                _this.state.itemImageTypes.map(image =>
                    this.itemImageLoad(_this, image)
                )
            ).then(() => {
                _this.setState({ imageLoading: false }, () => {
                    // Move elements in current view to the front
                    this.bringElementsInViewToFront(
                        _this.canvas,
                        _this.canvas.getObjects(),
                        _this.state.typeName,
                        _this.state.imageId
                    );
                });
            });
        });
    }

    /**
     * Loads the product images / types / view
     * the placerTypeName is used to retrieve and toggle the images in imageTypeToggle(image)
     * @param {Object} image
     */
    static itemImageLoad(_this, image) {
        return new Promise(resolve => {
            _this.fabric.Image.fromURL(
                image.url,
                oImg => {
                    oImg.set({
                        scaleX: image.width / oImg.width,
                        scaleY: image.height / oImg.height,
                        selectable: false,
                    });

                    _this.canvas.add(oImg);

                    // Get index of image in item.images or equivalent
                    if (_this.state.itemImageTypes.indexOf(image) !== 0) {
                        _this.canvas.sendToBack(oImg);
                    }

                    _this.canvas.renderAll();

                    return resolve(oImg);
                },
                {
                    placerType: "image",
                    placerTypeName: image.typeName,
                    imageId: image.id,
                    uid: image.id,
                }
            );
        });
    }

    /**
     * Gets all of the image "views" or types associated with the item
     * The views are sorted before being returned (1. front, 2. back ...rest)
     * @param {Object} props
     * @param {Object} product
     * @param {Object} item product or unit
     * @param {Boolean} isUnit
     */
    static getItemImageTypes(props, product, item, isUnit) {
        let itemImages = product.images;

        if (isUnit && item && item.color) {
            itemImages = product.imagesSortedInColors.find(
                image => image.color && image.color.id === item.color.id
            );

            if (itemImages && itemImages.images && itemImages.images.length) {
                itemImages = itemImages.images;
            } else {
                // @consideration, handle empty arrays / not found images
                itemImages = item.images;
            }
        }

        const itemImageTypes = itemImages.map(image => {
            if (!image.url) {
                throw Error("Missing image url", image);
            }

            // Resize image proportionally from natural width/height to constrain to the editor's width
            const imageResizedSize = ImageHelper.resizeToFitWidth(
                image.width,
                image.height,
                props.maxWidth
            );

            return {
                id: image.id,
                type: image.type,
                typeName: image.typeName,
                name: PlacerHelper.getTypeName(image.typeName),
                url: image.url,
                imgIxUrl: image.imgIxUrl,
                isDark: image.isDark,
                naturalWidth: image.width,
                naturalHeight: image.height,
                width: imageResizedSize.width,
                height: imageResizedSize.height,
            };
        });

        return PlacerHelper.sortImageTypes(itemImageTypes);
    }

    /**
     * Gets the name for an image type (Front, Back, etc.)
     * @param {String} name
     */
    static getTypeName(name) {
        const foundType = ImageHelper.getImageTypes(name);
        if (foundType) {
            return foundType.name;
        } else {
            return name;
        }
    }

    /**
     * Returns array of sorted imageTypes based on the typeName (1. front, 2. back, ...rest)
     * @param {Array} units Array of imageTypes with typeName
     */
    static sortImageTypes(imageTypes) {
        const imageTypesOrder = [
            "FRONT",
            "BACK",
            "SIDELEFT",
            "SIDERIGHT",
            "OTHER",
        ];

        return imageTypes.sort(
            (a, b) =>
                imageTypesOrder.indexOf(a.typeName.toUpperCase()) -
                imageTypesOrder.indexOf(b.typeName.toUpperCase())
        );
    }

    /**
     * Checks if the navigation should be disabled
     * @param {String} direction next|previous
     * @return {Boolean}
     */
    static navIsDisabled(props, direction) {
        const { state } = props;
        let disabled = false;

        // disable prev btn
        if (direction === "previous" && state.productIndex === 0) {
            disabled = true;
        }

        // disable next btn
        if (
            direction === "next" &&
            state.productIndex === state.products.length - 1
        ) {
            disabled = true;
        }

        return disabled;
    }

    /**
     * Navigates the products
     * @param {String} direction next|previous
     * @return {String} productId
     */
    static getNavigationItemId(props, direction) {
        const { state } = props;

        let nextIndex;

        if (direction === "next") {
            nextIndex = state.productIndex + 1;
        } else {
            nextIndex = state.productIndex - 1;
        }

        // @consideration: Save before navigating if any changes
        // Load the next product
        if (
            nextIndex !== -1 &&
            nextIndex < state.products.length &&
            state.products[nextIndex] &&
            state.products[nextIndex].id
        ) {
            return state.products[nextIndex].id;
        } else {
            return state.product.id;
        }
    }

    /**
     * Render image type buttons for switching the active typeName
     */
    static renderTypeRadioButtons(props) {
        const { parentThisRef, state } = props;
        // if (!state.unit) {}
        // TODO Render unit color name in text if product, must either get color info with itemImageTypes or find it in itemViews

        if (state.itemImageTypes) {
            return state.itemImageTypes.map((image, i) => {
                return (
                    <ButtonRadio
                        key={`radio-${i}`}
                        priority="secondary"
                        active={
                            state.typeName === image.typeName &&
                            state.imageId === image.id
                                ? true
                                : false
                        }
                        text={image.name}
                        type={image.typeName}
                        onClick={() =>
                            PlacerHelper.imageTypeToggle(parentThisRef, image)
                        }
                    />
                );
            });
        }

        return null;
    }

    /**
     * Toggles the item type / view between Front and Back
     * @param {Object} image imageType
     */
    static imageTypeToggle(_this, image) {
        let objects = _this.canvas.getObjects();

        // First bring the relevant item image to the top
        this.bringBackgroundInViewToFront(
            _this.canvas,
            objects,
            image.typeName,
            image.id
        );

        // Then bring all the relevant placements for that type to the top
        this.bringElementsInViewToFront(
            _this.canvas,
            objects,
            image.typeName,
            image.id
        );

        // Discard the active object in case user has selected a placement
        _this.canvas.discardActiveObject();
        _this.canvas.renderAll();

        // Set state
        _this.setState(
            {
                typeName: image.typeName,
                imageId: image.id,
                width: image.width,
                height: image.height,
            },
            () => {
                // Scale canvas and current background (in case the images have different sizes)
                _this.editor.current.setCanvasSize(
                    _this.state.width,
                    _this.state.height
                );
            }
        );
    }

    /**
     * Toggles the overlays (markers and buttons) over the item images on and off
     */
    static overlaysToggle(_this) {
        _this.setState({
            showOverlays: !_this.state.showOverlays,
        });
    }

    /**
     * Bring the relevant canvas item image to the top
     * @param {Array} objects Canvas elements
     * @param {String} typeName Image typeName
     */
    static bringBackgroundInViewToFront(canvas, objects, typeName, imageId) {
        objects.forEach(object => {
            if (
                object.placerType === "image" &&
                object.placerTypeName === typeName &&
                object.imageId === imageId
            ) {
                canvas.bringToFront(object);
                canvas.renderAll();
            }
        });
    }

    /**
     * Brings all visible elements (not images) in the desired (or current) typeName / view to the Front
     * @param {Array} objects Canvas elements
     * @param {String} typeName Image typeName
     */
    static bringElementsInViewToFront(canvas, objects, typeName, imageId) {
        objects.forEach(object => {
            if (
                object.placerType !== "image" &&
                object.placerTypeName === typeName &&
                object.imageId === imageId
            ) {
                canvas.bringToFront(object);
                canvas.renderAll();
            }
        });
    }

    /**
     * Check if product has images, or if unit exists and the product has images sorted in colors
     */
    static itemHasImages() {
        const { unit, product } = this.state;
        return (
            (!unit && product && product.images && product.images.length) ||
            (unit &&
                unit.color &&
                product &&
                product.imagesSortedInColors &&
                product.imagesSortedInColors.length &&
                product.imagesSortedInColors.find(
                    image => image.color && image.color.id === unit.color.id
                ))
        );
    }

    /**
     * Check if item (product or unit) has any placements
     */
    static itemHasPlacements() {
        return this.state.placements && this.state.placements.length; //&& this.state.placements.images;

        /*
        If we need to find previously saved placements only
            this.state.placements.images.length &&
            this.state.placements.images.some(image => image.placements.length)
        */
    }

    /**
     * Get the scale ratio which determines how much a value has been scaled
     * @param {Number} naturalProp
     * @param {Number} resizedProp
     */
    static getScaleRatio(naturalProp, resizedProp) {
        return naturalProp / resizedProp;
    }

    /**
     * Transform a resized value to what it would be in natural size
     * @param {Number} resizedProp
     * @param {Number} scaleRatio
     */
    static resizedToNatural(resizedProp, scaleRatio) {
        return Math.ceil(resizedProp * scaleRatio);
    }

    /**
     * Transform a natural value to what it will be when it it resized
     * @param {Number} naturalProp
     * @param {Number} scaleRatio
     */
    static naturalToResized(naturalProp, scaleRatio) {
        return naturalProp / scaleRatio;
    }
}
