import Axios from "axios";
import { config } from "../Helpers/Config";
import { authHeader } from "./AuthenticationActions";

export function getGroupLogosFetching(payload) {
    return {
        type: "GET_GROUP_LOGOS_FETCHING",
        payload,
    };
}

export function getGroupLogos(groupId) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getGroupLogosFetching(true));

        instance
            .get(`${config.apiUrl}/groups/${groupId}/logos`)
            .then(response => {
                dispatch({
                    type: "GET_GROUP_LOGOS",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "GET_GROUP_LOGOS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function uploadGroupLogoPosting(payload) {
    return {
        type: "UPLOAD_GROUP_LOGOS_POSTING",
        payload,
    };
}

export function uploadGroupLogos(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    // Prepare a request for each image
    const requests = payload.images.map(image => {
        let bodyFormData = new FormData();

        // PlacementType is optionsl
        // bodyFormData.set("PlacementTypeIds", payload.placementTypeIds);

        bodyFormData.set("DarknessType", payload.darknessType);
        bodyFormData.set("TextileType", payload.textileType);
        bodyFormData.append("Image", image);

        return instance.post(
            `${config.apiUrl}/groups/${payload.groupId}/logos`,
            bodyFormData
        );
    });

    return dispatch => {
        // Set fetching true
        dispatch(uploadGroupLogoPosting(true));

        Axios.all(requests)
            .then(response => {
                dispatch({
                    type: "UPLOAD_GROUP_LOGOS",
                    payload: response.map(response => response.data),
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "UPLOAD_GROUP_LOGOS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function updateGroupLogoPosting(payload) {
    return {
        type: "UPDATE_GROUP_LOGO_POSTING",
        payload,
    };
}

export function updateGroupLogo(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(updateGroupLogoPosting(true));

        instance
            .post(
                `${config.apiUrl}/groups/${payload.groupId}/logos/${
                    payload.logoId
                }`,
                {
                    placementTypeIds: payload.placementTypeIds,
                    darknessType: payload.darknessType,
                    textileType: payload.textileType,
                }
            )
            .then(response => {
                dispatch({
                    type: "UPDATE_GROUP_LOGO",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log("Update group logo error", error);

                dispatch({
                    type: "UPDATE_GROUP_LOGO_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function deleteGroupLogoDeleting(payload) {
    return {
        type: "DELETE_GROUP_LOGO_DELETING",
        payload,
    };
}

export function deleteGroupLogo(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(deleteGroupLogoDeleting(true));

        instance
            .delete(
                `${config.apiUrl}/groups/${payload.groupId}/logos/${
                    payload.logoId
                }`
            )
            .then(response => {
                dispatch({
                    type: "DELETE_GROUP_LOGO",
                    payload: response.data,
                    logoId: payload.logoId,
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "DELETE_GROUP_LOGO_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function getPlacementTypesFetching(payload) {
    return {
        type: "GET_PLACEMENT_TYPES_FETCHING",
        payload,
    };
}

export function getPlacementTypes() {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getPlacementTypesFetching(true));

        instance
            .get(`${config.apiUrl}/products/placement-types`)
            .then(response => {
                dispatch({
                    type: "GET_PLACEMENT_TYPES",
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: "GET_PLACEMENT_TYPES_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function getPlacementsFetching(payload) {
    return {
        type: "GET_PLACEMENTS_FETCHING",
        payload,
    };
}

export function getPlacements(productId) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getPlacementsFetching(true));

        instance
            .get(`${config.apiUrl}/products/${productId}/placements`)
            .then(response => {
                dispatch({
                    type: "GET_PLACEMENTS",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "GET_PLACEMENTS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function setPlacementsPosting(payload) {
    return {
        type: "SET_PLACEMENTS_POSTING",
        payload,
    };
}

export function setPlacements(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(setPlacementsPosting(true));

        instance
            .post(
                `${config.apiUrl}/products/${payload.productId}/placements/${
                    payload.productImageId
                }`,
                payload.placements
            )
            .then(response => {
                dispatch({
                    type: "SET_PLACEMENTS",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "SET_PLACEMENTS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function setUnitPlacements(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    // Prepare a request for each unit
    const requests = payload.units.map(data => {
        return instance.post(
            `${config.apiUrl}/products/${payload.productId}/placements/${
                data.productImageId
            }`,
            data.placements
        );
    });

    return dispatch => {
        // Set fetching true
        dispatch(setPlacementsPosting(true));

        Axios.all(requests)
            .then(response => {
                dispatch({
                    type: "SET_PLACEMENTS",
                    payload: response.map(response => response.data),
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "SET_PLACEMENTS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function getLogoPlacementsFetching(payload) {
    return {
        type: "GET_LOGO_PLACEMENTS_FETCHING",
        payload,
    };
}

export function getLogoPlacements(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getLogoPlacementsFetching(true));

        instance
            .get(
                `${config.apiUrl}/groups/${payload.groupId}/logos/placements/${
                    payload.productImageId
                }`
            )
            .then(response => {
                dispatch({
                    type: "GET_LOGO_PLACEMENTS",
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: "GET_LOGO_PLACEMENTS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function getAllLogoPlacements(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    // Prepare multiple requests for all images
    const requests = payload.itemImageTypes.map(image => {
        return instance.get(
            `${config.apiUrl}/groups/${payload.groupId}/logos/placements/${
                image.id
            }`
        );
    });

    return dispatch => {
        // Set fetching true
        dispatch(getLogoPlacementsFetching(true));

        Axios.all(requests)
            .then(response => {
                dispatch({
                    type: "GET_LOGO_PLACEMENTS",
                    payload: response.map(response => response.data),
                });
            })
            .catch(error => {
                dispatch({
                    type: "GET_LOGO_PLACEMENTS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function setLogoPlacementsPosting(payload) {
    return {
        type: "SET_LOGO_PLACEMENTS_POSTING",
        payload,
    };
}

export function setLogoPlacements(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(setLogoPlacementsPosting(true));

        instance
            .post(
                `${config.apiUrl}/groups/${payload.groupId}/logos/placements/${
                    payload.productImageId
                }`,
                payload.logoPlacements
            )
            .then(response => {
                dispatch({
                    type: "SET_LOGO_PLACEMENTS",
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: "SET_LOGO_PLACEMENTS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}
