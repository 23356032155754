import React, { Component } from "react";
import Slider from "react-slick";
import SlideIntro from "./SlideIntro";
import SlideContent from "./SlideContent";
import SlideEnd from "./SlideEnd";

class Onboarding extends Component {
    constructor(props) {
        super(props);

        this.slideNext = this.slideNext.bind(this);
        this.slidePrevious = this.slidePrevious.bind(this);
    }

    getSlideType(slide, index, array) {
        let slideType;

        switch (index) {
            case 0:
                slideType = (
                    <SlideIntro
                        slideNext={this.slideNext}
                        {...slide}
                        {...this.props}
                    />
                );
                break;
            case array.length - 1:
                slideType = (
                    <SlideEnd
                        slidePrevious={this.slidePrevious}
                        {...slide}
                        {...this.props}
                    />
                );
                break;
            default:
                slideType = (
                    <SlideContent
                        slideNext={this.slideNext}
                        slidePrevious={this.slidePrevious}
                        {...slide}
                        {...this.props}
                    />
                );
        }

        return slideType;
    }

    slideNext() {
        this.slider.slickNext();
    }

    slidePrevious() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows: false,
            dots: true,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        return (
            <div className="onboarding slick--dark">
                <Slider ref={slider => (this.slider = slider)} {...settings}>
                    {this.props.messages.map((message, i, array) => (
                        <div key={`onboarding-slide-${i}`}>
                            {this.getSlideType(message, i, array)}
                        </div>
                    ))}
                </Slider>
            </div>
        );
    }
}

export default Onboarding;
