import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import WebFont from "webfontloader";
import { ThemeProvider } from "styled-components";

// Actions
import { getGroup } from "../Actions/GroupActions";

// Components
import PageError from "./PageError";

// Helpers
import { Theme, GlobalStyle } from "../Helpers";

class DataLayerGroup extends Component {
    componentDidMount() {
        // Check if we got the right group in store or not, if not then get it
        // Do a background fetch of new group data each time we mount if we already have some group data in state
        if (
            !this.props.group.groupBackgroundFetching &&
            (!this.props.group.groupFetching ||
                !this.props.group.group.id ||
                this.props.group.group.id !== this.props.match.params.id)
        ) {
            if (
                this.props.group &&
                this.props.group.group &&
                this.props.group.group.id &&
                this.props.group.group.id === this.props.match.params.id
            ) {
                // Possibly refresh group data
                this.props.getGroup(
                    this.props.match.params.id,
                    this.props.group.group
                );
            } else {
                // Get new group data
                this.props.getGroup(this.props.match.params.id);
            }
        }
    }

    // Load Shop theme fonts
    componentDidUpdate(prevProps) {
        if (
            this.props.group.groupFetching === false &&
            this.props.group.group &&
            this.props.group.group !== prevProps.group.group
        ) {
            const shopTheme = Theme.getTheme(this.props.group.group.shop.name);

            if (shopTheme.fontConfig) {
                WebFont.load(shopTheme.fontConfig);
            }
        }
    }

    render() {
        // Could not find group ID or maybe API is dead
        if (this.props.group.groupError) {
            return <PageError {...this.props} />;
        }

        // App is currently trying to fetch data, please serve appshell
        if (this.props.group.groupFetching === true) {
            return null; //<Appshell/>
        }

        // App has successfully finished fetching
        if (this.props.group.groupFetching === false) {
            const themeName = this.props.group.group.shop.name;

            return (
                <ThemeProvider theme={Theme.getTheme(themeName)}>
                    <React.Fragment>
                        <GlobalStyle />
                        {this.props.children}
                    </React.Fragment>
                </ThemeProvider>
            );
        }

        // None of the above
        return null;
    }
}

DataLayerGroup.propTypes = {
    getGroup: PropTypes.func.isRequired,
};

DataLayerGroup.defaultProps = {
    getGroup: () => null,
};

function mapStateToProps(state) {
    return {
        group: state.group,
        user: state.user,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        getGroup,
    }
)(DataLayerGroup);
