import Axios from "axios";
import { config } from "../Helpers/Config";
import { getUserFetching } from "./UserActions";

export function loginRequest(payload) {
    return {
        type: "LOGIN_REQUEST",
        payload,
    };
}

export function login() {
    let instance = Axios.create({
        timeout: 30000,
    });

    // Community jwt
    let windowCommunityJwt =
        process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_JWT
            : window.JWT_TOKEN;
    let communityJwt = sessionStorage.jwtCommunity;

    return dispatch => {
        // Set fetching true
        dispatch(loginRequest(true));

        // Check for existing groupshop jwt
        let groupshopJwt = sessionStorage.jwtGroupshop;

        // Already logged in, just get the user data
        if (
            windowCommunityJwt === communityJwt &&
            groupshopJwt &&
            groupshopJwt.length
        ) {
            dispatch({
                type: "LOGIN_SUCCESS",
            });

            instance = Axios.create({
                timeout: 30000,
                headers: authHeader(),
            });

            dispatch(getUserFetching(true));

            Axios.all([
                instance.get(`${config.apiUrl}/customers/current-user`),
                instance.get(`${config.apiUrl}/groups/`),
            ])
                .then(response => {
                    dispatch({
                        type: "GET_USER",
                        payload: response.map(response => response.data),
                    });
                })
                .catch(error => {
                    console.log(error);
                    dispatch({
                        type: "GET_USER_ERROR",
                        payload: true,
                    });

                    // Dispatch generic error to the errorHandler
                    dispatch({ type: "ADD_ERROR", error: error });

                    // auto logout if 401 response returned from api
                    if (
                        error.response &&
                        error.response.status &&
                        (error.response.status === 400 ||
                            error.response.status === 401)
                    ) {
                        logout();
                    }
                });
        }

        // Do full login
        else {
            instance
                .post(`${config.apiUrl}/authentication`, {
                    token: windowCommunityJwt,
                })
                .then(response => {
                    if (response.data.token) {
                        sessionStorage.setItem(
                            "jwtCommunity",
                            windowCommunityJwt
                        );
                        sessionStorage.setItem(
                            "jwtGroupshop",
                            response.data.token
                        );
                    }

                    dispatch({
                        type: "LOGIN_SUCCESS",
                    });

                    instance = Axios.create({
                        timeout: 30000,
                        headers: authHeader(),
                    });

                    dispatch(getUserFetching(true));

                    Axios.all([
                        instance.get(`${config.apiUrl}/customers/current-user`),
                        instance.get(`${config.apiUrl}/groups/`),
                    ])
                        .then(response => {
                            dispatch({
                                type: "GET_USER",
                                payload: response.map(
                                    response => response.data
                                ),
                            });
                        })
                        .catch(error => {
                            console.log(error);
                            dispatch({
                                type: "GET_USER_ERROR",
                                payload: true,
                            });

                            // Dispatch generic error to the errorHandler
                            dispatch({ type: "ADD_ERROR", error: error });
                        });
                })
                .catch(error => {
                    console.log("Auth error", error);

                    dispatch({
                        type: "LOGIN_ERROR",
                        payload: true,
                    });

                    // Dispatch generic error to the errorHandler
                    dispatch({ type: "ADD_ERROR", error: error });

                    // auto logout if 401 response returned from api
                    if (
                        error.response &&
                        error.response.status &&
                        (error.response.status === 400 ||
                            error.response.status === 401)
                    ) {
                        logout();
                    }
                });
        }
    };
}

export function getUserData() {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        dispatch(getUserFetching(true));

        Axios.all([
            instance.get(`${config.apiUrl}/customers/current-user`),
            instance.get(`${config.apiUrl}/groups/`),
        ])
            .then(response => {
                dispatch({
                    type: "GET_USER",
                    payload: response.map(response => response.data),
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "GET_USER_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem("jwtGroupshop");
    sessionStorage.removeItem("jwtCommunity");

    window.location = config.communityLoginUrl;
}

// return authorization header with jwt token
export function authHeader() {
    let jwt = sessionStorage.jwtGroupshop;

    if (jwt && jwt.length) {
        return { Authorization: "Bearer " + jwt };
    } else {
        return {};
    }
}
