import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from 'prop-types';
import { Collapse, Button } from "reactstrap";

// Actions
import { getAdditions } from "../../Actions/AdditionsActions";

// Components
import Cart from "../Cart/Cart";
import Requirements from "./Requirements";
import CustomerCarts from "./CustomerCarts";

// Helpers
import { GroupHelper, CartHelper, CustomerHelper } from "../../Helpers";

class Carts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.toggleCarts = this.toggleCarts.bind(this);
    }

    componentDidMount() {
        if (this.props.order) {
            this.props.getAdditions({
                groupId: this.props.match.params.id,
                orderId: this.props.order.id,
            });
        }
    }

    toggleCarts() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    getSubtotal(items) {
        if (items) {
            return items.reduce(
                (total, item) =>
                    total + (item.qty ? item.price * item.qty : item.price),
                0
            );
        } else {
            return 0;
        }
    }

    getCustomersAcceptedCarts() {
        if (this.props.cart && this.props.group) {
            const customers = CustomerHelper.getCustomers(
                this.props,
                "customers"
            );

            if (customers && customers.length) {
                return customers
                    .map(customer => {
                        if (
                            CartHelper.hasCustomerAcceptedCart(
                                this.props.cart,
                                customer.customer
                            )
                        ) {
                            return 1;
                        }
                        return 0;
                    })
                    .reduce((a, b) => a + b);
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }

    getCustomersTotalCarts() {
        if (this.props.group.groupFetching === false) {
            const customers = CustomerHelper.getCustomers(
                this.props,
                "customers"
            );

            return customers.length;
        } else {
            return 0;
        }
    }

    render() {
        if (
            this.props.additions.addedAdditionsFetching === false &&
            this.props.cart
        ) {
            const { cart, additions } = this.props;
            const showPrices = GroupHelper.getNormalizedSetting(
                this.props,
                "CanDisplayPrices"
            );
            const isSeller =
                this.props.user.user.role === "Seller" ||
                this.props.user.user.role === "Admin";

            return (
                <>
                    {!this.props.isArchive ? (
                        <Requirements {...this.props} />
                    ) : null}

                    <Cart {...this.props}>
                        <div className="col-6 my-2 d-print-none">
                            <strong>Handlekurver</strong>
                        </div>

                        <div className="col-6 my-2 text-right d-print-none">
                            <Button
                                onClick={this.toggleCarts}
                                color="primary"
                                size="sm"
                                outline>
                                {this.state.isOpen ? (
                                    <span>
                                        Skjul{" "}
                                        <span className="d-none d-lg-inline">
                                            alle
                                        </span>{" "}
                                        handlekurver
                                    </span>
                                ) : (
                                    <span>
                                        Vis{" "}
                                        <span className="d-none d-lg-inline">
                                            alle
                                        </span>{" "}
                                        handlekurver
                                    </span>
                                )}
                            </Button>
                        </div>

                        <div className="col-12 pb-2 d-print-none">
                            <Collapse isOpen={this.state.isOpen}>
                                <CustomerCarts
                                    {...this.props}
                                    showPrices={showPrices}
                                    isSeller={isSeller}
                                />
                            </Collapse>
                        </div>

                        <div className="col-6 mb-2 py-2 border-top border-bottom">
                            <strong className="d-block">
                                Godkjente handlekurver
                            </strong>
                            <small className="d-block">
                                Inkluderer ikke ordreansvarlig
                            </small>
                        </div>

                        <div className="col-6 mb-2 py-2 text-right border-top border-bottom">
                            {this.getCustomersAcceptedCarts()}/
                            {this.getCustomersTotalCarts()}
                        </div>

                        <div className="col-12 mb-2">
                            <strong>Alle produkter</strong>
                        </div>

                        <Cart.List
                            list={cart.items}
                            showPrices={showPrices}
                            isSeller={isSeller}
                            type="product"
                        />

                        {isSeller || showPrices ? (
                            <>
                                <div className="col-6 mb-2 font-weight-bold">
                                    Subtotal handlekurver:
                                </div>
                                <div className="col-6 mb-2 font-weight-bold text-right">
                                    {cart.sumItems},-
                                </div>
                            </>
                        ) : null}
                    </Cart>

                    {additions.addedAdditions &&
                    additions.addedAdditions.length ? (
                        <Cart {...this.props}>
                            <div className="col-12 mb-2">
                                <strong>Tillegg</strong>
                            </div>

                            <Cart.List
                                list={additions.addedAdditions}
                                showPrices={showPrices}
                                isSeller={isSeller}
                                type="product"
                            />

                            {isSeller || showPrices ? (
                                <>
                                    <div className="col-6 mb-2 font-weight-bold">
                                        Subtotal tillegg:
                                    </div>
                                    <div className="col-6 mb-2 font-weight-bold text-right">
                                        {cart.sumAdditions},-
                                    </div>
                                </>
                            ) : null}
                        </Cart>
                    ) : null}

                    {cart.sumDiscounts ? (
                        <Cart {...this.props}>
                            <div className="col-12 mb-2">
                                <strong>Rabatter</strong>
                            </div>

                            <Cart.List
                                list={cart.discounts}
                                showPrices={showPrices}
                                isSeller={isSeller}
                                type="product"
                            />

                            {isSeller || showPrices ? (
                                <>
                                    <div className="col-6 mb-2 font-weight-bold">
                                        Subtotal rabatter:
                                    </div>
                                    <div className="col-6 mb-2 font-weight-bold text-right">
                                        {cart.sumDiscounts},-
                                    </div>
                                </>
                            ) : null}
                        </Cart>
                    ) : null}

                    {isSeller || showPrices ? (
                        <div className="border-bottom">
                            <Cart
                                className="row no-gutters mb-1 border-bottom"
                                {...this.props}>
                                <div className="col-6 mb-2 text-black-50">
                                    <strong>MVA</strong>
                                </div>
                                <div className="col-6 mb-2 text-black-50 text-right">
                                    <strong>{cart.vat},-</strong>
                                </div>

                                <div className="col-6 mb-2 font-weight-bold lead">
                                    Totalt
                                </div>
                                <div className="col-6 mb-2 font-weight-bold lead text-right">
                                    {cart.total},-
                                </div>
                            </Cart>
                        </div>
                    ) : null}
                </>
            );
        }

        return null;
    }
}

Carts.propTypes = {};
Carts.defaultProps = {};

function mapStateToProps(state) {
    return {
        additions: state.additions,
    };
}
export default connect(
    mapStateToProps,
    {
        getAdditions,
    }
)(Carts);
