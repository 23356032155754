import Axios from "axios";
import { config } from "../Helpers/Config";
import { authHeader } from "./AuthenticationActions";

export function resetSearch() {
    return dispatch => {
        dispatch({
            type: "RESET_SEARCH",
        });
    };
}

export function getSearchPosting(payload) {
    return {
        type: "GET_SEARCH_POSTING",
        payload,
    };
}

export function getCustomerByEmail(email) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getSearchPosting(true));

        instance
            .post(`${config.apiUrl}/customers/search/email`, null, {
                params: {
                    email,
                },
            })
            .then(response => {
                let data = response.data;
                if (!data.length) {
                    data = [data];
                }

                dispatch({
                    type: "GET_SEARCH",
                    payload: data,
                });
            })
            .catch(error => {
                dispatch({
                    type: "GET_SEARCH_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function getCustomerGroupsFetching(payload) {
    return {
        type: "GET_CUSTOMER_GROUPS_POSTING",
        payload,
    };
}

export function getCustomerGroups(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getCustomerGroupsFetching(true));

        instance
            .get(
                `${config.apiUrl}/customers/community/${
                    payload.communityCustomerId
                }/groups`
            )
            .then(response => {
                console.log("Get customer groups", response);
                dispatch({
                    type: "GET_CUSTOMER_GROUPS",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log("Get customer groups error", error, error.message);

                dispatch({
                    type: "GET_CUSTOMER_GROUPS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function getCustomerAndGroup(email) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getSearchPosting(true));

        instance
            .post(`${config.apiUrl}/customers/search/email`, null, {
                params: {
                    email,
                },
            })
            .then(response => {
                let data = response.data;
                if (!data.length) {
                    data = [data];
                }

                dispatch({
                    type: "GET_SEARCH",
                    payload: data,
                });

                data.forEach(customerResult => {
                    instance = Axios.create({
                        timeout: 30000,
                        headers: authHeader(),
                    });

                    dispatch(getCustomerGroupsFetching(true));

                    instance
                        .post(
                            `${config.apiUrl}/customers/community/${
                                customerResult.userId
                            }/groups`
                        )
                        .then(response => {
                            dispatch({
                                type: "GET_CUSTOMER_GROUPS",
                                payload: response.data,
                            });
                        })
                        .catch(error => {
                            dispatch({
                                type: "GET_CUSTOMER_GROUPS_ERROR",
                                payload: true,
                            });

                            // Dispatch generic error to the errorHandler
                            dispatch({ type: "ADD_ERROR", error: error });
                        });
                });
            })
            .catch(error => {
                if (
                    error.response &&
                    error.response.status &&
                    error.response.status === 404
                ) {
                    dispatch({
                        type: "GET_SEARCH_ERROR",
                        payload: { error, email },
                    });
                } else {
                    dispatch({
                        type: "GET_SEARCH_ERROR",
                        payload: true,
                    });

                    // Dispatch generic error to the errorHandler
                    dispatch({ type: "ADD_ERROR", error: error });
                }
            });
    };
}
