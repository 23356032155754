import Axios from "axios";
import { authHeader } from "././AuthenticationActions";
import { config } from "../Helpers/Config";

export function getAvailableAdditionsFetching(payload) {
    return {
        type: "GET_AVAILABLE_ADDITIONS_FETCHING",
        payload,
    };
}

export function getAvailableAdditions() {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getAvailableAdditionsFetching(true));

        instance
            .get(`${config.apiUrl}/products/additions`)
            .then(response => {
                dispatch({
                    type: "GET_AVAILABLE_ADDITIONS",
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: "GET_AVAILABLE_ADDITIONS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function getAdditionsFetching(payload) {
    return {
        type: "GET_ADDITIONS_FETCHING",
        payload,
    };
}

export function getAdditions(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getAdditionsFetching(true));

        instance
            .get(
                `${config.apiUrl}/groups/${payload.groupId}/orders/${
                    payload.orderId
                }/additions/`
            )
            .then(response => {
                dispatch({
                    type: "GET_ADDITIONS",
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: "GET_ADDITIONS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function addAdditionPosting(payload) {
    return {
        type: "ADD_ADDITION_POSTING",
        payload,
    };
}

export function addAddition(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(addAdditionPosting(true));

        instance
            .post(`${config.apiUrl}/groups/${payload.groupId}/cart/additions`, {
                additionId: payload.id,
                price: payload.price,
            })
            .then(response => {
                dispatch({
                    type: "ADD_ADDITION",
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: "ADD_ADDITION_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function removeAdditionDeleting(payload) {
    return {
        type: "DELETE_ADDITION_DELETING",
        payload,
    };
}

export function removeAddition(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(removeAdditionDeleting(true));

        const orderAdditionId = payload.orderAdditionId;

        instance
            .delete(
                `${config.apiUrl}/groups/${payload.groupId}/cart/additions`,
                {
                    params: {
                        orderAdditionId,
                    },
                }
            )
            .then(response => {
                dispatch({
                    type: "DELETE_ADDITION",
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: "DELETE_ADDITION_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}
