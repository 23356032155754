import React, { Component } from "react";
import { Draggable } from "react-beautiful-dnd";

// Components
import ProductSmall from "../Products/ProductSmall";

// Helpers
import { GroupHelper } from "../../Helpers";

export default class DraggableList extends Component {
    // Possible performance gain, do not rerender entire list when dragging items
    shouldComponentUpdate(nextProps) {
        if (
            this.props.list === nextProps.list &&
            this.props.showProductImages === nextProps.showProductImages
        ) {
            return false;
        }
        return true;
    }

    getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        width: "100%",

        // styles we need to apply on draggables
        ...draggableStyle,
    });

    getProductManage(groupProduct) {
        const { groupManageProducts } = this.props.products;

        return groupManageProducts.find(
            productManage =>
                productManage.productGroupId === groupProduct.productGroupId
        );
    }

    render() {
        return this.props.list.map((item, index) => (
            <Draggable
                isDragDisabled={GroupHelper.disableWhenLocked(this.props, [
                    "Seller",
                    "Manager",
                ])}
                key={`${item.productGroupId}-${index}`}
                draggableId={item.productGroupId}
                index={index}>
                {(provided, snapshot) => (
                    <div
                        className={`js-drag-item mb-2 ${
                            snapshot.isDragging ? "is-dragging" : ""
                        }`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )}>
                        <ProductSmall
                            {...this.props}
                            product={item}
                            showProductImages={this.props.showProductImages}
                            showPrice={this.props.showPrice}
                            hideProduct={() =>
                                this.props.changeProductVisibility(item, true)
                            }
                            showProduct={() =>
                                this.props.changeProductVisibility(item, false)
                            }
                        />
                    </div>
                )}
            </Draggable>
        ));
    }
}
