import React, { Component } from "react";
import { connect } from "react-redux";
//import PropTypes from "prop-types";
import { Button } from "reactstrap";

// Actions
import {
    addCartItem,
    editCartItem,
    replaceCartItem,
    deleteCartItem,
} from "../../Actions/GroupCartActions";

// Components
import Icon from "../Common/Icon";
import ProductLine from "./ProductLine";
import Restrict from "../../Auth/Restrict";

// Helpers
import { Manager } from "../../Auth/UserRoles";
import { GroupHelper, DateHelper, CartHelper } from "../../Helpers";

class ProductCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPosting: false,
            isFetching: false,
            localUnits: [],
        };

        this.updateSize = this.updateSize.bind(this);
        this.updateQty = this.updateQty.bind(this);
        this.updateNametag = this.updateNametag.bind(this);
        this.addProductLine = this.addProductLine.bind(this);
        this.removeProductLine = this.removeProductLine.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { cart, product } = this.props;
        // Reset the isPosting state used for showing that the current cart was posting an item
        if (
            cart &&
            cart.cart !== prevProps.cart.cart &&
            this.state.isPosting === true
        ) {
            this.setState({ isPosting: false });
        }

        // Update state after new product stock has come in
        if (product !== prevProps.product && this.state.isFetching === true) {
            this.setState({ isFetching: false });
        }
    }

    componentDidMount() {
        const customerCart = this.getCustomerUnitsInCart();
        if (!customerCart) {
            this.setState({
                localUnits: [this.createLocalUnit()],
            });
        }
    }

    // Creates a local placeholder unit that is used before user has selected a unit size
    createLocalUnit() {
        const { product, isCommunal } = this.props;
        let customerId = null;
        let qty = 1;

        if (this.props.customer && !this.props.self) {
            customerId = this.props.customer.customer.id;
        }

        // Communal items start at 0 qty because they have a qty input
        if (isCommunal) {
            qty = 0;
        }

        return {
            localUnit: true,
            id: null,
            unitId: null,
            productName: product.name,
            color: product.color,
            valueName: null,
            price: product.price,
            type: null,
            qty,
            isCommunalItem: null,
            textValue: null,
            customerId,
            createdAt: null,
            lockedAt: null,
        };
    }

    // Only adds a temporary local placeholder unit
    // Any local unit must have a selected size to be added to the cart
    addProductLine() {
        if (
            !this.state.localUnits ||
            !this.state.localUnits.length ||
            (this.state.localUnits.length && this.state.localUnits.length < 1)
        ) {
            this.setState({
                localUnits: [...this.state.localUnits, this.createLocalUnit()],
            });
        }
    }

    removeProductLine(unit) {
        const { product } = this.props;

        if (unit.localUnit) {
            const customerCart = this.getCustomerUnitsInCart();
            // Do not post a request, only remove from local state
            // But only remove the local unit if there are more than one, or there are real units in the same list
            if (
                this.state.localUnits.length > 1 ||
                (customerCart && customerCart.length)
            ) {
                let unitIndex = this.state.localUnits.indexOf(unit);

                this.setState({
                    localUnits: this.state.localUnits.filter(
                        (item, i) => i !== unitIndex
                    ),
                });
            }
        } else {
            // Post a request to remove the unit
            let customerId = null;

            if (!this.props.self) {
                customerId = unit.customerId;
            }

            this.props.deleteCartItem({
                productId: product.id,
                groupId: this.props.group.group.id,
                customerId,
                itemId: unit.id,
            });

            this.setState({ isPosting: true, isFetching: true });
        }
    }

    updateSize(payload, unit) {
        if (payload.id) {
            // Delete old unit, add new unit
            this.setState({ isPosting: true, isFetching: true });

            let customerId = null;

            if (!this.props.self) {
                customerId = payload.customerId;
            }

            this.props.replaceCartItem({
                productId: payload.productId,
                itemId: payload.id,
                groupId: payload.groupId,
                customerId,
                unitId: payload.unitId,
                textValue: payload.textValue,
                qty: payload.qty,
                isCommunalItem: payload.isCommunalItem,
            });
        } else {
            if (unit && unit.localUnit) {
                // Remove the old local unit before posting so we don't get "duplicate" units after posting
                const unitIndex = this.state.localUnits.indexOf(unit);

                this.setState(
                    {
                        isPosting: true,
                        isFetching: true,
                        localUnits: this.state.localUnits.filter(
                            (item, i) => i !== unitIndex
                        ),
                    },
                    () => {
                        this.props.addCartItem(payload);
                    }
                );
            } else {
                this.setState(
                    {
                        isPosting: true,
                        isFetching: true,
                    },
                    () => {
                        this.props.addCartItem(payload);
                    }
                );
            }
        }
    }

    updateQty(unit) {
        const { product } = this.props;
        if (unit.itemId) {
            // Update / Edit previously added item
            let customerId = null;

            if (!this.props.self) {
                customerId = unit.customerId;
            }

            this.props.editCartItem({
                productId: product.id,
                groupId: unit.groupId,
                itemId: unit.itemId,
                customerId,
                textValue: unit.textValue,
                qty: unit.qty,
            });
        } else {
            this.props.addCartItem(unit);
        }
        this.setState({ isPosting: true, isFetching: true });
    }

    updateNametag(unit) {
        const { product } = this.props;

        if (unit.itemId) {
            // Update / Edit previously added item
            let customerId = null;

            if (!this.props.self) {
                customerId = unit.customerId;
            }

            this.props.editCartItem({
                productId: product.id,
                groupId: unit.groupId,
                itemId: unit.itemId,
                customerId,
                textValue: unit.textValue,
                qty: unit.qty,
            });
        } else {
            this.props.addCartItem(unit);
        }
        this.setState({ isPosting: true, isFetching: true });
    }

    getCustomerUnitsInCart() {
        const { cart } = this.props.cart;
        const { product, isCommunal } = this.props;

        let foundUnits = cart.items.filter(item => {
            let foundUnit = product.units.find(unit => unit.id === item.unitId);

            // Do not check for customer id if looking for communal units
            if (
                (this.props.customer &&
                    foundUnit &&
                    item.unitId === foundUnit.id &&
                    item.customerId === this.props.customer.customer.id &&
                    item.isCommunalItem === isCommunal) ||
                (!this.props.customer &&
                    foundUnit &&
                    item.unitId === foundUnit.id &&
                    item.isCommunalItem === isCommunal)
            ) {
                return item;
            }

            return null;
        });

        if (foundUnits && foundUnits.length) {
            return foundUnits;
        }

        return null;
    }

    /**
     * Decides whether or not an interactable on the product is disabled
     * based on the current group status, user role and if the user has confirmed their cart
     */
    disableProductControl() {
        const hasStock = this.props.hasStock;
        const userRole = this.props.user.user.role;
        const currentStatus = this.props.group.group.status.statusCode;
        // disable when locked has first priority
        // if not disabled by status then check if user role can edit

        // Always allow admin to edit?
        if (userRole === "Admin") {
            return false;
        }

        const disableWhenLocked = GroupHelper.disableWhenLocked(this.props, [
            "Seller",
            "Manager",
            "Customer",
        ]);

        const sellerConditions =
            (hasStock && userRole === "Seller" && this.props.self === true) ||
            (hasStock &&
                userRole === "Seller" &&
                !this.props.self &&
                [
                    "LockedGroup",
                    "LockedCustomers",
                    "RejectedConfirmation",
                    "New",
                    "OpenForManager",
                ].includes(currentStatus));

        // Manager has ONLY full access rights to Customers' carts if status is LockedCustomers, RejectedConfirmation or OpenForManager
        const managerConditions =
            (hasStock &&
                userRole === "Manager" &&
                this.props.self === true &&
                [
                    "OpenForManager",
                    "LockedCustomers",
                    "RejectedConfirmation",
                ].includes(currentStatus)) ||
            (hasStock &&
                userRole === "Manager" &&
                !this.props.self &&
                [
                    "OpenForManager",
                    "LockedCustomers",
                    "RejectedConfirmation",
                ].includes(currentStatus));

        const customerConditions =
            hasStock &&
            userRole === "Customer" &&
            this.props.self === true &&
            ["Open"].includes(currentStatus) &&
            !CartHelper.hasCustomerAcceptedCart(
                this.props.cart.cart,
                this.props.user.user
            );

        if (disableWhenLocked) {
            return true;
        } else if (
            sellerConditions ||
            managerConditions ||
            customerConditions
        ) {
            return false;
        } else {
            return true;
        }
    }

    allowAddingProducts() {
        const userRole = this.props.user.user.role;

        //const currentStatus = this.props.group.group.status.statusCode;
        let disableProdControl = this.disableProductControl();

        if (
            (userRole === "Admin" ||
                (userRole !== "Customer" && !disableProdControl) ||
                (this.props.self && !disableProdControl)) &&
            this.props.hasStock
        ) {
            return true;
        } else {
            return false;
        }
    }

    // allowConfirmProducts() {
    //     if (this.state.localUnits && this.state.localUnits.length) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    // confirmProduct() {
    //     console.log("Confirm local unit");
    // }

    disableAddingProducts() {
        const hasLocalUnits =
            this.state.localUnits &&
            this.state.localUnits.length &&
            this.state.localUnits.length === 1;

        if (hasLocalUnits) {
            return true;
        } else {
            return this.disableProductControl();
        }
    }

    renderProductLines() {
        const customerCart = this.getCustomerUnitsInCart();
        const { isCommunal, product } = this.props;

        // Combination of customerCart items and localState items (unsaved cart) -> items with no selected unit
        // When the user selects a unit we post it to the customerCart endpoint, and remove it from local state
        // Then re render
        if (customerCart && customerCart.length) {
            // Sort combined carts by createdAt, oldest first
            let combinedCarts = DateHelper.sortByDate(
                customerCart,
                "createdAt",
                false
            );

            if (this.state.localUnits && this.state.localUnits.length) {
                combinedCarts = customerCart.concat(this.state.localUnits);
            }

            return combinedCarts.map((unit, i) => (
                <ProductLine
                    key={`productLine-${unit.id}-${i}`}
                    {...this.props}
                    isCommunal={isCommunal}
                    customer={this.props.customer}
                    product={product}
                    unit={unit}
                    productIndex={i}
                    removeProduct={() => this.removeProductLine(unit)}
                    updateSize={this.updateSize}
                    updateQty={this.updateQty}
                    updateNametag={this.updateNametag}
                    disableProductControl={() => this.disableProductControl()}
                />
            ));
        } else {
            return this.state.localUnits.map((unit, i) => (
                <ProductLine
                    key={`productLine-${i}`}
                    {...this.props}
                    isCommunal={isCommunal}
                    customer={this.props.customer}
                    product={product}
                    unit={unit}
                    productIndex={i}
                    removeProduct={() => this.removeProductLine(unit)}
                    updateSize={this.updateSize}
                    updateQty={this.updateQty}
                    updateNametag={this.updateNametag}
                    disableProductControl={() => this.disableProductControl()}
                />
            ));
        }
    }

    renderProductDetails() {
        if (this.props.customer) {
            const { customer } = this.props.customer;
            return (
                <span>
                    {CartHelper.hasCustomerAcceptedCart(
                        this.props.cart.cart,
                        customer
                    ) ? (
                        <span
                            className="tick"
                            title={
                                this.props.self
                                    ? "Du har godkjent handlekurven din"
                                    : `${customer.firstName} har godkjent handlekurven sin`
                            }
                        />
                    ) : null}
                    <span className="font-weight-bold">
                        {this.props.self
                            ? "Din handlekurv"
                            : `${customer.firstName} ${customer.lastName}`}
                    </span>
                </span>
            );
        } else {
            return (
                <span>
                    <span className="d-block font-weight-bold">
                        Fellesprodukter
                    </span>
                    <Restrict {...this.props} allow={Manager}>
                        <small className="d-block">
                            Dersom du vil bestille produkter på gruppens vegne
                            kan du bruke dette feltet
                        </small>
                    </Restrict>
                </span>
            );
        }
    }

    render() {
        const { cart } = this.props;

        if (cart.cartFetching === false && cart.cart) {
            return (
                <div className="product__customer px-3 py-2 border-top">
                    {(cart.cartPosting === true && this.state.isPosting) ||
                    this.state.isFetching ? (
                        <div className="product__posting">
                            Oppdaterer handlekurv...
                        </div>
                    ) : null}

                    <div className={this.props.className}>
                        <div className="col-12 mb-2">
                            {this.renderProductDetails()}
                        </div>

                        <div className="col-12 px-0">
                            {this.renderProductLines()}
                        </div>
                    </div>

                    <div className="d-flex">
                        {/* {this.allowConfirmProducts() ? (
                            <Button
                                color="primary"
                                size="sm"
                                className="mr-2 d-flex align-items-center"
                                title={`Bestill ${this.props.product.name}`}
                                disabled={this.disableAddingProducts()}
                                onClick={this.confirmProduct}>
                                Bestill
                            </Button>
                        ) : null} */}

                        {this.allowAddingProducts() ? (
                            <div className="row no-gutters">
                                <Button
                                    color="primary"
                                    outline
                                    size="sm"
                                    className="d-flex align-items-center"
                                    title={`Legg til flere ${this.props.product.name}`}
                                    disabled={this.disableAddingProducts()}
                                    onClick={this.addProductLine}>
                                    <Icon
                                        name="plus"
                                        fill="currentColor"
                                        title={`Legg til flere ${this.props.product.name}`}
                                    />
                                    <span className="ml-1">Legg til</span>
                                </Button>
                            </div>
                        ) : null}
                    </div>
                </div>
            );
        }

        return null;
    }
}

ProductCustomer.propTypes = {};
ProductCustomer.defaultProps = {};

function mapStateToProps(state) {
    return {
        cart: state.cart,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        addCartItem,
        editCartItem,
        replaceCartItem,
        deleteCartItem,
    }
)(ProductCustomer);
