import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Popover, PopoverBody } from "reactstrap";

// Components
import Badge from "../Common/Badge";
import Restrict from "../../Auth/Restrict";

// Helpers
import { GroupOnly, Seller } from "../../Auth/UserRoles";
import { GroupHelper } from "../../Helpers";

class GroupCard extends Component {
    constructor(props) {
        super(props);

        this.togglePopover = this.togglePopover.bind(this);
        this.activatePopover = this.activatePopover.bind(this);
        this.deactivatePopover = this.deactivatePopover.bind(this);
        this.state = {
            popoverOpen: false,
        };
    }

    togglePopover() {
        this.setState({
            popoverOpen: !this.state.popoverOpen,
        });
    }

    activatePopover() {
        if (!this.state.popoverOpen) {
            this.togglePopover();
        }
    }

    deactivatePopover() {
        if (this.state.popoverOpen) {
            this.togglePopover();
        }
    }

    render() {
        const { group } = this.props;
        const currentOrder = GroupHelper.getCurrentOrder(group);

        return (
            <div className="col-sm-12 mb-2">
                <div
                    className="group card h-100"
                    onMouseOver={this.activatePopover}
                    onMouseOut={this.deactivatePopover}>
                    <div className="card-body p-3">
                        <div className="row justify-content-around align-items-center">
                            {/* <Restrict {...this.props} allow={Seller}>
                                <div className="col-12 col-md-auto col-lg mb-3 mb-lg-0">
                                    <span className="d-block">
                                        <strong>Gruppe:</strong> {group.id}
                                    </span>
                                    {this.props.customer ? (
                                        <span className="d-block">
                                            <strong>Kunde:</strong>{" "}
                                            {this.props.customer.id}
                                        </span>
                                    ) : null}
                                </div>
                            </Restrict> */}

                            <div
                                className="col-12 col-sm group__name"
                                id={`Popover-${group.id}`}>
                                {group.names ? (
                                    <Popover
                                        placement="top"
                                        isOpen={this.state.popoverOpen}
                                        target={`Popover-${group.id}`}
                                        toggle={this.togglePopover}>
                                        <PopoverBody>
                                            <b>Teaser:</b>{" "}
                                            {group.names.TeaserName}
                                            <br /> <b>Dekknavn:</b>{" "}
                                            {group.names.CoverName}
                                            <br /> <b>Konsept:</b>{" "}
                                            {group.names.ConceptName}
                                        </PopoverBody>
                                    </Popover>
                                ) : null}

                                <strong>{group.name}</strong>
                            </div>

                            <div className="col-12 col-md-auto col-lg mb-3 mb-lg-0">
                                <Badge {...group.status} />
                            </div>

                            <Restrict {...this.props} allow={Seller}>
                                {this.props.cartsDone && this.props.carts ? (
                                    <div className="col-12 col-sm">
                                        <span>
                                            <strong>Handlekurver:</strong>
                                            <br /> {this.props.cartsDone}/
                                            {this.props.carts}
                                        </span>
                                    </div>
                                ) : null}

                                <div className="col-12 col-sm">
                                    <span>
                                        <strong>Sum:</strong>{" "}
                                        {currentOrder.total
                                            ? currentOrder.total
                                            : 0}
                                        ,-
                                    </span>
                                </div>
                            </Restrict>

                            {this.props.customer ? (
                                <div className="col-12 col-sm">
                                    <Restrict {...this.props} allow={GroupOnly}>
                                        <span>
                                            <strong>Ordreansvarlig:</strong>
                                            <br />
                                            {this.props.customer.firstName}{" "}
                                            {this.props.customer.lastName}
                                        </span>
                                    </Restrict>
                                </div>
                            ) : null}

                            <div className="col-12 col-lg-auto mt-3 mt-lg-0">
                                <Link
                                    to={`/group/${group.id}/overview`}
                                    className="btn btn-outline-primary">
                                    Se gruppe
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

GroupCard.propTypes = {
    group: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        names: PropTypes.object.isRequired,
        orders: PropTypes.array.isRequired,
        status: PropTypes.object,
    }),
    customer: PropTypes.object,
    carts: PropTypes.number,
    cartsDone: PropTypes.number,
};

GroupCard.defaultProps = {};

export default GroupCard;
