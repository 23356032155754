let assign = Object.assign || require("object.assign");

const DEFAULT_STATE = {
    availableAdditions: [],
    availableAdditionsFetching: null,
    availableAdditionsError: null,

    addedAdditions: [],
    addedAdditionsFetching: null,
    addedAdditionsError: null,
};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case "GET_AVAILABLE_ADDITIONS":
            return assign({}, state, {
                availableAdditions: action.payload,
                availableAdditionsFetching: false,
            });

        case "GET_AVAILABLE_ADDITIONS_FETCHING":
            return assign({}, state, {
                availableAdditionsFetching: action.payload,
                availableAdditionsError: null,
            });

        case "GET_AVAILABLE_ADDITIONS_ERROR":
            return assign({}, state, {
                availableAdditionsError: action.payload,
                availableAdditions: null,
                availableAdditionsFetching: null,
            });

        case "ADD_ADDITION":
        case "DELETE_ADDITION":
        case "GET_ADDITIONS":
            return assign({}, state, {
                addedAdditions: action.payload,
                addedAdditionsFetching: false,
            });

        case "ADD_ADDITION_POSTING":
        case "DELETE_ADDITION_DELETING":
        case "GET_ADDITIONS_FETCHING":
            return assign({}, state, {
                addedAdditionsFetching: action.payload,
                addedAdditionsError: null,
            });

        case "ADD_ADDITIONS_ERROR":
        case "DELETE_ADDITIONS_ERROR":
        case "GET_ADDITIONS_ERROR":
            return assign({}, state, {
                addedAdditionsError: action.payload,
                addedAdditions: null,
                addedAdditionsFetching: null,
            });

        default:
            return state;
    }
}
