import React, { Component } from "react";
//import PropTypes from "prop-types";
//import FormGroup from "./components/FormGroup";
import { LogoHelper } from "../Helpers";

export default class PlacementSettings extends Component {
    constructor(props) {
        super(props);
        this.togglePlacement = this.togglePlacement.bind(this);
    }

    togglePlacement(e) {
        this.props.handleActivePlacementType(parseInt(e.target.value, 0));
    }

    render() {
        const { state, activePlacement } = this.props;

        if (activePlacement && activePlacement.imageId === state.imageId) {
            return (
                <div>
                    <h3 className="h4 mt-3">
                        Redigerer plassering
                        <small className="d-block mt-2">
                            {activePlacement.imageType.name}
                            {/* : ID{" "}
                            {activePlacement.id} */}
                        </small>
                    </h3>

                    <div className="form-group row mb-2">
                        <div className="col-6 col-form-label col-form-label-sm">
                            <label
                                className="font-weight-bold"
                                htmlFor={`placement${activePlacement.id}Type`}>
                                Type plassering (påkrevd)
                            </label>
                        </div>
                        <div className="col-6">
                            <select
                                className="form-control form-control-sm"
                                name="placementType"
                                type="radio"
                                id={`placement${activePlacement.id}Type`}
                                onChange={this.togglePlacement}
                                value={activePlacement.placementType.id}>
                                {LogoHelper.getPlacementTypes(this.props).map(
                                    type => (
                                        <option
                                            key={type.typeName}
                                            disabled={state.placements.find(
                                                placement =>
                                                    placement.imageId ===
                                                        state.imageId &&
                                                    placement.placementTypeId ===
                                                        type.id
                                            )}
                                            value={type.id}>
                                            {type.name}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                    </div>

                    {/* <div className="form-group row mb-2">
                        <label
                            className="font-weight-bold col-6 col-form-label col-form-label-sm"
                            htmlFor={`placementDarkness`}>
                            Tillatt logofarge
                        </label>
                        <div className="col-6">
                            <select
                                className="form-control form-control-sm"
                                id={`placementDarkness`}
                                ref={el => (this.placementDarknessType = el)}
                                defaultValue={activePlacement.darknessTypeName}>
                                {LogoHelper.getDarknessTypes().map(type => (
                                    <option
                                        key={type.typeName}
                                        value={type.typeName}>
                                        {type.name}
                                    </div>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="form-group row mb-2">
                        <label
                            className="font-weight-bold col-6 col-form-label col-form-label-sm"
                            htmlFor={`placementTextile`}>
                            Type trykk
                        </label>
                        <div className="col-6">
                            <select
                                className="form-control form-control-sm"
                                id={`placementTextile`}
                                ref={el => (this.placementTextileType = el)}
                                defaultValue={activePlacement.textileTypeName}>
                                {LogoHelper.getTextileTypes().map(type => (
                                    <option
                                        key={type.typeName}
                                        value={type.typeName}>
                                        {type.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div> */}
                    {/* <FormGroup
                        id="cmBig"
                        size="big"
                        title={this.props.forms.big.title}
                        placeholder={this.props.forms.big.placeholder}
                        value={this.state.sizes.big}
                        valueHandler={this.props.sizeUpdate}
                    />
                    <FormGroup
                        id="cmSmall"
                        size="small"
                        title={this.props.forms.small.title}
                        placeholder={this.props.forms.small.placeholder}
                        info={this.props.forms.small.info}
                        value={this.state.sizes.small}
                        valueHandler={this.props.sizeUpdate}
                    /> */}
                </div>
            );
        }

        return null;
    }
}

PlacementSettings.defaultProps = {
    forms: {
        big: {
            value: 35,
            title: "CM-bredde på logo (M - XXL)",
            placeholder: "Bredde i CM, kun tall",
        },
        small: {
            value: 20,
            title: "CM-bredde på logo (XXS - S)",
            placeholder: "Bredde i CM, kun tall",
            info:
                "Disse størrelsene påvirker ikke logoplasseringen, men vises kun som info til kundene.",
        },
    },
};
