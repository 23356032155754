import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// Components
import PageError from "./PageError";

class IndexHandler extends Component {
    getPath = user => {
        if (user.role === "Admin" || user.role === "Seller") {
            return "/admin";
        } else if (
            !user.role ||
            user.role === "Manager" ||
            user.role === "Customer"
        ) {
            // @consideration omit - user should only have 1 group
            if (user.groups && user.groups.length > 1) {
                // @consideration
                // Also allow redirect to onboarding if version is too old
                // Do this after selecting an group instead of forcing the onboarding?

                if (!user.onboardingFinishedAt) {
                    return `/group/${user.groups[0].id}/help`;
                }

                return `/group/${user.groups[0].id}/products`;
            } else if (user.groups && user.groups.length === 1) {
                let groupPage = "/products";

                if (user.role === "Manager") {
                    groupPage = "/overview";
                }

                // @consideration
                // Also allow redirect to onboarding if version is too old
                if (!user.onboardingFinishedAt) {
                    groupPage = "/help";
                }

                return `/group/${user.groups[0].id}${groupPage}`;
            } else {
                return "/error";
            }
        } else {
            return "/error";
        }
    };

    getRedirect(user) {
        return (
            <Redirect
                to={{
                    pathname: this.getPath(user),
                    state: { from: this.props.location },
                }}
            />
        );
    }

    renderRedirect() {
        // Return Error comp
        if (this.props.user.userError) return <PageError {...this.props} />;

        // Return appshell comp
        if (this.props.user.userFetching === true) {
            return null;
        }

        // Finished fetching data, allowed to access
        if (this.props.user.userFetching === false && this.props.user.user) {
            return this.getRedirect(this.props.user.user);
        } else {
            // @consideration: Redirect to history -1?
            return null;
        }
    }

    render() {
        return <>{this.renderRedirect()}</>;
    }
}

IndexHandler.propTypes = {
    user: PropTypes.object,
};
IndexHandler.defaultProps = {};

function mapStateToProps(state) {
    return {
        errors: state.errors,
        user: state.user,
    };
}
export default connect(mapStateToProps)(IndexHandler);
