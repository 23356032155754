import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setOnboarding } from "../../Actions/UserActions";
import { DateHelper } from "../../Helpers";

class FinishButton extends Component {
    constructor(props) {
        super(props);

        this.finishOnboarding = this.finishOnboarding.bind(this);
    }

    finishOnboarding(e) {
        e.preventDefault();

        this.props.setOnboarding({
            onboardingVersion: this.props.onboarding.onboarding
                .onboardingVersion,
            onboardingFinishedAt: DateHelper.string(),
            redirect: e.target.href,
        });
    }

    render() {
        return (
            <a
                href={this.props.href}
                className={this.props.className}
                onClick={this.finishOnboarding}>
                {this.props.children}
            </a>
        );
    }
}

FinishButton.propTypes = {
    href: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
};
FinishButton.defaultProps = {};

function mapStateToProps(state) {
    return {
        onboarding: state.onboarding,
        user: state.user,
    };
}

export default connect(
    mapStateToProps,
    { setOnboarding }
)(FinishButton);
