import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { getLogs } from "../../Actions/GroupLogActions";

// Components
import LogItemAppshell from "./LogItemAppshell";
import LogItem from "./LogItem";

class Logs extends Component {
    componentDidMount() {
        this.props.getLogs(this.props.match.params.id);
    }

    render() {
        // Echo possible error messages or that there is no dataz etc
        if (this.props.logs.logsError) return null; // <SectionError />;

        // Echo appshell while loading dataz
        if (this.props.logs.logsFetching === true) return <LogItemAppshell />;

        // Fetching is done and we got dataz
        if (
            this.props.logs.logsFetching === false &&
            this.props.logs.logs.length
        ) {
            return this.props.logs.logs.map((log, i) => (
                <LogItem {...this.props} log={log} key={`section-${i}`} />
            ));
        }

        if (
            this.props.logs.logsFetching === false &&
            !this.props.logs.logs.length
        ) {
            return (
                <div className="col">
                    <p className="text-black-50 mb-0">
                        Ingen loggoppføringer tilgjengelig...
                    </p>
                </div>
            );
        }

        return null;
    }
}

function mapStateToProps(state) {
    return {
        group: state.group,
        logs: state.logs,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        getLogs,
    }
)(Logs);
