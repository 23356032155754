import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TabContent, TabPane } from "reactstrap";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Alert } from "reactstrap";

// Actions
import { manageProduct, manageSortOrder } from "../../Actions/ProductsActions";

// Components
import DraggableList from "./DraggableList";
//import ProductAvailable from "../Products/ProductAvailable";
//import Restrict from "../../Auth/Restrict";

// Helpers
import { Utils, ProductHelper } from "../../Helpers";
//import { Seller } from "../../Auth/UserRoles";

class SettingsTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: this.getProductsByisHidden(false),
            hidden: this.getProductsByisHidden(true),
        };
        this.onDragEnd = this.onDragEnd.bind(this);
        this.updateList = this.updateList.bind(this);
        this.changeProductVisibility = this.changeProductVisibility.bind(this);
    }

    onDragEnd(result, visibility = "visible") {
        this.updateList(result, visibility);
    }

    // Updates the sortOrder of the items in the list
    updateList(result, itemsList) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const newOrder = this.reorder(
            this.state[itemsList],
            result.source.index,
            result.destination.index
        );

        /*
        const affectedIndices = [
            result.source.index,
            result.destination.index,
        ];
        const minIndex = Math.min(...affectedIndices);
        const maxIndex = Math.max(...affectedIndices);

        for (let i = minIndex; i <= maxIndex; i++) {
        }
        */

        // We have to update the sortOrder of all productGroups every time
        // Because we always have to override the default sortOrder of an item
        // In case the item was previously a different isHidden state, or just hadn't been sorted
        let productGroups = newOrder.slice();
        productGroups.forEach((group, i) => {
            group.units = group.units.map(unit => {
                return {
                    id: unit.id,
                    sortOrder: this.getSortOrder(i),
                };
            });
        });

        let unitsOrder = Utils.flatten(productGroups.map(item => item.units));

        this.props.manageSortOrder({
            groupId: this.props.group.group.id,
            products: [],
            units: unitsOrder,
        });
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    /**
     * Get products based on visibility
     */
    getProductsByisHidden(isHidden = false) {
        const { groupManageProducts } = this.props.products;

        const filteredProducts = groupManageProducts.filter(productManage =>
            this.filterProducts(productManage.units, isHidden)
        );

        return filteredProducts;
    }

    filterProducts(units, isHidden) {
        if (isHidden) {
            return units.every(unit => unit.isHidden === isHidden);
        } else {
            return units.some(unit => unit.isHidden === isHidden);
        }
    }

    getReadableVisibility(visibility) {
        if (visibility === "visible") {
            return "synlige";
        } else {
            return "skjulte";
        }
    }

    // Sort order starts at 1000
    getSortOrder(index) {
        return index + 1000;
    }

    changeProductVisibility(item, isHidden) {
        let newProductManage = ProductHelper.cloneDeepProduct(item);

        // Set isHidden for all productGroup units
        // Reset price by setting null if it is 0
        if (newProductManage.price === 0) {
            newProductManage.price = null;
        }

        newProductManage.units.forEach(unit => {
            unit.isHidden = isHidden;

            if (unit.price === 0) {
                unit.price = null;
            }
        });

        this.props.manageProduct({
            groupId: this.props.group.group.id,
            productId: newProductManage.id,
            ...newProductManage,
        });
    }

    renderListsByVisibility(visibility = "visible") {
        if (this.state[visibility].length) {
            return (
                <DragDropContext
                    onDragEnd={result => this.onDragEnd(result, visibility)}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={{ width: 100 + "%" }}>
                                <DraggableList
                                    {...this.props}
                                    changeProductVisibility={
                                        this.changeProductVisibility
                                    }
                                    showProductImages={
                                        this.props.showProductImages
                                    }
                                    list={ProductHelper.sortProductGroups(
                                        this.state[visibility]
                                    )}
                                />
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            );
        } else {
            return (
                <Alert color="primary">
                    <h4 className="m-0">
                        Ingen {this.getReadableVisibility(visibility)} produkter
                    </h4>
                </Alert>
            );
        }
    }

    render() {
        return (
            <TabContent activeTab={this.props.activeTab} className="products">
                {this.props.activeTab === "visibleProducts" && (
                    <TabPane tabId="visibleProducts">
                        <div className="my-2">
                            {this.renderListsByVisibility("visible")}
                        </div>
                    </TabPane>
                )}

                {this.props.activeTab === "hiddenProducts" && (
                    <TabPane tabId="hiddenProducts">
                        <div className="products__hidden my-2">
                            {this.renderListsByVisibility("hidden")}
                        </div>
                    </TabPane>
                )}

                {/* <Restrict {...this.props} allow={Seller}>
                    <TabPane tabId="allProducts">
                        <div className="row my-2">
                            {this.props.products.allProducts
                                ? this.props.products.allProducts.map(
                                      product => (
                                          <div
                                              className="col-12 col-md-6 mb-2"
                                              key={product.id}>
                                              <ProductAvailable
                                                  {...this.props}
                                                  product={product}
                                              />
                                          </div>
                                      )
                                  )
                                : null}
                        </div>
                    </TabPane>
                </Restrict> */}
            </TabContent>
        );
    }
}

SettingsTabs.propTypes = {
    products: PropTypes.object.isRequired,
};

SettingsTabs.defaultProps = {};

function mapStateToProps(state) {
    return {
        products: state.products,
    };
}
export default connect(
    mapStateToProps,
    {
        manageProduct,
        manageSortOrder,
    }
)(SettingsTabs);
