import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ButtonToggle extends Component {
    /*
    constructor(props) {
        super(props);
        this.state = { active: false };
    }
    */

    render() {
        return (
            <button className={`btn ${(this.props.priority ? `btn-${this.props.priority}` : '')} ${(this.props.active ? 'active' : '')}`} 
            aria-pressed={(this.props.active ? true : false)}
            onClick={() => this.props.onClick()}
            type={this.props.type}>{(this.props.active ? this.props.activeText : this.props.inactiveText)}</button>
        );
    }
}

ButtonToggle.propTypes = {
    priority: PropTypes.string,
    type: PropTypes.string,
    active: PropTypes.bool.isRequired,
    activeText: PropTypes.string.isRequired,
    inactiveText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

ButtonToggle.defaultProps = {
    priority: 'primary',
    type: 'button'
};

export default ButtonToggle;