import React, { Component } from "react";
import PropTypes from "prop-types";
import ActionText from "./ActionText";
import ActionButton from "./ActionButton";
import ActionLink from "./ActionLink";

class Action extends Component {
    render() {
        return (
            <div className={`action card mb-4 bg-${this.props.color}`}>
                <div className="action__inner card-body">
                    <div className="action__content row align-items-center justify-content-center">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

Action.propTypes = {
    children: PropTypes.node,
    color: PropTypes.string,
};

Action.defaultProps = {
    color: "primary",
};

Action.Link = ActionLink;
Action.Button = ActionButton;
Action.Text = ActionText;

export default Action;
