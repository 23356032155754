import React, { Component } from "react";
import PropTypes from "prop-types";
import CartList from "./CartList";

class Cart extends Component {
    render() {
        return (
            <div
                className={`${
                    this.props.className
                        ? this.props.className
                        : "row no-gutters mb-2 border-bottom"
                }`}>
                {this.props.children}
            </div>
        );
    }
}

Cart.propTypes = {
    children: PropTypes.node,
};

Cart.defaultProps = {};

Cart.List = CartList;

export default Cart;
