import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Input, Button, FormGroup } from "reactstrap";

// Actions
import {
    uploadImage,
    uploadImagesSortedInColors,
    deleteImage,
} from "../../Actions/ImageActions";

// Helpers
import { ImageHelper } from "../../Helpers";

class ImageUpload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            file: null,
            invalidFile: null,
            type: null,
            invalidType: null,
            isDark: true,
            invalidIsDark: null,
        };

        this.addFile = this.addFile.bind(this);
        this.setType = this.setType.bind(this);
        this.setIsDark = this.setIsDark.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    addFile(e) {
        // File is valid
        this.setState({ file: e.target.files[0], invalidFile: false });
    }

    setType(e) {
        // Type is valid
        this.setState({ type: e.target.value, invalidType: false });
    }

    setIsDark(e) {
        // IsDark is valid
        this.setState({ isDark: e.target.value, invalidIsDark: false });
    }

    submitForm(e) {
        const { units } = this.props;
        e.preventDefault();

        // File is invalid
        if (!this.state.file) {
            this.setState({ invalidFile: true });
        }

        // Type is invalid
        if (!this.state.type) {
            this.setState({ invalidType: true });
        }

        // isDark is invalid
        if (!this.state.isDark) {
            this.setState({ invalidIsDark: true });
        }

        // Submit form
        // @consideration, show progress, then reset form after uploading?
        if (this.state.type && this.state.file) {
            if (units && units.length) {
                // Post image for color
                this.props.uploadImagesSortedInColors({
                    productId: this.props.productId,
                    colorId: units[0].color.id,
                    type: this.state.type,
                    isDark: this.state.isDark,
                    image: this.state.file,
                });
            } else {
                // Upload single image
                this.props.uploadImage({
                    productId: this.props.productId,
                    unitId: this.props.unitId,
                    type: this.state.type,
                    isDark: this.state.isDark,
                    image: this.state.file,
                });
            }
        }
    }

    render() {
        return (
            <Form onSubmit={this.submitForm}>
                <Row noGutters>
                    <FormGroup className="col-12 col-lg">
                        <div className="custom-file">
                            <input
                                type="file"
                                className={`custom-file-input ${
                                    this.state.file ? "is-valid" : ""
                                } ${
                                    this.state.invalidFile ? "is-invalid" : ""
                                }`}
                                id={this.props.id}
                                name="customFile"
                                accept="image/jpg,image/png"
                                onChange={this.addFile}
                            />
                            <label
                                className="custom-file-label"
                                htmlFor={this.props.id}
                                data-browse="Velg">
                                {this.state.file
                                    ? this.state.file.name
                                    : "Velg bilde"}
                            </label>
                        </div>
                    </FormGroup>
                    <FormGroup className="col-12 col-lg">
                        <Row noGutters>
                            <Col className="pl-lg-2">
                                <Input
                                    type="select"
                                    invalid={
                                        this.state.invalidType ? true : false
                                    }
                                    defaultValue={0}
                                    onChange={this.setType}>
                                    <option value={0} disabled>
                                        Velg bildetype
                                    </option>
                                    {ImageHelper.getImageTypes().map(item => (
                                        <option
                                            key={item.type}
                                            value={item.type}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Input>
                            </Col>

                            <Col className="pl-2">
                                <Input
                                    type="select"
                                    invalid={
                                        this.state.invalidIsDark ? true : false
                                    }
                                    defaultValue={true}
                                    onChange={this.setIsDark}>
                                    <option value={true}>Mørkt plagg</option>
                                    <option value={false}>Lyst plagg</option>
                                </Input>
                            </Col>

                            <Col className="pl-2">
                                <Button color="primary" type="submit" block>
                                    {this.props.products &&
                                    this.props.products.productImage &&
                                    this.props.products.productImage
                                        .productImagePosting === true
                                        ? "Laster opp..."
                                        : "Last opp"}
                                </Button>
                            </Col>
                        </Row>
                    </FormGroup>
                </Row>
            </Form>
        );
    }
}

function mapStateToProps(state) {
    return {
        products: state.products,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    {
        uploadImage,
        uploadImagesSortedInColors,
        deleteImage,
    }
)(ImageUpload);
