import "../styles/logo-placer.css";

// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Actions
import { getPlacements, setPlacements } from "../Actions/GroupLogoActions";
import { getProductUnits } from "../Actions/ProductsActions";

// Helpers
import PlacementCreator from "./PlacementCreator";
import { PlacerHelper, Utils } from "../Helpers";

class PlacementLoad extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: null,
            product: null,
            unit: null,
            itemImageTypes: null,
            itemViews: null,
            imageLoading: null,
            shouldLoadImages: null,
            shouldDrawSavedPlacements: false,
            placements: [],
            placementsLoaded: false,
            newPlacementId: 0,
            activePlacement: null,
            showOverlays: true,
            typeName: null,
            imageId: null,
            width: null,
            height: null,
        };

        this.editor = React.createRef();
        this.fabric = window.fabric;
        this.canvas = new this.fabric.Canvas();

        this.drawPlacements = this.drawPlacements.bind(this);
        this.savePlacements = this.savePlacements.bind(this);
        this.handlePlacementProps = this.handlePlacementProps.bind(this);
        this.handleActivePlacementType = this.handleActivePlacementType.bind(
            this
        );
        this.handleActivePlacement = this.handleActivePlacement.bind(this);
        this.placementAdd = this.placementAdd.bind(this);
        this.placementRemove = this.placementRemove.bind(this);
        this.itemLoad = this.itemLoad.bind(this);

        this.itemHasPlacements = PlacerHelper.itemHasPlacements.bind(this);
        this.itemHasImages = PlacerHelper.itemHasImages.bind(this);
    }

    // Load first product, or product from this.props.match.params.productId
    // TODO, always init / load product and navigate to first available product unit.
    // Never go to just product
    componentDidMount() {
        const { products } = this.props;
        PlacerHelper.handleInit(this);

        if (
            this.props.match.params.productId &&
            !products.productUnitsFetching
        ) {
            this.props.getProductUnits(this.props.match.params.productId);
        }
    }

    componentDidUpdate(prevProps) {
        const { groupLogo } = this.props;

        PlacerHelper.handleNavigation(this, prevProps);
        PlacerHelper.handleUnitLoading(this, prevProps);

        // Prepare incoming fetched saved placements and item (product or unit) views
        if (
            groupLogo.placementsFetching === false &&
            groupLogo.placements &&
            this.state.product &&
            this.state.product.id === groupLogo.placements.id &&
            this.state.placementsLoaded === false
        ) {
            const isUnit = this.state.unit ? true : false;

            const itemViews = PlacerHelper.getItemViews(
                groupLogo.placements,
                isUnit
            );

            let placements;

            // @consideration sort item views? (to get id's in ascending order)
            if (itemViews) {
                placements = PlacerHelper.getSavedPlacements(this, itemViews);
            } else {
                placements = [];
            }

            this.setState({
                placementsLoaded: true,
                shouldDrawSavedPlacements: true,
                placements,
                itemViews,
            });
        }

        // Make sure this.editor.current is defined before loading any images
        // If the canvas hasn't initialized we have nowhere to put any images
        // Load all product images => then load and render all placeholders
        if (
            this.editor &&
            this.editor.current &&
            this.state.itemImageTypes &&
            this.state.shouldLoadImages
        ) {
            this.setState({ shouldLoadImages: false }, () => {
                PlacerHelper.itemImageHandler(this);
            });
        }

        // Draw all saved placements to the canvas after images have loaded
        if (
            this.state.imageLoading === false &&
            this.state.shouldDrawSavedPlacements === true &&
            this.state.placements &&
            this.editor &&
            this.editor.current
        ) {
            this.setState({ shouldDrawSavedPlacements: false }, () => {
                this.drawPlacements();
            });
        }
    }

    drawPlacements(placements = this.state.placements) {
        placements.forEach(placement => {
            this.editor.current.createCanvasRect(placement);
        });
    }

    /**
     * Initialize the placement creator -> Check if we can load the first item
     */
    init = (productId, unitId) => {
        const { products, groupLogo } = this.props;

        if (
            products &&
            products.allProducts &&
            groupLogo &&
            groupLogo.placementTypes
        ) {
            const foundProduct = products.allProducts.find(
                product => product.id === productId
            );
            let product = products.allProducts[0];
            let productIndex = 0;
            let foundUnit;

            if (unitId && foundProduct && foundProduct.units) {
                foundUnit = foundProduct.units.find(unit => unit.id === unitId);
            }

            if (productId && foundProduct) {
                product = foundProduct;
                productIndex = products.allProducts.indexOf(foundProduct);
            }

            this.setState(
                {
                    products: products.allProducts,
                    product,
                    productIndex,
                    unit: foundUnit,
                },
                () => {
                    if (this.state.unit) {
                        this.itemLoad(this.state.product, this.state.unit);
                    } else {
                        this.itemLoad(this.state.product);
                    }
                }
            );
        }
    };

    /**
     * Loads a single item
     * @param {Object} product
     * @param {Object} unit (optional)
     */
    itemLoad(product, unit) {
        // Update current state
        if (this.state.products.indexOf(product) !== -1) {
            this.setState(
                {
                    productIndex: this.state.products.indexOf(product),
                    placements: [],
                    placementsLoaded: false,
                    productViews: null,
                    activePlacement: null,
                    imageLoading: null,
                    shouldLoadImages: null,
                },
                () => {
                    // Decide if item to load is unit or product
                    const item = unit ? unit : product;
                    const isUnit = unit ? true : false;

                    // Clear canvas of previous product images and logos
                    this.canvas.clear();

                    // Fetch product placements
                    this.props.getPlacements(product.id);

                    const itemImageTypes = PlacerHelper.getItemImageTypes(
                        this.props,
                        product,
                        item,
                        isUnit
                    );
                    const firstItemImage = itemImageTypes[0];

                    if (firstItemImage) {
                        this.setState({
                            itemImageTypes,
                            typeName: firstItemImage.typeName,
                            imageId: firstItemImage.id,
                            width: firstItemImage.width,
                            height: firstItemImage.height,
                            shouldLoadImages: true,
                        });
                    } else {
                        // Item has no images
                        this.setState({
                            typeName: this.props.defaultTypeName,
                            width: this.props.maxWidth,
                            height: this.props.defaultHeight,
                            shouldLoadImages: false,
                        });
                    }
                }
            );
        }
    }

    getAvailablePlacementTypes() {
        const { groupLogo } = this.props;
        const placementsForImage = this.state.placements.filter(
            placement => placement.imageId === this.state.imageId
        );
        return groupLogo.placementTypes.filter(
            placementType =>
                !placementsForImage.find(
                    placement => placement.placementTypeId === placementType.id
                )
        );
    }

    /**
     * Adds a placement to the canvas and the state
     * Can only add one of each placementType
     */
    placementAdd() {
        const availablePlacementTypes = this.getAvailablePlacementTypes();

        if (availablePlacementTypes && availablePlacementTypes.length) {
            const imageType = this.state.itemImageTypes.find(
                imageType =>
                    imageType.typeName === this.state.typeName &&
                    imageType.id === this.state.imageId
            );

            // Pick a random placementTypeId, the first in the list available
            let newPlacement = {
                id: this.state.newPlacementId + 1,
                imageId: imageType.id,
                placementType: {
                    id: availablePlacementTypes[0].id,
                },
                placementTypeId: availablePlacementTypes[0].id,
                coords: { x: null, y: null },
                boundingCoords: { x: null, y: null },
                x: null,
                y: null,
                rotation: null,
                imageType,
            };
            let newPlacements = [...this.state.placements, newPlacement];

            this.setState(
                {
                    newPlacementId: newPlacement.id,
                    activePlacement: newPlacement,
                    placements: newPlacements,
                },
                () => {
                    this.editor.current.createCanvasRect(
                        this.state.activePlacement
                    );
                }
            );
        }
        // Can not add another placement, no available placementTypes
    }

    /**
     * Removes a placement from the canvas and the state
     */
    placementRemove() {
        if (
            this.state.activePlacement &&
            this.state.activePlacement.id &&
            this.state.placements &&
            this.state.placements.length
        ) {
            this.editor.current.removeActiveObject();

            /*
            placement.placementType.id === canvasPlacement.uid &&
            placement.imageId === canvasPlacement.imageId
                */

            this.setState({
                placements: [
                    ...this.state.placements.filter(
                        p => p.id !== this.state.activePlacement.id
                    ),
                ],
                activePlacement: null,
            });
        }
    }

    /**
     * Save the currently active placement and swap it out for a new active placement
     * @param {Object} canvasPlacement
     */
    handleActivePlacement(canvasPlacement) {
        const newActivePlacement = this.state.placements.find(
            placement => placement.id === canvasPlacement.uid
        );

        let newPlacements;

        // Only handle the active placement if it matches the current typeName and imageId
        if (
            newActivePlacement &&
            newActivePlacement.imageType &&
            newActivePlacement.imageType.typeName === this.state.typeName &&
            newActivePlacement.imageId === this.state.imageId
        ) {
            // Filter out the active placement if we have it
            if (this.state.activePlacement) {
                newPlacements = [
                    ...this.state.placements.filter(
                        p => p.id !== this.state.activePlacement.id
                    ),
                    this.state.activePlacement,
                ];
            } else {
                newPlacements = [...this.state.placements];
            }

            this.setState({
                placements: newPlacements,
                activePlacement: newActivePlacement,
            });
        }
    }

    /**
     * Updates a placement's props after being modified in the canvas
     * @param {Object} canvasPlacement
     */
    handlePlacementProps(canvasPlacement) {
        const foundPlacement = this.state.placements.find(
            p => p.id === canvasPlacement.uid
        );

        if (!foundPlacement) return false;

        let placement = Object.assign({}, foundPlacement, {
            id: canvasPlacement.uid,
            imageId: foundPlacement.imageId,
            placementType: foundPlacement.placementType,
            coords: canvasPlacement.coords,
            boundingCoords: canvasPlacement.boundingCoords,
            x: canvasPlacement.left,
            y: canvasPlacement.top,
            rotation: canvasPlacement.angle,
            width: canvasPlacement.width * canvasPlacement.scaleX,
            height: canvasPlacement.height * canvasPlacement.scaleY,
            imageType: foundPlacement.imageType,
        });

        this.setState({
            placements: [
                ...this.state.placements.filter(p => p.id !== placement.id),
                placement,
            ],
            activePlacement: placement,
        });
    }

    /**
     * Updates a placement's placementType.id after being modified in PlacementSettings
     * @param {Number} id
     */
    handleActivePlacementType(id) {
        let newActivePlacement = Object.assign({}, this.state.activePlacement);
        newActivePlacement.placementType.id = id;
        newActivePlacement.placementTypeId = id;

        this.setState({
            placements: [
                ...this.state.placements.filter(
                    p => p.id !== newActivePlacement.id
                ),
                newActivePlacement,
            ],
            activePlacement: newActivePlacement,
        });
    }

    // Gets a placement from the canvas
    getCanvasPlacement(id) {
        return this.canvas
            .getObjects()
            .find(o => o.placerType === "placement" && o.uid === id);
    }

    handlePlacementDataBeforeSave(placements = this.state.placements) {
        // Prepare placement data to conform to api
        return placements.map(placement => {
            // Transform coordinates from resized image to fit real image size
            let scaleRatio = PlacerHelper.getScaleRatio(
                placement.imageType.naturalWidth,
                placement.imageType.width
            );

            const foundCanvasPlacement = this.getCanvasPlacement(placement.id);
            let boundingCoords;

            if (foundCanvasPlacement && foundCanvasPlacement.boundingCoords) {
                boundingCoords = {
                    x: PlacerHelper.resizedToNatural(
                        foundCanvasPlacement.boundingCoords.x,
                        scaleRatio
                    ),
                    y: PlacerHelper.resizedToNatural(
                        foundCanvasPlacement.boundingCoords.y,
                        scaleRatio
                    ),
                };
            }

            const coords = {
                x: PlacerHelper.resizedToNatural(placement.x, scaleRatio),
                y: PlacerHelper.resizedToNatural(placement.y, scaleRatio),
            };

            return {
                ...placement,
                width: PlacerHelper.resizedToNatural(
                    placement.width,
                    scaleRatio
                ),
                height: PlacerHelper.resizedToNatural(
                    placement.height,
                    scaleRatio
                ),
                boundingCoords,
                coords,
                placementTypeId: placement.placementType.id,
                productImageId: placement.imageType.id,
                rotation: placement.rotation ? placement.rotation : 0,
            };
        });
    }

    // Sort based on imageType id
    getPlacementTypesGroups(allPlacements) {
        return Utils.sortByProp(allPlacements, "productImageId");
    }

    // If no placementTypeGroups (no placements at all)
    // Delete all the placements by posting empty array
    getSortedPlacements(placementTypeGroups, imageType) {
        let placements = [];

        if (placementTypeGroups && placementTypeGroups[imageType.id]) {
            placements = placementTypeGroups[imageType.id];
        }

        return placements;
    }

    /**
     * Saves all the placements associated with any images of the item
     * NOTE: Always save everything as saving overrides any previously saved data
     */
    savePlacements() {
        const allPlacements = this.handlePlacementDataBeforeSave();
        const placementTypeGroups = this.getPlacementTypesGroups(allPlacements);

        this.state.itemImageTypes.forEach(imageType => {
            const placements = this.getSortedPlacements(
                placementTypeGroups,
                imageType
            );

            this.props.setPlacements({
                productId: this.state.product.id,
                productImageId: imageType.id,
                placements: placements,
            });
        });
    }

    render() {
        const { groupLogo } = this.props;

        // Echo possible error messages
        if (groupLogo.placementsError)
            return (
                <div className="col">
                    Kunne ikke laste {this.state.unit ? "enhet" : "produkt"}.
                </div>
            );

        // Echo appshell
        if (groupLogo.placementsFetching === true)
            return (
                <div className="col">
                    Laster {this.state.unit ? "enhet" : "produkt"}...
                </div>
            );

        // Fetching is done
        if (groupLogo.placementsFetching === false && this.state.product) {
            return (
                <PlacementCreator
                    parentThisRef={this}
                    state={this.state}
                    fabric={this.fabric}
                    editor={this.editor}
                    canvas={this.canvas}
                    savePlacements={this.savePlacements}
                    placementAdd={this.placementAdd}
                    placementRemove={this.placementRemove}
                    handlePlacementProps={this.handlePlacementProps}
                    handleActivePlacement={this.handleActivePlacement}
                    handleActivePlacementType={this.handleActivePlacementType}
                    itemHasImages={this.itemHasImages}
                    itemHasPlacements={this.itemHasPlacements}
                    {...this.props}
                />
            );
        }

        return null;
    }
}

PlacementLoad.propTypes = {
    logoTypes: PropTypes.object,
};

PlacementLoad.defaultProps = {
    maxWidth: 345,
    defaultHeight: 545,
    defaultTypeName: "Front",
    text: {
        save: "Lagre",
        saving: "Lagrer...",
        placementAdd: "Legg til plassering",
        placementRemove: "Fjern plassering",
        nextProduct: "Neste produkt",
        previousProduct: "Forrige produkt",
        resetToDefault: "Tilbakestill til standard",
        activeText: "Skjul overlegg",
        inactiveText: "Vis overlegg",
    },
};

function mapStateToProps(state) {
    return {
        products: state.products,
        groupLogo: state.groupLogo,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    { getPlacements, setPlacements, getProductUnits }
)(PlacementLoad);
