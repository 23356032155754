import React, { Component } from "react";
import { config } from "../../Helpers";
//import PropTypes from 'prop-types';

export default class LockedAlert extends Component {
    shouldRender() {
        let statusCodes = [
            "New",
            "RejectedConfirmation",
            "RequestingConfirmation",
            "SendToProcessing",
            "Confirmed",
            "LockedSellers",
            "LockedGroup",
            "LockedCustomers",
        ];
        let userRoles = ["Manager", "Customer"];

        if (
            statusCodes.includes(this.props.group.group.status.statusCode) &&
            userRoles.includes(this.props.user.user.role)
        ) {
            return true;
        } else {
            return false;
        }
    }

    getStatusMessage() {
        switch (this.props.group.group.status.statusCode) {
            case "New":
                return {
                    title: "Er låst",
                    text:
                        "Gruppebestillingen er låst ettersom den er fersk. Det vil si at selgeren klargjør bestillingen, og er den eneste som kan redigere handlekurver. Vent til gruppebestillingen låses opp for å gjøre endringer, eller la selgeren ta seg av saken.",
                };
            case "OpenForManager":
                return {
                    title: "Er låst for endringer",
                    text:
                        "Når en bestilling er låst kan kun selger eller ordreansvarlig redigere handlekurver. Vent til gruppebestillingen låses opp igjen for å gjøre endringer, eller la ordreansvarlig ta seg av saken.",
                };
            case "RequestingConfirmation":
                return {
                    title: "Venter på godkjennelse",
                    text:
                        "Bestillingen venter nå på godkjennelse av selgeren. Godkjennes bestillingen kan den sendes videre til produksjon av ordreansvarlig. Dersom bestillingen har mangler blir den sendt tilbake til ordreansvarlig for utbedring.",
                };
            case "RejectedConfirmation":
                return {
                    title: "Er midlertidig stanset",
                    text:
                        "Ordreansvarlig har fått en melding om hvorfor bestillingen er stanset.",
                };
            case "SendToProcessing":
                return {
                    title:
                        "Er sendt til produksjon og vil låses automatisk om kort tid.",
                    text: "Bestillingen er nå sendt til produksjon.",
                };

            case "Confirmed":
                return {
                    title: "Er godkjent av selger!",
                    text:
                        "Når en bestilling er godkjent kan den ikke redigeres. Ordreansvarlig må ta valget om å sende bestillingen til produksjon, eller å låse den for å gjøre flere endringer.",
                };
            default:
                return {
                    title: "Er låst",
                    text:
                        "Når en gruppebestilling er låst kan kun selger eller ordreansvarlig redigere handlekurver. Vent til gruppebestillingen låses opp igjen for å gjøre endringer, eller la ordreansvarlig eller selger ta seg av saken.",
                };
        }
    }

    render() {
        if (this.shouldRender()) {
            const statusMessage = this.getStatusMessage();

            return (
                <div className="row justify-content-center align-items-center pt-2 pb-4 mb-2">
                    <div className="col-12 col-md-10 col-lg-8">
                        <h2 className="text-center">
                            <span className="h3">
                                “{this.props.group.group.name}”
                            </span>
                            <span className="d-block">
                                {statusMessage.title}
                            </span>
                        </h2>

                        <p className="text-center mt-4 mb-2">
                            {statusMessage.text}
                        </p>

                        <div className="text-center mt-4">
                            <a
                                href={config.feedUrl}
                                className="btn btn-outline-secondary m-1">
                                Tilbake til russ.no
                            </a>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}
