import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from 'prop-types';

// Actions
import { getPlacementTypes } from "../Actions/GroupLogoActions";
import { getAllProducts } from "../Actions/ProductsActions";

// Components
import AdminLayout from "../Components/Admin/AdminLayout";
import PlacementLoad from "../LogoPlacer/PlacementLoad";
import LogoPlacerScript from "../LogoPlacer/LogoPlacerScript";
import Subheader from "../Components/Common/Subheader";

// Helpers
import { TitleHelper } from "../Helpers";

class AdminPlacementCreator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scriptLoaded: false,
            scriptError: null,
        };
    }

    componentDidMount() {
        if (this.props.products.allProductsFetching !== false) {
            this.props.getAllProducts();
        }

        if (this.props.groupLogo.placementTypesFetching !== false) {
            this.props.getPlacementTypes();
        }

        TitleHelper.setTitle("Admin - Logoplassering");
    }

    handleScriptCreate() {
        this.setState({ scriptLoaded: false });
    }

    handleScriptError() {
        this.setState({ scriptError: true });
    }

    handleScriptLoad() {
        this.setState({ scriptLoaded: true });
    }

    renderPlacementCreator() {
        // Echo possible error messages or that there is no dataz etc
        if (
            this.props.products.groupProductsError ||
            this.props.groupLogo.placementTypesError
        )
            return null; // <SectionError />;

        // Echo appshell while loading dataz
        if (
            !this.state.scriptLoaded ||
            this.props.products.allProductsFetching === true ||
            this.props.groupLogo.placementTypesFetching === true
        )
            return <div className="col">Laster plasseringer...</div>;

        // Fetching is done and we got dataz
        if (
            this.state.scriptLoaded &&
            this.props.products.allProductsFetching === false &&
            this.props.products.allProducts.length &&
            this.props.groupLogo.placementTypesFetching === false &&
            this.props.groupLogo.placementTypes.length
        ) {
            return <PlacementLoad {...this.props} />;
        }
    }

    // TODO: Restrict placementCreator to Admin role only
    render() {
        return (
            <AdminLayout {...this.props}>
                <div className="products container">
                    <Subheader
                        linkTo={`/images`}
                        linkName="Tilbake til produktbilder"
                    />
                    <div className="row mb-4">
                        <LogoPlacerScript
                            handleScriptCreate={this.handleScriptCreate.bind(
                                this
                            )}
                            handleScriptError={this.handleScriptError.bind(
                                this
                            )}
                            handleScriptLoad={this.handleScriptLoad.bind(this)}
                        />

                        {this.renderPlacementCreator()}
                    </div>
                </div>
            </AdminLayout>
        );
    }
}

AdminPlacementCreator.propTypes = {};
AdminPlacementCreator.defaultProps = {};

function mapStateToProps(state) {
    return {
        products: state.products,
        groupLogo: state.groupLogo,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    { getPlacementTypes, getAllProducts }
)(AdminPlacementCreator);
