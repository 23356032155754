import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { getCart } from "../../Actions/GroupCartActions";

// Components
import Carts from "./Carts";

// Helpers
import { GroupHelper } from "../../Helpers";

class OrderGroup extends Component {
    componentDidMount() {
        this.props.getCart(this.props.match.params.id);
    }

    render() {
        const order = GroupHelper.getCurrentOrder(this.props.group.group);

        // Echo possible error messages or that there is no dataz etc
        if (this.props.cart.cartFetching) return null;

        // Echo appshell while loading dataz
        if (this.props.cart.cartFetching === true)
            return <div className="col">Laster oppsummering...</div>;

        // Fetching is done and we got dataz
        if (this.props.cart.cartFetching === false) {
            const { group, cart } = this.props;

            return (
                <div className="mb-3">
                    <div className="mb-3">
                        <h3>Detaljer</h3>
                    </div>
                    <Carts
                        match={this.props.match}
                        user={this.props.user}
                        group={group}
                        order={order}
                        cart={cart.cart}
                    />
                </div>
            );
        }

        return null;
    }
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        errors: state.errors,
    };
}
export default connect(
    mapStateToProps,
    { getCart }
)(OrderGroup);
