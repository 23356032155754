let assign = Object.assign || require("object.assign");

const DEFAULT_STATE = {
    notifications: [],
    notificationsFetching: null,
    notificationsPosting: null,
    notificationsError: null,
};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case "GET_NOTIFICATIONS":
            return assign({}, state, {
                notifications: action.payload,
                notificationsFetching: false,
            });

        case "GET_NOTIFICATIONS_FETCHING":
            return assign({}, state, {
                notificationsFetching: action.payload,
                notificationsError: null,
            });

        case "GET_NOTIFICATIONS_ERROR":
            return assign({}, state, {
                notificationsError: action.payload,
                notifications: null,
                notificationsFetching: null,
            });

        case "ADD_NOTIFICATION":
            return assign({}, state, {
                notifications: action.payload,
                notificationsPosting: false,
            });

        case "ADD_NOTIFICATION_POSTING":
            return assign({}, state, {
                notificationsPosting: action.payload,
                notificationsError: null,
            });

        case "ADD_NOTIFICATION_ERROR":
            return assign({}, state, {
                notificationsError: action.payload,
                notifications: null,
                notificationsPosting: null,
            });

        case "EDIT_NOTIFICATION":
            return assign({}, state, {
                notifications: action.payload,
                notificationsPosting: false,
            });

        case "EDIT_NOTIFICATION_POSTING":
            return assign({}, state, {
                notificationsPosting: action.payload,
                notificationsError: null,
            });

        case "EDIT_NOTIFICATION_ERROR":
            return assign({}, state, {
                notificationsError: action.payload,
                notifications: null,
                notificationsPosting: null,
            });

        case "DELETE_NOTIFICATION":
            return assign({}, state, {
                notifications: action.payload,
                notificationsPosting: false,
            });

        case "DELETE_NOTIFICATION_POSTING":
            return assign({}, state, {
                notificationsPosting: action.payload,
                notificationsError: null,
            });

        case "DELETE_NOTIFICATION_ERROR":
            return assign({}, state, {
                notificationsError: action.payload,
                notifications: null,
                notificationsPosting: null,
            });

        default:
            return state;
    }
}
