import React, { Component } from "react";
import { connect } from "react-redux";
import { Nav, NavItem, NavLink } from "reactstrap";
import PropTypes from "prop-types";
import classnames from "classnames";

// Actions
import {
    getGroupProducts,
    getGroupManageProducts,
} from "../../Actions/ProductsActions";
import { setSettings } from "../../Actions/GroupSettingsActions";

// Components
import ProductSmallAppshell from "../Products/ProductSmallAppshell";
import SettingsTabs from "./SettingsTabs";
import Restrict from "../../Auth/Restrict";

// Helpers
import { Seller } from "../../Auth/UserRoles";
import { GroupHelper } from "../../Helpers";

class SettingsProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "visibleProducts",
            showProductImages: true,
        };

        this.toggleSetting = this.toggleSetting.bind(this);
        this.toggleProductImages = this.toggleProductImages.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
    }

    componentDidMount() {
        const { group } = this.props.group;

        this.props.getGroupProducts(group.id);
        this.props.getGroupManageProducts(group.id);
    }

    toggleProductImages() {
        this.setState({
            showProductImages: !this.state.showProductImages,
        });
    }

    toggleSetting(settingName) {
        const { group } = this.props.group;

        this.props.setSettings({
            groupId: group.id,
            type: settingName,
            value: !GroupHelper.getNormalizedSetting(this.props, settingName),
        });
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    renderProducts() {
        const { products } = this.props;

        // Echo generic error message
        if (products.groupProductsError || products.groupManageProductsError)
            return (
                <div className="alert alert-danger my-3">
                    Kunne ikke laste produkter...
                </div>
            );

        // Echo appshell while loading
        if (
            products.groupProductsFetching === true ||
            products.groupManageProductsFetching === true
        )
            return <ProductSmallAppshell />;

        // Fetching is done
        if (
            products.groupProductsFetching === false &&
            products.groupProducts.length &&
            products.groupManageProductsFetching === false &&
            products.groupManageProducts.length
        ) {
            return (
                <SettingsTabs
                    {...this.props}
                    activeTab={this.state.activeTab}
                    showProductImages={this.state.showProductImages}
                    showPrice={GroupHelper.getNormalizedSetting(
                        this.props,
                        "CanDisplayPrices"
                    )}
                />
            );
        }
    }

    render() {
        return (
            <>
                <div className="row mb-2">
                    <div className="col">
                        <h2 id="produkter">Produkter</h2>
                        <p>
                            Dra og slipp produkter for å endre rekkefølgen de
                            vises i på "Bestilling"-siden.
                            <br />
                            Kun synlige produkter vises på "Bestilling"-siden.
                        </p>
                    </div>
                </div>

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active:
                                    this.state.activeTab === "visibleProducts",
                            })}
                            onClick={() => {
                                this.toggleTab("visibleProducts");
                            }}>
                            Synlige produkter
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active:
                                    this.state.activeTab === "hiddenProducts",
                            })}
                            onClick={() => {
                                this.toggleTab("hiddenProducts");
                            }}>
                            Skjulte produkter
                        </NavLink>
                    </NavItem>

                    {/* <Restrict {...this.props} allow={Seller}>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active:
                                        this.state.activeTab === "allProducts",
                                })}
                                onClick={() => {
                                    this.toggleTab("allProducts");
                                }}>
                                Alle produkter
                            </NavLink>
                        </NavItem>
                    </Restrict> */}
                </Nav>

                <div className="tabsettings bg-white p-3 border-left border-bottom border-right">
                    <div className="row">
                        <div className="col-12 col-md-auto">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="showProductImages"
                                    onChange={this.toggleProductImages}
                                    defaultChecked
                                />

                                <label
                                    className="form-check-label"
                                    htmlFor="showProductImages">
                                    Vis produktbilder{" "}
                                    <small>(Kun denne siden)</small>
                                </label>
                            </div>
                        </div>

                        <Restrict {...this.props} allow={Seller}>
                            <div className="col-12 col-md-auto">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="showPrice"
                                        onChange={() =>
                                            this.toggleSetting(
                                                "CanDisplayPrices"
                                            )
                                        }
                                        disabled={GroupHelper.disableWhenLocked(
                                            this.props,
                                            ["Seller"]
                                        )}
                                        defaultChecked={GroupHelper.getNormalizedSetting(
                                            this.props,
                                            "CanDisplayPrices"
                                        )}
                                    />

                                    <label
                                        className="form-check-label"
                                        htmlFor="showPrice">
                                        Vis priser{" "}
                                        <small>(Hele bestillingen)</small>
                                    </label>
                                </div>
                            </div>
                        </Restrict>

                        <Restrict {...this.props} allow={Seller}>
                            <div className="col-12 col-md-auto">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="groupManagerProducts"
                                        onChange={() =>
                                            this.toggleSetting(
                                                "CanManagerControlProductSelection"
                                            )
                                        }
                                        disabled={GroupHelper.disableWhenLocked(
                                            this.props,
                                            ["Seller"]
                                        )}
                                        defaultChecked={GroupHelper.getNormalizedSetting(
                                            this.props,
                                            "CanManagerControlProductSelection"
                                        )}
                                    />

                                    <label
                                        className="form-check-label"
                                        htmlFor="groupManagerProducts">
                                        Ordreansvarlig kan skjule produkter
                                    </label>
                                </div>
                            </div>
                        </Restrict>

                        <Restrict {...this.props} allow={Seller}>
                            <div className="col-12 col-md-auto">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="canDisplayLogo"
                                        onChange={() =>
                                            this.toggleSetting("CanDisplayLogo")
                                        }
                                        disabled={GroupHelper.disableWhenLocked(
                                            this.props,
                                            ["Seller"]
                                        )}
                                        defaultChecked={GroupHelper.getNormalizedSetting(
                                            this.props,
                                            "CanDisplayLogo"
                                        )}
                                    />

                                    <label
                                        className="form-check-label"
                                        htmlFor="canDisplayLogo">
                                        Vis logo på plagg{" "}
                                        <small>(Hele bestillingen)</small>
                                    </label>
                                </div>
                            </div>
                        </Restrict>
                    </div>
                </div>

                {this.renderProducts()}
            </>
        );
    }
}

SettingsProducts.propTypes = {
    updateSettingsProducts: PropTypes.func.isRequired,
};

SettingsProducts.defaultProps = {
    updateSettingsProducts: () => null,
};

function mapStateToProps(state) {
    return {
        group: state.group,
        products: state.products,
    };
}
export default connect(
    mapStateToProps,
    {
        setSettings,
        getGroupProducts,
        getGroupManageProducts,
    }
)(SettingsProducts);
