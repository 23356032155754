import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from 'prop-types';

// Components
import DataLayerGroup from "./DataLayerGroup";
import Layout from "../Components/Group/Layout";
import AdditionItemAppshell from "../Components/Additions/AdditionItemAppshell";
import Additions from "../Components/Additions/Additions";

// Helpers
import { TitleHelper } from "../Helpers";

class GroupAdditions extends Component {
    componentDidMount() {
        TitleHelper.setTitle("Tilleggsprodukter");
    }

    renderAdditions() {
        // Could not find group ID or maybe API is dead
        if (this.props.group.groupError) {
            return null;
        }

        // App is currently trying to fetch data, please serve appshell
        if (this.props.group.groupFetching === true) {
            return <AdditionItemAppshell />;
        }

        // App has successfully finished fetching
        if (this.props.group.groupFetching === false) {
            return <Additions {...this.props} />;
        }
    }

    render() {
        return (
            <DataLayerGroup {...this.props}>
                <Layout {...this.props}>
                    <div className="container">{this.renderAdditions()}</div>
                </Layout>
            </DataLayerGroup>
        );
    }
}

GroupAdditions.propTypes = {};
GroupAdditions.defaultProps = {};

function mapStateToProps(state) {
    return {
        group: state.group,
        additions: state.additions,
        errors: state.errors,
    };
}
export default connect(mapStateToProps)(GroupAdditions);
