export function createError(error, info) {
    let customError = new Error(error);
    let errorInfo = { data: { Message: info } };
    customError.info = errorInfo;

    return customError;
}

export function removeError(payload) {
    return dispatch => {
        // Set fetching true
        dispatch({ type: "REMOVE_ERROR", index: payload });
    };
}
