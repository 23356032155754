import React from "react";
import AppShell from "../AppShell/AppShell";
import { Number } from "../../Helpers";

export default class LogItemAppshell extends AppShell {
    renderShell(i) {
        return (
            <div className="shell col-12" key={`shell-${i}`}>
                <div className="card mb-2">
                    <div className="card-body p-3">
                        <div className="row">
                            <div className="col">
                                <span
                                    className="shell__text"
                                    style={{
                                        "--width": Number.random(30, 70) + "%",
                                    }}
                                />
                            </div>

                            <div className="col-auto">
                                <small className="shell__text" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return <>{this.createAppShell(10)}</>;
    }
}
