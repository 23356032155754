import Axios from "axios";
import { authHeader } from "././AuthenticationActions";
//import { config } from "../Helpers/Config";

export function getOnboardingFetching(payload) {
    return {
        type: "GET_ONBOARDING_FETCHING",
        payload,
    };
}

export function getOnboarding(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // GET fetching true
        dispatch(getOnboardingFetching(true));

        instance
            .get(`https://jsonplaceholder.typicode.com/posts`)
            .then(response => {
                dispatch({
                    type: "GET_ONBOARDING",
                    // payload: response.data
                    payload: dummyData,
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: "GET_ONBOARDING_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export const dummyData = {
    onboardingVersion: 1,
    messages: [
        {
            title: "Velkommen til din gruppebestilling",
            text:
                "Er du klar til russetiden? Her får du noen enkle tips og triks på hvordan dere kan forberede gruppen deres til en enkel og rask bestillingsprosess.",
        },
        {
            title: "1. Ordreansvarlig klargjør bestillingen",
            text:
                'I første omgang er bestillingen kun åpen for ordreansvarlig. Den som har rollen som ordreansvarlig har tilgang til å endre på enkelte innstillinger for gruppen, velge produkter til alle medlemmer, samt fellesprodukter. Når gruppen er klar til å velge sine produkter klikker ordreansvarlig på knappen "Åpne bestilling"',
            image: {
                src:
                    "https://russedress.imgix.net/gruppe/Forsiden/bestilldinegruppekler.jpg?w=740&fit=clip&q=75&fm=jpg",
                alt: "Bestill dine gruppeklær",
            },
        },
        {
            title: "2. Medlemmer bestiller produkter",
            text:
                'Når bestillingen er "Åpen" er det fritt frem for alle å legge til flere produkter samt endre størrelser og navn på navntags. Husk å ta kontakt med ordreansvarlig om det er noe dere lurer på! Når gruppen anser seg som ferdige med bestillingen har ordreansvarlig ansvar for å sende bestillingen til godkjennelse av selger.',
            image: {
                src:
                    "https://russedress.imgix.net/gruppe/produkter/custom.jpg?w=740&fit=clip&q=75&fm=jpg",
                alt: "Bestill dine gruppeklær",
            },
        },
        {
            title: "3. Selger godkjenner bestillingen",
            text:
                "Når bestillingen er sendt til godkjennelse av selger kan prisene endres, samt nye produkter og rabatter legges til. Pass derfor på at ordreansvarlig ser skikkelig gjennom ordren når den godkjennes – en godkjent ordre må deretter sendes til produksjon av ordreansvarlig. Dersom bestillingen har mangler blir den sendt tilbake til ordreansvarlig for utbedring.",
            image: {
                src:
                    "https://russedress.imgix.net/gruppe/produkter/spesialfarger.jpg?w=740&fit=clip&q=75&fm=jpg",
                alt: "Gensere i mange farger",
            },
        },
    ],
};
