import React, { Component } from "react";
import { Button, Collapse } from "reactstrap";

// Components
import ImagesItem from "./ImagesItem";

export default class ImagesUnit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };

        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    toggleCollapse() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    render() {
        const { product, unitGroup } = this.props;
        return (
            <div className="col-12 mb-3 pt-3 border-top">
                <div className="row no-gutters">
                    <h4 className="col-12 h5">{unitGroup.color.name}</h4>

                    <ImagesItem
                        product={product}
                        item={product}
                        group={unitGroup}
                        showImagesSortedInColors
                    />

                    <Button
                        color="secondary"
                        onClick={this.toggleCollapse}
                        size="sm"
                        outline
                        className="mb-2">
                        {this.state.isOpen
                            ? "Skjul enkeltenheter"
                            : "Vis enkeltenheter"}
                    </Button>

                    <Collapse className="w-100" isOpen={this.state.isOpen}>
                        <div className="py-3 mt-2 bg-light">
                            <div className="mx-3 alert alert-warning">
                                Merk! Bilder til enkeltenheter vises ikke for
                                kunder. Last opp bilder til en produktfarge for
                                å endre hvilke bilder som vises.
                            </div>
                            {unitGroup.units && unitGroup.units.length
                                ? unitGroup.units.map(unit => (
                                      <React.Fragment key={unit.id}>
                                          <h5 className="col-12 h6 font-weight-bold">
                                              {unit.color.name} {unit.valueName}{" "}
                                          </h5>

                                          <ImagesItem
                                              product={product}
                                              item={unit}
                                              isUnit
                                              showImagesSortedInColors
                                          />
                                      </React.Fragment>
                                  ))
                                : null}
                        </div>
                    </Collapse>
                </div>
            </div>
        );
    }
}
