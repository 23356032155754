import Axios from "axios";
import { authHeader } from "././AuthenticationActions";
import { config } from "../Helpers/Config";
import { createError } from "./ErrorsActions";

export function getGroupProductsFetching(payload) {
    return {
        type: "GET_GROUP_PRODUCTS_FETCHING",
        payload,
    };
}

export function getGroupProducts(groupId) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getGroupProductsFetching(true));

        instance
            .get(`${config.apiUrl}/groups/${groupId}/products`)
            .then(response => {
                dispatch({
                    type: "GET_GROUP_PRODUCTS",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log("Get group products error", error);
                dispatch({
                    type: "GET_GROUP_PRODUCTS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function getGroupManageProductsFetching(payload) {
    return {
        type: "GET_GROUP_MANAGE_PRODUCTS_FETCHING",
        payload,
    };
}

export function getGroupManageProducts(groupId) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getGroupManageProductsFetching(true));

        instance
            .get(`${config.apiUrl}/groups/${groupId}/products/manage`)
            .then(response => {
                dispatch({
                    type: "GET_GROUP_MANAGE_PRODUCTS",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log("Get group MANAGE products error", error);
                dispatch({
                    type: "GET_GROUP_MANAGE_PRODUCTS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function manageProductPosting(payload) {
    return {
        type: "MANAGE_PRODUCT_POSTING",
        payload,
    };
}

export function manageProduct(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(manageProductPosting(true));

        instance
            .post(
                `${config.apiUrl}/groups/${payload.groupId}/products/manage/${
                    payload.productId
                }`,
                {
                    minimumQty: payload.minimumQty,
                    isHidden: payload.isHidden,
                    sortOrder: payload.sortOrder,
                    displayTextValue: payload.displayTextValue,
                    units: payload.units,
                }
            )
            .then(response => {
                dispatch({
                    type: "MANAGE_PRODUCT",
                    payload: response.data,
                    productGroupId: payload.productGroupId,
                });
            })
            .catch(error => {
                // Handle manage product visibility error
                if (
                    error.response &&
                    error.response.status &&
                    error.response.status === 400
                ) {
                    dispatch({
                        type: "MANAGE_PRODUCT_ERROR",
                        payload: error,
                    });

                    dispatch({
                        type: "ADD_ERROR",
                        error: createError(
                            "Kan ikke skjule et produkt som finnes i handlekurven",
                            "Fjern produktet fra handlekurven og prøv igjen."
                        ),
                    });
                } else {
                    dispatch({
                        type: "MANAGE_PRODUCT_ERROR",
                        payload: error,
                    });

                    // Dispatch generic error to the errorHandler
                    dispatch({ type: "ADD_ERROR", error: error });
                }
            });
    };
}

export function manageSortOrderPosting(payload) {
    return {
        type: "MANAGE_SORT_ORDER_POSTING",
        payload,
    };
}

export function manageSortOrder(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(manageSortOrderPosting(true));

        instance
            .post(
                `${config.apiUrl}/groups/${
                    payload.groupId
                }/products/manage/sort-order`,
                {
                    products: payload.products,
                    units: payload.units,
                }
            )
            .then(response => {
                dispatch({
                    type: "MANAGE_SORT_ORDER",
                    payload: response.data,
                    productGroupId: payload.productGroupId,
                });
            })
            .catch(error => {
                console.log("Manage sortOrder error", error);
                dispatch({
                    type: "MANAGE_SORT_ORDER_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function getAllProductsFetching(payload) {
    return {
        type: "GET_ALL_PRODUCTS_FETCHING",
        payload,
    };
}

export function getAllProducts() {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getAllProductsFetching(true));

        instance
            .get(`${config.apiUrl}/products`)
            .then(response => {
                dispatch({
                    type: "GET_ALL_PRODUCTS",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log("Get products error", error);
                dispatch({
                    type: "GET_ALL_PRODUCTS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function getProductUnitsFetching(payload) {
    return {
        type: "GET_PRODUCT_UNITS_FETCHING",
        payload,
    };
}

export function getProductUnits(productId) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getProductUnitsFetching(true));

        instance
            .get(`${config.apiUrl}/products/${productId}`)
            .then(response => {
                dispatch({
                    type: "GET_PRODUCT_UNITS",
                    payload: { productId: productId, data: response.data },
                });
            })
            .catch(error => {
                console.log("Get product units error", error);
                dispatch({
                    type: "GET_PRODUCT_UNITS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function getGroupProductFetching(payload) {
    return {
        type: "GET_GROUP_PRODUCT_FETCHING",
        payload,
    };
}

// Gets a specific group product and swaps it out with the old one in groupProducts on success
export function getGroupProduct(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set fetching true
        dispatch(getGroupProductFetching(true));

        instance
            .get(
                `${config.apiUrl}/groups/${payload.groupId}/products/${
                    payload.productId
                }`
            )
            .then(response => {
                console.log("Got the  group product", response);
                dispatch({
                    type: "GET_GROUP_PRODUCT",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log("Get group product error", error);
                dispatch({
                    type: "GET_GROUP_PRODUCT_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}
