import React, { Component } from "react";
import { connect } from "react-redux";
//import PropTypes from "prop-types";

// Components
import AdminLayout from "../Components/Admin/AdminLayout";
//import PageError from "./PageError";

class AdminReport extends Component {
    renderSections() {
        // Echo possible error messages or that there is no dataz etc
        if (this.props.groups.groupsError) return; //<PageError {...this.props} />;

        // Echo appshell while loading dataz
        if (this.props.groups.groupsFetching === true) return null;

        // Fetching is done and we got dataz
        if (
            this.props.groups.groupsFetching === false &&
            this.props.groups.groups.length
        ) {
            return null;
        }
    }

    render() {
        return (
            <AdminLayout {...this.props}>
                <div className="container">{this.renderSections()}</div>
            </AdminLayout>
        );
    }
}

AdminReport.propTypes = {};

AdminReport.defaultProps = {};

function mapStateToProps(state) {
    return {
        errors: state.errors,
        groups: state.groups,
    };
}
export default connect(
    mapStateToProps,
    {}
)(AdminReport);
