import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap";
import Logo from "../Common/Logo";
import Icon from "../Common/Icon";
import Restrict from "../../Auth/Restrict";

// Helpers
import { Manager, Seller } from "../../Auth/UserRoles";
import { GroupHelper, config } from "../../Helpers";

class Navigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.toggleNavbar = this.toggleNavbar.bind(this);
    }

    toggleNavbar() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    render() {
        const { group } = this.props.group;
        const hasTrackableOrders = GroupHelper.hasTrackableOrder(group);

        return (
            <div className="container container--navbar container-sticky--md">
                <Navbar
                    className="bg-white border mb-3 p-3"
                    color="light"
                    light
                    expand="lg">
                    <a href={config.feedUrl} className="logo">
                        <div className="logo__inner col-8 pl-0">
                            <Logo
                                className="logo__image"
                                name={group.shop.name}
                            />
                        </div>
                    </a>

                    <div className="navbar__header d-flex justify-content-between">
                        <Link
                            className={`ml-sm-auto mr-2 d-flex d-lg-none align-items-center btn ${
                                this.props.match.path ===
                                "/group/:id/products"
                                    ? "btn-outline-primary"
                                    : "btn-outline-dark"
                            }`}
                            to={`/group/${group.id}/products`}>
                                <Icon
                                    name="shopping_cart"
                                    fill="currentColor"
                                    title={this.props.text.products}
                                    className="mr-1"
                                />
                            {this.props.text.products} 
                        </Link>

                        <NavbarToggler onClick={this.toggleNavbar}>
                            <span className="navbar-toggler__title d-none d-md-inline-block">
                                {this.props.text.menu}
                            </span>
                            <span className="navbar-toggler-icon" />
                        </NavbarToggler>
                    </div>

                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav
                            className="navbar-nav w-100 justify-content-end"
                            navbar>

                            <NavItem
                                className={`d-none d-lg-block ${
                                    this.props.match.path ===
                                    "/group/:id/products"
                                        ? "nav-item active"
                                        : "nav-item"
                                }`}>
                                <Link
                                    className={`d-flex align-items-center btn ${
                                        this.props.match.path ===
                                        "/group/:id/products"
                                            ? "btn-outline-primary"
                                            : "btn-outline-dark"
                                    }`}
                                    to={`/group/${group.id}/products`}>
                                        <Icon
                                            name="shopping_cart"
                                            fill="currentColor"
                                            title={this.props.text.products}
                                            className="mr-1"
                                        />
                                    {this.props.text.products} 
                                </Link>
                            </NavItem>

                            <Restrict {...this.props} allow={Manager}>
                                <NavItem
                                    className={
                                        this.props.match.path ===
                                        "/group/:id/overview"
                                            ? "nav-item active"
                                            : "nav-item"
                                    }>
                                    <Link
                                        className="nav-link"
                                        to={`/group/${group.id}/overview`}>
                                        {this.props.text.overview}
                                    </Link>
                                </NavItem>
                            </Restrict>

                            <Restrict {...this.props} allow={Manager}>
                                <NavItem
                                    className={
                                        this.props.match.path ===
                                        "/group/:id/settings"
                                            ? "nav-item active"
                                            : "nav-item"
                                    }>
                                    <Link
                                        className="nav-link"
                                        to={`/group/${group.id}/settings`}>
                                        {this.props.text.settings}
                                    </Link>
                                </NavItem>
                            </Restrict>

                            <Restrict {...this.props} allow={Seller}>
                                <NavItem
                                    className={
                                        this.props.match.path ===
                                        "/group/:id/additions"
                                            ? "nav-item active"
                                            : "nav-item"
                                    }>
                                    <Link
                                        className="nav-link"
                                        to={`/group/${group.id}/additions`}>
                                        {this.props.text.additions}
                                    </Link>
                                </NavItem>
                            </Restrict>

                            {hasTrackableOrders ? (
                                <NavItem
                                    className={
                                        this.props.match.path ===
                                        "/group/:id/tracking"
                                            ? "nav-item active"
                                            : "nav-item"
                                    }>
                                    <Link
                                        className="nav-link"
                                        to={`/group/${group.id}/tracking`}>
                                        {this.props.text.tracking}
                                    </Link>
                                </NavItem>
                            ) : null}

                            <NavItem
                                className={
                                    this.props.match.path ===
                                    "/group/:id/members"
                                        ? "nav-item active"
                                        : "nav-item"
                                }>
                                <Link
                                    className="nav-link"
                                    to={`/group/${group.id}/members`}>
                                    {this.props.text.members}
                                </Link>
                            </NavItem>

                            <Restrict {...this.props} allow={Manager}>
                                <NavItem
                                    className={
                                        this.props.match.path ===
                                        "/group/:id/log"
                                            ? "nav-item active"
                                            : "nav-item"
                                    }>
                                    <Link
                                        className="nav-link"
                                        to={`/group/${group.id}/log`}>
                                        {this.props.text.log}
                                    </Link>
                                </NavItem>
                            </Restrict>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}

Navigation.propTypes = {
    text: PropTypes.shape({
        menu: PropTypes.string,
        overview: PropTypes.string,
        settings: PropTypes.string,
        additions: PropTypes.string,
        products: PropTypes.string,
        members: PropTypes.string,
        help: PropTypes.string,
        tracking: PropTypes.string,
        log: PropTypes.string,
    }),
};
Navigation.defaultProps = {
    text: {
        menu: "Meny",
        overview: "Oversikt",
        settings: "Innstillinger",
        additions: "Tillegg",
        products: "Bestilling",
        members: "Medlemmer",
        help: "Hjelp",
        tracking: "Sporing",
        log: "Logg",
    },
};

export default Navigation;
