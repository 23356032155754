let assign = Object.assign || require("object.assign");

const DEFAULT_STATE = {
    user: {},
    userFetching: null,
    userPosting: null,
    userError: null,
};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case "SET_USER_GROUP_ROLE":
            // Set the user role based on their role in the group
            let userObject = state.user;
            let foundCustomer = action.payload.customers.find(
                customer => customer.customer.id === state.user.id
            );

            if (foundCustomer && foundCustomer.customerType) {
                userObject.role = foundCustomer.customerType.name;
            }

            return assign({}, state, {
                user: userObject,
                userPosting: false,
            });

        case "SET_USER_GROUP_ROLE_ERROR":
            return assign({}, state, {
                userError: action.payload,
                user: null,
                userPosting: null,
            });

        case "SET_USER":
            // Set the role prop
            if (action.payload.customerTypeName && !action.payload.role) {
                action.payload.role = action.payload.customerTypeName;
            }

            return assign({}, state, {
                user: action.payload,
                userPosting: false,
            });

        case "SET_USER_POSTING":
            return assign({}, state, {
                userPosting: action.payload,
                userError: null,
            });

        case "SET_USER_ERROR":
            return assign({}, state, {
                userError: action.payload,
                user: null,
                userPosting: null,
            });

        case "GET_USER":
            let userPayload;
            let groupsPayload;

            action.payload.forEach(response => {
                if (Array.isArray(response)) {
                    groupsPayload = response;
                } else {
                    userPayload = response;
                }
            });

            // Set the role prop
            if (userPayload.customerTypeName && !userPayload.role) {
                userPayload.role = userPayload.customerTypeName;
            }

            userPayload.groups = groupsPayload;

            return assign({}, state, {
                user: userPayload,
                userFetching: false,
            });

        case "GET_USER_FETCHING":
            return assign({}, state, {
                userFetching: action.payload,
                userError: null,
            });

        case "GET_USER_ERROR":
            return assign({}, state, {
                userError: action.payload,
                user: null,
                userFetching: null,
            });

        default:
            return state;
    }
}
