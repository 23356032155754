import React, { Component } from "react";
import PropTypes from "prop-types";
import Badge from "../Common/Badge";
import LazyImage from "../Common/LazyImage";
import { GroupHelper, ImageHelper } from "../../Helpers";

class Product extends Component {
    renderStock(className = "") {
        const { product } = this.props;
        if (
            !this.unitsHaveStock() &&
            typeof product.unitsStockQtyLeft !== "undefined" &&
            product.unitsStockQtyLeft !== 0
        ) {
            return (
                <Badge className={className} statusCode="warning">
                    Få produkter
                </Badge>
            );
        } else if (
            typeof product.unitsStockQtyLeft !== "undefined" &&
            product.unitsStockQtyLeft <= 0
        ) {
            return (
                <Badge className={className} statusCode="danger">
                    Utsolgt
                </Badge>
            );
        }
    }

    hasStock() {
        const { product } = this.props;
        if (
            typeof product.unitsStockQtyLeft !== "undefined" &&
            product.unitsStockQtyLeft > 0
        ) {
            return true;
        } else {
            return false;
        }
    }

    unitsHaveStock(visibleOnly = true) {
        const { product } = this.props;
        const units = product.units;

        if (
            (visibleOnly &&
                units
                    .filter(unit => unit.isHidden === false)
                    .every(unit => unit.stockQtyLeft >= 10)) ||
            units.every(unit => unit.stockQtyLeft >= 10)
        ) {
            return true;
        } else {
            return false;
        }
    }

    getImageAlt(image) {
        const { product } = this.props;

        if (image.alt && typeof image.alt !== "number") {
            return image.alt;
        } else if (product.name && product.color.name) {
            return `${product.name} ${product.color.name}`;
        } else if (product.name) {
            return product.name;
        } else if (image.type) {
            return image.type;
        }

        return "";
    }

    /**
     * Selects which "item" to render images from
     * Try to get any matching product.imagesSortedInColors, then fall back to product images
     */
    getImageItem() {
        const { product } = this.props;
        let item = product;

        if (
            product.units &&
            product.units.length &&
            product.imagesSortedInColors
        ) {
            const foundColorImageObject = product.imagesSortedInColors.find(
                o => o.color.id === product.units[0].color.id
            );

            if (
                foundColorImageObject &&
                foundColorImageObject.images &&
                foundColorImageObject.images.length
            ) {
                // Found match
                item = foundColorImageObject;
            }
        }

        // Keep images with no color object (these are primary product / fallback images not tied to a unit)
        // Keep images with color object matching the first unit
        if (
            item.images &&
            item.images.length &&
            product.units &&
            product.units.length
        ) {
            item.images = item.images.filter(
                image =>
                    !image.color ||
                    (image.color &&
                        image.color.id === product.units[0].color.id)
            );
        }

        return item;
    }

    renderImages(width, height, maxImages = 2) {
        const item = this.getImageItem();
        const images = ImageHelper.sortImages(item);

        if (item && images && images.length) {
            const itemImages = images.slice(0, maxImages);

            let size = {
                width,
                height,
            };

            if (itemImages.length === 2) {
                size = {
                    width: width / 2,
                    height,
                };
            }

            return itemImages.map(image => (
                <div className="col" key={`image-${image.id}`}>
                    <LazyImage
                        src={image.imgIxUrl ? image.imgIxUrl : image.url}
                        alt={this.getImageAlt(image)}
                        width={size.width}
                        height={size.height}
                        className="img-fluid"
                        productImage
                    />
                </div>
            ));
        }
    }

    renderName(includePrice = true) {
        const { product } = this.props;
        return (
            <>
                <span className="font-weight-bold">{`${product.name} `}</span>
                <span className="font-weight-bold">{`${product.color.name} `}</span>

                {this.renderPrice(includePrice)}
            </>
        );
    }

    renderPrice(includePrice = true) {
        const { product } = this.props;
        const representingAllUnits =
            product.units && product.units.length ? product.units[0] : null;
        const price = representingAllUnits ? representingAllUnits.price : null;

        return (
            <>
                {includePrice &&
                GroupHelper.getNormalizedSetting(
                    this.props,
                    "CanDisplayPrices"
                ) ? (
                    <span className="price">
                        {price ? price : product.price},-
                    </span>
                ) : null}
            </>
        );
    }
}

Product.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        color: PropTypes.object.isRequired,
        images: PropTypes.array,
        isHidden: PropTypes.bool,
        stock_low_warning: PropTypes.number,
        nametag: PropTypes.object,
        units: PropTypes.array,
        name_short: PropTypes.string,
        discounts: PropTypes.object,
        logo: PropTypes.object,
    }),
    renderPrice: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
};

Product.defaultProps = {
    stock_low_warning: 10,
    renderPrice: true,
    disabled: false,
};

export default Product;
