import {Component} from 'react';

export default class AppShell extends Component {

    createAppShell(itemCount) {
        let shell = [];

        for(let i = 0; i < itemCount; i++) {
            shell.push(this.renderShell(i));
        }

        return shell;
    }
}