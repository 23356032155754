import React, { Component } from "react";
import { Collapse } from "reactstrap";

export default class BigDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = { collapse: false };

        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {}

    toggle() {
        this.setState(state => ({ collapse: !state.collapse }));
    }

    handleChange(e) {
        this.toggle();
        this.props.onChange(e);
    }

    render() {
        const { items, value, valueText } = this.props;
        return (
            <div>
                <label
                    className="d-block my-1 mr-2 font-weight-bold"
                    htmlFor="changeStatus">
                    Ordrestatus
                </label>
                <button
                    type="button"
                    className="form-control custom-select d-inline-flex justify-content-between align-items-center mb-2"
                    onClick={this.toggle}>
                    {valueText}
                </button>
                <Collapse isOpen={this.state.collapse}>
                    {items && items.length
                        ? items.map(item => (
                              <div
                                  key={item.statusCode}
                                  className={`card mb-2 ${
                                      value === item.statusCode
                                          ? "border-primary"
                                          : ""
                                  } ${
                                      item.attr && item.attr.disabled
                                          ? "bg-light"
                                          : ""
                                  }`}>
                                  <label
                                      className="card-body d-flex flex-column p-2 mb-0"
                                      style={{ cursor: "pointer" }}
                                      {...item.attr}>
                                      <div className="custom-control custom-radio custom-control-inline mb-1">
                                          <input
                                              type="radio"
                                              id="customRadioInline1"
                                              name="customRadioInline1"
                                              className="custom-control-input"
                                              onChange={this.handleChange}
                                              checked={
                                                  value === item.statusCode
                                                      ? true
                                                      : false
                                              }
                                              value={item.statusCode}
                                              {...item.attr}
                                          />
                                          <span className="custom-control-label">
                                              {item.statusText}
                                          </span>
                                      </div>
                                      <small className="d-block text-secondary mb-0">
                                          {item.description}
                                      </small>
                                  </label>
                              </div>
                          ))
                        : null}
                </Collapse>
            </div>
        );
    }
}
