import Axios from "axios";
import { authHeader } from "././AuthenticationActions";
import { config } from "../Helpers/Config";

export function getSettingsFetching(payload) {
    return {
        type: "GET_SETTINGS_FETCHING",
        payload,
    };
}

export function getSettings(groupId) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set posting true
        dispatch(getSettingsFetching(true));

        instance
            .get(`${config.apiUrl}/groups/${groupId}/settings`)
            .then(response => {
                dispatch({
                    type: "GET_SETTINGS",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log("Get settings error", error);

                dispatch({
                    type: "GET_SETTINGS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}

export function setSettingsPosting(payload) {
    return {
        type: "SET_SETTINGS_POSTING",
        payload,
    };
}

export function setSettings(payload) {
    let instance = Axios.create({
        timeout: 30000,
        headers: authHeader(),
    });

    return dispatch => {
        // Set posting true
        dispatch(setSettingsPosting(true));

        instance
            .post(`${config.apiUrl}/groups/${payload.groupId}/settings`, {
                type: payload.type,
                value: payload.value,
            })
            .then(response => {
                dispatch({
                    type: "SET_SETTINGS",
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log("Set settings error", error);

                dispatch({
                    type: "SET_SETTINGS_ERROR",
                    payload: true,
                });

                // Dispatch generic error to the errorHandler
                dispatch({ type: "ADD_ERROR", error: error });
            });
    };
}
