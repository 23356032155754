import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Icon from "./Icon";

// Helpers
import { config } from "../../Helpers";

class Subheader extends Component {
    render() {
        const LinkInner = () => {
            return (
                <>
                    <Icon
                        name="arrow_back"
                        style={{ fill: "var(--primary)" }}
                        fill="#007bff"
                    />

                    <span>{this.props.linkName}</span>
                </>
            );
        };

        return (
            <div className="container my-4 d-print-none">
                {/^https?:\/\//.test(this.props.linkTo) ? (
                    <a href={this.props.linkTo}>
                        <LinkInner />
                    </a>
                ) : (
                    <Link to={this.props.linkTo}>
                        <LinkInner />
                    </Link>
                )}
            </div>
        );
    }
}

Subheader.propTypes = {
    linkTo: PropTypes.string,
    linkName: PropTypes.string,
};

Subheader.defaultProps = {
    linkTo: config.universityAdminUrl,
    linkName: "University admin",
};

export default Subheader;
