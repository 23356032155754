import { createGlobalStyle } from "styled-components";

/**
 * Inject the global styles for the currently active Shop Theme
 */
export const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${props => props.theme.fontFamily};
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: ${props =>
        props.theme.fontFamilyHeaders
            ? props.theme.fontFamilyHeaders
            : props.theme.fontFamily};
  }
`;

export default GlobalStyle;
