import React from "react";
import AppShell from "../AppShell/AppShell";

export default class GroupSectionAppshell extends AppShell {
    renderShell(i) {
        return (
            <div className="shell col-sm-12 mb-2" key={`shell-${i}`}>
                <div className="group card h-100">
                    <div className="card-body p-3">
                        <div className="row justify-content-around align-items-center">
                            <div className="col-12 col-md-auto col-lg mb-3 mb-lg-0">
                                <span className="d-block shell__text" />
                                <span className="d-block shell__text" />
                            </div>

                            <div className="col-12 col-sm group__name">
                                <strong className="shell__text" />
                            </div>

                            <div className="col-12 col-md-auto col-lg mb-3 mb-lg-0">
                                <span className="shell__text" />
                            </div>

                            <div className="col-12 col-sm">
                                <span className="shell__text" />
                            </div>

                            <div className="col-12 col-sm">
                                <span className="shell__text" />
                            </div>

                            <div className="col-12 col-sm" />

                            <div className="col-12 col-lg-auto mt-3 mt-lg-0">
                                <span className="shell__btn" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="shell row mb-2">
                    <div className="col">
                        <span className="h2">
                            <span
                                className="shell__text"
                                style={{
                                    "--width": 45 + "%",
                                }}
                            />
                        </span>
                    </div>
                </div>

                <div className="row mb-4">{this.createAppShell(10)}</div>
            </>
        );
    }
}
