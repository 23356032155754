export default class Number {
    /**
     * Returns a random number in the given min-max range
     * @param {Number} min
     * @param {Number} max
     * @returns {Number}
     */
    static random = (min, max) =>
        Math.floor(Math.random() * (max - min + 1) + min);
}
