import React, { Component } from "react";
import PropTypes from "prop-types";
import LazyImage from "../../Components/Common/LazyImage";

class ButtonLogo extends Component {
    render() {
        const { logo } = this.props;
        // TODO Optimize logo images when they are served from imgix
        return (
            <button
                type="button"
                className={`btn placer__logo ${
                    this.props.active ? "placer__logo--active" : ""
                }`}
                aria-pressed={this.props.active}
                onClick={() => this.props.onClick()}>
                <LazyImage
                    src={logo.url ? logo.url : logo.preview}
                    alt={logo.alt ? logo.alt : logo.name}
                    width={253}
                    height={144}
                    className="img-fluid"
                />
            </button>
        );
    }
}

ButtonLogo.propTypes = {
    logo: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

ButtonLogo.defaultProps = {};

export default ButtonLogo;
