export const config = {
    apiUrl: process.env.REACT_APP_API_URL,
    universityAdminUrl: process.env.REACT_APP_UNIVERSITY_ADMIN_URL,
    communityUrl: process.env.REACT_APP_COMMUNITY_URL,
    communityLoginUrl: process.env.REACT_APP_COMMUNITY_LOGIN_URL,
    feedUrl: process.env.REACT_APP_FEED_URL,
    smsInvitationUrl: process.env.REACT_APP_SMS_INVITATION_URL,
};

export default config;
