import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

// Components
import Fabric from "./components/Fabric";
import PlacementSettings from "./PlacementSettings";
import PlacerNav from "./PlacerNav";

// Helpers
import { PlacerHelper } from "../Helpers";
import UnitNav from "./UnitNav";

export default class PlacementCreator extends Component {
    render() {
        const { state, products, groupLogo } = this.props;

        return (
            <div
                className={`placer container mb-3 ${
                    state.showOverlays ? "show" : "hide"
                }-overlays`}>
                <PlacerNav {...this.props} to="/placement/" />

                <div className="row justify-content-center">
                    <div className="col-12 col-md-auto col-lg-6">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <div className="editor">
                                    <Fabric
                                        ref={this.props.editor}
                                        canvas={this.props.canvas}
                                        product={state.product}
                                        width={state.width}
                                        height={state.height}
                                        fabric={this.props.fabric}
                                        typeName={state.typeName}
                                        imageId={state.imageId}
                                        activePlacementHandler={
                                            this.props.handleActivePlacement
                                        }
                                        handlePlacementProps={
                                            this.props.handlePlacementProps
                                        }
                                        placements={state.placements}
                                    />

                                    {state.imageLoading ? (
                                        <div className="editor__loading">
                                            Laster bilder...
                                        </div>
                                    ) : null}

                                    {!this.props.itemHasImages() ? (
                                        <div className="editor__empty">
                                            <h3 className="h5 d-block">
                                                {state.unit
                                                    ? "Enheten"
                                                    : "Produktet"}{" "}
                                                har ingen bilder
                                            </h3>{" "}
                                            <Link to="/images">
                                                Gå til "Bilder og plasseringer"
                                            </Link>{" "}
                                            for å laste opp bilder
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        {this.props.itemHasImages() ? (
                            <div className="p-3 btn-group btn-group-toggle flex-wrap">
                                {PlacerHelper.renderTypeRadioButtons(
                                    this.props
                                )}
                            </div>
                        ) : null}
                    </div>

                    <div className="col-12 col-md col-lg-5 mt-3 mt-lg-0">
                        <div className="controls">
                            <h1 className="placer__name h2 mb-3">
                                {PlacerHelper.getItemName(state)}
                            </h1>

                            <p>
                                Plasseringer som hører til produktet brukes
                                dersom det ikke finnes plasseringer for enheter.
                                {/* <small className="d-block">
                                    Alle enheter må ha bilder for å kunne
                                    opprette plasseringer. Vises ikke enheten i
                                    listen under må bildet lastes opp på nytt.
                                </small> */}
                            </p>
                            <UnitNav
                                to="/placement/"
                                match={this.props.match}
                                state={state}
                                products={products}
                            />

                            {this.props.itemHasImages() ? (
                                <>
                                    <p>
                                        Klikk "Legg til plassering" for å
                                        definere en logoplassering.{" "}
                                        <small className="d-block">
                                            Logoplasseringen vises som et
                                            rektangel på bildet.
                                        </small>
                                    </p>

                                    <Button
                                        color="primary"
                                        className="mr-2 mb-2"
                                        onClick={this.props.placementAdd}>
                                        {this.props.text.placementAdd}
                                    </Button>

                                    {this.props.itemHasPlacements() ? (
                                        <Button
                                            color="danger"
                                            className="mr-2 mb-2"
                                            onClick={
                                                this.props.placementRemove
                                            }>
                                            {this.props.text.placementRemove}
                                        </Button>
                                    ) : null}
                                </>
                            ) : null}

                            {this.props.itemHasImages() &&
                            this.props.itemHasPlacements() ? (
                                <PlacementSettings
                                    state={state}
                                    groupLogo={this.props.groupLogo}
                                    getAvailablePlacementTypes={
                                        this.props.getAvailablePlacementTypes
                                    }
                                    handleActivePlacementType={
                                        this.props.handleActivePlacementType
                                    }
                                    activePlacement={state.activePlacement}
                                />
                            ) : null}

                            <div>
                                {this.props.itemHasImages() ? (
                                    <>
                                        <Button
                                            color="primary"
                                            className="mr-2 mb-2"
                                            disabled={
                                                groupLogo.placementsPosting
                                                    ? true
                                                    : false
                                            }
                                            onClick={this.props.savePlacements}>
                                            {groupLogo.placementsPosting
                                                ? this.props.text.saving
                                                : this.props.text.save}
                                        </Button>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
