// Dependencies
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Collapse, Button } from "reactstrap";

// Components
import ButtonLogo from "./components/ButtonLogo";
import ButtonToggle from "./components/ButtonToggle";
import LogoPlacement from "./components/LogoPlacement";
import Fabric from "./components/Fabric";
import PlacerNav from "./PlacerNav";
//import FormGroup from "./components/FormGroup";

// Helpers
import { PlacerHelper, LogoHelper } from "../Helpers";
import UnitNav from "./UnitNav";

class LogoPlacer extends Component {
    constructor(props) {
        super(props);

        this.state = { collapse: false };
        this.toggleCollapse = this.toggleCollapse.bind(this);
        //this.sizeUpdate = this.sizeUpdate.bind(this);
    }

    toggleCollapse() {
        this.setState(state => ({ collapse: !state.collapse }));
    }

    /**
     * Sets the size state for a product
     */
    //TODO: Implement when api supports logo CM / print sizes
    /*
    sizeUpdate(settings) {
        let newProductState = { ...this.state.product };
        newProductState.sizes[settings.key] = settings.value;

        this.setState({
            product: newProductState,
        });
    }
    */

    renderPlacements() {
        const { state } = this.props;

        // If logoPlacement is in state, do not show this
        if (state.placements && state.placements.length) {
            return state.placements.map((placement, i) => {
                const foundActivePlacement = state.logoPlacements.find(
                    logoPlacement =>
                        logoPlacement.imageId === placement.imageId &&
                        logoPlacement.placementType &&
                        placement.placementType &&
                        logoPlacement.placementType.id ===
                            placement.placementType.id
                );
                const active = foundActivePlacement ? false : true;
                return (
                    <LogoPlacement
                        key={`placeholder-${i}`}
                        active={active}
                        imageId={state.imageId}
                        placement={placement}
                        id={placement.id}
                        top={placement.y}
                        left={placement.x}
                        angle={placement.rotation}
                        width={placement.width}
                        height={placement.height}
                        onClick={() => this.props.logoAdd(placement, true)}
                    />
                );
            });
        }
    }

    renderLogoButtons() {
        // Echo possible error messages or that there is no dataz etc
        if (this.props.groupLogo.groupLogosError)
            return <div className="col">Kunne ikke laste logoer</div>;

        // Echo appshell while loading dataz
        if (this.props.groupLogo.groupLogosFetching === true)
            return <div className="col">Laster logoer...</div>;

        // Fetching is done and we got dataz
        if (
            this.props.groupLogo.groupLogosFetching === false &&
            this.props.groupLogo.groupLogos &&
            this.props.groupLogo.groupLogos.length
        ) {
            const { groupLogo } = this.props;

            let sortedLogos = {};

            // Sort logos into groups for the darkness type
            groupLogo.groupLogos.forEach((logo, i) => {
                if (!sortedLogos[logo.darknessTypeName]) {
                    sortedLogos[logo.darknessTypeName] = [];
                }

                sortedLogos[logo.darknessTypeName].push(logo);
            });

            // @consideration Sort the sortedLogos in this order -> Both, dark, light
            return Object.entries(sortedLogos).map(([id, group]) => {
                const selectedLogo = this.props.getSelectedLogoButton();
                let title = (
                    <h3 className="h6 mb-3 col-12">
                        {this.props.getLogoGroupTitle(group)}
                    </h3>
                );

                return (
                    <React.Fragment key={`group-${id}`}>
                        {title}
                        {group.map((logo, i) => (
                            <div key={`logo-${i}`} className="col-6 mb-4">
                                <ButtonLogo
                                    logo={logo}
                                    active={
                                        selectedLogo &&
                                        selectedLogo.id === logo.id
                                            ? true
                                            : false
                                    }
                                    onClick={() =>
                                        this.props.logoButtonToggleActive(logo)
                                    }
                                />
                                <div className="d-block mt-1">
                                    <small
                                        className="font-weight-bold"
                                        title="Logoen settes inn automatisk på følgende plasseringer">
                                        Plasseringer:
                                    </small>
                                    {logo.placementTypes &&
                                    logo.placementTypes.length ? (
                                        logo.placementTypes.map(type => (
                                            <span
                                                className="m-1 badge badge-primary"
                                                key={type.id}>
                                                {LogoHelper.getPlacementName(
                                                    type.name
                                                )}
                                            </span>
                                        ))
                                    ) : (
                                        <span className="m-1 badge badge-secondary">
                                            Ingen plasseringer
                                        </span>
                                    )}
                                </div>
                                {logo.textileTypeName !== "Normal" ? (
                                    <div className="d-block mt-1">
                                        <span className="badge badge-info">
                                            {this.props.getLogoTextileTypeName(
                                                logo
                                            )}
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                        ))}
                    </React.Fragment>
                );
            });
        }
    }

    renderPlacerAlert() {
        const { state } = this.props;
        let title;
        let text;
        let item = state.unit ? "Enheten" : "Produktet";

        if (this.props.itemHasPlacements() && !this.props.itemHasImages()) {
            title = `${item} har ingen bilder`;
            text = "for å laste opp bilder";
        } else if (
            !this.props.itemHasPlacements() &&
            this.props.itemHasImages()
        ) {
            title = `${item} har ingen plasseringer`;
            text = "for å legge til plasseringer";
        } else if (
            !this.props.itemHasPlacements() &&
            !this.props.itemHasImages()
        ) {
            title = `${item} har ingen bilder eller plasseringer`;
            text = "for å håndtere bilder og plasseringer";
        }

        if (title && text) {
            return (
                <div className="editor__empty">
                    <h3 className="h5 d-block">{title}</h3>{" "}
                    <Link to="/images">Gå til "Bilder og plasseringer"</Link>{" "}
                    {text}
                </div>
            );
        }

        return null;
    }

    render() {
        const { state, products, parentThisRef, groupLogo } = this.props;
        return (
            <div
                className={`placer container mb-3 ${
                    state.showOverlays ? "show" : "hide"
                }-overlays`}>
                <PlacerNav
                    {...this.props}
                    to={`/group/${this.props.group.group.id}/placement/`}
                />

                <div className="row justify-content-center">
                    <div className="col-12 col-md-auto col-lg-6">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <div className="editor">
                                    <Fabric
                                        ref={this.props.editor}
                                        canvas={this.props.canvas}
                                        product={state.product}
                                        width={state.width}
                                        height={state.height}
                                        fabric={this.props.fabric}
                                        typeName={state.typeName}
                                        imageId={state.imageId}
                                        handleLogoProps={
                                            this.props.handleLogoProps
                                        }
                                        selectedLogoHandler={
                                            this.props.selectedLogoHandler
                                        }
                                    />

                                    {state.imageLoading ? (
                                        <div className="editor__loading">
                                            Laster bilder...
                                        </div>
                                    ) : null}

                                    {this.renderPlacerAlert()}

                                    {this.props.itemHasPlacements() ? (
                                        <div className="editor__placeholders">
                                            {this.renderPlacements()}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        {this.props.itemHasImages() ? (
                            <div className="p-3 btn-group btn-group-toggle flex-wrap">
                                {PlacerHelper.renderTypeRadioButtons(
                                    this.props
                                )}
                            </div>
                        ) : null}

                        {this.props.itemHasImages() &&
                        this.props.itemHasPlacements() ? (
                            <div className="row mt-3 justify-content-center">
                                <ButtonToggle
                                    priority="secondary"
                                    active={state.showOverlays}
                                    activeText={this.props.text.activeText}
                                    inactiveText={this.props.text.inactiveText}
                                    onClick={() =>
                                        PlacerHelper.overlaysToggle(
                                            parentThisRef
                                        )
                                    }
                                />
                            </div>
                        ) : null}
                    </div>

                    <div className="col-12 col-md col-lg-5 mt-3 mt-lg-0">
                        <div className="controls">
                            <h1 className="placer__name h2 mb-3">
                                {PlacerHelper.getItemName(state)}
                            </h1>

                            <UnitNav
                                to={`/group/${this.props.group.group.id}/placement/`}
                                match={this.props.match}
                                state={state}
                                products={products}
                            />

                            <h2 className="h3 mb-2">{this.props.text.logos}</h2>

                            <Button
                                className="mb-2"
                                color="primary"
                                size="sm"
                                onClick={this.toggleCollapse}>
                                {this.state.collapse
                                    ? "Skjul hjelp"
                                    : "Vis hjelp"}
                            </Button>

                            <Collapse
                                className="mb-2"
                                isOpen={this.state.collapse}>
                                <div>
                                    <h3 className="h4">
                                        Automatisk plassering av logoer
                                    </h3>
                                    <p>
                                        Logoer blir automatisk plassert på
                                        bildene dersom det finnes felter som er
                                        like som de "tillatte plasseringene" til
                                        logoen.
                                        <small className="d-block">
                                            Gå tilbake til innstillinger for å
                                            endre "Tillatte plasseringer på
                                            plagg" for logoer.
                                        </small>
                                    </p>
                                    <h3 className="h4">
                                        Manuell plassering av logoer
                                    </h3>
                                    <p>
                                        For å legge til en logo som ikke har
                                        blitt plassert automatisk gjør man
                                        følgende:
                                    </p>
                                    <ol>
                                        <li>Klikk på ønsket logo</li>
                                        <li>
                                            Klikk på et ledig felt (+) på et
                                            bilde
                                        </li>
                                    </ol>
                                </div>
                            </Collapse>

                            <div className="row">
                                {this.renderLogoButtons()}
                            </div>

                            {/* 
                                TODO: Implement when api supports logo CM / print sizes
                                <FormGroup
                                id="cmBig"
                                size="big"
                                title={this.props.forms.big.title}
                                placeholder={this.props.forms.big.placeholder}
                                value={state.product.sizes.big}
                                valueHandler={this.sizeUpdate}
                            />

                            <FormGroup
                                id="cmSmall"
                                size="small"
                                title={this.props.forms.small.title}
                                placeholder={this.props.forms.small.placeholder}
                                info={this.props.forms.small.info}
                                value={state.product.sizes.small}
                                valueHandler={this.sizeUpdate}
                            /> */}

                            <div>
                                {this.props.itemHasPlacements() ? (
                                    <div className="d-block">
                                        <Button
                                            color="danger"
                                            className="mr-2 mb-2"
                                            onClick={() =>
                                                this.props.logoReset()
                                            }>
                                            {this.props.text.deleteLogo}
                                        </Button>
                                        <small className="d-block mb-2 text-secondary">
                                            Sletter en valgt logo fra bildet.
                                        </small>

                                        <Button
                                            color="danger"
                                            className="mr-2 mb-2"
                                            onClick={() =>
                                                this.props.logoResetAllToDefault()
                                            }>
                                            {this.props.text.resetToDefault}
                                        </Button>
                                        <small className="d-block mb-2 text-secondary">
                                            Sletter alle logoer som er plassert
                                            på bildet, og legger inn
                                            standardlogoer for alle
                                            plasseringer. Gjelder alle bilder
                                            for{" "}
                                            {state.unit
                                                ? "enheten"
                                                : "produktet"}
                                            .
                                        </small>
                                    </div>
                                ) : null}

                                {this.props.itemHasImages() &&
                                this.props.itemHasPlacements() ? (
                                    <div className="d-block">
                                        <Button
                                            color="primary"
                                            className="mr-2 mb-2"
                                            disabled={
                                                groupLogo.logoPlacementsPosting
                                                    ? true
                                                    : false
                                            }
                                            onClick={() =>
                                                this.props.saveLogoPlacements()
                                            }>
                                            {groupLogo.logoPlacementsPosting
                                                ? this.props.text.saving
                                                : this.props.text.save}
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LogoPlacer;
