import React from "react";
import { Modal, ModalHeader, ModalBody, Collapse, Button } from "reactstrap";
import Slider from "react-slick";

// Components
import SliderNextArrow from "../Slider/SliderNextArrow";
import SliderPrevArrow from "../Slider/SliderPrevArrow";
import Product from "./Product";
import ProductCustomer from "./ProductCustomer";
import LazyImage from "../Common/LazyImage";
import Icon from "../Common/Icon";
import Restrict from "../../Auth/Restrict";

// Helpers
import { Manager } from "../../Auth/UserRoles";
import { Utils, CustomerHelper, CartHelper, ImageHelper } from "../../Helpers";

class ProductCard extends Product {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            collapse: this.props.collapse,
            item: null,
        };

        this.toggleLightBox = this.toggleLightBox.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.renderCustomerProducts = this.renderCustomerProducts.bind(this);
        this.renderCommunalProduct = this.renderCommunalProduct.bind(this);
    }

    componentDidMount() {
        // Set the item to get images from in the state,
        // so it won't get replaced if sorted or otherwise manipulated
        if (!this.state.item) {
            const item = this.getImageItem();
            this.setState({ item });
        }
    }

    toggleCollapse() {
        this.props.handleCollapse();
    }

    // Toggle the lightbox and go to the correct slide
    toggleLightBox(index) {
        this.setState(
            {
                modal: !this.state.modal,
            },
            () => {
                if (this.state.modal && this.slider) {
                    this.slider.slickGoTo(index);
                }
            }
        );
    }

    renderSlider() {
        const { product } = this.props;
        const item = this.state.item;
        const images = ImageHelper.sortImages(item);
        // const displayLogo = GroupHelper.getNormalizedSetting(
        //     this.props,
        //     "CanDisplayLogo"
        // );

        let settings = {
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 2,
            slidesToScroll: 1,
            nextArrow: <SliderNextArrow fill={"#212529"} />,
            prevArrow: <SliderPrevArrow fill={"#212529"} />,
        };
        let size = {
            width: 538,
            height: 424,
        };

        if (item && images && images.length >= 2) {
            size = {
                width: 269,
                height: 424,
            };
        }

        if (item && images && images.length) {
            if (images.length > 2) {
                return (
                    <Slider {...settings}>
                        {images.map((image, i) => (
                            <button
                                type="button"
                                className="product__btn-image"
                                onClick={() => this.toggleLightBox(i)}
                                key={`product-button-${i}`}>
                                <LazyImage
                                    src={
                                        image.imgIxUrl
                                            ? image.imgIxUrl
                                            : image.url
                                    }
                                    alt={image.alt ? image.alt : product.name}
                                    width={size.width}
                                    height={size.height}
                                    className="img-fluid"
                                    productImage
                                />
                            </button>
                        ))}
                    </Slider>
                );
            } else {
                return (
                    <div className="row no-gutters">
                        {images.slice(0, 2).map((image, i) => (
                            <div className="col" key={`product-image-${i}`}>
                                <button
                                    type="button"
                                    className="product__btn-image"
                                    onClick={() => this.toggleLightBox(i)}>
                                    <LazyImage
                                        src={
                                            image.imgIxUrl
                                                ? image.imgIxUrl
                                                : image.url
                                        }
                                        alt={
                                            image.alt ? image.alt : product.name
                                        }
                                        width={size.width}
                                        height={size.height}
                                        className="img-fluid"
                                        productImage
                                    />
                                </button>
                            </div>
                        ))}
                    </div>
                );
            }
        }
    }

    renderLightBox() {
        const { product } = this.props;
        const item = this.state.item;
        const images = ImageHelper.sortImages(item);
        // const displayLogo = GroupHelper.getNormalizedSetting(
        //     this.props,
        //     "CanDisplayLogo"
        // );

        let settings = {
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SliderNextArrow fill={"#212529"} />,
            prevArrow: <SliderPrevArrow fill={"#212529"} />,
        };
        const size = {
            width: 1024,
            height: 768,
        };

        if (item && images && images.length) {
            if (images.length > 1) {
                return (
                    <Slider
                        ref={slider => (this.slider = slider)}
                        {...settings}>
                        {images.map((image, i) => (
                            <LazyImage
                                key={`lightBox-slide-${i}`}
                                src={
                                    image.imgIxUrl ? image.imgIxUrl : image.url
                                }
                                alt={image.alt ? image.alt : product.name}
                                width={size.width}
                                height={size.height}
                                className="img-fluid w-100"
                                productImage
                            />
                        ))}
                    </Slider>
                );
            } else {
                return (
                    <div className="row no-gutters">
                        {images.slice(0, 1).map((image, i) => (
                            <div className="col" key={`lightBox-image-${i}`}>
                                <LazyImage
                                    src={
                                        image.imgIxUrl
                                            ? image.imgIxUrl
                                            : image.url
                                    }
                                    alt={image.alt ? image.alt : product.name}
                                    width={size.width}
                                    height={size.height}
                                    className="img-fluid w-100"
                                    productImage
                                />
                            </div>
                        ))}
                    </div>
                );
            }
        }
    }

    getTotalCartItemsQty() {
        const { cart } = this.props.cart;
        const { product } = this.props;

        let foundUnits = cart.items.filter(item => {
            let foundUnit = product.units.find(unit => unit.id === item.unitId);
            if (foundUnit && item.unitId === foundUnit.id) {
                return item;
            }
            return null;
        });

        if (foundUnits && foundUnits.length) {
            return foundUnits.length;
        }

        return 0;
    }

    showMinimumQty() {
        const { product } = this.props;
        const representingAllUnits = product.units[0];
        const totalCartItemsQty = this.getTotalCartItemsQty();

        if (
            representingAllUnits.minimumQty &&
            representingAllUnits.minimumQty > totalCartItemsQty
        ) {
            return true;
        }
        return false;
    }

    isProductInCart(cart, product) {
        if (
            cart &&
            cart.length &&
            product &&
            product.units &&
            product.units.length
        ) {
            return cart.find(item =>
                product.units.find(unit => unit.id === item.unitId)
            )
                ? true
                : false;
        } else {
            return false;
        }
    }

    renderCustomerProducts() {
        const { product } = this.props;
        const self = CustomerHelper.getCustomers(this.props, "self");
        const customers = CustomerHelper.getCustomers(this.props, "others");

        /* If customer, only show other customers if they have this product in cart */
        if (
            self &&
            self.customerType &&
            self.customerType.name === "Customer" &&
            customers &&
            customers.length
        ) {
            const customersWithProductInCart = customers.filter(customer => {
                const customerCart = CartHelper.getCustomerCart(
                    this.props.cart.cart,
                    customer.customer
                );

                // Must check if a customer has an item with unitId that belongs to the current product in cart
                const itemInCart = this.isProductInCart(customerCart, product);

                if (itemInCart) {
                    return true;
                } else {
                    return false;
                }
            });

            if (
                customersWithProductInCart &&
                customersWithProductInCart.length
            ) {
                return customersWithProductInCart.map((customer, i) => {
                    return (
                        <ProductCustomer
                            {...this.props}
                            product={product}
                            isCommunal={false}
                            hasStock={this.hasStock()}
                            customer={customer}
                            className="row"
                            key={`product-${product.id}-customer-${customer.customer.id}`}
                        />
                    );
                });
            } else {
                return (
                    <div className="product__customer px-3 py-2">
                        Ingen andre medlemmer har dette produktet i
                        handlekurven...
                    </div>
                );
            }
        } else if (customers && customers.length) {
            return customers.map((customer, i) => {
                return (
                    <ProductCustomer
                        {...this.props}
                        product={product}
                        isCommunal={false}
                        hasStock={this.hasStock()}
                        customer={customer}
                        className="row"
                        key={`product-${product.id}-customer-${customer.customer.id}`}
                    />
                );
            });
        }

        return null;
    }

    renderCommunalProduct() {
        const { product } = this.props;
        const self = CustomerHelper.getCustomers(this.props, "self");

        /* If customer, only show communal items if items in cart */
        if (
            self &&
            self.customerType &&
            self.customerType.name === "Customer"
        ) {
            const communalItems = CartHelper.getCustomerCart(
                this.props.cart.cart
            );
            const itemInCart = this.isProductInCart(communalItems, product);

            if (itemInCart) {
                return (
                    <ProductCustomer
                        {...this.props}
                        product={product}
                        isCommunal={true}
                        hasStock={this.hasStock()}
                        className="row"
                    />
                );
            } else {
                return null;
            }
        } else {
            return (
                <ProductCustomer
                    {...this.props}
                    product={product}
                    isCommunal={true}
                    hasStock={this.hasStock()}
                    className="row"
                />
            );
        }
    }

    render() {
        const { product } = this.props;
        const representingAllUnits = product.units[0];
        const self = CustomerHelper.getCustomers(this.props, "self");

        return (
            <div className="product card h-100">
                <div className="card-body card-body--product">
                    <h5 className="card-title mb-0">
                        <div className="row">
                            <div className="col">{this.renderName(false)}</div>
                            <div className="col-auto">
                                {this.renderPrice(this.props.renderPrice)}
                            </div>
                        </div>
                    </h5>
                </div>

                <div className="card-img-top card-img-top--product">
                    <div>{this.renderSlider()}</div>

                    {this.renderStock()
                        ? this.renderStock("badge--product")
                        : null}
                </div>

                {product.description && product.description !== "" ? (
                    <div className="p-3 border-top">
                        {Utils.sanitizeString(product.description)}
                    </div>
                ) : null}

                {self ? (
                    <ProductCustomer
                        {...this.props}
                        self
                        product={product}
                        isCommunal={false}
                        hasStock={this.hasStock()}
                        customer={self}
                        className="row"
                    />
                ) : null}

                <div className="product__bottom mt-auto">
                    <Button
                        className="rounded-0 border-top border-bottom"
                        color="light"
                        block
                        onClick={this.toggleCollapse}>
                        {this.props.collapse ? (
                            <Icon
                                name="arrow_drop_up"
                                fill="currentColor"
                                title="Pil opp"
                            />
                        ) : (
                            <Icon
                                name="arrow_drop_down"
                                fill="currentColor"
                                title="Pil ned"
                            />
                        )}
                        {this.props.collapse
                            ? "Skjul alle medlemmer"
                            : "Vis alle medlemmer"}
                    </Button>

                    <Collapse isOpen={this.props.collapse}>
                        <div className="pt-3 pb-1 product__members">
                            <Restrict {...this.props} allow={Manager}>
                                <div className="row m-0 pb-2">
                                    <div className="col">
                                        <span className="font-weight-bold">
                                            Totalt antall {product.name}:{" "}
                                            {this.getTotalCartItemsQty()}
                                        </span>
                                    </div>

                                    {this.showMinimumQty() ? (
                                        <div className="col-auto">
                                            <span className="badge badge-danger">
                                                Minimum:{" "}
                                                {
                                                    representingAllUnits.minimumQty
                                                }
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                            </Restrict>

                            {this.renderCustomerProducts()}
                            {this.renderCommunalProduct()}
                        </div>
                    </Collapse>
                </div>

                {this.state.modal && (
                    <Modal
                        size="lg"
                        isOpen={this.state.modal}
                        toggle={this.toggleLightBox}
                        centered={true}>
                        <ModalHeader toggle={this.toggleLightBox}>
                            {this.renderName(false)}
                        </ModalHeader>
                        <ModalBody className="p-0">
                            {this.renderLightBox()}
                        </ModalBody>
                    </Modal>
                )}
            </div>
        );
    }
}

export default ProductCard;
