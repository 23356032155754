import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Components
import Errors from "./Errors";

class AlertHandler extends Component {
    render() {
        if (this.props.errors.errors && this.props.errors.errors.length) {
            return (
                <div className="alerthandler">
                    <div className="container">
                        <Errors {...this.props} className="d-block" />
                    </div>
                </div>
            );
        }

        return null;
    }
}

AlertHandler.propTypes = {
    statusCode: PropTypes.string,
    children: PropTypes.node,
};

AlertHandler.defaultProps = {};

function mapStateToProps(state) {
    return {
        errors: state.errors,
    };
}
export default connect(mapStateToProps)(AlertHandler);
