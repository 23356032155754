import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from 'prop-types';

// Components
import DataLayerGroup from "./DataLayerGroup";
import ErrorBoundary from "../Components/Common/ErrorBoundary";
import AlertHandler from "../Components/Common/AlertHandler";
import Subheader from "../Components/Common/Subheader";
import OrderPage from "../Components/Group/OrderPage";

// Helpers
import { TitleHelper } from "../Helpers";

class OrderOverview extends Component {
    componentDidMount() {
        TitleHelper.setTitle("Ordre");
    }

    render() {
        return (
            <DataLayerGroup {...this.props}>
                <ErrorBoundary>
                    <AlertHandler {...this.props} />
                    {this.props.group && this.props.group.group ? (
                        <Subheader
                            linkTo={`/group/${
                                this.props.group.group.id
                            }/overview/`}
                            linkName="Tilbake"
                        />
                    ) : null}

                    <main className="main" id="main">
                        <div className="container">
                            {this.props.group && this.props.group.group ? (
                                <OrderPage {...this.props} />
                            ) : null}
                        </div>
                    </main>
                </ErrorBoundary>
            </DataLayerGroup>
        );
    }
}

OrderOverview.propTypes = {};
OrderOverview.defaultProps = {};

function mapStateToProps(state) {
    return {
        group: state.group,
        errors: state.errors,
    };
}
export default connect(mapStateToProps)(OrderOverview);
