import { Utils } from "../Helpers";

let assign = Object.assign || require("object.assign");

const DEFAULT_STATE = {
    logoPlacements: [],
    logoPlacementsFetching: null,
    logoPlacementsPosting: null,
    logoPlacementsError: null,

    groupLogos: [],
    groupLogosFetching: null,
    groupLogosError: null,

    placements: {},
    placementsFetching: null,
    placementsPosting: null,
    placementsError: null,

    placementTypes: [],
    placementTypesFetching: null,
    placementTypesError: null,
};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case "GET_PLACEMENTS":
            return assign({}, state, {
                placements: action.payload,
                placementsFetching: false,
            });

        case "GET_PLACEMENTS_FETCHING":
            return assign({}, state, {
                placementsFetching: action.payload,
                placementsError: null,
            });

        case "GET_PLACEMENTS_ERROR":
            return assign({}, state, {
                placementsError: action.payload,
                placements: null,
                placementsFetching: null,
            });

        case "SET_PLACEMENTS":
            return assign({}, state, {
                placements: action.payload,
                placementsPosting: false,
            });

        case "SET_PLACEMENTS_POSTING":
            return assign({}, state, {
                placementsPosting: action.payload,
                placementsError: null,
            });

        case "SET_PLACEMENTS_ERROR":
            return assign({}, state, {
                placementsError: action.payload,
                placements: null,
                placementsPosting: null,
            });

        case "SET_LOGO_PLACEMENTS":
            return assign({}, state, {
                logoPlacements: state.logoPlacements,
                logoPlacementsPosting: false,
            });

        case "SET_LOGO_PLACEMENTS_POSTING":
            return assign({}, state, {
                logoPlacementsPosting: action.payload,
                logoPlacementsError: null,
            });

        case "SET_LOGO_PLACEMENTS_ERROR":
            return assign({}, state, {
                logoPlacementsError: action.payload,
                logoPlacements: null,
                logoPlacementsPosting: null,
            });

        case "GET_PLACEMENT_TYPES":
            return assign({}, state, {
                placementTypes: action.payload,
                placementTypesFetching: false,
            });

        case "GET_PLACEMENT_TYPES_FETCHING":
            return assign({}, state, {
                placementTypesFetching: action.payload,
                placementTypesError: null,
            });

        case "GET_PLACEMENT_TYPES_ERROR":
            return assign({}, state, {
                placementTypesError: action.payload,
                placementTypes: null,
                placementTypesFetching: null,
            });

        case "GET_LOGO_PLACEMENTS":
            return assign({}, state, {
                logoPlacements: action.payload,
                logoPlacementsFetching: false,
            });

        case "GET_LOGO_PLACEMENTS_FETCHING":
            return assign({}, state, {
                logoPlacementsFetching: action.payload,
                logoPlacementsError: null,
            });

        case "GET_LOGO_PLACEMENTS_ERROR":
            return assign({}, state, {
                logoPlacementsError: action.payload,
                logoPlacements: null,
                logoPlacementsFetching: null,
            });

        case "GET_GROUP_LOGOS":
            return assign({}, state, {
                groupLogos: action.payload,
                groupLogosFetching: false,
            });

        case "GET_GROUP_LOGOS_FETCHING":
            return assign({}, state, {
                groupLogosFetching: action.payload,
                groupLogosError: null,
            });

        case "GET_GROUP_LOGOS_ERROR":
            return assign({}, state, {
                groupLogosError: action.payload,
                groupLogos: null,
                groupLogosFetching: null,
            });

        case "UPLOAD_GROUP_LOGOS":
            // Flatten logs from different sources into one array
            let logos = Utils.flatten(action.payload);
            // Filter out only unique occurences of logos,
            // since we get "all" the existing logos at the point of each response
            logos = Utils.filterUniqueObjects(logos, "id");

            return assign({}, state, {
                groupLogos: logos,
                groupLogosFetching: false,
            });

        case "UPLOAD_GROUP_LOGOS_POSTING":
            return assign({}, state, {
                groupLogosFetching: action.payload,
                groupLogosError: null,
            });

        case "UPLOAD_GROUP_LOGOS_ERROR":
            return assign({}, state, {
                groupLogosError: action.payload,
                groupLogos: null,
                groupLogosFetching: null,
            });

        case "UPDATE_GROUP_LOGO":
            return assign({}, state, {
                groupLogos: [
                    ...state.groupLogos.filter(
                        item => item.id !== action.payload.id
                    ),
                    action.payload,
                ],
                groupLogosFetching: false,
            });

        case "UPDATE_GROUP_LOGO_POSTING":
            return assign({}, state, {
                groupLogosFetching: action.payload,
                groupLogosError: null,
            });

        case "UPDATE_GROUP_LOGO_ERROR":
            return assign({}, state, {
                groupLogosError: action.payload,
                groupLogos: null,
                groupLogosFetching: null,
            });

        case "DELETE_GROUP_LOGO":
            return assign({}, state, {
                groupLogos: state.groupLogos.filter(
                    image => image.id !== action.logoId
                ),
                groupLogosFetching: false,
            });

        case "DELETE_GROUP_LOGO_DELETING":
            return assign({}, state, {
                groupLogosFetching: action.payload,
                groupLogosError: null,
            });

        case "DELETE_GROUP_LOGO_ERROR":
            return assign({}, state, {
                groupLogosError: action.payload,
                groupLogos: null,
                groupLogosFetching: null,
            });

        default:
            return state;
    }
}
