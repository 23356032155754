import React from "react";
import AppShell from "../AppShell/AppShell";

class ProductSmallAppshell extends AppShell {
    renderShell(i) {
        return (
            <div className="shell mb-2 product card h-100" key={`shell-${i}`}>
                <div className="row">
                    <div
                        className="product__images col-3 mr-0 mb-4 mb-sm-0 col-md-2 col-lg-2"
                        style={{
                            maxWidth: 150 + "px",
                        }}>
                        <div className="row no-gutters">
                            <div className="col">
                                <img
                                    className="shell__img img-fluid"
                                    src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 680 1074'%2F%3E"
                                    alt="Laster bilde"
                                />
                            </div>
                            <div className="col">
                                <img
                                    className="shell__img img-fluid"
                                    src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 680 1074'%2F%3E"
                                    alt="Laster bilde"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="product__settings py-3 py-lg-3 col">
                        <div className="row px-3 mr-0 h-100 align-items-center">
                            <div className="col-12 col-lg mb-3 mb-lg-0">
                                <h3 className="h6 mb-0">
                                    <span className="shell__text font-weight-bold" />
                                </h3>
                            </div>

                            <div className="col">
                                <span className="shell__text badge badge-danger" />
                            </div>

                            <div className="col">
                                <span className="shell__text badge badge-danger" />
                            </div>

                            <div className="col-12 col-md-auto mt-3 mt-lg-0">
                                <div className="form-group row mb-0">
                                    <div className="col-sm-4" />
                                </div>
                            </div>

                            <div className="col col-sm-auto pr-0 mt-3 mt-lg-0">
                                <button
                                    type="button"
                                    className="shell__btn mr-sm-2"
                                />

                                <button type="button" className="shell__btn" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    render() {
        return (
            <>
                <div className="my-2">{this.createAppShell(6)}</div>
            </>
        );
    }
}

ProductSmallAppshell.propTypes = {};

ProductSmallAppshell.defaultProps = {};

export default ProductSmallAppshell;
